export function getUnit(title) {
    const titleMap = {
        'duration': 'h',
        'mileage': 'km',
        'speed': 'km/h'
    }
    let titleUnit = Object.keys(titleMap).find(key => title.includes(key));
    return titleUnit ? titleMap[titleUnit] : '';
}

export function round2(num) {
    return Math.round(num * 100) / 100
}