import { Table, Button, Modal } from 'antd';
import { useEffect, useState, useRef } from 'react';
import {PlusOutlined, DeleteOutlined, SettingOutlined, CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {Link, useHistory} from 'react-router-dom';
import http from '../../http';
import {useTranslation} from 'react-i18next'
import {useTable} from '../../hooks/table';

export default function Index() {
  const { t } = useTranslation();
  const {tableData, onSearch, pagination, loading} = useTable(getData);
  function getData() {
    return http.get(`/User?Page=${pagination.current}&PageSize=${pagination.pageSize}`)
  }
  const columns = [
    {
      title: '#',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: t("user.displayName"),
      dataIndex: 'displayName',
    },
    {
      title: t("user.login"),
      dataIndex: 'login',
    },
    {
      title: t("user.email"),
      dataIndex: 'email',
    },
    {
      title: t("user.role"),
      dataIndex: 'role',
      render: role => role?.name,
    },
    {
      title: t("common.action"),
      key: 'delete',
      render: item => 
        <div>
          <Button icon={<DeleteOutlined />} style={{marginRight: '10px'}} onClick={() => confirmDelete(item.id, item.displayName)}>{t('common.delete')}</Button>
          <Button icon={<SettingOutlined />} onClick={() => history.push(`/fleet/user-management/edit/${item.id}`)}>{t('common.edit')}</Button>
        </div>
        
    },
  ];
  const history = useHistory();
  const confirmDelete = (id, name) => {
    Modal.confirm({
      title: t('common.confirm'),
      content: `${t('common.confirmDelete')} ${t('user.user')} [${name}] ?`,
      cancelButtonProps: {
        type: 'default',
        icon: <CloseCircleOutlined />
      },
      okButtonProps: {
        type: 'default',
        icon: <CheckCircleOutlined />
      },
      onOk() {
        return http.delete(`/User/${id}`).then(onSearch);
      }
    });
  }
  return <div className="container">
    <div style={{marginBottom: '20px'}}>
      <Button icon={<PlusOutlined />}>
        <Link to="/fleet/user-management/add"> {t('user.addUser')}</Link>
      </Button>
    </div>
    <Table
      loading={loading}
      pagination={pagination}
      rowKey="id"
      columns={columns}
      dataSource={tableData} />
  </div> 
}
