import { useState, useEffect } from 'react';
import http from '../../http';
export const useTabs = () => {
    const [appTabList, setAppTabList] = useState([]);
    useEffect(() => {
        http.get('/aaa/Application?Page=-1').then(res => {
            setAppTabList(res.data)
        })
    }, [])
    return appTabList
}