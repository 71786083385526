import { Table, Row, Col, Button, DatePicker, Form, Input, Select } from 'antd';
import http from '../../http';
import {useTranslation} from 'react-i18next'
import {useTable} from '../../hooks/table';
import {useVerticalForm} from '../../hooks/form';
import moment from 'moment';
const { RangePicker } = DatePicker;

export default function Bills() {
    const { t } = useTranslation();
    const {tableData, pagination, loading, setQuery} = useTable(getData);
    function getData(param) {
      return http.get('/bill-mgm/vendorBills/search', { 
        pageNum: pagination.current,
        pageSize: pagination.pageSize, 
        ...param
      })
    }
    const columns = [
      {
        title: t("bills.plateNumber"),
        dataIndex: 'plateNumberEncrypt',
      },
      {
        title: t("bills.policyNumber"),
        dataIndex: 'policyNumber',
      },
      {
        title: t("bills.quarter"),
        dataIndex: 'quarter',
      },
      {
        title: t("bills.tenantId"),
        dataIndex: 'tenantId',
      },
      {
        title: t("bills.vendorName"),
        dataIndex: 'vendorName',
      },
      {
        title: t("bills.vin"),
        dataIndex: 'vinEncrypt',
      },
      {
        title: t("bills.deviceInstallDate"),
        dataIndex: 'deviceInstallDate',
        render: date => <span>{moment(date).format('YYYY-MM-DD')}</span>
      },
      {
        title: t("bills.contractStartDate"),
        dataIndex: 'contractStartDate',
        render: date => <span>{moment(date).format('YYYY-MM-DD')}</span>
      },
    ];
    const {form, formProps, colProps} = useVerticalForm();
    const handleSearch = (e) => {
      const values = {
        ...form.getFieldsValue(),
        deviceInstallDateFrom: '',
        deviceInstallDateTo: '',
        contractStartDateFrom: '',
        contractStartDateTo: ''
      }
      if (values.deviceInstallDate) {
        values.deviceInstallDateFrom = values.deviceInstallDate[0].startOf('day').valueOf();
        values.deviceInstallDateTo = values.deviceInstallDate[1].endOf('day').valueOf();
      };
      if (values.contractStartDate) {
        values.contractStartDateFrom = values.contractStartDate[0].startOf('day').valueOf();
        values.contractStartDateTo = values.contractStartDate[1].endOf('day').valueOf();
      }

      delete values.contractStartDate;
      delete values.deviceInstallDate;
      setQuery(values);
    }
    return <div className="container">
      <Form {...formProps} form={form} name="basic">
        <Row >
          <Col {...colProps}>
            <Form.Item label={t('bills.plateNumber')} name="plateNumber">
              <Input/>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={t('bills.vin')} name="vin">
              <Input/>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={t('bills.policyNumber')} name="policyNumber">
              <Input/>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={t('bills.quarter')} name="quarter">
              <Input/>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={t('bills.tenantId')} name="tenantId">
              <Input/>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={t('bills.vendorName')} name="vendorName">
              <Input/>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={t('bills.deviceInstallDate')} name="deviceInstallDate">
              <RangePicker style={{width: '100%'}} placeholder={null}/>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={t('bills.contractStartDate')} name="contractStartDate">
              <RangePicker style={{width: '100%'}} placeholder={null}/>
            </Form.Item>
          </Col>
            <Button style={{marginBottom: '32px'}} onClick={handleSearch}>{t('common.search')}</Button>
        </Row>
      </Form>
      <Table
        loading={loading}
        pagination={pagination}
        rowKey="id"
        columns={columns}
        dataSource={tableData} />
    </div> 
  }