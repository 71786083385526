import { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart, LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash';

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

const useCampare = (value, compare) => {
  const ref = useRef(null);

  if (!compare(value, ref.current)) {  // deep compare
    ref.current = value;
  }

  return ref.current;
}

const RiskAnalysisChart = ({ chartList=[], selectParams, groupUUID, Units }) => {
  console.log('chartList', chartList, selectParams)
  const { t } = useTranslation();
  const uid = groupUUID || 0

  function initChartRender (list) {
    const { xAxis, count, Value } = list.reduce((p, v) => {
      p.xAxis.push(v.Feature)
      p.count.push(v.count)
      if(selectParams.strValue === 'LossRatio' || selectParams.strValue === 'Frequency'){
        p.Value.push(v.Value * 100)
      }else{
        p.Value.push(v.Value)
      }
      return p
    }, { xAxis: [], count: [], Value: [] })
    const chartDom = document.getElementById('main'+uid);
    const myChart = echarts.init(chartDom);
    const colors = ['#1455B4', '#91C8FF'];
    const option = {
      color: colors,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      grid: {
        right: '20%',
        y2: 120,
      },
      legend: {
        data: [t(`vehicleRisk.Vehicle`), t(`OnewayLossFeature.${selectParams.strValue}`)]
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          axisLabel:{
            rotate: 15
          },
          // prettier-ignore
          data: xAxis
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: t(`vehicleRisk.Vehicle`),
          min: 0,
          position: 'right',
          axisLine: {
            show: true,
          }
        },
        {
          type: 'value',
          name: t(`OnewayLossFeature.${selectParams.strValue}`) + (Units.UnitY !== '-' ? ` (${t(`units.${Units.UnitY}`)})` : ''),
          min: 0,
          position: 'left',
          axisLine: {
            show: true,
          },
          axisLabel: {
            formatter: (selectParams.strValue === 'LossRatio' || selectParams.strValue === 'Frequency') ?  '{value} %' : '{value}'
          }
        }
      ],
      series: [
        {
          name: t(`vehicleRisk.Vehicle`),
          type: 'bar',
          data: count
        },
        {
          name: t(`OnewayLossFeature.${selectParams.strValue}`),
          type: 'line',
          yAxisIndex: 1,
          data: Value
        }
      ]
    };
    myChart.setOption(option);
  }

  const compareObject = useCampare(chartList, isEqual);
  useEffect(() => {
    if(chartList.length){
      initChartRender(chartList)
    }
  }, [compareObject])

  return <div id={`main${uid}`} style={{ height: 600, width: 1200, margin: '0 auto' }}></div>
}

export default RiskAnalysisChart;