import { useEffect, useState } from "react";
import http from "../http";

export function useUserName(token) {
    const [userName, setUserName] = useState('');
    useEffect(() => {
        if (!token) return;
        http.get('/aaa/User/my').then(res => {
            setUserName(res.displayName)
        })
    }, [token])
    return {
        userName
    }
}