import { useEffect, useState } from "react";
import http from '../http';

export function useInsuranceList() {
    const [insuranceList, setInsuranceList] = useState([]);
    useEffect(() => {
        http.get('/system-setting/common/insurer').then(res => {
            setInsuranceList(res.map(one => {
                return {
                  label: one.value,
                  value: one.id
                }
              }));
        })
    }, [])
    return {
        insuranceList
    }
}
