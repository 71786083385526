import { Spin } from 'antd';
import { useState } from 'react';
import http from '../../../../http';
import { useEffect } from 'react'
import DatabaseChart from './databaseChart'



export default function PreviewDatabase ({serchParams}) {
  // default dispaly table head
  const [chartLoading, setChartLoading] = useState(true)
  const [chartData, setChartData] = useState({})

  const onFormSearch = async () => {
    setChartLoading(true)
    // let paramas = {
    //   strDataTable: serchParams.strDataTable,
    //   strColumns: serchParams.strColumns,
    //   strDateFrom: serchParams.startTimestamp,
    //   strDateTo: serchParams.endTimestamp
    // }
    // let res = await http.get('/recommendationapi/Recommendation/GetDBTable', paramas)
    // console.log('res---', res)
    setChartData([{Feature: 1, count: 23},{Feature: 1, count: 122},{Feature: 1, count: 332}])
    setChartLoading(false)
  }


  useEffect(() => {
    onFormSearch()
  }, [])

  return <div>
    <Spin spinning={chartLoading}>
      <DatabaseChart chartList={chartData} selectParams={{strFeature: 'AA'}}/>
    </Spin>
  </div>
}