import {useTranslation} from 'react-i18next'
import {Dropdown, Menu} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useState } from "react";

import moment from 'moment';
import 'moment/locale/zh-cn';
import {useWindowSize} from '../../hooks/useWindow';
import { useEffect } from 'react';

export const I18nMenu = () => {
    const {width: windowWidth} = useWindowSize();
    const [language, setLanguage] = useState(window.localStorage.language === 'cn'? '简体中文': 'English');

    useEffect(() => {
        setLanguage(window.localStorage.language === 'cn'
            ? windowWidth > 960 ? '简体中文': '中文'
            : windowWidth > 960 ? 'English': 'En')
    }, [windowWidth])
    const { i18n } = useTranslation();
    function onI18nClick({key}) {
        window.localStorage.language = key;
        i18n.changeLanguage(key);
        setLanguage(key === 'cn' 
            ? windowWidth > 960 ? '简体中文': '中文'
            : windowWidth > 960 ? 'English': 'En')
        moment.locale(key === 'cn' ? 'zh-cn' : 'en');
    }; 

    const menu = (
        <Menu onClick={onI18nClick}>
            <Menu.Item key="en">English</Menu.Item>
            <Menu.Item key="cn">简体中文</Menu.Item>
        </Menu>
    )
    return (
        <Dropdown overlay={menu}>
            <a className="ant-dropdown-link"  style={{fontSize: '16px', marginRight: '10px', color: '#000'}} onClick={e => e.preventDefault()}>{language} <DownOutlined /></a>
        </Dropdown>
    )
}