import { useEffect, useState , useRef} from 'react';
import i18n from '../locales/i18n';
export const useTableWithLocalPagination = getData => {
    const [tableData, setTableData] = useState(null);
    const [totalData, setTotalData] = useState([]);
    const [total, setTotal] = useState(10);
    const [loading, setLoading] = useState(false);
    const [param, setParam] = useState({});
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    function setQuery(param) {
        setParam(param);
        setPagination({
            current: 1,
            pageSize: pagination.pageSize,
        })
    }
    function setCurrentData(data) {
        const {current, pageSize} = pagination;
        setTableData(data || totalData.slice((current - 1) * pageSize, current * pageSize));
    }
    useEffect(() => {
        setLoading(true);
        getData().then(res => {
            setTotalData(res);
            setTotal(res.length);
            setCurrentData(res);
        }).finally(() => {
            setLoading(false);
        });
    }, []);
    useEffect(() => {
        const filterData = totalData.filter(item => {
            return Object.keys(param).every(key => {
                if (!param[key]) return true;
                return item[key] && item[key].includes(param[key]);
            })
        })
        setTotal(filterData.length);
        setCurrentData(filterData);
    }, [param, pagination])
    return {
        setQuery,
        tableData,
        loading,
        pagination : {
            ...pagination,
            total,
            itemRender,
            onChange(page, pageSize) {
                setPagination({
                    current: page,
                    pageSize,
                });
            }
        },
    }
}
export const useTable = (getData, init = true) => {
    const [tableData, setTableData] = useState(null);
    const [total, setTotal] = useState(10);
    const [loading, setLoading] = useState(false);
    const [param, setParam] = useState({});
    const [initFlag, setInitFlag] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    function onPageChange(page, pageSize) {
        setPagination({
            current: page,
            pageSize,
        });
    }
    useEffect(() => {
        if (!init && !initFlag) {
            setInitFlag(true);
        } else {
            onSearch();
        }
    }, [pagination]);
    
    function setQuery(param) {
        setParam(param);
        setPagination({
            current: 1,
            pageSize: pagination.pageSize,
        })
    }
    function onSearch(searchParam) {
        setLoading(true);
        return getData(searchParam || param).then(res => {
            const data = res.data || res.list;
            const totalCount = res.totalCount || res.total
            setTableData(data);
            setTotal(totalCount);
        }).finally(() => {
            setLoading(false);
        });
    };
    const handleTableChange = (pagination, filters, sorter) => {
        const map = {
            'ascend': true,
            'descend': false
        }
        setQuery({...param, orderBy: sorter.field, up: map[sorter.order]});
        setPagination(pagination)
    };
    return {
        query: param,
        setQuery,
        tableData,
        onSearch,
        loading,
        handleTableChange,
        pagination : {
            ...pagination,
            total,
            onChange: onPageChange,
            itemRender
        },
    }
}

function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return <a style={{marginRight: '8px'}}>{i18n.t('common.prev')}</a>;
    }
    if (type === 'next') {
      return <a style={{marginLeft: '8px'}}>{i18n.t('common.next')}</a>;
    }
    return originalElement;
  }
  