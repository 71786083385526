import { Alert, Row, Col, Button, Spin, Modal, Form, Input, Select, DatePicker, Checkbox } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons'
import http, { onBlobExport, downloadBlob } from '../../http';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next'
// import { useTable } from '../../hooks/table';
// import { useVerticalForm } from '../../hooks/form';
// import moment from "moment";
// import { useDealList } from '../../data/deal';
// import { useInsuranceList } from '../../data/insurer';
// import { FileUpload } from './components/Upload';
import PolicyHistory  from './components/PolicyHistory';
import BillHistory from './components/BillHistory';
import ClaimHistory from './components/ClaimHistory';
import WarningInfo from './components/WarningInfo';
import {initMap} from './amap';
import './index.css';
import AgreeCheck from './components/AgreeCheck';
import {usePlateNumberVin} from './data';
const vehicleKeys = [
    'guidancePrice',
    'marketDate',
    'cph',
    'wheelBase',
    'shaft',
    'height',
    'type',
    'width',
    'engine',
    'insuranceDate',
    'cc',
    'properties',
    'carriagenumber',
    'manufacturingDate',
    'retirementDate',
    'validityDayEnd',
    'vin',
    'color',
    'frontwheelbase',
    'registerDate',
    'engineModel',
    'ratedquality',
    'emissions',
    'brand',
    'carModel',
    'vehiclemodel',
    'totalquality',
    'passengers',
    'styleName',
    'length',
    'curbweight',
    'transmissionType',
    'fueltypes',
    'vehiclename',
    'modelYear',
    'rearwheelbase',
    'power',
    'demio',
    'vehicleStatus'
]
const { RangePicker } = DatePicker;


const AccidentInquire = () => {
    const [form] = Form.useForm();
    const [vehicleInfoForm] = Form.useForm();
    const [vehicleModalVisible, setVehicleModalVisible] = useState(false);
    const [loading ,setLoading] = useState(false);
    const [warningParams, setWarningParams] = useState(null);
    const [formValues, setFormValues] = useState({vin: '', plateNumber: ''});
    const { t } = useTranslation();
    const {vinList, plateNumberList, loading: selectLoading} = usePlateNumberVin();
    const [dates, setDates] = useState([]);
    const disabledDate = current => {
        let beforeSelectMax = moment().subtract(2, 'days');
        if (!dates || dates.length === 0 || (dates[0] && dates[1])) {
            return current && (current > beforeSelectMax);
        }

        let afterSelectMin = moment(dates[0]).subtract(2, 'days');
        let afterSelectMax = moment(dates[0]).add(2, 'days');
        return current && (current > beforeSelectMax || current > afterSelectMax || current < afterSelectMin)
    }
    const initTrips = (vin , plateNumber, date) => {
        const handleClick =  (warningId, timestamp) => {
            const warningParams = {
                plateNumber,
                vin,
                warningId,
                timestamp
            }
            setWarningParams(warningParams);
        }
        const tripParams = {
            vin,
            plateNumber,
            startTimestamp: date ? date[0].startOf('day').valueOf() : '',
            endTimestamp: date ? date[1].endOf('day').valueOf(): '',

        }
        return http.get('/accident/trips', tripParams).then(res => {
            initMap('vehicleTrip',res, {onMarkerClick: handleClick})
        })
    }
    const onSearchVehicle = () => {
        form.validateFields().then(res => {
            setLoading(true);
            const formValues = form.getFieldsValue();
            const {vin = '', plateNumber = '', date} = formValues;
            http.get('/system-setting/accident/searchVehicle', {vin, plateNumber}).then(res => {
                vehicleInfoForm.setFieldsValue(res);
                return initTrips(vin, plateNumber, date);
            }).finally(e => setLoading(false))
        })
    }
    const onValuesChange = (changeValues, {vin = '', plateNumber = ''}) => {
        setFormValues({vin, plateNumber})
    };
    useEffect(() => {

        initMap('vehicleTrip',[]);

        setFormValues(form.getFieldsValue(['vin', 'plateNumber']));
    }, [])
    return (  
        <div className="container">
            <div className="claim-title">{t('accident.accidentInquire')}</div>
            <Form
                form={form}
                layout="vertical"
                onValuesChange={onValuesChange}
                >
                <Row gutter={16} align="top">
                    <Col span={8}>
                        <Form.Item label={t('fields.plateNumber')} name="plateNumber">
                            <Select options={plateNumberList} allowClear showSearch loading={selectLoading}></Select>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label={t('fields.vin')} name="vin">
                            <Select options={vinList} allowClear showSearch></Select>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label={t('common.date')} name="date" help={t('tips.checkNextDay')} rules={[{ required: true, message: t('common.pleaseSelect') + t('common.date') }]}
>
                            <RangePicker style={{width: '100%'}} disabledDate={disabledDate} onCalendarChange={val => setDates(val)} onOpenChange={() => setDates([])}></RangePicker>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item label=" ">
                            <Button icon={<SearchOutlined />}   onClick={onSearchVehicle}>{t('common.search')}</Button>
                        </Form.Item>
                    </Col>    
                </Row>    
            </Form>
            <Spin spinning={loading}>
            <div className="claim-title">{t('accident.vehicleInformation')}
                <span style={{float: 'right', fontSize: '14px', cursor: 'pointer'}} onClick={() => setVehicleModalVisible(true)}>{t('common.more')} &gt;&gt;</span>
            </div>
            <Form form={vehicleInfoForm} layout="vertical">
                <Row gutter={16} align="middle">
                    <Col span={6}>
                        <Form.Item label={t('fields.vehicleType')} name="type">
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('fields.vehicleLoad')} name="ratedquality">
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('fields.manufacturingDate')} name="manufacturingDate">
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('fields.deviceType')} name="deviceType">
                            <Input disabled/>
                        </Form.Item>
                    </Col>    
                </Row>    
            </Form>
            <Modal
                width={600}
                title={t('accident.vehicleInformation')}
                visible={vehicleModalVisible}
                onCancel={() => setVehicleModalVisible(false)}
                footer={null}
            >
                <Form form={vehicleInfoForm} layout="vertical">
                    <Form.Item shouldUpdate>
                        {() => {
                            return <Row gutter={16} align="middle">
                                {
                                    vehicleKeys.filter(key => ![null, undefined, ''].includes(vehicleInfoForm.getFieldValue(key))).map((key, index) => {
                                        return (
                                            <Col span={12}>
                                                <Form.Item label={t(`fields.${key}`)} name={key}>
                                                    <Input disabled></Input>
                                                </Form.Item>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        }}
                    </Form.Item>
                </Form>
            </Modal>
            { warningParams ? <WarningInfo warningParams={warningParams} onCancel={() => setWarningParams(null)}></WarningInfo>: '' }
            <Row>
                <Col span={3}><PolicyHistory params={formValues}/></Col>
                <Col span={3}><BillHistory params={formValues}/></Col>
                <Col span={3}><ClaimHistory params={formValues}/></Col>
            </Row>
            <div className="claim-title" style={{marginTop: "16px"}}>{t('accident.tripVisualization')}</div>
            <AgreeCheck  params={formValues} />
            <Alert style={{marginTop: '16px'}} message={t('tips.agreePrivacy')} type="warning" />
            <div id="vehicleTrip" style={{height: '80vh'}}></div>
        </Spin>
    </div>
    )
}
export default AccidentInquire;