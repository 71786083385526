// router/index.js
import Welcome from '../pages/Welcome'
import UserManagement from '../pages/UserManagement'
import RoleManagement from '../pages/RoleManagement'
import UserAdd from '../pages/UserAdd'
import UserEdit from '../pages/UserEdit'
import DealManagement from '../pages/TenantManagement'
import PortfolioSummary from '../pages/PortfolioMgt'
import Triangle from '../pages/PortfolioMgt/Triangle/index'
import PolicyAnalysis from '../pages/PortfolioMgt/PolicyAnalysis/index'
import ClaimAnalysis from '../pages/PortfolioMgt/ClaimAnalysis/index'
import FeatureAnalysis from '../pages/RiskAnalysis/FeatureAnalysis/index'
import VehicleRisk from '../pages/RiskAnalysis/VehicleRisk/index'
import RenewSuggestion from '../pages/RiskAnalysis/RenewSuggestion/index'
import SuggestionRule from '../pages/RiskAnalysis/SuggestionRule/index'
import Recommendation from '../pages/Recommendation/index'
import EditRecommendation from '../pages/Recommendation/editRecommendation/index'
import ViewRecommendation from '../pages/Recommendation/viewRecommendation/index'
import VendorManagement from '../pages/VendorManagement'
import InsurerManagement from '../pages/InsurerManagement'
import Bills from '../pages/Bills'
import Files from '../pages/Files'
import BillCheck from '../pages/BillCheck'
import {Hello} from '../pages/Hello'
import DataCollection from '../pages/TMXDashboard/DataCollection'
import DataQuality from '../pages/TMXDashboard/DataQuality'
import DataInsight from '../pages/TMXDashboard/DataInsight'
import LossAnalysis from '../pages/LossAnalysis'
import DealEvaluation from '../pages/PartnerEvaluation/DealEvaluation'
import PartnerEvaluation from '../pages/PartnerEvaluation/PartnerEvaluation';
import TripQualityDetail from '../pages/PartnerEvaluation/TripQualityDetail';
import Login from '../pages/Login'
import VehicleInformation from '../pages/SystemSetting/VehicleInformation'
import InsuranceBills from '../pages/SystemSetting/InsuranceBills'
import PolicyInformation from '../pages/SystemSetting/PolicyInformation'
import ClaimInfomation from '../pages/SystemSetting/ClaimInformation'
import ClaimAggData from '../pages/SystemSetting/ClaimAggData'
import AccidentInquire from '../pages/AccidentInquire/AccidentInquire'
import ClaimList from '../pages/AccidentInquire/ClaimList'
import ClaimReport from '../pages/AccidentInquire/ClaimReport'
import PrivacyList from '../pages/PrivacyAuthorization/PrivacyList'
import BillList from '../pages/BillManagement/BillList'
import  TruckPricingAPI from '../pages/TruckPricingAPI/index'
import  TruckPricingAPIText from '../pages/TruckPricingAPI/apiText'

import AAARoleManagement from '../pages/AAARoleManagement'
import AAAUserManagement from '../pages/AAAUserManagement'
import AAAUserAdd from '../pages/AAAUserAdd'
import AAAUserEdit from '../pages/AAAUserEdit'
import AAAAccountManagement from '../pages/AAAAccountManagement'
import AuditManagementEmails from '../pages/AuditManagementEmails'
import AuditManagementActivityLog from '../pages/AuditManagementActivityLog'
import UserValid from '../pages/UserValid'
const routes = [
  {
    path: "login",
    component: Login,
  },
  {
    path: "/hello",
    component: Hello
  },
  {
    path: "/fleet/user-management",
    component: UserManagement,
  },
  {
    path: "/",
    component: Welcome,
  },
  {
    path: "/fleet/role-management",
    component: RoleManagement,
  },
  {
    path: "/fleet/user-management/add",
    component: UserAdd,
  },
  {
    path: "/fleet/user-management/edit/:id",
    component: UserEdit,
  },
  {
    path: "/fleet/privacy-auth-log",
    component: PrivacyList
  },
  {
    path: "/fleet/portfolio-summary",
    component: PortfolioSummary,
  },
  {
    path: "/fleet/triangle",
    component: Triangle,
  },
  {
    path: "/fleet/policy-analysis",
    component: PolicyAnalysis,
  },
  {
    path: "/fleet/claim-analysis",
    component: ClaimAnalysis,
  },
  {
    path: "/fleet/recommendation",
    component: Recommendation,
  },
  {
    path: "/fleet/edit-recommendation/:ReportId?",
    component: EditRecommendation,
  },
  {
    path: "/fleet/view-recommendation/:ReportId",
    component: ViewRecommendation,
  },
  {
    path: "/fleet/feature-analysis",
    component: FeatureAnalysis,
  },
  {
    path: "/fleet/vehicle-risk",
    component: VehicleRisk,
  },
  {
    path: "/fleet/renew-suggestion",
    component: RenewSuggestion,
  },
  {
    path: "/fleet/suggestion-rule",
    component: SuggestionRule,
  },
  {
    path: "/fleet/deal-management",
    component: DealManagement,
  },
  {
    path: "/fleet/vendor-management",
    component: VendorManagement,
  },
  {
    path: "/fleet/insurer-management",
    component: InsurerManagement,
  },
  {
    path: "/fleet/billCheck",
    component: BillCheck,
  },
  {
    path: "/fleet/bills",
    component: Bills,
  },
  {
    path: "/fleet/bill-list",
    component: BillList
  },
  {
    path: "/fleet/files",
    component: Files,
  },
  {
    path: "/fleet/data-collection",
    component: DataCollection,
  },
  {
    path: "/fleet/data-quality",
    component: DataQuality,
  },
  {
    path: "/fleet/data-insight",
    component: DataInsight,
  },
  {
    path: "/fleet/loss_analysis",
    component: LossAnalysis,
  },
  {
    path: "/fleet/accident-inquire",
    component: AccidentInquire,
  },
  {
    path: "/fleet/claim-list",
    component: ClaimList
  },
  {
    path: "/fleet/claim-report/:id",
    component: ClaimReport
  },
  {
    path: "/fleet/vehicle-information",
    component: VehicleInformation
  },
  {
    path: "/fleet/insurance-bills",
    component: InsuranceBills
  },
  {
    path: "/fleet/policy-information",
    component: PolicyInformation
  },
  {
    path: "/fleet/claim-information",
    component: ClaimInfomation
  },
  {
    path: "/fleet/claim-agg-data",
    component: ClaimAggData
  },{
    path: "/fleet/deal-evaluation",
    component: DealEvaluation
  },
  {
    path: "/fleet/partner-evaluation",
    component: PartnerEvaluation
  },
  {
    path: "/fleet/trip-quality-detail",
    component: TripQualityDetail
  },
  {
    path: '/fleet/truck-pricing-api',
    component: TruckPricingAPI
  },
  {
    path: '/fleet/truck-pricing-api-text',
    component: TruckPricingAPIText
  },
  {
    path: "/aaa/role-management",
    component: AAARoleManagement
  },
  {
    path: "/aaa/user-management",
    component: AAAUserManagement
  },
  {
    path: "/aaa/user-management/add",
    component: AAAUserAdd,
  },
  {
    path: "/aaa/user-management/edit/:id",
    component: AAAUserEdit,
  },
  {
    path: "/aaa/account-management",
    component: AAAAccountManagement
  },
  {
    path: "/aaa/Audit-management/emails",
    component: AuditManagementEmails
  },
  {
    path: "/aaa/Audit-management/activity-log",
    component: AuditManagementActivityLog
  },
  {
    path: "/aaa/User/valid",
    component: UserValid,
  },
];

export default routes