import { Table, Row, Col, Button, Modal, Form, Input, Select } from 'antd';
import { DownloadOutlined , PlusOutlined, SettingOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined} from '@ant-design/icons'
import { FileUpload } from './components/Upload';
import TableColumnFilter from '../../components/TableColumnFilter';

import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useTable } from '../../hooks/table';
import { useVerticalForm } from '../../hooks/form';
import { useDealList } from '../../data/deal';
import { useInsuranceList } from '../../data/insurer';

import moment from "moment";
import http, { onBlobExport, downloadBlob } from '../../http';

const formatEmpty = val => {
    return val === true
        ? 'Y'
        : val === false ? 'N': ''
}
const ClaimInfomation = () => {
    const { t } = useTranslation();
    const { tableData, pagination, loading, setQuery, handleTableChange } = useTable(getData);

    const { dealList } = useDealList();
    const { insuranceList } = useInsuranceList();
    function getData(param) {
        return http.get('/system-setting/claims/search', {
            orderBy: 'lossPaid',
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            ...param
        })
    }
    const fieldsColumns = [
        {title: t("fields.policyNumber"),dataIndex: 'policyNumber' },
        {title: t("claim.registrationNumber"),dataIndex: 'claimId' },
        {title: t("claim.asOfDate"),dataIndex: 'asOfDate', render: date => moment(date).format('YYYY-MM-DD'), sorter: true, hide: true },
        {title: t("fields.insurerName"),dataIndex: 'insurerName', width: 350 },
        {title: t("insurer.branch"),dataIndex: 'clientBranch', hide: true  },
        {title: t("insurer.subBranch"),dataIndex: 'clientSubBranch', hide: true  },
        {title: t("fields.deal"),dataIndex: 'deal' },
        {title: t("fields.vin"),dataIndex: 'vin' },
        {title: t("fields.plateNumber"),dataIndex: 'plateNumber' },
        {title: t("claim.status"),dataIndex: 'status', sorter: true },
        {title: t("claim.proportionOfResponsibility"),dataIndex: 'proportionOfResponsibility', sorter: true  },
        {title: t("claim.lossPaid"),dataIndex: 'lossPaid', sorter: true },
        {title: t("claim.lossPaidMod"),dataIndex: 'lossPaidMod', sorter: true, hide: true  },
        {title: t("claim.lossPaidVtpl"),dataIndex: 'lossPaidVtpl', sorter: true, hide: true },
        {title: t("claim.lossPaidPdl"),dataIndex: 'lossPaidPdl', hide: true },
        {title: t("claim.lossPaidBil"),dataIndex: 'lossPaidBil', hide: true },
        {title: t("claim.lossPaidTPLOther"),dataIndex: 'lossPaidTPLOther', hide: true },
        {title: t("claim.lossPaidOther"),dataIndex: 'lossPaidOther',sorter: true, hide: true  },
        {title: t("claim.lossOutstanding"),dataIndex: 'lossOutstanding', sorter: true  },
        {title: t("claim.lossOutstandingMod"),dataIndex: 'lossOutstandingMod', sorter: true, hide: true },
        {title: t("claim.lossOutstandingVtpl"),dataIndex: 'lossOutstandingVtpl', sorter: true, hide: true },
        {title: t("claim.lossOutstandingPdl"),dataIndex: 'lossOutstandingPdl', hide: true },
        {title: t("claim.lossOutstandingBil"),dataIndex: 'lossOutstandingBil', hide: true },
        {title: t("claim.lossOutstandingTPLOther"),dataIndex: 'lossOutstandingTPLOther', hide: true },
        {title: t("claim.lossOutstandingOther"),dataIndex: 'lossOutstandingOther', sorter: true, hide: true },
        {title: t("claim.subrogation"),dataIndex: 'subrogation', sorter: true, hide: true },
        {title: t("claim.reportDate"),dataIndex: 'reportDate', width: 120, render: date => date && date.slice(0, 19).replace('T', ' '), sorter: true, hide: true },
        {title: t("claim.lossDate"), dataIndex: 'incidentTime', width: 120, render: date => date && date.slice(0, 19).replace('T', ' '), sorter: true },
        {title: t("claim.registrationTime"),dataIndex: 'registrationTime', render: date => date && date.slice(0, 19).replace('T', ' '), sorter: true , hide: true},
        {title: t("claim.cause"),dataIndex: 'cause' },
        {title: t("claim.accidentLocation"),dataIndex: 'accidentLocation', hide: true },
        {title: t("claim.accidentDescription"),dataIndex: 'accidentDescription' , hide: true},
        {title: t("claim.isSinglePartyClaim"), dataIndex: 'isSinglePartyClaim', render: formatEmpty, hide: true },
        {title: t("claim.includeBodilyInjury"),dataIndex: 'includeBodilyInjury',  render: formatEmpty, hide: true },
    ]
    const commonColumns = [
        {
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: time => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            width: 120,
            hide: true
        },
        {
            title: t('common.createdBy'),
            dataIndex: 'createdBy',
            hide: true
        },
        {
            title: t('common.updatedAt'),
            dataIndex: 'updatedAt',
            render: time => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            width: 120,
            hide: true
        },
        {
            title: t('common.updatedBy'),
            dataIndex: 'updatedBy',
            hide: true
        },
    ]
    const [columns, setColumns] = useState([
        ...fieldsColumns,
        ...commonColumns,
        {
            title: t("common.action"),
            fixed: 'right',
            hide: true,
            render: item => <Button icon={<DeleteOutlined />} onClick={() => confirmDelete(item)}>{t('common.delete')}</Button>
        },
    ]);
    const { form, formProps, colProps } = useVerticalForm();
    const handleSearch = (e) => {
        const values = form.getFieldsValue();
        setQuery(values);
    }
    const confirmDelete = (item) => {
        Modal.confirm({
            title: t('common.confirm'),
            content: `${t('common.confirmDelete')} [${item.policyNumber}] ?`,
            cancelButtonProps: {
                type: 'default',
                icon: <CloseCircleOutlined />
              },
              okButtonProps: {
                type: 'default',
                icon: <CheckCircleOutlined />
              },
            onOk() {
                return http.delete(`/system-setting/claims/delete?id=${item.id}`).then(handleSearch);
            }
        });
    }
    const handleExport = e => {
        const values = form.getFieldsValue();
        onBlobExport('/system-setting/claims/export', values).then(res => {
            downloadBlob(res.data, 'claims.xlsx')
        });
    }
    return <div className="container">
        <Form {...formProps} form={form} name="basic">
            <Row align="bottom">
                <Col {...colProps}>
                    <Form.Item label={t('fields.policyNumber')} name="policyNumber">
                        <Input  />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.deal')} name="tenantId">
                        <Select options={dealList} allowClear/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.insurer')} name="insurerId">
                        <Select options={insuranceList} allowClear/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.plateNumber')} name="plateNumber">
                        <Input />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.vin')} name="vin">
                        <Input />
                    </Form.Item>
                </Col>
                <Col {...colProps} style={{ marginBottom: '24px' }}>
                    <Button icon={<SearchOutlined />}   onClick={handleSearch}>{t('common.search')}</Button>
                    <Button style={{ marginLeft: '16px' }} icon={<DownloadOutlined />} onClick={handleExport}>{t('common.export')}</Button>
                </Col>
            </Row>
        </Form>
        <Row justify="space-between" align="middle">
            <FileUpload
                url="/system-setting/claims"
                template={{
                    method: "get",
                    fileName: "claim-template.xlsx"
                }}
                onSubmit={handleSearch}
                columns={fieldsColumns}
                tableProps={{scroll:{ x: 2500 }}}
            />
            <TableColumnFilter columns={columns} onSetColumns={setColumns} />
        </Row>
        <Table
            size="small"
            tableLayout='fixed'
            scroll={{x: columns.filter(column => !column.hide).length * 150}}
            loading={loading}
            pagination={pagination}
            rowKey="id"
            onChange={handleTableChange}
            columns={columns.filter(column => !column.hide)}
            dataSource={tableData} />
        
    </div>
}
export default ClaimInfomation;