import { Table, Button, Modal, Form, Input, Select, DatePicker, Row, Col, InputNumber, Switch, Checkbox } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { DownloadOutlined, PlusOutlined, SettingOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import http, { onBlobExport, downloadBlob } from '../../http';
import moment from 'moment';
import { useModal } from '../../hooks/useModal';
import { useVerticalForm } from '../../hooks/form';
import { useTranslation } from 'react-i18next'
import { useTable } from '../../hooks/table';

function getApplicationList() {
  return http.get('/aaa/Application?Page=-1');
}
function getUserList() {
  return http.get('/aaa/User?Page=-1');
}
function getFunctionList(date) {
  return http.get(`/fleet-auth/log/function/list?date=${date}`);
}
export default function Index() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { form: queryForm, formProps, colProps } = useVerticalForm();
  const { tableData, onSearch, pagination, loading, setQuery } = useTable(getData,false);
  const [ApplicationOptions, setApplicationOptions] = useState([]);
  const [UserOptions, setUserOptions] = useState([]);
  const [FunctionOptions, setFunctionOptions] = useState([]);
  const YESTERDAY = moment().add(-1, 'days');
  const [selectDate, setSelectDate] = useState(YESTERDAY);
  const disabledDate = current => {
    // Can not select days before today and today
    return current && current > moment()
  }


  function getData(param) {
    return http.get('/fleet-auth/log/activity/list', {
      pageNum: pagination.current,
      pageSize: pagination.pageSize,
      ...param
    })
  }
  const columns = [
    {
      title: t('activityLog.sendTime'),
      key: 'time',
      dataIndex: 'time'
    },
    {
      title: t('activityLog.user'),
      key: 'user',
      dataIndex: 'user',
    },
    {
      title: t('activityLog.application'),
      key: 'application',
      dataIndex: 'application',
    },
    {
      title: t('activityLog.function'),
      key: 'function',
      dataIndex: 'function',
    },
  ];
  const handleSearch = (e) => {
    const values = queryForm.getFieldsValue();
    if (values.date) {
      values.date = moment(values.date).format('YYYY-MM-DD')
    }
    setQuery(values);
  }
  useEffect(() => {
    getApplicationList().then(res => {
      setApplicationOptions(
        res.data.map(one => {
          return {
            label: one.fullName,
            value: one.name,
          }
        })
      );
    }),
      getUserList().then(res => {
        setUserOptions(
          res.data.map(one => {
            return {
              label: one.login,
              value: one.login,
            }
          })
        );
      })
    handleSearch()
  }, [])
  
  useEffect(() => {
    queryForm.setFieldsValue({ function: null})
    let date = moment(selectDate).format('YYYY-MM-DD')
    getFunctionList(date).then(res => {
      setFunctionOptions(
        res.map(one => {
          return {
            label: one,
            value: one,
          }
        })
      );
    })
  },[selectDate])
  const handleExport = e => {
    const values = queryForm.getFieldsValue();
    if (values.date) {
      values.date = moment(values.date).format('YYYY-MM-DD')
    }
    onBlobExport('/fleet-auth/log/activity/list/export', values).then(res => {
      downloadBlob(res.data, 'Activity Log')
    });
  }
  return <div className="container">
    <Form {...formProps} form={queryForm} name="basic">
      <Row align="bottom">
        <Form.Item label={t('activityLog.date')} name="date" initialValue={YESTERDAY} rules={[{ required: true, message: t('common.pleaseInput') + t('activityLog.date') }]}>
          <DatePicker allowClear={false} onChange={setSelectDate} disabledDate={disabledDate} style={{ width: '200px', marginRight: '10px' }} format="YYYY-MM-DD" />
        </Form.Item>
        <Col>
          <Form.Item label={t('activityLog.user')} name="user">
            <Select showSearch style={{ width: '200px', marginRight: '10px' }} options={UserOptions} allowClear></Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={t('activityLog.application')} initialValue='fleet' name="application">
            <Select style={{ width: '200px', marginRight: '10px' }} options={ApplicationOptions} allowClear></Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={t('activityLog.function')} name="function">
            <Select showSearch style={{ width: '200px', marginRight: '10px' }} options={FunctionOptions} allowClear></Select>
          </Form.Item>
        </Col>
        <Col style={{ marginBottom: '24px' }}>
          <Button style={{ marginLeft: '32px' }} icon={<SearchOutlined />} onClick={handleSearch}>{t('common.search')}</Button>
          <Button style={{ marginLeft: '32px' }} icon={<DownloadOutlined />} onClick={handleExport}>{t('common.export')}</Button>
        </Col>
      </Row>
    </Form>
    <Table
      loading={loading}
      pagination={pagination}
      rowKey="id"
      columns={columns}
      dataSource={tableData} 
      expandable={{
        expandedRowRender: record => <p style={{ margin: 0 }}>{record.result}</p>,
        rowExpandable: record => {return true},
      }}
      />
  </div>
}
