import { Form, Select, DatePicker, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next'
import { useState } from 'react';
import moment from 'moment';
import { usePlateNumberVin } from '../../../AccidentInquire/data';

const { RangePicker } = DatePicker;

export default function PageDataInsightByGroup ({ Section, SectionJS }) {
  const { t } = useTranslation();

  const [dates, setDates] = useState([]);

  const { vinList, plateNumberList } = usePlateNumberVin();
  const disabledDate = current => {
    let beforeSelectMax = moment().subtract(2, 'days');
    if (!dates || dates.length === 0 || (dates[0] && dates[1])) {
      return current && (current > beforeSelectMax);
    }

    let afterSelectMin = moment(dates[0]).subtract(2, 'days');
    let afterSelectMax = moment(dates[0]).add(2, 'days');
    return current && (current > beforeSelectMax || current > afterSelectMax || current < afterSelectMin)
  }

  const saveFeature = (data) => {
    SectionJS.serchParams.startTimestamp = data[0].valueOf()
    SectionJS.serchParams.endTimestamp = data[1].valueOf()
    setDates([])
  }

  return <div>
    <Row align="middle" justify="space-between">
      <Col span={4}>
        <Form.Item
          label={t('fields.plateNumber')}
          name={[Section.name, 'serchParams', 'plateNumber']}
          rules={[{ required: true}]}
        >
          <Select options={plateNumberList} allowClear showSearch></Select>
        </Form.Item>
      </Col>
      <Col span={5}>
        <Form.Item
          label={t('fields.vin')}
          name={[Section.name, 'serchParams', 'vin']}
        >
          <Select options={vinList} allowClear showSearch></Select>
        </Form.Item>
      </Col>
      <Col span={11}>
        <Form.Item
          label={t('common.date')}
          name={[Section.name, 'serchParams', 'date']}
          help={t('tips.checkNextDay')}
          rules={[{ required: true, message: t('common.pleaseSelect') + t('common.date') }]}>
          <RangePicker style={{ width: '100%' }} disabledDate={disabledDate} onChange={saveFeature} onCalendarChange={val => setDates(val)}></RangePicker>
        </Form.Item>
      </Col>
    </Row>
  </div>
}