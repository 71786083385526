import { Table, Select, Button, Spin } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useTable } from '../../hooks/table';
import http, { onBlobExport, downloadBlob } from '../../http';
import { useTranslation } from 'react-i18next'
import style from './Analysis.module.css'
import ChangeColumns from './components/ChangeColumns'
import { useState, useEffect } from 'react';

const { Option } = Select;



const getGroupBy = () => {
  return http.get('/portfolioapi/LossPivot/GetInitData')
}

let groupByColumns = []

export default function LossPivot () {
  const { t } = useTranslation();

  const { tableData, pagination, loading, setQuery, handleTableChange } = useTable(getData);
  const [groupBy, setGroupBy] = useState([])
  const [selectGroupBy, setSelectGroupBy] = useState([])
  const [groupByLoading, setGroupByLoading] = useState(true)

  const columns = [
    {
      title: t.bind(null,'tabletitle.PolicyCount'),
      dataIndex: 'PolicyCount',
      default: true,
      sorter: {
        compare: (a, b) => a.PolicyCount - b.PolicyCount,
      },
    },
    {
      title: t.bind(null,'tabletitle.Premium'),
      dataIndex: 'WritenPremium',
      default: true,
      sorter: {
        compare: (a, b) => a.WritenPremium - b.WritenPremium,
      },
    },
    {
      title: t.bind(null,'tabletitle.EarnedPremium'),
      dataIndex: 'EarnedPremium',
      showSorterTooltip: false,
      default: true,
      sorter: {
        compare: (a, b) => a.EarnedPremium - b.EarnedPremium,
      },
    },
    {
      title: t.bind(null,'tabletitle.PolicyYears'),
      dataIndex: 'CarYears',
      showSorterTooltip: false,
      default: true,
      sorter: {
        compare: (a, b) => a.PolicyYears - b.PolicyYears,
      },
    }, {
      title: t.bind(null,'tabletitle.ExpYears'),
      dataIndex: 'ExpYears',
      default: true,
      sorter: {
        compare: (a, b) => a.ExpYears - b.ExpYears,
      },
    }, {
      title: t.bind(null,'tabletitle.ClaimCount'),
      dataIndex: 'ClaimCount',
      default: true,
      sorter: {
        compare: (a, b) => a.ClaimCount - b.ClaimCount,
      },
    }, {
      title: t.bind(null,'tabletitle.AmountOfLossPaid'),
      dataIndex: 'AmountOfLossPaid',
      default: true,
      sorter: {
        compare: (a, b) => a.AmountOfLossPaid - b.AmountOfLossPaid,
      },
    }, {
      title: t.bind(null,'tabletitle.LossRatio'),
      dataIndex: 'LossRatio',
      default: true,
      sorter: {
        compare: (a, b) => a.LossRatio - b.LossRatio,
      },
      render: (text) =>{
        return (text * 100).toFixed(2) + '%'
      }
    }, {
      title: t.bind(null,'tabletitle.Frequency'),
      dataIndex: 'Frequency',
      default: true,
      sorter: {
        compare: (a, b) => a.Frequency - b.Frequency,
      }
    }, {
      title: t.bind(null,'tabletitle.Severity'),
      dataIndex: 'Severity',
      default: true,
      sorter: {
        compare: (a, b) => a.Severity - b.Severity,
      }
    }
  ];
  // default dispaly table head
  const defaultColumns = columns.filter(e=>e.default)
  // selected GroupBy Columns
  const [serchGroupByColumns, setSerchGroupByColumns] = useState([])
  // dynamic regulation table head
  const [displayColumn, setDisplayColumnColumns] = useState(defaultColumns)
  // true table columns
  const [tableColumn, setTableColumns] = useState(defaultColumns)

  // get table date
  function getData (params) {
    return http.get('/portfolioapi/LossPivot/GetData', {
      PageNum: pagination.current,
      PageSize: pagination.pageSize,
      strGroup: selectGroupBy.toString() || '1,2',
      ...params
    })
  }

  // create GroupByColumns by GroupByList
  function initGroupByColumns (list) {
    groupByColumns = list.map(e => ({
      title: t.bind(null,`groupname.${e.typeName}`),
      dataIndex: e.typeName,
      id: e.id
    }));
    let defaultGroupByColumns = groupByColumns.slice(0,2)
    setSerchGroupByColumns(defaultGroupByColumns)
    // init show the first two groupBy
    setTableColumns(defaultGroupByColumns.concat(tableColumn))
  }

  function serch(){
    let serchColumns = selectGroupBy.map(e=>{
      return groupByColumns.find(a=>a.id === e)
    })
    setSerchGroupByColumns(serchColumns)
    // merge GroupBy and select for TableColumns
    setTableColumns(serchColumns.concat(displayColumn))
    setQuery()
  }

  function changeColumns(list){
    setDisplayColumnColumns(list)
    setTableColumns(serchGroupByColumns.concat(list))
  }

  useEffect(() => {
    setGroupByLoading(true)
    getGroupBy().then(res => {
      setGroupBy(res.GroupBy)
      initGroupByColumns(res.GroupBy)
      setSelectGroupBy(['1','2'])
      setGroupByLoading(false)
    })
  },[])

  const exportExcl = () => {
    let params = {
      la: localStorage.getItem('language'),
      strGroup: selectGroupBy.toString()
    }
    onBlobExport('/portfolioapi/LossPivot/GetExcel', params, { 'method': 'get' }).then(res => {
      downloadBlob(res.data, 'Loss Pivot')
    });
  }

  return <div>
    <div className={style.titleBox}>
      <span className={style.title}>{t('portfolio.lossPivotTable')}</span>
    </div>
    <div className={style.grouptit}><span className={style.star}>*</span>{t('portfolio.groupBy')}</div>
    <Spin spinning={groupByLoading}>
      <div className={style.group}>
        <Select className={style.select}
          mode="multiple"
          allowClear
          placeholder="Please select"
          value={selectGroupBy}
          onChange={setSelectGroupBy}
          >
          {groupBy.map(e=><Option value={e.id}>{t(`groupname.${e.typeName}`)}</Option>)}
        </Select>
        <Button className={style.subbtn} onClick={serch} disabled={!selectGroupBy.length}><SearchOutlined />{t('common.search')}</Button>
      </div>
    </Spin>
    <div className={style.iconslist}>
      <Button className={style.icbtn} onClick={exportExcl} icon={<DownloadOutlined />}>{t('common.export')}</Button>
      <ChangeColumns Columns={columns} changeColumns={changeColumns} />
    </div>
    <Table pagination={{...pagination, showSizeChanger: true}} scroll={{ x: tableColumn.length * 150 }} onChange={handleTableChange} showSorterTooltip={false} columns={tableColumn} loading={loading} dataSource={tableData} />
  </div>
}