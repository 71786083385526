import { useTranslation } from 'react-i18next'

import { SignIn } from "../SignIn";
import { ContactUs } from "../ContactUs";
import { Layout, ConfigProvider, Space, Row, Col, Spin } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined
} from '@ant-design/icons'
import { I18nMenu } from './lang';
import { SideMenu } from './menu';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import { useMsal, useAccount } from "@azure/msal-react";
import { msalConfig, loginRequest, protectedResources, b2cPolicies } from "../../authConfig";
import { useWindowSize } from '../../hooks/useWindow';
const { Header, Content, Sider } = Layout;
import './style.css';
import { useEffect, useState } from "react";
import { useUserName } from '../../hooks/useUserName';
import AppMenu from './AppMenu';
import { useApplication } from '../../hooks/application';

export const PageLayout = (props) => {
    const locale = window.localStorage.language === 'cn' ? zhCN : enUS;
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = useState(false)
    const [token, setToken] = useState(null);
    const { applications, activeApp } = useApplication(token);
    const { userName } = useUserName(token);
    function toggleFold() {
        setCollapsed(!collapsed)
    }
    const { width: windowWidth } = useWindowSize();
    /**
   * useMsal is hook that returns the PublicClientApplication instance, 
   * an array of all accounts currently signed in and an inProgress value 
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
    const { instance } = useMsal()
    useEffect(() => {
        if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
            instance.setActiveAccount(instance.getAllAccounts()[0])
        }
        instance.acquireTokenSilent({
            scopes: protectedResources.apiHello.scopes,
        }).then((response) => {
            window.localStorage.setItem('token', response.accessToken);
            setToken(response.accessToken);
            console.log("+++acquireTokenSilent success, from layout response role:", response.account.idTokenClaims.extension_UserRole);
        }).catch(error => {
            console.log('acquireToken error:', error);
        })
    }, [])
    return (
        <ConfigProvider locale={locale}>
            {
                !activeApp
                    ? <Spin style={{ position: 'absolute', left: '50%', top: '100px', transform: 'translateX(-50%)' }}></Spin>
                    : <Layout style={{ height: '100%' }}>
                        <Sider
                            trigger={null} collapsible collapsed={collapsed}
                            width={260}
                            collapsedWidth={80}
                            style={{
                                overflow: 'overlay',
                            }}
                        >
                            <div className={["logo", collapsed && "collapsed"].join(' ')}>
                                {!collapsed ? <img src='/img/SrLogoBlack.png' style={{ width: '132px' }} alt="RITS Logo"></img> : <img src='/img/SrLogoSmall.jpg' alt="RITS Logo"></img>}
                            </div>
                            {token ? <SideMenu app={activeApp.name} /> : null}
                            <div className="backlog">京ICP2020037404</div>
                        </Sider>
                        <Layout className="site-layout">
                            <Header className="site-layout-header">
                                <Row align='middle' justify='space-between'>
                                    <Space>
                                        {collapsed ? <MenuUnfoldOutlined onClick={toggleFold}></MenuUnfoldOutlined> : <MenuFoldOutlined onClick={toggleFold}></MenuFoldOutlined>}
                                        <span className="site-layout-header-name"> {windowWidth > 960 ? t('navbar.platformName') : t('navbar.platformNameMobile')}</span>
                                        <span> - </span>
                                        <AppMenu applications={applications} app={activeApp.fullName}></AppMenu>
                                    </Space>
                                    <Space style={{ float: 'right' }}>
                                        <div>{userName} | </div>
                                        <I18nMenu></I18nMenu>
                                        <ContactUs></ContactUs>
                                        <SignIn></SignIn>
                                    </Space>
                                </Row>
                            </Header>
                            <Content style={{ padding: '24px 16px', overflow: 'auto' }}>
                                {props.children}
                            </Content>
                        </Layout>
                    </Layout>
            }
        </ConfigProvider>
    )
}
