import React from "react";
import ReactDOM from "react-dom";

import { PublicClientApplication, EventType, InteractionType } from "@azure/msal-browser";
import { msalConfig, loginRequest, protectedResources, b2cPolicies } from "./authConfig";

import App from "./App.jsx";
import './locales/i18n';
// import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";
import { BrowserRouter as Router } from "react-router-dom";


/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders. 
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <Router>
    <App instance={msalInstance} />    
  </Router>,
  document.getElementById('root')
);
