import { Table } from 'antd';
import { useTranslation } from 'react-i18next'
import style from './index.module.css'
import http from '../../http';
import { useState, useEffect } from 'react';

export default function TruckPricingAPIIndex () {
  const { t } = useTranslation();
  const [modelVersion, setModelVersion] = useState('')

  const requestColumns = [{title: '', dataIndex: 'key'}, {title: '字段名', dataIndex: 'name'},{title: '字段类型', dataIndex: 'type'},{title: '字段含义', dataIndex: 'sense'},{title: '是否允许为空',dataIndex: 'canI'},{title: '说明', dataIndex:'range'}]

  const requestTableData = [
    {key: '1', name: 'plate_no', type: 'string', sense: '车牌号', canI: '否' },
    {key: '2', name: 'vin', type: 'string', sense: 'VIN码', canI: '是' },
    {key: '3', name: 'inception_date', type: 'datetime', sense: '起保日期', canI: '是' },
    {key: '4', name: 'region', type: 'string', sense: '车辆省份', canI: '否' },
    {key: '5', name: 'car_age', type: 'float', sense: '车龄', canI: '否' },
    {key: '6', name: 'insurer', type: 'string', sense: '保司名称', canI: '否' },
    {key: '7', name: 'sum_insured_tpl', type: 'float', sense: '三责险投保保额（元）', canI: '是 (当 flag_combinati on_tpl为True 时，此值不能为空）',  range: "取值必须在下⾯ list ⾥⾯ [100000, 150000, 200000, 300000, 500000, 1000000, 1500000, 2000000, 3000000, 4000000, 5000000, 6000000, 8000000, 10000000]"},
    {key: '8', name: 'sum_insured_mod', type: 'float', sense: '车损险投保保额（元）', canI: '是' },
    {key: '9', name: 'sum_insured_driver', type: 'float', sense: '司机险投保保额（元）', canI: '是' },
    {key: '10', name: 'sum_insured_passenger', type: 'float', sense: '乘客险投保保额（元）', canI: '是' },
    {key: '11', name: 'flag_combination_mod', type: 'bool', sense: '投保组合中是否含有车损险', canI: '否' },
    {key: '12', name: 'flag_combination_tpl', type: 'bool', sense: '投保组合中是否含有三责险', canI: '否' },
    {key: '13', name: 'flag_combination_person', type: 'bool', sense: '投保组合中是否含有车上人员险', canI: '否' },
    {key: '14', name: 'std_premium_mod', type: 'float', sense: '车损险标准保费（不含NCD和交通违法系数，单位：元）', canI: '是' },
    {key: '15', name: 'std_premium_tpl', type: 'float', sense: '三责险标准保费（不含NCD和交通违法系数，单位：元）', canI: '是' },
    {key: '16', name: 'std_premium_driver', type: 'float', sense: '司机险标准保费（不含NCD和交通违法系数，单位：元）', canI: '是' },
    {key: '17', name: 'std_premium_passenger', type: 'float', sense: '乘客险标准保费（不含NCD和交通违法系数，单位：元）', canI: '是' },
    {key: '18', name: 'std_premium_policy', type: 'float', sense: '商业险保单合计标准保费（不含NCD和交通违法系数，单位：元）', canI: '是' },
    {key: '19', name: 'ncd', type: 'float', sense: 'NCD系数', canI: '是' },
    {key: '20', name: 'car_brand', type: 'string', sense: '车辆品牌', canI: '否', range: "['解放', '陕汽', '东⻛', '福⽥', '欧曼', '豪沃', '江淮', '中国重汽', '乘⻰', '红岩', '其他']" },
    {key: '21', name: 'car_class', type: 'string', sense: '车辆类型', canI: '否',range: "['厢式货⻋', '特种⻋', '半挂牵引⻋', '载货汽⻋', '禽畜及仓栅式运输⻋', '牵引汽⻋', '⾃卸⻋', '其他']" },
    {key: '22', name: 'car_price_new', type: 'float', sense: '新车购置价 单位: 元', canI: '否' },
    {key: '23', name: 'weight', type: 'float', sense: '载重 单位: 吨', canI: '否' }
  ]

  const responseTableData = [
    {key: '1', name: 'code', type: 'int', sense: '状态码', canI: '否',range: '200，成功' },
    {key: '2', name: 'message', type: 'String', sense: '消息', canI: '否',range: 'SUCCESS' },
    {key: '3', name: 'data', type: 'Object', sense: '返回数据', canI: '否',range: '数据' },
    {key: '4', name: 'data>>plate_no', type: 'String', sense: '车牌号', canI: '否' },
    {key: '5', name: 'data>>expires', type: 'String', sense: '过期时间', canI: '否' },
  ]

  const getInit = () => {
    // 1-车辆品牌，2-车辆类型，3-地域, 4是保司名称
    http.get('/truckpricingapi/openApi/v1/getModelVersion').then(res=>{
      setModelVersion(res.model_version)
    })
  }


  useEffect(() => {
    getInit()
  }, [])

  return <div>
    <div className={style.titleBox}>
      <span className={style.title}>{t('TruckPricingAPI.TruckPricingTool')}</span>
    </div>
    <div className={style.p10}>
      <div className={style.subTitle}>更新日期: 2022-08-29</div>
      <div className={style.textTitle}>1. 接口描述</div>
      <div>基于车辆的Risk Score 给出智途的车险报价</div>
      <div className={style.textTitle}>2. 模型版本</div>
      <div>v.{modelVersion}</div>
      <div className={style.textTitle}>3.请求说明</div>
      <p>请求方式:POST</p>
      <p>接口地址: https://prod.fleetplatform.swissre.cn/api/truckpricingapi/openApi/v1/inner/getTrunkPricing</p>
      <p className={style.textTitle}>请求参数</p>
      <Table 
        size='small'
        columns={requestColumns} 
        dataSource={requestTableData} 
        pagination={false}
      />
      <p className={style.textTitle}>Header</p>
      <Table 
        size='small'
        columns={[{title: '请求头信息', dataIndex: 'key'},{title: 'application/json; charset=UTF-8', dataIndex: 'val'}]} 
        dataSource={[{key: 'Authorization', val: 'accessToken'}, {key: 'Content-Type', val: 'application/json'}]} 
        pagination={false}
      />
      <p className={style.textTitle}>响应参数</p>
      <Table 
        size='small'
        columns={requestColumns} 
        dataSource={responseTableData} 
        pagination={false}
      />
    </div>
  </div>
}

