import { Alert, Row, Col, Modal, Form, Input, Spin } from 'antd';
import http, { onBlobExport, downloadBlob } from '../../http';
// import TableColumnFilter from '../../components/TableColumnFilter';
// import { useState } from 'react';
import { useTranslation } from 'react-i18next'
// import { useTable } from '../../hooks/table';
// import { useVerticalForm } from '../../hooks/form';
import moment from "moment";
// import { useDealList } from '../../data/deal';
// import { useInsuranceList } from '../../data/insurer';
// import { FileUpload } from './components/Upload';
import PolicyHistory from './components/PolicyHistory';
import BillHistory from './components/BillHistory';
import ClaimHistory from './components/ClaimHistory';
import WarningInfo from './components/WarningInfo';
import { initMap } from './amap';
import './index.css';
import { useRouteMatch } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';

import { setChart } from './chart';
import { useEffect, useState } from 'react';
import UploadPrivacy from './components/Upload';
import AgreeCheck from './components/AgreeCheck';
const vehicleKeys = [
    'guidancePrice',
    'marketDate',
    'cph',
    'wheelBase',
    'shaft',
    'height',
    'type',
    'width',
    'engine',
    'insuranceDate',
    'cc',
    'properties',
    'carriagenumber',
    'manufacturingDate',
    'retirementDate',
    'validityDayEnd',
    'vin',
    'color',
    'frontwheelbase',
    'registerDate',
    'engineModel',
    'ratedquality',
    'emissions',
    'brand',
    'carModel',
    'vehiclemodel',
    'totalquality',
    'passengers',
    'styleName',
    'length',
    'curbweight',
    'transmissionType',
    'fueltypes',
    'vehiclename',
    'modelYear',
    'rearwheelbase',
    'power',
    'demio',
    'vehicleStatus'
]
const claimKeys = [
    "policyNumber",
    "insurerName",
    "branch",
    "deal",
    "vin",
    "plateNumber",
    "status",
    "proportionOfResponsibility",
    "lossPaid",
    "lossPaidMod",
    "lossPaidVtpl",
    "lossPaidPdl",
    "lossPaidBil",
    "lossPaidTPLOther",
    "lossPaidOther",
    "lossOutstanding",
    "lossOutstandingMod",
    "lossOutstandingVtpl",
    "lossOutstandingPdl",
    "lossOutstandingBil",
    "lossOutstandingTPLOther",
    "lossOutstandingOther",
    "subrogation",
    "reportDate",
    "lossDate",
    "registrationTime",
    "registrationNumber",
    "cause",
    "accidentLocation",
    "accidentDescription",
    "isSinglePartyClaim",
    "includeBodilyInjury",
]
const statusMap = {
    'NULL': '',
    'PASS': 'success',
    'UNREAD': '',
    'ERROR': 'error',
}

const ClaimReport = props => {
    const { params: { id } } = useRouteMatch();

    const [form] = Form.useForm();
    const [charts, setCharts] = useState([]);
    const [vehicleInfoForm] = Form.useForm();
    const [vehicleModalVisible, setVehicleModalVisible] = useState(false);
    const [vehicleMoreVisible, setVehicleMoreVisible] = useState(false);
    const [claimForm] = Form.useForm();
    const [claimModalVisible, setClaimModalVisible] = useState(false);
    const [formValues, setFormValues] = useState({ vin: '', plateNumber: '' });
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [warningParams, setWarningParams] = useState(null);
    const initTrips = (vehicle) => {
        const { plateNumber, timestamp } = vehicle;
        const handleClick = (warningId, timestamp) => {
            const warningParams = {
                plateNumber,
                warningId,
                timestamp
            }
            setWarningParams(warningParams);
        }
        const tripParams = {
            plateNumber,
            startTimestamp: new Date(timestamp).getTime() - 24 * 3600 * 1000,
            endTimestamp: new Date(timestamp).getTime() + 24 * 3600 * 1000,
        }
        return http.get('/accident/trips', tripParams).then(res => {
            if (res) {
                initMap('vehicleTrip', res, {
                    onMarkerClick: handleClick,
                    targetDay: moment(timestamp).format('YYYY-MM-DD')
                })
            }
        })
    }
    const initVehicleInfo = (params) => {
        return http.get('/system-setting/accident/searchVehicle', params).then(res => {
            if (res) {
                setVehicleMoreVisible(true);
                vehicleInfoForm.setFieldsValue(res)
            } else {
                setVehicleMoreVisible(false);
            }
        });
    }
    const initTmxData = (params) => {
        return http.get('/accident/recent/data', params).then(res => {
            if (res) {
                const charts = setChart(res);
                setCharts(charts);
            }
        });
    }
    const clkVehicleMore = () => {
        if (!vehicleMoreVisible) {
            Modal.error({
                title: t('common.error'),
                width: 800,
                maskClosable: true,
                okButtonProps: {
                  type: 'default',
                  icon: <CheckCircleOutlined />
                },
                content: <div style={{ maxHeight: '400px', overflow: 'auto' }}>查询无车辆数据</div>
            });
        } else {
            setVehicleModalVisible(true);
        }
    }
    const getReportDetail = () => {
        setLoading(true);
        return http.get('/system-setting/claimReport/detail', { id }).then(res => {
            if (res) {
                res.incidentTime = res.incidentTime.slice(0, 19).replace('T', ' ')
                form.setFieldsValue(res);
                claimForm.setFieldsValue(res)
                setFormValues({ plateNumber: res.plateNumber });
                return Promise.all([
                    initTrips({ plateNumber: res.plateNumber, timestamp: res.incidentTime }),
                    initVehicleInfo({ plateNumber: res.plateNumber }),
                    initTmxData({ plateNumber: res.plateNumber, timestamp: new Date(res.incidentTime).getTime() })
                ])
            }
        }).finally(e => setLoading(false))
    }
    useEffect(() => {
        getReportDetail()
    }, [])
    useEffect(() => {
        if (charts.length) {
            charts.forEach(one => {
                window.addEventListener('resize', one.resize);
            })
        } return () => {
            charts.forEach(one => window.removeEventListener('resize', one.resize))
        }
    }, [charts])
    return (
        <div className="container claim-container">
            <Spin spinning={loading}>
                <div className="claim-title">{t('accident.claimInfo')}
                    <span style={{ float: 'right', fontSize: '14px', cursor: 'pointer' }} onClick={() => setClaimModalVisible(true)}>{t('common.more')} &gt;&gt;</span>
                </div>
                <Form form={form} layout="vertical" style={{ marginBottom: '-12px' }}>
                    <Row gutter={16} align="middle">
                        <Col span={6}>
                            <Form.Item label={t('claim.lossDate')} name="incidentTime">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={t('claim.lossAmount')} name="lossAmount">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={t('fields.insurerName')} name="insurerName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={t('fields.deal')} name="deal">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={t('claim.accidentDescription')} name="accidentDescription">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className="claim-title">{t('accident.vehicleInformation')}
                    <span style={{ float: 'right', fontSize: '14px', cursor: 'pointer' }} onClick={() => clkVehicleMore()}>{t('common.more')} &gt;&gt;</span>
                </div>
                <Form form={vehicleInfoForm} layout="vertical">
                    <Row gutter={16} align="middle">
                        <Col span={6}>
                            <Form.Item label={t('policy.vehicleType')} name="type">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={t('fields.vehicleLoad')} name="ratedquality">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={t('fields.manufacturingDate')} name="manufacturingDate">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={t('fields.deviceType')} name="deviceType">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Modal
                    width={600}
                    title={t('accident.vehicleInformation')}
                    visible={vehicleModalVisible}
                    onCancel={() => setVehicleModalVisible(false)}
                    footer={null}
                >
                    <Form form={vehicleInfoForm} layout="vertical">
                        <Form.Item shouldUpdate>
                            {() => {
                                return <Row gutter={16} align="middle">
                                    {
                                        vehicleKeys.filter(key => ![null, undefined, ''].includes(vehicleInfoForm.getFieldValue(key))).map((key, index) => {
                                            return (
                                                <Col span={12}>
                                                    <Form.Item label={t(`fields.${key}`)} name={key}>
                                                        <Input disabled></Input>
                                                    </Form.Item>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            }}
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    width={600}
                    title={t('accident.claimDetail')}
                    visible={claimModalVisible}
                    onCancel={() => setClaimModalVisible(false)}
                    footer={null}
                >
                    <Form form={claimForm} layout="vertical">
                        <Row gutter={16} align="middle">
                            {
                                claimKeys.filter(key => ![null, undefined, ''].includes(claimForm.getFieldValue(key))).map((key, index) =>
                                    <Col span={12}>
                                        <Form.Item label={t(`claim.${key}`)} name={key}>
                                            <Input disabled></Input>
                                        </Form.Item>
                                    </Col>
                                )
                            }
                        </Row>
                    </Form>
                </Modal>
                {warningParams ? <WarningInfo warningParams={warningParams} onCancel={() => setWarningParams(null)}></WarningInfo> : ''}
                <Row>
                    <Col span={3}><PolicyHistory params={formValues} /></Col>
                    <Col span={3}><BillHistory params={formValues} /></Col>
                    <Col span={3}><ClaimHistory params={formValues} /></Col>
                </Row>
                <div className="claim-title" style={{ marginTop: "16px" }}>{t('accident.tripVisualization')}</div>
                <AgreeCheck params={formValues} />
                <Alert style={{ marginTop: '16px' }} message={t('tips.agreePrivacy')} type="warning" />
                <div id="vehicleTrip" style={{ height: '80vh', margin: '12px 0' }}></div>
                <div className="claim-title">{t('accident.tmxData')}</div>
                <Row>
                    <Col span={12}><div id="adasFCWCount" className="claim-tmxChart"></div></Col>
                    <Col span={12}><div id="adasLDWCount" className="claim-tmxChart"></div></Col>
                </Row>
                <Row>
                    <Col span={12}><div id="dsmCallingCount" className="claim-tmxChart"></div></Col>
                    <Col span={12}><div id="dsmfatigueDrivingCount" className="claim-tmxChart"></div></Col>
                </Row>
                <Row>
                    <Col span={12}><div id="mileageTotal" className="claim-tmxChart"></div></Col>
                    <Col span={12}><div id="tripCount" className="claim-tmxChart"></div></Col>
                </Row>
            </Spin>
        </div>
    )
}
export default ClaimReport;