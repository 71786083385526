import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react';
import http from '../../../../http';

const { Option } = Select;

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  function fake() {
    http.get('/recommendationapi/Recommendation/GetClaims', {strClaimID: value})
      .then(res => {
        if (currentValue === value) {
          console.log(res.data)
          callback && callback(res.data);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

export default function PageClaimReport ({ Section, SectionJS }) {
  const { t } = useTranslation();

  const [claimList, setClaimList] = useState([]);
  const [selectLoading, setSelectLoading] = useState(false);

  const handleSearch = (value) => {
    if (value) {
      setSelectLoading(true)
      fetch(value, data => {
        setClaimList(data)
        setSelectLoading(false)
      });
    } else {
      setClaimList([]);
      setSelectLoading(false)
    }
  }
  const handleChange = (value) => {
    const item = claimList.find(e=>e.ClaimId === value)
    SectionJS.serchParams.plateNumber = item.PlateNumber
    SectionJS.serchParams.timestamp = item.IncidentTime
    SectionJS.serchParams.lossPaid = item.LossPaid
    SectionJS.serchParams.insurerName = item.InsurerName
    SectionJS.serchParams.deal = item.Deal
    SectionJS.serchParams.cause = item.Cause
  }

  return <div>
    <Form.Item
      label={t('portfolio.ClaimNo')}
      name={[Section.name, 'serchParams', 'ClaimId']}
      rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.feature') }]}
    >
     <Select
        showSearch
        loading={selectLoading}
        defaultActiveFirstOption={false}
        showArrow={true}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={null}
      >
        {claimList.map(d => <Option key={d.ClaimId} value={d.ClaimId}>{d.ClaimId}</Option>)}
      </Select>
    </Form.Item>
  </div>
}