import { Table, Button, Modal, Badge, Form, Input, InputNumber, Row, Col, Select, Radio, Spin, message } from 'antd';
import { PlusOutlined, SettingOutlined, DeleteOutlined, CloseCircleOutlined, CheckCircleOutlined, CloudSyncOutlined  } from '@ant-design/icons';
import { useTable } from '../../../hooks/table';
import http from '../../../http';
import { useTranslation } from 'react-i18next'
import style from './index.module.css'
import { useState, useEffect } from 'react';
import moment from 'moment/moment';

const VehicleType = ['=' , '≠']
const otherType = ['>', '≥', '<', '≤']

export default function VehicleRisk () {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeId, setIsActiveId] = useState('')
  const [initLoading, setInitLoading] = useState(true)
  const [selectOptionsData, setSelectOptionsData] = useState({})
  const [strConditionItem1, setStrConditionItem1] = useState(otherType)
  const [strConditionItem2, setStrConditionItem2] = useState(otherType)
  const [strItemType1, setStrItemType1] = useState(1)
  const [strItemType2, setStrItemType2] = useState(1)


  const columns = [
    { "title": t('SuggestionRule.Priority'), "dataIndex": "Priority",
      render: (text) => <Badge count={text} />
    }, 
    { "title": t('SuggestionRule.Suggestion'), "dataIndex": "Suggestion" }, 
    { "title": t('SuggestionRule.Rule'), "dataIndex": "Rule" }, 
    { "title": t('SuggestionRule.Description'), "dataIndex": "Description" }, 
    { "title": t('SuggestionRule.EditBy'), "dataIndex": "EditBy" }, 
    { "title": t('SuggestionRule.EditDate'), "dataIndex": "EditDate", render: item => <div style={{width: '100px'}}>{moment(item).format('YYYY-MM-DD')}</div> }, 
    { "title": t('SuggestionRule.Action'),
      render: item => (
        <div style={{width: '170px'}}>
          <Button icon={<SettingOutlined />} style={{ marginRight: '10px' }}  onClick={() => onEdit(item)}>{t('common.edit')}</Button>
          <Button icon={<DeleteOutlined />} onClick={() => onDelete(item)}>{t('common.delete')}</Button>
        </div>
      )  
    }, 
  ]

  // dynamic regulation table head
  const { tableData, pagination, loading, setQuery, handleTableChange } = useTable(getData);

  // get table date
  async function getData (param) {
    let res = await http.get('/vehicleriskapi/SuggestionRules/GetSuggestionRulesList', {
      PageNum: pagination.current,
      PageSize: pagination.pageSize,
      ...param,
    })
    return {
      total: res.totalPage||0,
      data: res.SuggestionRulesListData||[]
    }
  }

  const addRules = (params) => {
    return http.post('/vehicleriskapi/SuggestionRules/InsertSuggestionRules', params)
  }

  const editRules = (params) => {
    return http.post('/vehicleriskapi/SuggestionRules/UpdateSuggestionRules', params)
  }

  const getInit = () => {
    return http.get('/vehicleriskapi/SuggestionRules/GetInitData')
  }


  const onApply = () => {
    http.get('/vehicleriskapi/SuggestionRules/UpdateSuggestion').then(res=>{
      message.success(t('SuggestionRule.checkLater'))
    })
  }

  const getDetailsData = (id) => {
    http.get('/vehicleriskapi/SuggestionRules/GetSuggestionRulesData', {
      SuggestionRulesId: id
    }).then(res=>{
      form.setFieldsValue(res.RuleDetail)
    })
  }

  useEffect(() => {
    setInitLoading(false)
    getInit().then(res => {
      setInitLoading(false)
      setSelectOptionsData(res)
    })
  }, [])


  // 编辑
  const onEdit = (e) => {
    console.log(e)
    if(e && e.id){
      setIsActiveId(e.id)
      getDetailsData(e.id)
    }else{
      setIsActiveId('')
    }
    setIsModalOpen(true)
  }

  // 删除
  const onDelete = (item) => {
    Modal.confirm({
      title: t('common.confirm'),
      content: `${t('common.confirmDelete')} ${item.Priority} ?`,
      cancelButtonProps: {
          type: 'default',
          icon: <CloseCircleOutlined />
        },
        okButtonProps: {
          type: 'default',
          icon: <CheckCircleOutlined />
        },
      onOk() {
        http.get('/vehicleriskapi/SuggestionRules/DeleteSuggestionRules', {
          SuggestionRulesId: item.id
        }).then(()=>{
          message.success('success')
          setQuery()
        })
      }
    });
    
  }
  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }

  const AssembleRules = (params) => {
    if(params.strAdd || params.strItem2 || params.strCondition2 || params.strValue2){
      if(!params.strAdd || !params.strItem2 || !params.strCondition2 || !params.strValue2){
        return false
      }
    }
    let rule = `${params.strItem1} ${params.strCondition1} ${params.strValue1}`
    if(params.strAdd && params.strItem2 && params.strCondition2 && params.strValue2){
      rule += ` ${params.strAdd} ${params.strItem2} ${params.strCondition2} ${params.strValue2}`
    }
    return rule
  }

  // 创建和编辑
  const onFormFinish = (e) => {
    const strRule = AssembleRules(e)
    if(!strRule){
      message.error(t('SuggestionRule.completely'))
      return
    }
    const params = {
      Suggestion: e.Suggestion,
      Priority: e.Priority,
      Description: e.Description || '',
      Rule: strRule,
      RuleDetails: JSON.stringify(e) 
    }
    if(activeId){
      params.id = activeId
      editRules(params).then(()=>{
        message.success('success')
        handleCancel()
        setQuery()
      })
    }else{
      addRules(params).then(()=>{
        message.success('success')
        handleCancel()
        setQuery()
      })
    }
  }

  // item 发生改变处理逻辑
  const onValuesChange = (e) => {
    if(e && e.strItem1){
      if(e.strItem1 === 'VehicleType'){
        setStrConditionItem1(VehicleType)
      }else{
        setStrConditionItem1(otherType)
      }
      if(e.strItem1 === 'SwissReRiskScore'){
        setStrItemType1(1)
      }else if(e.strItem1 === 'VehicleType'){
        setStrItemType1(3)
      }else{
        setStrItemType1(2)
      }
      form.setFieldsValue({
        strCondition1: '',
        strValue1: ''
      })
    }
    if(e && e.strItem2){
      if(e.strItem2 === 'VehicleType'){
        setStrConditionItem2(VehicleType)
      }else{
        setStrConditionItem2(otherType)
      }
      if(e.strItem2 === 'SwissReRiskScore'){
        setStrItemType2(1)
      }else if(e.strItem2 === 'VehicleType'){
        setStrItemType2(3)
      }else{
        setStrItemType2(2)
      }
      form.setFieldsValue({
        strCondition2: '',
        strValue2: ''
      })
    }
  }

  const { Option } = Select;
  return <div>
    <div className={style.titleBox}>
      <span className={style.title}>{t('SuggestionRule.SuggestionRule')}</span>
    </div>
    <div className={style.create}>
     <Button className={style.submitbtn} onClick={() => onApply()}><CloudSyncOutlined />{t('SuggestionRule.apply')}</Button>
     <Button className={style.submitbtn} onClick={() => onEdit()}><PlusOutlined />{t('common.create')}</Button>
    </div>
    <Table pagination={pagination} scroll={{ x: columns.length * 150 }} onChange={handleTableChange} columns={columns} loading={loading} dataSource={tableData} />
    <Modal
      width={600}
      title={activeId ? t('SuggestionRule.edit') : t('SuggestionRule.ruleAdd')}
      visible={isModalOpen}
      confirmLoading={true}
      onCancel={handleCancel}
      destroyOnClose
      footer={false}
      style={{ top: '10px' }}
    >
      <Spin spinning={initLoading}>
        <Form layout="vertical" form={form} onFinish={onFormFinish} onValuesChange={onValuesChange}>
          <Form.Item
            name='Suggestion'
            label={t('RenewSuggestion.Suggestion')}
            rules={[{ required: true }]}
            extra="用于在Suggestion中展示的文字"
          >
            <Input placeholder={t('common.pleaseInput')}/>
          </Form.Item>
          <Form.Item
            name='Priority'
            label={t('RenewSuggestion.Priority')}
            rules={[{ required: true }]}
            extra="规则优先级，数值越大优先级越高，最小为1"
          >
            <InputNumber precision={0} style={{width:'100%'}} placeholder={t('common.pleaseInput')} min={1}></InputNumber>
          </Form.Item>
          <Form.Item
            name='Description'
            label={t('RenewSuggestion.Description')}
            extra="内部的规则备注，不对外展示"
          >
            <TextArea
              placeholder={t('common.pleaseInput')}
              allowClear={true}
              showCount
              maxLength={100}
              autoSize={{ minRows: 4, maxRows: 5  }}
            />
          </Form.Item>
          <div className={style.tablebot}>{t('SuggestionRule.Rule')}</div>
          <Row gutter={[8]}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name='strItem1'
                label={t('RenewSuggestion.Item')}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select"
                  allowClear
                >
                  {selectOptionsData.Item && selectOptionsData.Item.map(e => <Option key={e.typeName} value={e.typeName}>{e.typeName}</Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                name='strCondition1'
                label={t('RenewSuggestion.Condition')}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select"
                  allowClear
                >
                  {strConditionItem1.map(e => <Option key={e} value={e}>{e}</Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                name='strValue1'
                label={t('RenewSuggestion.Value')}
                rules={[{ required: true }]}
              >
                {strItemType1 === 3 ? <Select
                  placeholder="Select"
                  allowClear
                >
                  {selectOptionsData.VehicleType && selectOptionsData.VehicleType.map(e => <Option key={e.typeName} value={e.typeName}>{e.typeName}</Option>)}
                </Select>
                : (strItemType1 === 1 ? <InputNumber style={{width:'100%'}}></InputNumber> : <InputNumber style={{width:'100%'}} min={0}></InputNumber>) }
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name='strAdd'
            label={''}
          >
            <Radio.Group>
              <Radio value='and'>{t('RenewSuggestion.And')}</Radio>
              <Radio value='or'>{t('RenewSuggestion.Or')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Row gutter={[8]}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name='strItem2'
                label={t('RenewSuggestion.Item')}
              >
                <Select
                  placeholder="Select"
                  allowClear
                >
                  {selectOptionsData.Item && selectOptionsData.Item.map(e => <Option key={e.typeName} value={e.typeName}>{e.typeName}</Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                name='strCondition2'
                label={t('RenewSuggestion.Condition')}
              >
                <Select
                  placeholder="Select"
                  allowClear
                >
                  {strConditionItem2.map(e => <Option key={e} value={e}>{e}</Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                name='strValue2'
                label={t('RenewSuggestion.Value')}
              >
                {strItemType2 === 3 ? <Select
                  placeholder="Select"
                  allowClear
                >
                  {selectOptionsData.VehicleType && selectOptionsData.VehicleType.map(e => <Option key={e.typeName} value={e.typeName}>{e.typeName}</Option>)}
                </Select>
                : (strItemType2 === 1 ? <InputNumber style={{width:'100%'}}></InputNumber> : <InputNumber style={{width:'100%'}} min={0}></InputNumber>) }
              </Form.Item>
            </Col>
          </Row>
          <div className={style.create}>
            <Button className={style.submitbtn} htmlType="submit" icon={<CheckCircleOutlined />}>
              {t('SuggestionRule.submit')}
            </Button>
            <Button key="back" icon={<CloseCircleOutlined />} onClick={handleCancel}>
              {t('common.cancel')}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  </div>
}
