import { Table, Row, Col, Button, Modal, Form, Input, Select, DatePicker } from 'antd';
import {useState} from 'react';
import {useTable} from '../../../hooks/table';
import { useTranslation } from 'react-i18next'
import http from '../../../http';
import moment from 'moment';
const formatEmpty = val => {
    return val === true
        ? 'Y'
        : val === false ? 'N': ''
}
const ClaimHistory = ({params: formParams}) => {
    const { t } = useTranslation();

    const [modalVisible, setModalVisible] = useState(false);
    const {tableData, pagination, loading, setQuery, onSearch} = useTable(getData, false);
    function getData(params) {
        if(params.orderBy) {
            delete params.orderBy
        }
      return http.get('/system-setting/accident/searchClaims', { 
        pageNum: pagination.current,
        pageSize: pagination.pageSize, 
        ...params
      })
    }
    const onClickClaimHistory = () => {
        onSearch(formParams).then(res => {
            setModalVisible(true)
        })
    }
    const columns = [
        {title: t("fields.policyNumber"),dataIndex: 'policyNumber' },
        {title: t("fields.insurerName"),dataIndex: 'insurerName', width: 350 },
        {title: t("insurer.branch"),dataIndex: 'clientBranch'  },
        {title: t("fields.deal"),dataIndex: 'deal' },
        {title: t("fields.vin"),dataIndex: 'vin' },
        {title: t("fields.plateNumber"),dataIndex: 'plateNumber' },
        {title: t("claim.status"),dataIndex: 'status', },
        {title: t("claim.proportionOfResponsibility"),dataIndex: 'proportionOfResponsibility',  },
        {title: t("claim.lossPaid"),dataIndex: 'lossPaid', },
        {title: t("claim.lossPaidMod"),dataIndex: 'lossPaidMod',  },
        {title: t("claim.lossPaidVtpl"),dataIndex: 'lossPaidVtpl', },
        {title: t("claim.lossPaidPdl"),dataIndex: 'lossPaidPdl' },
        {title: t("claim.lossPaidBil"),dataIndex: 'lossPaidBil' },
        {title: t("claim.lossPaidTPLOther"),dataIndex: 'lossPaidTPLOther' },
        {title: t("claim.lossPaidOther"),dataIndex: 'lossPaidOther'  },
        {title: t("claim.lossOutstanding"),dataIndex: 'lossOutstanding',  },
        {title: t("claim.lossOutstandingMod"),dataIndex: 'lossOutstandingMod', },
        {title: t("claim.lossOutstandingVtpl"),dataIndex: 'lossOutstandingVtpl', },
        {title: t("claim.lossOutstandingPdl"),dataIndex: 'lossOutstandingPdl' },
        {title: t("claim.lossOutstandingBil"),dataIndex: 'lossOutstandingBil' },
        {title: t("claim.lossOutstandingTPLOther"),dataIndex: 'lossOutstandingTPLOther' },
        {title: t("claim.lossOutstandingOther"),dataIndex: 'lossOutstandingOther', },
        {title: t("claim.subrogation"),dataIndex: 'subrogation', },
        {title: t("claim.reportDate"),dataIndex: 'reportDate', width: 120, render: date => date && date.slice(0, 19).replace('T', ' '), },
        {title: t("claim.lossDate"), dataIndex: 'incidentTime', width: 120, render: date => date && date.slice(0, 19).replace('T', ' '), },
        {title: t("claim.registrationNumber"),dataIndex: 'registrationNumber'},
        {title: t("claim.registrationTime"),dataIndex: 'registrationTime', render: date => date && date.slice(0, 19).replace('T', ' '), },
        {title: t("claim.cause"),dataIndex: 'cause' },
        {title: t("claim.accidentLocation"),dataIndex: 'accidentLocation' },
        {title: t("claim.accidentDescription"),dataIndex: 'accidentDescription' },
        {title: t("claim.isSinglePartyClaim"), dataIndex: 'isSinglePartyClaim', render: formatEmpty },
        {title: t("claim.includeBodilyInjury"),dataIndex: 'includeBodilyInjury',  render: formatEmpty },
    ];

    return (
        <>
            <Modal
                width={1200}
                title={t('accident.claimHistory')}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
            >
                <Table
                    size="small"
                    scroll={{x: 4200}}
                    loading={loading}
                    pagination={pagination}
                    rowKey="id"
                    columns={columns}
                    dataSource={tableData} />
            </Modal>
            <Button onClick={onClickClaimHistory} loading={loading}>{t('accident.claimHistory')}</Button>
        </>
    )
}

export default ClaimHistory