import { Button,Popover,Checkbox} from 'antd';
import {SettingOutlined} from '@ant-design/icons';
import { useState } from 'react';
const CheckboxGroup = Checkbox.Group;
import { useTranslation } from 'react-i18next'

export default function ChangeColumns ({Columns, changeColumns}) {
  const { t } = useTranslation();
  const disableHeader = Columns.filter(a=> a.disabled)
  const plainOptions = Columns.filter(a=> !a.disabled).map(e=>({ label: e.title(), value: e.dataIndex }));
  const defaultCheckedList = Columns.filter(e=>e.default && !e.disabled).map(a=>a.dataIndex);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const onChange = list => {
    setCheckedList(list);
    let disList = disableHeader.map(e=>e.dataIndex)
    assembleColumns(disList.concat(list))
  };

  const onCheckAllChange = e => {
    setCheckedList(e ? plainOptions.map(e=>e.value) : []);
    changeColumns(e?Columns:disableHeader)
  };

  const assembleColumns = list => {
    let columnsList = list.map(e => Columns.find(a=>a.dataIndex === e));
    changeColumns(columnsList)
  }

  const content = (
    <div>
      <div style={{borderBottom: '1px solid #eee', paddingBottom:'10px', marginBottom: '10px', gap: '8px', display: 'flex'}}>
        <Button type="text" onClick={onCheckAllChange.bind(this, 1)}>{t('common.selectAll')}</Button>
        <Button type="text" onClick={onCheckAllChange.bind(this, 0)}>{t('common.cancleAll')}</Button>
      </div>
      <div>
        <CheckboxGroup style={{maxWidth: '500px', display: 'flex', flexDirection: 'column', maxHeight: '600px', overflow: 'scroll'}} options={plainOptions} value={checkedList} onChange={onChange} />
      </div>
    </div>
  );

  return <div>
    <Popover placement="left" content={content}>
      <Button style={{margin:'5px 10px'}} icon={<SettingOutlined />}>{t('common.Columns')}</Button>
    </Popover>
  </div>
    
}