import { Modal } from 'antd';
import i18n from './locales/i18n';
import { CheckCircleOutlined } from '@ant-design/icons';

import axios from 'axios';
import { loginRequest, msalConfig, protectedResources, b2cPolicies } from "./authConfig";
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal } from "@azure/msal-react";
const $msalInstance = new PublicClientApplication(
    msalConfig
);
const getToken = () => {
    return window.localStorage.getItem('token');
}
const getApplication = () => {
    return window.localStorage.getItem('application');
}

const redirectTologin = () => {
    $msalInstance.loginRedirect(loginRequest());
}
// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || '' + '/api';
axios.defaults.baseURL = '/api';

axios.interceptors.request.use(config => {
    config.baseURL = '/api'
    config.headers.Authorization = `Bearer ${getToken()}`;
    config.headers["X-Application"] = getApplication();

    const instance = $msalInstance
    if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(instance.getAllAccounts()[0])
    }
    instance.acquireTokenSilent({
        scopes: protectedResources.apiHello.scopes,
    }).then((response) => {
        window.localStorage.setItem('token', response.accessToken);
        window.localStorage.setItem('UserName', response.account.idTokenClaims.name);
        console.log("+++token update", response);
        // setToken(response.accessToken);
        console.log("+++acquireTokenSilent success, response role:", response.account.idTokenClaims.extension_UserRole);
    })

    return config;
}, error => {
    return Promise.resolve(error)
})
let timer;
const showError = errorContent => {
    if (errorContent.includes('524:Unassigned')) return;
    clearTimeout(timer);
    timer = setTimeout(() => {
        Modal.error({
            title: i18n.t('common.error'),
            width: 800,
            maskClosable: true,
            okButtonProps: {
                type: 'default',
                icon: <CheckCircleOutlined />
            },
            content: <div style={{ maxHeight: '400px', overflow: 'auto' }}>{errorContent}</div>
        });
    }, 1000)
}
const responseInterceptor = response => {
    const res = response.data;
    if (res.status && res.status === 401) {
        console.log('redirect to login');
        return Promise.reject()
    }
    if (res.status && res.status === 500) {
        showError(JSON.stringify(res));
        return Promise.reject(res.error);
    }
    if (res.errors) {
        showError(Object.keys(res.errors).map(errorKey => <div>{errorKey}: {res.errors[errorKey].join(';')}</div>));
        return Promise.reject(res.errors);
    } else if (res.success === false) {
        showError(i18n.t(res.msg));
        return Promise.reject(res.msg);
    }
    const code = res.status || res.code;
    if (code === 200) {
        return Promise.resolve(res.response || res.data);
    } else if (code === 4000) {
        return Promise.resolve(res.response || res.data);
    } else if (code === 400) {
        showError(i18n.t(res.message || res.msg));
        return Promise.reject();
    } else {
        showError(JSON.stringify(res));
        return Promise.reject()
    }
}
const responseErrorInterceptor = error => {
    if (error.response && error.response.status === 401) {
        redirectTologin();
        return Promise.reject()
    };
    const { status, statusText, url, data } = error.response;
    if (data && data.msg === 'Not allowed') {
        showError(i18n.t('tips.notAllowed'))
    } else {
        showError(`${status}:${statusText} ${url || ''}; ${JSON.stringify(data)}`)
    }
    return Promise.reject(error);
}
axios.interceptors.response.use(response => {
    return responseInterceptor(response);
}, error => {
    return responseErrorInterceptor(error);
})

const http = {
    post(url, param, config) {
        return axios.post(url, param, config)
    },
    put(url, param) {
        return axios.put(url, param)
    },
    delete(url) {
        return axios.delete(url)
    },
    get(url, params) {
        return axios.get(url, { params })
    }
}
export default http

export const callApiWithToken = async (accessToken, apiEndpoint) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    console.log("callApiWithToken", accessToken);

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(apiEndpoint, options)
        .then(response => JSON.stringify(response))
        .catch(error => console.log(error));
}

export function onBlobExport(url, params = {}, { method = 'get', params: queryParams } = {}) {
    const instance = axios.create();

    instance.interceptors.request.use(config => {
        config.baseURL = '/api'
        if (config.method === 'get') {
            config.params = config.params || {};
            config.params.Language = window.localStorage.language === 'cn' ? 'zh' : 'en';
        }
        if (queryParams) {
            config.params = queryParams;
        }
        return config;
    })
    instance.interceptors.response.use(response => response, error => {
        const { status, statusText, url, data } = error.response;

        var reader = new FileReader();
        reader.readAsText(data, 'utf-8');
        reader.onload = function (e) {
            const errorJson = JSON.parse(reader.result);
            if (errorJson && errorJson.msg === 'Not allowed') {
                showError(i18n.t('tips.notAllowed'))
            } else {
                showError(errorJson.msg || JSON.stringify(errorJson))
            }
            // Modal.error({
            //     title: i18n.t('common.error'),
            //     width: 800,
            //     content: errorJson.msg
            //     // content: `${status}:${statusText} ${url||''}; ${reader.result}`
            // });
        }

        return Promise.reject(error);
    })
    instance.defaults.headers.Authorization = `Bearer ${getToken()}`;
    instance.defaults.headers["X-Application"] = getApplication();

    return method === 'get' ? instance.get(url, { params, responseType: "blob" }) : instance.post(url, params, { responseType: "blob" })
}
export function downloadBlob(data, fileName) {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const blobUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.download = fileName
    a.href = blobUrl
    a.click()
    window.URL.revokeObjectURL(a.href)
}

export function downloadByBlob(url, fileName) {
    onBlobExport(`/Blob/Download/${encodeURIComponent(url)}`).then(res => {
        downloadBlob(res.data, fileName || res.headers['content-disposition'].match(/filename="(.*)";/)[1])
    });
}