import { Checkbox, Divider, Popover, Button, Row } from 'antd';
import {SettingOutlined} from '@ant-design/icons';

import { useState } from 'react';
import { useTranslation } from 'react-i18next'

const CheckboxGroup = Checkbox.Group;

export default function TableColumnFilter({columns, onSetColumns}) {
    const defaultCheckedList = columns.filter(column => !column.hide).map(column => column.key || column.dataIndex);
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    const { t } = useTranslation();
    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < columns.length);
        setCheckAll(list.length === columns.length);
        onSetColumns && onSetColumns(columns.map(column => {
            return {
                ...column,
                hide: !list.includes(column.key || column.dataIndex)
            }
        }))
    };

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? columns.map(column => column.key || column.dataIndex) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        onSetColumns && onSetColumns(columns.map(column => {
            return {
                ...column,
                hide: !e.target.checked
            }
        }))
    };
    const List = () => 
        <>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                {t('common.checkAll')}
            </Checkbox>
            <Divider style={{margin: '8px 0'}} />
            <CheckboxGroup value={checkedList} onChange={onChange} style={{maxHeight: '500px', overflow: 'auto'}} >
                {
                  columns.map(column => <Row style={{margin: '4px 0'}}><Checkbox value={column.key || column.dataIndex}>{column.title}</Checkbox></Row> )
                }
            </CheckboxGroup>
        </>
    return (
        <Popover placement="leftTop" content={List} trigger="click" arrowPointAtCenter>
            <Button icon={<SettingOutlined />}>{t('common.column')}</Button>
        </Popover>
    );
};