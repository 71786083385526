import { Table, Button, Modal, Form, Input, DatePicker, Radio, InputNumber,  Upload, Row, Col, message, Switch, Checkbox } from 'antd';
import {DownloadOutlined, UploadOutlined, ExclamationCircleOutlined, CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import { useState } from 'react';

const { RangePicker } = DatePicker;

import http, {downloadByBlob} from '../../http';
import {checkIfArrayIntervalOverLap} from './check';
import {useTranslation} from 'react-i18next'
import moment from 'moment';
import styles from './index.module.css';

const formProps = {
    layout: 'vertical',
    labelCol: {
        span: 22
    },
    wrapperCol: {
        span: 22
    }
    
};
const colProps = {
    lg: 12,
    md: 12,
    sm: 12
}
const tableData = [{id: 0}, {id: 1}, {id: 2}, {id:3}];
function transformYear(obj) {
    const ret = {...obj}
    Object.keys(ret).forEach(key => {
        if (key.includes('year') && ret[key]) {
            const year = ret[key];
            ret[key] =  /^\d{4}$/.test(year) ? moment(year) : moment();
        }
    })
    return ret;
};
function parseYear(obj) {
    const ret = {...obj};
    Object.keys(ret).forEach(key => {
        if (key.includes('year') && ret[key]) {
            ret[key] = ret[key].format('YYYY');
        }
    })
    return ret;
};
  
export default function DealCosting({tenantID}) {
    const { t } = useTranslation();

    const [visible, setVisible] = useState(false);
    
    const [loading ,setLoading] = useState(false);
    const [billPriceSource, setBillPriceSource] = useState('period');
    const [fullData, setFullData] = useState({});
    const [uploadFileList, setUploadFileList] = useState([])
    const [form] = Form.useForm();
    const [lossHistoryForm] = Form.useForm();
    const lossHistoryColumns = [
        {
            title: t('deal.costing.year'),
            render: (text, record, index) => 
                <Form.Item name={`year${index}`} style={{ width: '100%'}}>
                    <DatePicker disabledDate={current => disableYear(lossHistoryForm, current)} picker="year" />
                </Form.Item>
        },
        {
            title: t('deal.costing.lossRatio'),
            render: (text, record, index) => <Form.Item name={`lossRatio${index}`}  style={{ width: '100%'}}>
                <InputNumber
                    style={{ width: '100%'}}
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                /></Form.Item>
        },
        {
            title: t('deal.costing.ubiScore'),
            render: (text, record, index) => <Form.Item name={`ubiScore${index}`}  style={{ width: '100%'}}><Input></Input></Form.Item>
        },
        {
            title: t('deal.costing.evaPremium'),
            render: (text, record, index) => <Form.Item name={`evaPremium${index}`}  style={{ width: '100%'}}><Input></Input></Form.Item>
        }
    ]
    const [costingForm] = Form.useForm();
    const costingColumns = [
        {
            width: 120,
            title: t('deal.costing.period'),
            render: (text, record, index) => <Input disabled value={`${t('deal.costing.period')} ${index + 1}`}></Input>
        },
        {
            width: 300,
            title: t('deal.costing.dateRange'),
            render: (text, record, index) => 
                <Form.Item
                    name={`dateRange${index}`}
                    style={{ width: '100%'}}
                    >
                    <RangePicker allowClear={false}/>
                </Form.Item>
        },
        {
            title: t('deal.costing.riShare'),
            render: (text, record, index) => <Form.Item name={`riShare${index}`} style={{ width: '100%'}}><Input></Input></Form.Item>
        },
        {
            title: t('deal.costing.costingLossRatio'),
            render: (text, record, index) => <Form.Item name={`costingLossRatio${index}`}  style={{ width: '100%'}}>
                <InputNumber
                    style={{ width: '100%'}}
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                /></Form.Item>
        },
        {
            title: t('deal.costing.riCommission'),
            render: (text, record, index) => <Form.Item name={`riCommission${index}`} style={{ width: '100%'}}><Input></Input></Form.Item>
        },
        // {
        //     title: t('deal.costing.feeRatio'),
        //     render: (text, record, index) => <Form.Item name={`feeRatio${index}`}  style={{ width: '100%'}}>
        //         <InputNumber
        //             style={{ width: '100%'}}
        //             min={0}
        //             max={100}
        //             formatter={value => `${value}%`}
        //             parser={value => value.replace('%', '')}
        //         /></Form.Item>
        // }
    ]
    const [periodForm] = Form.useForm();

    const periodColumns = [
        {
            width: 120,
            title: t('deal.costing.period'),
            render: (text, record, index) => <Input disabled value={`${t('deal.costing.period')} ${index + 1}`}></Input>
        },
        {
            width: 300,
            title: t('deal.costing.price'),
            render: (text, record, index) =>
                <Form.Item name={`price${index}`} style={{ width: '100%', marginBottom: 0}} rules={[{ required: true, message: t('common.pleaseInput') + t('deal.costing.price') }]}>
                    <InputNumber min={0} style={{width: '100%'}} disabled={billPriceSource!=='period'}  value={`Period ${index + 1}`}></InputNumber>
                </Form.Item>
        },
    ]
    const [yearsForm] = Form.useForm();
    const yearsColumns = [
        {
            width: 120,
            title: t('deal.costing.years'),
            render: (text, record, index) => <Input disabled value={t(`deal.costing.years${index +1}`)}></Input>
        },
        {
            width: 300,
            title: t('deal.costing.price'),
            render: (text, record, index) => 
                <Form.Item name={`price${index}`} style={{ width: '100%', marginBottom: 0}} rules={[{ required: true, message: t('common.pleaseInput') + t('deal.costing.price') }]}>
                    <InputNumber min={0} style={{width: '100%'}} disabled={billPriceSource!=='year'} value={`Period ${index + 1}`}></InputNumber>
                </Form.Item>
        },
    ]
    const onShowCosting = () => {
        http.get(`/DealCosting/${tenantID}`).then(res => {
            form.setFieldsValue({
                vehicleCount: res.vehicleCount,
                vehicleUsage: res.vehicleUsage,
                fleetVehicleCount: res.fleetVehicleCount,
                vehicleType: res.vehicleType
            })
            setBillPriceSource(res.billPriceSource || 'period');
            setFullData(res);
            res.lossHistory && lossHistoryForm.setFieldsValue(transformYear(JSON.parse(res.lossHistory)));
            res.dealCostingPrices && setDealCostingPrices(res.dealCostingPrices, res.billPriceSource);
            // res.costing && costingForm.setFieldsValue(transformYear(JSON.parse(res.costing)));
            res.files && setUploadFileList(JSON.parse(res.files));
            setVisible(true);
        })
    }
    const disableYear = (form, current) => {
        const years = [];
        const values = form.getFieldsValue();
        Object.keys(values).forEach(key => {
            if (values[key] && key.includes('year')) {
                years.push(values[key].year());
            }
        })
        return years.length && years.includes(current.year());
    }
    const setDealCostingPrices = (data, type) => {
        data.forEach((one, index) => {
            costingForm.setFieldsValue({[`riShare${index}`]: one.riShare});
            costingForm.setFieldsValue({[`riCommission${index}`]: one.riCommision});
            costingForm.setFieldsValue({[`costingLossRatio${index}`]: one.costingLossRatio});
            costingForm.setFieldsValue({[`dateRange${index}`]: one.dataRange ? one.dataRange.split(',').map(one => moment(one)): [moment('2021-07-01'), moment('2021-07-01')]} );

            if (type === 'period') {
                periodForm.setFieldsValue({[`price${index}`]: one.price});
            } else {
                yearsForm.setFieldsValue({[`price${index}`]: one.price});
            }
        })
    };
    const getCosting = () => {
        const formValue = costingForm.getFieldValue();
        const result = new Array(4).fill({})
        return result.map((one, index) => ({
            period: index + 1 + '',
            dataRange: formValue[`dateRange${index}`] ? formValue[`dateRange${index}`].map(item => item.format('YYYY-MM-DD')).join(','): '',
            costingLossRatio: formValue[`costingLossRatio${index}`] ? formValue[`costingLossRatio${index}`] + '': null,
            riShare: formValue[`riShare${index}`],
            riCommision: formValue[`riCommission${index}`],
            price: billPriceSource === 'period' ? periodForm.getFieldsValue()[`price${index}`] : yearsForm.getFieldsValue()[`price${index}`]
        }));
    }
    const validateCostingDate = costings => {
        const datas = costings.filter(one => one.dataRange).map(one => one.dataRange.split(','));
        return datas.every((one, index) => {
            if (index) {
                return moment(one[0]).subtract(1, 'days').valueOf() === moment(datas[index - 1][1]).valueOf();
            } else {
                return true
            }
        })
    }
    const handleOk = async  () => {
        let validateForm = billPriceSource === 'period' ? periodForm : yearsForm;
        
        try {
            await validateForm.validateFields();
            const param = {
                id: fullData.id,
                tenantId: tenantID,
                ...form.getFieldsValue(),
                lossHistory: JSON.stringify(parseYear(lossHistoryForm.getFieldsValue())),
                billPriceSource,
                files: JSON.stringify(uploadFileList.filter(one => one.url).map(file => ({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: file.url,
                })))
            }
            param.dealCostingPrices = getCosting();
            if (!validateCostingDate(param.dealCostingPrices)) {
                message.error(t('deal.costing.yearDuplicateError'))
                return
            }
            setLoading(true);
            return http.put('/DealCosting', param).then(res => {
                message.success(t('common.success'))
                setVisible(false)
            }).finally( () => setLoading(false));
        } catch (e) {
            console.log(e)
        }
    }
    const uploadProps = {
        // maxCount: 2,
        defaultFileList: uploadFileList,
        name: 'file',
        action: `/api/Blob/upload`,
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            'X-Application': window.localStorage.getItem('application') || 'fleet',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let file = info.file;
                if (file.response.success) {
                    file.url = file.response.response;
                    file.name = file.response.response.split('/')[file.response.response.split('/').length -1];
                } else {
                    file.status = 'error';
                    file.response = file.response.msg;
                }
                setUploadFileList([...uploadFileList, file])
            } else if (info.file.status === 'removed') {
                const index = uploadFileList.findIndex(uploadFile => uploadFile.uid === info.file.uid);
                setUploadFileList([...uploadFileList.slice(0, index), ...uploadFileList.slice(index + 1)])
            }
        },
        onPreview(file) {
            file.url && downloadByBlob(file.url, file.name);
        }
    };
    return (
        <div style={{display: 'inline-block'}}>
            <Modal
                width={1000}
                title={t('deal.costing.dealCosting')}
                visible={visible}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={() => setVisible(false)}
                destroyOnClose
                maskClosable={false}
                footer={[
                  <Button key="back" icon={<CloseCircleOutlined />} onClick={() => setVisible(false)}>
                    {t('common.cancel')}
                  </Button>,
                  <Button key="submit" icon={<CheckCircleOutlined />} onClick={handleOk}>
                    {t('common.ok')}
                  </Button>
                ]}
                centered
                bodyStyle={{padding:0}}>
                <div style={{ maxHeight: '80vh', overflow:'auto', padding: '24px'}}>
                    <Form form={form} name="basic" {...formProps}>
                        <Row>
                            <Col {...colProps}>
                                <Form.Item name={"vehicleCount"} label={t('deal.costing.vehicleCount')}><Input></Input></Form.Item>
                            </Col>
                            <Col {...colProps}>
                                <Form.Item name="fleetVehicleCount" label={t('deal.costing.fleetVehicleCount')}><Input></Input></Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col {...colProps}>
                                <Form.Item name="vehicleUsage" label={t('deal.costing.vehicleUsage')}><Input></Input></Form.Item>
                            </Col>
                            <Col {...colProps}>
                                <Form.Item name="vehicleType" label={t('deal.costing.vehicleType')}><Input></Input></Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div className={styles.title}>{t('deal.costing.lossHistory')}</div>
                    <Form form={lossHistoryForm} layout='inline' preserve={false}>
                        <Table columns={lossHistoryColumns} dataSource={tableData} pagination={false} rowKey="id"></Table>
                    </Form>
                    <div className={styles.title} style={{marginTop: '16px'}}>{t('deal.costing.costing')}</div>
                    <Form
                        form={costingForm}
                        layout='inline'
                        preserve={false}
                        initialValues={{
                            dateRange0: [moment('2021-07-01'), moment('2021-07-01')],
                            dateRange1: [moment('2021-07-01'), moment('2021-07-01')],
                            dateRange2: [moment('2021-07-01'), moment('2021-07-01')],
                            dateRange3: [moment('2021-07-01'), moment('2021-07-01')],
                        }}
                    >
                        <Table columns={costingColumns} dataSource={tableData} pagination={false} rowKey="id" layout="fixed"></Table>
                    </Form>
                    <div className={styles.title} style={{marginTop: '16px'}}>{t('deal.costing.price')}</div>
                    <Radio.Group onChange={e => setBillPriceSource(e.target.value)} value={billPriceSource} style={{width: '100%'}}>
                        <Radio value='period'>{t('deal.costing.byPeriod')}</Radio>
                        <Form form={periodForm} layout='inline' preserve={false}>
                            <Table style={{width: '100%'}} columns={periodColumns} dataSource={tableData} pagination={false} rowKey="id" layout="fixed"></Table>
                        </Form>
                        <Radio value='year'>{t('deal.costing.byYear')}</Radio>
                        <Form form={yearsForm} layout='inline' preserve={false}>
                            <Table  style={{width: '100%'}} columns={yearsColumns} dataSource={tableData} pagination={false} rowKey="id" layout="fixed"></Table>
                        </Form>
                    </Radio.Group>
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}  style={{marginTop: '10px'}} >{t('common.upload')}</Button>
                    </Upload>
                </div>
            </Modal>
        <Button onClick={onShowCosting}>{t('deal.costing.costing')}</Button>
        </div>
    )
}