
import { useState, useEffect } from "react";
import http from '../../http';
import {listToTree} from '../../utils/array';
export const useRole = () => {
  const [data, setData] = useState(null);
  const [originData, setOriginData] = useState([]);
  const [loading, setLoading] = useState(false);
  const onSearch = ({roleName} = {}) => {
    setLoading(true);
    if (roleName) {
      setData(originData.filter(one => one.name.toLowerCase().includes(roleName.toLowerCase())));
      setLoading(false)
    } else {
      setData(null);
      http.get('/fleet-auth/role/list', {roleName}).then((res) => {
        setData(res);
        setOriginData(res);
      }).finally((err) => {
        setLoading(false);
      })
    }
    
  }
  useEffect(() => {
    onSearch()
  }, []);
  return {
    loading,
    data,
    onSearch
  };
};

export const useApplicationList = (roleId) => {
  const [data, setData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setData([]);
    http.get('/fleet-auth/application/list', {roleId}).then((res) => {
        res = res.map(one => {
          return {
            ...one,
            title: one.name,
            key: one.id
          }
        })
        setLoading(false);
        setData(res);
        setTreeData(listToTree(res));
      }).finally((err) => {
        setLoading(false);
      })
  }, [roleId]);
  return {
    loading,
    data,
    treeData,
  };
};
