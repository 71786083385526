import { Table, Card, Select, Button, Row, Col, Form, Checkbox, Spin } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useTable } from '../../../hooks/table';
import { useState } from 'react';
import http, { onBlobExport, downloadBlob }  from '../../../http';
import { useTranslation } from 'react-i18next'
import style from './index.module.css'
import { useEffect } from 'react'
import BubbleChart from '../components/BubbleChart'
import VehicleType from '../components/VehicleType'
import ChangeColumns from '../components/ChangeColumns'
import {DataCard} from '../../../components/DataCard/card';

export default function VehicleRisk () {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const columns = [
    { "title": t.bind(null, 'vehicleRiskTable.PlateNumber'), "dataIndex": "PlateNumber", disabled: true, default: true }, 
    { "title": t.bind(null, 'vehicleRiskTable.VIN'), "dataIndex": "VIN" }, 
    { "title": t.bind(null, 'vehicleRiskTable.VehicleType'), "dataIndex": "VehicleType" }, 
    { "title": t.bind(null, 'vehicleRiskTable.VehicleAge'), "dataIndex": "VehicleAge",sorter: {compare: (a, b) => a.VehicleAge - b.VehicleAge} }, 
    { "title": t.bind(null, 'vehicleRiskTable.VehicleLoad'), "dataIndex": "VehicleLoad", disabled: true, default: true, sorter: {compare: (a, b) => a.VehicleLoad - b.VehicleLoad} }, 
    { "title": t.bind(null, 'vehicleRiskTable.VehicleLength'), "dataIndex": "VehicleLength" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DeviceType'), "dataIndex": "DeviceType", disabled: true, default: true }, 
    { "title": t.bind(null, 'vehicleRiskTable.dealsName'), "dataIndex": "DealsName", disabled: true, default: true }, 
    { "title": t.bind(null, 'vehicleRiskTable.Period'), "dataIndex": "Period", disabled: true, default: true }, 
    { "title": t.bind(null, 'vehicleRiskTable.Insurer'), "dataIndex": "Insurer" }, 
    { "title": t.bind(null, 'vehicleRiskTable.Partner'), "dataIndex": "Partner" }, 
    { "title": t.bind(null, 'vehicleRiskTable.PolicyStartDate'), "dataIndex": "PolicyStartDate" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ExpYears'), "dataIndex": "ExpYears" }, 
    { "title": t.bind(null, 'vehicleRiskTable.Premium'), "dataIndex": "Premium", disabled: true, default: true }, 
    { "title": t.bind(null, 'vehicleRiskTable.EarnedPremium'), "dataIndex": "EarnedPremium" }, 
    { "title": t.bind(null, 'vehicleRiskTable.TotalCaseCount'), "dataIndex": "TotalCaseCount", sorter: {compare: (a, b) => a.TotalCaseCount - b.TotalCaseCount} }, 
    { "title": t.bind(null, 'vehicleRiskTable.TotalLossPaid'), "dataIndex": "TotalLossPaid", disabled: true, default: true, sorter: {compare: (a, b) => a.TotalLossPaid - b.TotalLossPaid} }, 
    { "title": t.bind(null, 'vehicleRiskTable.SwissReScore'), "dataIndex": "SwissReScore", disabled: true, default: true, sorter: {compare: (a, b) => a.SwissReScore - b.SwissReScore} }, 
    { "title": t.bind(null, 'vehicleRiskTable.FirstTripDay'), "dataIndex": "FirstTripDay", sorter: {compare: (a, b) => a.FirstTripDay - b.FirstTripDay} },
    { "title": t.bind(null, 'vehicleRiskTable.ObservedDays'), "dataIndex": "ObservedDays", sorter: {compare: (a, b) => a.ObservedDays - b.ObservedDays} }, 
    { "title": t.bind(null, 'vehicleRiskTable.ActiveDays'), "dataIndex": "ActiveDays", sorter: {compare: (a, b) => a.ActiveDays - b.ActiveDays} }, 
    { "title": t.bind(null, 'vehicleRiskTable.TripCount'), "dataIndex": "TripCount" }, 
    { "title": t.bind(null, 'vehicleRiskTable.TotalMileage'), "dataIndex": "TotalMileage", disabled: true, default: true, sorter: {compare: (a, b) => a.TotalMileage - b.TotalMileage} }, 
    { "title": t.bind(null, 'vehicleRiskTable.AnnualMileage'), "dataIndex": "AnnualMileage", sorter: {compare: (a, b) => a.AnnualMileage - b.AnnualMileage} }, 
    { "title": t.bind(null, 'vehicleRiskTable.TotalDuration'), "dataIndex": "TotalDuration" }, 
    { "title": t.bind(null, 'vehicleRiskTable.AnnualDuration'), "dataIndex": "AnnualDuration" }, 
    { "title": t.bind(null, 'vehicleRiskTable.Region'), "dataIndex": "Region" }, 
    { "title": t.bind(null, 'vehicleRiskTable.WarningCount'), "dataIndex": "WarningCount", disabled: true, default: true, sorter: {compare: (a, b) => a.WarningCount - b.WarningCount} }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASForwardCollision'), "dataIndex": "ADASForwardCollision" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASFrequentChangeLane'), "dataIndex": "ADASFrequentChangeLane" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASFollowClosely'), "dataIndex": "ADASFollowClosely" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASPedestrianCollision'), "dataIndex": "ADASPedestrianCollision" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASLaneDeparture'), "dataIndex": "ADASLaneDeparture" }, 
    { "title": t.bind(null, 'vehicleRiskTable.BSDRightsideRear'), "dataIndex": "BSDRightsideRear" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMFatigueDriving'), "dataIndex": "DSMFatigueDriving" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMSmoking'), "dataIndex": "DSMSmoking" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMCellphone'), "dataIndex": "DSMCellphone" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMAnomalyDriving'), "dataIndex": "DSMAnomalyDriving" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMDistractedDriving'), "dataIndex": "DSMDistractedDriving" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMDriverIdentify'), "dataIndex": "DSMDriverIdentify" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMChangeDriver'), "dataIndex": "DSMChangeDriver" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMActiveCapture'), "dataIndex": "DSMActiveCapture" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMInfraredBlocking'), "dataIndex": "DSMInfraredBlocking" }, 
    { "title": t.bind(null, 'vehicleRiskTable.BSDLeftsideRear'), "dataIndex": "BSDLeftsideRear" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMDriverUnbelt'), "dataIndex": "DSMDriverUnbelt" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMLoosenSteering'), "dataIndex": "DSMLoosenSteering" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMDriverDrink'), "dataIndex": "DSMDriverDrink" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASLowSpeedCollision'), "dataIndex": "ADASLowSpeedCollision" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASOverSpeedInfo'), "dataIndex": "ADASOverSpeedInfo" }, 
    { "title": t.bind(null, 'vehicleRiskTable.WaringPer100km'), "dataIndex": "WaringPer100km" }, 
    { "title": t.bind(null, 'vehicleRiskTable.MainDrivingArea'), "dataIndex": "MainDrivingArea", with:200, disabled: true, default: true }, 
    { "title": t.bind(null, 'vehicleRiskTable.DrivingInMultipleProvinces'), "dataIndex": "DrivingInMultipleProvinces", disabled: true, default: true }, 
    { "title": t.bind(null, 'vehicleRiskTable.FixedDrivingLine'), "dataIndex": "FixedDrivingLine", disabled: true, default: true }, 
    { "title": t.bind(null, 'vehicleRiskTable.ParkingPoints'), "dataIndex": "ParkingPoints", disabled: true, default: true }, 
    { "title": t.bind(null, 'vehicleRiskTable.DrivingHourRate'), "dataIndex": "DrivingHourRate", disabled: true, default: true }, 
    { "title": t.bind(null, 'vehicleRiskTable.RegularityRate'), "dataIndex": "RegularityRate", disabled: true, default: true }, 
    { "title": t.bind(null, 'vehicleRiskTable.PolicyCountHis'), "dataIndex": "PolicyCountHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ExpYearsHis'), "dataIndex": "ExpYearsHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.PremiumHis'), "dataIndex": "PremiumHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.EarnedPremiumHis'), "dataIndex": "EarnedPremiumHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.TotalCaseCountHis'), "dataIndex": "TotalCaseCountHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.TotalLossPaidHis'), "dataIndex": "TotalLossPaidHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ObservedDaysHis'), "dataIndex": "ObservedDaysHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ActiveDaysHis'), "dataIndex": "ActiveDaysHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.TripCountHis'), "dataIndex": "TripCountHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.TotalMileageHis'), "dataIndex": "TotalMileageHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.AnnualMileageHis'), "dataIndex": "AnnualMileageHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.TotalDurationHis'), "dataIndex": "TotalDurationHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.AnnualDurationHis'), "dataIndex": "AnnualDurationHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.WarningCountHis'), "dataIndex": "WarningCountHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASForwardCollisionHis'), "dataIndex": "ADASForwardCollisionHis" },
    { "title": t.bind(null, 'vehicleRiskTable.ADASFrequentChangeLaneHis'), "dataIndex": "ADASFrequentChangeLaneHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASFollowCloselyHis'), "dataIndex": "ADASFollowCloselyHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASPedestrianCollisionHis'), "dataIndex": "ADASPedestrianCollisionHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASLaneDepartureHis'), "dataIndex": "ADASLaneDepartureHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.BSDRightsideRearHis'), "dataIndex": "BSDRightsideRearHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMFatigueDrivingHis'), "dataIndex": "DSMFatigueDrivingHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMSmokingHis'), "dataIndex": "DSMSmokingHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMCellphoneHis'), "dataIndex": "DSMCellphoneHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMAnomalyDrivingHis'), "dataIndex": "DSMAnomalyDrivingHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMDistractedDrivingHis'), "dataIndex": "DSMDistractedDrivingHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMDriverIdentifyHis'), "dataIndex": "DSMDriverIdentifyHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMChangeDriverHis'), "dataIndex": "DSMChangeDriverHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMActiveCaptureHis'), "dataIndex": "DSMActiveCaptureHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMInfraredBlockingHis'), "dataIndex": "DSMInfraredBlockingHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.BSDLeftsideRearHis'), "dataIndex": "BSDLeftsideRearHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMDriverUnbeltHis'), "dataIndex": "DSMDriverUnbeltHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMLoosenSteeringHis'), "dataIndex": "DSMLoosenSteeringHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.DSMDriverDrinkHis'), "dataIndex": "DSMDriverDrinkHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASLowSpeedCollisionHis'), "dataIndex": "ADASLowSpeedCollisionHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.ADASOverSpeedInfoHis'), "dataIndex": "ADASOverSpeedInfoHis" }, 
    { "title": t.bind(null, 'vehicleRiskTable.WaringPer100kmHis'), "dataIndex": "WaringPer100kmHis" },
  ]
  // default dispaly table head
  const [initLoading, setInitLoading] = useState(true)
  const [chartDataLoading, setChartDataLoading] = useState(true)
  const [downDataLoading, setDownDataLoading] = useState(false)
  const [selectOptionsData, setSelectOptionsData] = useState({})
  const [chartData, setChartData] = useState({})
  const [vehicleSummary, setVehicleSummary] = useState({})
  const [downExclParams, setDownExclParams] = useState({strDisPlayAll:1})


  // default dispaly table head
  const defaultColumns = columns.filter(e => e.default)
  // dynamic regulation table head
  const [column, setColumns] = useState(defaultColumns)

  // dynamic regulation table head
  const { tableData, pagination, loading, setQuery, handleTableChange } = useTable(getData);

  const getInit = () => {
    return http.get('/vehicleriskapi/VehicleRisk/GetInitData')
  }

  const getChartData = async (param={}) => {
    let res = await http.get('/vehicleriskapi/VehicleRisk/GetData', param)
    setChartData(res)
    setVehicleSummary(res.VehicleSummary[0] || {})
    setChartDataLoading(false)
  }

  // get table date
  async function getData (param) {
    let res = await http.get('/vehicleriskapi/VehicleRisk/GetDetailData', {
      PageNum: pagination.current,
      PageSize: pagination.pageSize,
      strDisPlayAll: 1,
      ...param,
    })
    return res
  }

  const onFormSearch = (e) => {
    e.strDisPlayAll = e.strPolicy[0] ? 1 : 0
    delete e.strPolicy
    setChartDataLoading(true)
    setQuery(e)
    getChartData(e)
  }

  useEffect(() => {
    setInitLoading(false)
    getInit().then(res => {
      setInitLoading(false)
      setSelectOptionsData(res)
    })
  }, [])

  useEffect(() => {
    getChartData({strDisPlayAll: '1'})
    form.setFieldsValue({strPolicy: ['1']});
  }, [])

  const onValuesChange = async (e) => {
    const row = (await form.validateFields())
    row.strDisPlayAll = row.strPolicy[0] ? 1 : 0
    delete e.strPolicy
    setDownExclParams(row)
  }

  const exportExcl = () => {
    let params = {
      la: localStorage.getItem('language'),
      ...downExclParams
    }
    setDownDataLoading(true)
    onBlobExport('/vehicleriskapi/VehicleRisk/GetExcel', params, { 'method': 'get' }).then(res => {
      setDownDataLoading(false)
      downloadBlob(res.data, 'Vehicle Risk')
    });
  }

  const formatPercent = num => {
    if (!num) return '-';
    return (num * 100).toFixed(2) + '%';
  }

  const { Option } = Select;
  return <div>
    <div className={style.titleBox}>
      <span className={style.title}>{t('vehicleRisk.VehicleRisk')}</span>
    </div>
    <Spin spinning={initLoading}>
      <Form layout="vertical" form={form} onFinish={onFormSearch} onValuesChange={onValuesChange}>
        <Row gutter={[8]}>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name='strDeal'
              label={t('vehicleRisk.Deal')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.DealData && selectOptionsData.DealData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name='strInsurer'
              label={t('vehicleRisk.Insurer')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.InsurerData && selectOptionsData.InsurerData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name='strPartner'
              label={t('vehicleRisk.Partner')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.PartnerData && selectOptionsData.PartnerData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name='strRegion'
              label={t('vehicleRisk.Region')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.RegionData && selectOptionsData.RegionData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={4}>
            <Form.Item
              name='strVehicleType'
              label={t('vehicleRisk.VehicleType')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.VehicleTypeData && selectOptionsData.VehicleTypeData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          </Row>
          <Row gutter={[8]}>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name='strLoad'
              label={t('vehicleRisk.Load')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.VehicleLoadData && selectOptionsData.VehicleLoadData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name='strAge'
              label={t('vehicleRisk.Age')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.VehicleAgeData && selectOptionsData.VehicleAgeData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name='strLength'
              label={t('vehicleRisk.Length')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.VehicleLengthData && selectOptionsData.VehicleLengthData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={3}>
            <Form.Item
              name='strPolicy'
              label={t('vehicleRisk.Policy')}
            >
              <Checkbox.Group options={[ { label: 'True', value: '1' }]} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <div style={style} className={style.btnbox}>
              <Button className={style.submitbtn} htmlType="submit"><SearchOutlined />{t('common.search')}</Button>
              <Button className={style.exportbtn} loading={downDataLoading} onClick={exportExcl}><DownloadOutlined />{t('common.export')}</Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
    <Spin spinning={chartDataLoading}>
    <Row style={{marginBottom: "24px"}}>
      <Col span={12}><DataCard title={t('vehicleRisk.Vehicle')}  num={vehicleSummary.Vehicle}></DataCard></Col>
      <Col span={12}><DataCard title={t('vehicleRisk.ClaimCount')} num={vehicleSummary.ClaimCount}></DataCard></Col>
      {/* <Col span={8}><DataCard title={t('vehicleRisk.LossRatio')} num={formatPercent(vehicleSummary.LossRatio)}></DataCard></Col> */}
    </Row>
    </Spin>
    <Spin spinning={chartDataLoading}>
      <BubbleChart chartList={chartData.dtVehicleRiskBubbleChart}/>
    </Spin>
    <Spin spinning={chartDataLoading}>
    <div style={{ 'marginTop': '20px', 'marginBottom': '20px', 'background': "#fff", 'paddingTop': '10px' }}>
      <Row>
        <Col className="gutter-row" span={12}>
          <VehicleType chartList={chartData.VehicleType} type="VehicleType"/>
        </Col>
        <Col className="gutter-row" span={12}>
          <VehicleType chartList={chartData.VehicleLoad} type="Load" unit="kg"/>
        </Col>
        <Col className="gutter-row" span={12}>
          <VehicleType chartList={chartData.VehicleAge} type="Age" unit="year"/>
        </Col>
        <Col className="gutter-row" span={12}>
          <VehicleType chartList={chartData.VehicleLength} type="Length" unit="mm"/>
        </Col>
      </Row>
    </div>
    </Spin>
    <div className={style.tablebot}>{t('vehicleRisk.VehicleRiskFactorDetail')}</div>
    <div className={style.right}>
      <ChangeColumns Columns={columns} changeColumns={setColumns} />
    </div>
    <Table pagination={pagination} scroll={{ x: column.length * 150 }} onChange={handleTableChange} columns={column} loading={loading} dataSource={tableData} />
  </div>
}

