import { Table, Row, Col, Button, Modal, Form, Input, Select, DatePicker } from 'antd';
import {useState} from 'react';
import {useTable} from '../../../hooks/table';
import { useTranslation } from 'react-i18next'
import http from '../../../http';
import moment from 'moment';
import styles from './index.module.css';

const StatusTag = ({status}) => {
    const { t } = useTranslation();
    return status === 1 
        ? <span className={styles.green}>{t('bills.pass')}</span>
        : status === -1 
            ? <span className={styles.red}>{t('bills.fail')}</span>
            : <span>{t('bills.null')}</span>
}
const BillHistory = ({params: formParams}) => {
    const { t } = useTranslation();

    const [modalVisible, setModalVisible] = useState(false);
    const {tableData, pagination, loading, setQuery, onSearch} = useTable(getData, false);
    function getData(params) {
      params.orderBy = 'billStatus'
      return http.get('/bill/bill-quarter/query', { 
        pageNum: pagination.current,
        pageSize: pagination.pageSize, 
        ...params
      })
    }
    const onClickBillHistory = () => {
        onSearch(formParams).then(res => {
            setModalVisible(true)
        })
    }
    const columns = [
      { title: t("billCheck.billQuarter"),dataIndex: 'billQuarter'},
      { title: t("fields.deal"),dataIndex: 'deal'},
      { title: t("bills.billStatus"), dataIndex: 'billStatus', render: status => <StatusTag status={status}/>},
      { title: t("bills.billPrice"), dataIndex: 'billPrice'},
      { title: t("bills.confirmedBy") ,dataIndex: 'confirmedBy'},
    ];

    return (
        <>
            <Modal
                width={1200}
                title={t('accident.billHistory')}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
            >
                <Table
                    size="small"
                    // scroll={{x: 2800}}
                    loading={loading}
                    pagination={pagination}
                    rowKey="id"
                    columns={columns}
                    dataSource={tableData} />
            </Modal>
            <Button onClick={onClickBillHistory} loading={loading}>{t('accident.billHistory')}</Button>
        </>
    )
}

export default BillHistory