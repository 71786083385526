import {
    MenuFoldOutlined,
    CarOutlined,
    MenuUnfoldOutlined,
    SnippetsOutlined,
    UserOutlined,
    TeamOutlined,
    PieChartOutlined,
    DashboardOutlined,
    ProfileOutlined,
    FileTextOutlined,
    FileSearchOutlined,
    AuditOutlined,
    InsuranceOutlined,
    RocketOutlined,
    SettingOutlined,
    CopyOutlined,
    ContainerOutlined,
    ControlOutlined,
    ClusterOutlined,
    DeploymentUnitOutlined,
    MoneyCollectOutlined,
    RadarChartOutlined,
    DotChartOutlined,
    AlertOutlined,
    EnvironmentOutlined,
    FileDoneOutlined,
    FolderOpenOutlined,
    ReconciliationOutlined,
    FolderViewOutlined,
    ApartmentOutlined,
    UserSwitchOutlined,
    MailOutlined,
    ToolOutlined,
    ReadOutlined
} from '@ant-design/icons'
const config = {
    'System Settings': { key: 'sysytem-setting', icon: <SettingOutlined />, title: 'menu.systemSetting'},
    'Privacy Authorization Log': {key: 'privary-auth-log', link: '/privacy-auth-log', icon: <FileTextOutlined />, title: 'menu.privacyLog'},
    'Welcome': { key: 'welcome', link: '/welcome', icon: <FileTextOutlined />, title: 'menu.welcome'},
    // 'User Management': { key: 'user-management', link: '/fleet/user-management', icon: <UserOutlined />, title: 'menu.userMangement'},
    // 'Role Management': { key: 'role-management', link: '/fleet/role-management', icon: <TeamOutlined />, title: 'menu.roleManagement'},
    'Deal Management': { key: 'deal-management', link: '/fleet/deal-management', icon: <AuditOutlined />, title: 'menu.dealManagement'},
    'Vendor Management': { key: 'vendor-management', link: '/fleet/vendor-management', icon: <RocketOutlined />, title: 'menu.vendorManagement'},
    'Insurer Management': { key: 'insurer-management', link: '/fleet/insurer-management', icon: <InsuranceOutlined />, title: 'menu.insurerManagement'},
    'Bill Management': { key: 'bill-management', icon: <MoneyCollectOutlined />, title: 'menu.billManagement',},
    'Bill Management P3': { key: 'bill-management-p3', icon: <ProfileOutlined />, title: 'menu.billManagementP3'},
    'Vehicle Bill List': { key: 'bill-list', link: '/fleet/bill-list', icon: <ProfileOutlined />, title: 'menu.billList'},
    'Bill Check': { key: 'billCheck', link: '/fleet/billCheck', icon: <FileSearchOutlined />, title: 'menu.billCheck'},
    'Bills': { key: 'bills', link: '/fleet/bills', icon: <ProfileOutlined />, title: 'menu.bills'},
    'Files': { key: 'files', link: '/fleet/files', icon: <FileTextOutlined />, title: 'menu.files'},
    'TMX Dashboard': { key: 'tmx-dashboard', icon: <DashboardOutlined />, title: 'menu.tmxDashboard'},
    'Data Collection': { key: 'data-collection', link: '/fleet/data-collection', icon: <PieChartOutlined />, title: 'menu.dataCollection'},
    'Data Quality': { key: 'data-quality', link: '/fleet/data-quality', icon: <ProfileOutlined />, title: 'menu.dataQuality'},
    'Data Insight': { key: 'data-insight', link: '/fleet/data-insight', icon: <FileTextOutlined />, title: 'menu.dataInsight' },
    'Partner Evaluation': { key: '/partner-evaluation', link: '/fleet/partner-evaluation', icon: <RadarChartOutlined />, title: 'menu.partnerEvaluation' },
    'Deal Evaluation': { key: '/deal-evaluation', link: '/fleet/deal-evaluation', icon: <FileTextOutlined />, title: 'menu.dealEvaluation' },
    'Trip Quality Detail': { key: '/trip-quality-detail', link: '/fleet/trip-quality-detail', icon: <FileTextOutlined />, title: 'menu.tripQualityDetail' },
    'Vehicle Information Management' : {key: 'vehicle-information', link: '/fleet/vehicle-information', icon: <CarOutlined />, title: 'menu.vehicleInformation'},
    'Insurance Bills Management': {key: 'insurance-bills', link: '/fleet/insurance-bills', icon: <FileTextOutlined />, title: 'menu.insuranceBills'},
    'Policy Information Management': {key: 'policy-information', link: '/fleet/policy-information', icon: <FileTextOutlined />, title: 'menu.policyInformation'},
    'Claim Information Management': {key: 'claim-information', link: '/fleet/claim-information', icon: <FileTextOutlined />, title: 'menu.claimInformation'},
    'Claim Agg Data Management': {key: 'claim-agg-data', link: '/fleet/claim-agg-data', icon: <FileTextOutlined />, title: 'menu.claimAggData'},
    'risk-analysis': {key: 'risk-analysis', icon: <DotChartOutlined />, title: 'menu.riskAnalysis'},
    'feature-analysis': {key: 'feature-analysis', icon: <ContainerOutlined />,link: '/fleet/feature-analysis', title: 'menu.featureAnalysis'},
    'vehicle-risk': {key: 'vehicle-risk', icon: <ContainerOutlined />,link: '/fleet/vehicle-risk', title: 'menu.vehicleRisk'},
    'recommendation': {key: 'recommendation', icon: <FileDoneOutlined />, title: 'menu.Recommendation'},
    'recommendation-list': {key: 'recommendation-list', icon: <DeploymentUnitOutlined />,link: '/fleet/recommendation', title: 'menu.Recommendation'},
    'Accident Inquire': {key: 'accident-inqure', link: '/fleet/accident-inquire', icon: <EnvironmentOutlined />, title: 'menu.accidentInquire'},
    'Claim List': {key: 'claim-list', link: '/fleet/claim-list', icon: <FileTextOutlined />, title: 'menu.claimList'},
    'Claim Report': {key: 'claim-report', link: '/fleet/claim-report', icon: <AlertOutlined />, title: 'menu.claimReport'},
    'risk-analysis': {key: 'risk-analysis', icon: <DotChartOutlined />, title: 'menu.riskAnalysis'},
    'feature-analysis': {key: 'feature-analysis', icon: <ContainerOutlined />,link: '/fleet/feature-analysis', title: 'menu.featureAnalysis'},
    'RenewSuggestion': {key: 'renew-suggestion', icon: <ContainerOutlined />,link: '/fleet/renew-suggestion', title: 'menu.renewSuggestion'},
    'SuggestionRules': {key: 'suggestion-rule', icon: <ContainerOutlined />,link: '/fleet/suggestion-rule', title: 'menu.suggestionRule'},
    'vehicle-risk': {key: 'vehicle-risk', icon: <ContainerOutlined />,link: '/fleet/vehicle-risk', title: 'menu.vehicleRisk'},
    'portfolio-management': {key: 'portfolio-management', icon: <ControlOutlined />, title: 'menu.portfolioManagement'},

    'truck-pricing': {key: 'truck-pricing', icon: <ToolOutlined  />, title: 'menu.TruckPricingTool'},
    'truck-pricing-api': {key: 'truck-pricing-api', icon: <ToolOutlined />, link: '/fleet/truck-pricing-api', title: 'menu.TruckPricingToolResult'},
    'truck-pricing-api-text': {key: 'truck-pricing-api-text', icon: <ReadOutlined />, link: '/fleet/truck-pricing-api-text', title: 'menu.TruckPricingToolDocument'},

    'portfolio-summary': { key: 'portfolio-summary', link: '/fleet/portfolio-summary', icon: <CopyOutlined />, title: 'menu.portfolioSummary' },
    'triangle': { key: 'triangle', link: '/fleet/triangle', icon: <CopyOutlined />, title: 'menu.triangle' },
    'policy-analysis': { key: 'policy-analysis', link: '/fleet/policy-analysis', icon: <CopyOutlined />, title: 'menu.policyAnalysis' },
    'claim-analysis': { key: 'claim-analysis', link: '/fleet/claim-analysis', icon: <CopyOutlined />, title: 'menu.claimAnalysis' },

    'Account': { key: 'account-management', icon: <ApartmentOutlined />, title: 'menu.account'},
    'Account Page': { key: 'account-management-page', link: '/aaa/account-management', icon: <ApartmentOutlined />, title: 'menu.account'},
    'User': { key: 'aaa-user', icon: <UserOutlined />, title: 'menu.userMangement'},
    'User List': { key: 'aaa-user-management', link: '/aaa/user-management', icon: <UserOutlined />, title: 'menu.userMangement'},
    'Role': { key: 'aaa-role', icon: <UserSwitchOutlined />, title: 'menu.roleManagement'},
    'Role List': { key: 'aaa-role-management', link: '/aaa/role-management', icon: <UserSwitchOutlined />, title: 'menu.roleManagement'},

    'Audit': { key: 'aaa-audit', icon: <AuditOutlined />, title: 'menu.Audit'},
    'Audit Log': { key: 'aaa-audit-log', link: '/aaa/Audit-management/activity-log', icon: <ReconciliationOutlined />, title: 'menu.activityLog'},
    'Email Log': { key: 'aaa-email-log', link:'/aaa/Audit-management/emails', icon: <MailOutlined />, title: 'menu.emailLog'},
}

export default config;