import { Tabs } from 'antd';
import {useTranslation} from 'react-i18next'
import Analysis from './Analysis'
import LossPivot from './LossPivot'
import LossCorrelation from './LossCorrelation'

const { TabPane } = Tabs;

export default function Index() {
  const { t } = useTranslation();

  return <div className="container">
          <Tabs defaultActiveKey="1">
            <TabPane tab={t('portfolio.portfolioSummary')} key="1">
              <Analysis></Analysis>
            </TabPane>
            <TabPane tab={t('portfolio.lossPivotTable')} key="2">
            <LossPivot></LossPivot>
            </TabPane>
            <TabPane tab={t('portfolio.lossCorrelationChart')} key="3">
             <LossCorrelation></LossCorrelation>
            </TabPane>
          </Tabs>
        </div> 
}
