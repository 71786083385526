import { Spin, Row, Col, Form, Input } from 'antd';
import { useState } from 'react';
import http from '../../../../http';
import { useEffect } from 'react'
import { setChart } from '../../../AccidentInquire/chart';
import { useTranslation } from 'react-i18next'
import moment from 'moment';

export default function PreviewClaimReport ({ serchParams }) {
  // default dispaly table head
  const { t } = useTranslation();

  const [chartLoading, setChartLoading] = useState(true)
  const [form] = Form.useForm();

  const initTmxData = (params) => {

    return http.get('/accident/recent/data', params).then(res => {
      setChart(res)
      setChartLoading(false)
    });
  }

  useEffect(() => {
    console.log('---serchParams', serchParams)
    form.setFieldsValue({
      ...serchParams,
      incidentTime: moment(serchParams.timestamp).format('YYYY-MM-DD HH:mm:ss')
    });
    initTmxData({
      strDataCope: serchParams.strDataCope,
      plateNumber: serchParams.plateNumber,
      timestamp: new Date(serchParams.timestamp).getTime()
    })
  }, [])


  return <div>

    <Spin spinning={chartLoading}>
      <Form form={form} layout="vertical">
        <Row gutter={16} align="middle">
          <Col span={6}>
            <Form.Item label={t('claim.lossDate')} name="incidentTime">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('claim.totalLoss')} name="lossPaid">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('fields.insurerName')} name="insurerName">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('fields.deal')} name="deal">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={t('claim.cause')} name="cause">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ color: '#627D77', fontWeight: 'bold', fontSize: '20px',marginBottom: '8px'}}>{t('accident.tmxData')}</div>
      <Row>
        <Col><div id="adasFCWCount" style={{ width: '1000px', height: '320px' }}></div></Col>
        <Col><div id="adasLDWCount" style={{ width: '1000px', height: '320px' }}></div></Col>
      </Row>
      <Row>
        <Col><div id="dsmCallingCount" style={{ width: '1000px', height: '320px' }}></div></Col>
        <Col><div id="dsmfatigueDrivingCount" style={{ width: '1000px', height: '320px' }}></div></Col>
      </Row>
      <Row>
        <Col><div id="mileageTotal" style={{ width: '1000px', height: '320px' }}></div></Col>
        <Col><div id="tripCount" style={{ width: '1000px', height: '320px' }}></div></Col>
      </Row>
    </Spin>
  </div>
}