import { Spin, Modal, Form, Input, Image, Row, Col } from 'antd';
import http from '../../../http';
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react';
import {RightCircleOutlined, LeftCircleOutlined} from '@ant-design/icons';
import '../index.css';
import moment from 'moment';
const WarningInfo = props => {
    const { t } = useTranslation();
    const [warningModalVisible, setWarningModalVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm()
    const [previewIndex, setPreviewIndex] = useState(0);
    const [previewList, setPreviewList] = useState([]);
    useEffect(() => {
        setLoading(true);
        http.get('/accident/warning/info', props.warningParams).then(res => {
            if (res.warningType === 'ADAS') {
                res.event = (res.event >= 8 && res.event <= 15) || (res.event >= 18 && res.event <= 31) ? 0 : res.event;
            }else if (res.warningType === 'DMS') {
                res.event = (res.event >= 6 && res.event <= 15) || (res.event >= 18 && res.event <= 31) ? 0 : res.event;
            }
            res.timestamp = res.timestamp.replace('-', '/').replace('-', '/').replace('-', ' ').replaceAll('-', ':')
            form.setFieldsValue(res);
            initList(res);
            // http.get('https://restapi.amap.com/v3/geocode/regeo?parameters', {key: '0c054b321017ec2c0c4680f20aba7b98', location:`${res.longitude},${res.latitude}`})
        }).finally(() => setLoading(false))
    }, [props.warningParams]);

    const initList = info => {
        let list = [];
        info.image1FileUrl && list.push(<Image width={180} height={180} src={ info.image1FileUrl } />);
        info.image2FileUrl && list.push(<Image width={180} height={180} src={ info.image2FileUrl } />);
        info.image3FileUrl && list.push(<Image width={180} height={180} src={ info.image3FileUrl } />);
        info.videoFileUrl && list.push(<video src={info.videoFileUrl} controls style={{width: '400px', height: '200px'}}></video>);
        setPreviewList(list);
    }
    const onCancel = () => {
        setWarningModalVisible(false);
        props.onCancel && props.onCancel();
    }
    return ( 
            <Modal
                width={600}
                visible={warningModalVisible}
                onCancel={onCancel}
                footer={null}
            >
                <Spin spinning={loading}>
                    <div className="claim-title">{form.getFieldValue('warningType')} {form.getFieldValue('warningType') ? '(' + t('accident.' + form.getFieldValue('warningType') + '.' + form.getFieldValue('event')) + ')' : ""}</div>
                    { previewList.length > 0 ? <Row align="middle" justify='center'>
                        <Col span={4} style={{textAlign: 'center'}}>
                            <LeftCircleOutlined style={{opacity: previewIndex === 0 ? 0: 1, fontSize: '40px', color: '#999'}} onClick={() => setPreviewIndex(previewIndex - 1)}/>
                        </Col>
                        <Col span={16} style={{textAlign: 'center'}}>{previewList[previewIndex]}</Col>
                        <Col span={4} style={{textAlign: 'center'}}>
                            <RightCircleOutlined style={{opacity: previewIndex === (previewList.length - 1) ? 0: 1, fontSize: '40px', color: '#999'}} onClick={() => setPreviewIndex(previewIndex + 1)}/>
                        </Col>
                    </Row> : null
                    }
                    <Form form={form} layout="vertical">
                        <Form.Item label={t('accident.warningTime')} name="timestamp">
                            <Input placeholder={t('accident.warningTime')} />
                        </Form.Item>
                        <Form.Item label={t('accident.speed')} name="speed">
                            <Input placeholder={t('accident.speed')} />
                        </Form.Item>
                        <Form.Item label={t('accident.warningLocation')} name="address">
                            <Input placeholder={t('accident.warningLocation')} />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
    )
}
export default WarningInfo;