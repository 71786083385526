import {Form, Select, DatePicker, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next'
import moment from 'moment';

const { RangePicker } = DatePicker;

const disabledDate = current => current && current > moment().endOf('day');

console.log('---disabledDate', disabledDate)

export default function PageDataQuality ({ Section, SectionJS }) {
  const { t } = useTranslation();

  const saveFeature = (data) => {
    SectionJS.serchParams.startTimestamp = data[0].valueOf()
    SectionJS.serchParams.endTimestamp = data[1].valueOf()
  }

  return <div>
    <Row justify="space-between">
      <Col span={11}>
        <Form.Item
          label={t('common.date')}
          name={[Section.name, 'serchParams', 'date']}
          rules={[{ type: 'array', required: true, message: t('common.pleaseSelect') + t('common.date') }]}
        >
          <RangePicker disabledDate={disabledDate} allowClear={false} onChange={saveFeature}/>
        </Form.Item>
      </Col>
      <Col span={11}>
        <Form.Item
          label={t('tmx.valueType')}
          name={[Section.name, 'serchParams', 'valueType']}
          rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.valueType') }]}
        >
          <Select>
            <Select.Option value="TRIP_CORRECT_RATE">{t('tmx.vehicleTripCorrectRate')}</Select.Option>
            <Select.Option value="VEHICLE_ACTIVE_RATE">{t('tmx.vehicleActiveRate')}</Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </div>
}