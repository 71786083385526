import { Radio, Form, Select, DatePicker, Row, Col } from 'antd';
import { useState } from 'react';
import http from '../../../../http';
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import moment from 'moment';

const { RangePicker } = DatePicker;

const valueOptions = [
  'ADASFollowCloselyNumber',
  'ADASFollowCloselyPer100kmNumber',
  'ADASForwardCollisionNumber',
  'ADASForwardCollisionPer100kmNumber',
  'ADASLaneDepartureNumber',
  'ADASLaneDeparturePer100kmNumber',
  'ADASWarningNumber',
  'ADASWarningPer100kmNumber',
  'ActiveVehicleNumber',
  'ConnectedVehicleNumber',
  'DSMCellphoneNumber',
  'DSMCellphonePer100kmNumber',
  'DSMFatigueDrivingNumber',
  'DSMFatigueDrivingPer100kmNumber',
  'DSMSmokingNumber',
  'DSMSmokingPer100kmNumber',
  'DSMWarningNumber',
  'DSMWarningPer100kmNumber',
  'Duration',
  'Mileage',
  'TripNumber',
  'WarningNumber',
  'WarningPer100kmNumber'
]
const groupOptions = ['DEAL', 'INSURANCE', 'REGION', 'VENDOR'];

const disabledDate = current => current && current > moment().endOf('day');

export default function PageDataCollection ({ Section, SectionJS }) {
  const { t } = useTranslation();
  // default dispaly table head

  const [dateRange, setDateRange] = useState('Day')

  useEffect(() => {

  }, [])

  const saveFeature = (data) => {
    console.log(data[0].valueOf())
    SectionJS.serchParams.startTimestamp = data[0].valueOf()
    SectionJS.serchParams.endTimestamp = data[1].valueOf()
  }

  return <div>
    <Row justify="space-between">
      <Col span={11}>
        <Form.Item
          name={[Section.name, 'serchParams', 'dateRange']}
          label={t('tmx.granularity')}
          rules={[{ required: false }]} >
          <Radio.Group onChange={e => setDateRange(e.target.value)}>
            <Radio.Button value="Day" style={{ color: '#000' }}>{t('common.day')}</Radio.Button>
            <Radio.Button value="Week">{t('common.week')}</Radio.Button>
            <Radio.Button value="Month">{t('common.month')}</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={11}>
        {(() => {
          const DateForm = props =>
            <Form.Item
              name={[Section.name, 'serchParams', 'date']}
              label={t('common.date')}
              rules={[{ type: 'array', required: true, message: t('common.pleaseSelect') + t('common.date') }]}
            >
              {props.children}
            </Form.Item>
          switch (dateRange) {
            case 'Day':
              return <DateForm><RangePicker disabledDate={disabledDate} allowClear={false} onChange={saveFeature} /></DateForm>
            case 'Week':
              return <DateForm><RangePicker disabledDate={disabledDate} picker="week" allowClear={false} onChange={saveFeature} /></DateForm>
            case 'Month':
              return <DateForm><RangePicker disabledDate={disabledDate} picker="month" allowClear={false} onChange={saveFeature} /></DateForm>
          }
        })()}
      </Col>
    </Row>
    <Row justify="space-between">
      <Col span={11}>
        <Form.Item
          name={[Section.name, 'serchParams', 'valueType']}
          label={t('tmx.valueType')}
          rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.valueType') }]}
        >
          <Select >
            {valueOptions.map(item => <Select.Option value={item} key={item}>{t(`tmx.options.${item}`)}</Select.Option>)}
          </Select>
        </Form.Item>
      </Col>
      <Col span={11}>
        <Form.Item
          name={[Section.name, 'serchParams', 'group']}
          label={t('tmx.group')}
          rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.group') }]}
        >
          <Select>
            {groupOptions.map(item => <Select.Option value={item} key={item}>{t('tmx.options.' + item)}</Select.Option>)}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </div>
}