import { Table, Row, Col, Button, Modal, Form, Input,  Select } from 'antd';
import { DownloadOutlined , SearchOutlined } from '@ant-design/icons'
import TableColumnFilter from '../../components/TableColumnFilter';
import BillQuarter from './BillQuarter';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useTable } from '../../hooks/table';
import { useVerticalForm } from '../../hooks/form';
import {useInsuranceList} from '../../data/insurer';
import NewQuarter from './NewQuarter';

import styles from './index.module.css';
import moment from "moment";
import http, { onBlobExport, downloadBlob } from '../../http';

const StatusTag = ({status}) => {
    const { t } = useTranslation();
    return status === 1 
        ? <span className={styles.green}>{t('bills.pass')}</span>
        : status === -1 
            ? <span className={styles.red}>{t('bills.fail')}</span>
            : <span>{t('bills.null')}</span>
}
const BillList = () => {
    const { t } = useTranslation();
    const { tableData, pagination, loading, setQuery, query, handleTableChange } = useTable(getData);
    const [modalTitle, setModalTitle] = useState(t('bills.inputReason'));
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [diasblePlateNumber, setDisablePlateNumber] = useState(false);
    const [disableInsurer, setDisableInsurer] = useState(false);
    const [actionParam, setActionParam] = useState({});
    const [modalForm] = Form.useForm();
    const {insuranceList } = useInsuranceList();
    
    const [quarterList, setQuarterList] = useState(null);
    const [formRules, setFormRules] = useState([[],[]])
    const [quarterListLoading, setQuarterListLoading] = useState(false);
    const setQuartersByInsurer = (insureId) => {
        setQuarterListLoading(true);
        http.get('/bill/insurer-bill-quarter/quarter/quarters', {insureId}).then(res => {
            setQuarterList(res.map(quarter => ({label: quarter, value: quarter})))
        }).finally(() => setQuarterListLoading(false))
    }

    const onAction = (confirmStatus, vehicleBillId) => {
        setActionParam({
            confirmStatus,
            vehicleBillId
        })
        setVisible(true);
    }
    const handleOk = () => {
        modalForm.validateFields().then(res => {
            const reason = modalForm.getFieldValue('reason');
            const param = {
                ...actionParam,
                reason
            }
            setConfirmLoading(true);
            return http.post('/bill/bill-quarter/confirm', param)
                .then(res => {
                    handleSearch();
                })
                .finally(() => {
                    setConfirmLoading(false);
                    modalForm.resetFields();
                    setVisible(false);
                })
        })
        
    };
    const handleCancel = () => {
        modalForm.resetFields();
        setVisible(false);
    }
    function getData(param) {        
        return http.get('/bill/bill-quarter/query', {
            orderBy: 'billStatus',
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            ...param
        })
    }
    
    const [columns, setColumns] = useState([
        { title: t("bills.vehicleBillId"), dataIndex: 'vehicleBillId', width: 200, hide: true},
        { title: t("bills.billYear"), dataIndex: 'billYear', width: 80, sorter: true, hide: true},
        { title: t("bills.billQuarter"), dataIndex: 'billQuarter', width: 100, sorter: true },
        { title: t("bills.dealId"), dataIndex: 'dealId', hide: true },
        { title: t("bills.dealName"), dataIndex: 'dealName' },
        { title: t("bills.insurerName"), dataIndex: 'insurerName', hide: true},
        { title: t("bills.contractStartDate"), dataIndex: 'contractStartDate', hide: true, render: date => <span>{date && moment(date).format('YYYY-MM-DD')}</span> },
        { title: t("bills.plateNumber"), dataIndex: 'plateNumber' },
        { title: t("bills.vin"),width: 230, dataIndex: 'vin', hide: true },
        { title: t("bills.firstTripDate"), dataIndex: 'firstTripDate', sorter: true, render: date => <span>{date && moment(date).format('YYYY-MM-DD')}</span> },
        { title: t("bills.lastTripDate"), dataIndex: 'lastTripDate', sorter: true, render: date => <span>{date && moment(date).format('YYYY-MM-DD')}</span> },
        { title: t("bills.policyNumberCI"), dataIndex: 'policyNumber' },
        { title: t("bills.inceptionDateCI"), dataIndex: 'inceptionDate', render: date => <span>{date && moment(date).format('YYYY-MM-DD')}</span> },
        { title: t("bills.endDateCI"), dataIndex: 'endDate', render: date => <span>{date && moment(date).format('YYYY-MM-DD')}</span> },
        
        { title: t("bills.policyNumberMI"), dataIndex: 'mpolicyNumber' },
        { title: t("bills.inceptionDateMI"), dataIndex: 'minceptionDate', render: date => <span>{date && moment(date).format('YYYY-MM-DD')}</span> },
        { title: t("bills.endDateMI"), dataIndex: 'mendDate', render: date => <span>{date && moment(date).format('YYYY-MM-DD')}</span> },
        { title: t("bills.billPrice"), width: 80,  dataIndex: 'billPrice' },
        { title: t("bills.billStatus"), dataIndex: 'billStatus', sorter: true,  width: 110, render: status =>status && <StatusTag status={status}/>},
        { title: t("bills.tmxCheck"), dataIndex: 'tmxcheck', sorter: true, width: 110, render: status => status && <StatusTag status={status}/> },
        { title: t("bills.policyCheckCI"), dataIndex: 'policyCheck', sorter: true,  width: 110, render: status => status && <StatusTag status={status}/>},
        { title: t("bills.reinsureCheckCI"), dataIndex: 'reinsureCheck', sorter: true, width: 110, render: status => status && <StatusTag status={status}/> },
        { title: t("bills.policyCheckMI"), dataIndex: 'mpolicyCheck', sorter: true,  width: 110, render: status => status && <StatusTag status={status}/>},
        { title: t("bills.reinsureCheckMI"), dataIndex: 'mreinsureCheck', sorter: true, width: 110, render: status => status && <StatusTag status={status}/> },
        { title: t("bills.failDescription"), dataIndex: 'failDescription' },
        { title: t("bills.confirmedAt"), dataIndex: 'confirmedAt',render: date => <span>{date && moment(date).format('YYYY-MM-DD HH:mm:ss')}</span>  },
        { title: t("bills.confirmedBy"), dataIndex: 'confirmedBy' },
        { title: t("bills.confirmReason"), dataIndex: 'confirmReason', sorter: true },
        // { title: t("bills.confirmStatus"), dataIndex: 'confirmStatus', width: 80, render: status => <StatusTag status={status}/>, hide: true},
        {
            title: t("common.action"),
            width: 100,
            align: 'center',
            fixed: 'right',
            render: item => <div>
                { item.isCurrentQuarter && (item.billStatus === 1
                    ? <Button type="text" danger onClick={() => onAction(-1, item.vehicleBillId)} >{t('bills.reject')}</Button> :
                    <Button type="text"  onClick={() => onAction(1, item.vehicleBillId)}>{t('bills.confirm')}</Button>)
                }
            </div>
        },
    ]);
    const { form, formProps } = useVerticalForm();
    const colProps = {
        lg: 6,
        md: 12,
        sm: 24
    };  
    const handleSearch = (e) => {
        form.validateFields().then(values => {
            setQuery({...values, orderBy: 'billStatus'});
        })
    }
    const handleExport = e => {
        const params = {
            ... form.getFieldsValue(),
            orderBy: 'billStatus',
        };

        onBlobExport('/bill/bill-quarter/export', params).then(res => {
            downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*)/)[1])
        });
    }
    const onInputChange = e => {
        if (e.target.value) {
            setDisableInsurer(true);
            form.setFields([
                { name: 'insurerId' ,value: undefined, errors:null},
                { name: 'billQuarter' ,value: undefined, errors: null}
            ])
            setFormRules([[],[]])
        } else {
            setDisableInsurer(false);
        }
    }
    const onInsurerChange = value => {
        if (value) {
            setQuartersByInsurer(value);
            setFormRules([
                [{ required: true, message: t('common.pleaseSelect') + t('bills.insurer')}],
                [{ required: true, message: t('common.pleaseSelect') + t('bills.billQuarter')}],
            ])
            form.setFieldsValue({
                plateNumber: undefined,
                billQuarter: undefined
            })
            setDisablePlateNumber(true);
        } else {
            setQuarterList(null);
            setFormRules([[],[]]);
            form.setFields([
                { name: 'insurerId' ,value: undefined, errors:null},
                { name: 'billQuarter' ,value: undefined, errors: null}
            ])
            form.setFieldsValue({
                billQuarter: undefined,
            })
            setDisablePlateNumber(false)
        }
    }
    return <div className="container">
        <div className={styles.title}>{t('bills.title')}</div>
        <Form {...formProps} form={form} name="basic">
            <Row align="bottom">
                <Col {...colProps}>
                    <Form.Item label={t('bills.plateNumber')} name="plateNumber">
                        <Input onInput={onInputChange} disabled={diasblePlateNumber}/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('bills.insurer')} name="insurerId" rules={formRules[0]}>
                        <Select
                            onChange={onInsurerChange}
                            options={insuranceList}
                            allowClear
                            disabled={disableInsurer}
                        >
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('bills.billQuarter')} name="billQuarter" rules={formRules[1]}>
                        <Select
                            loading={quarterListLoading}
                            options={quarterList}
                            allowClear
                            disabled={disableInsurer}
                        >
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...colProps} style={{ marginBottom: '24px' }}>
                    <Button icon={<SearchOutlined />}  onClick={handleSearch}>{t('common.search')}</Button>
                    <Button style={{ margin: '0 16px' }} icon={<DownloadOutlined />} onClick={handleExport}>{t('common.export')}</Button>
                    {quarterList && quarterList.length === 0 && <NewQuarter insurerId={form.getFieldValue('insurerId')} />}
                </Col>
            </Row>
        </Form>
        { 
            query.insurerId && <BillQuarter insurerId={query.insurerId} currentQuarter={query.billQuarter}></BillQuarter>
        }
        <Row justify="end" align="end">
            <TableColumnFilter columns={columns} onSetColumns={setColumns} />
        </Row>
        <Table
            size="small"
            tableLayout='fixed'
            scroll={{x: columns.filter(column => !column.hide).length * 150}}
            loading={loading}
            pagination={pagination}
            rowKey="id"
            onChange={handleTableChange}
            columns={columns.filter(column => !column.hide)}
            dataSource={tableData} />
         <Modal
            title={modalTitle}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
        >
            <Form form={modalForm}>
                <Form.Item name="reason" rules={[{ required: true, message: t('bills.inputReason')  }]}>
                    <Input.TextArea  />
                </Form.Item>
            </Form>
        </Modal>
    </div>
}
export default BillList;