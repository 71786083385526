import { Table, Button, Modal, Form, Input, Checkbox, Row, Col, InputNumber,} from 'antd';
import { useEffect, useState, useRef} from 'react';
import {DownloadOutlined, PlusOutlined, SettingOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined} from '@ant-design/icons'
import http, {onBlobExport, downloadBlob} from '../../http';
import moment from 'moment';
import {useModal} from '../../hooks/useModal';
import {useVerticalForm} from '../../hooks/form';
const CheckboxGroup = Checkbox.Group;
import {useTranslation} from 'react-i18next'
import {useTable} from '../../hooks/table';

export default function Index() {
  const { t } = useTranslation();
  const defaultCheckedList = ['1'];
  const {form:queryForm, formProps, colProps} = useVerticalForm();
  const [form] = Form.useForm();
  const {tableData, onSearch, pagination, loading, setQuery} = useTable(getData);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  console.log(checkedList)
  const [activeId, setActiveId] = useState([null]);
  const plainOptions = [{
    label: t('insurer.mandatoryInsurance'),
    value: '2'
  },
  {
    label: t('insurer.commercialInsurance'),
    value: '1'
  }];
  function getData(param) {
    return http.get('/Insurer', { 
      Page: pagination.current,
      PageSize: pagination.pageSize, 
      ...param
    })
  }
  const columns = [
    {
      title: t('insurer.insurerName'),
      key: 'insurerName',
      dataIndex: 'insurerName'
    },
    {
      title: t('insurer.branch'),
      key: 'branch',
      dataIndex: 'branch'
    },{
      title: t('insurer.subBranch'),
      key: 'subBranch',
      dataIndex: 'subBranch'
    },
    {
      title: t('insurer.contactPerson'),
      key: 'contactPerson',
      dataIndex: 'contactPerson'
    },
    {
      title: t('insurer.phoneNo'),
      key: 'phoneNo',
      dataIndex: 'phoneNo'
    },
    {
      title: t('insurer.aebInstallOverdueDays'),
      key: 'aebInstallOverdueDays',
      dataIndex: 'aebInstallOverdueDays',
    },
    {
      title: t('insurer.adasInstallOverdueDays'),
      key: 'adasInstallOverdueDays',
      dataIndex: 'adasInstallOverdueDays',
    },
    {
      title: t('common.editAt'),
      key: 'editAt',
      dataIndex: 'editAt',
      render: text => <span>{text ? moment(text).format('YYYY-MM-DD') : text} </span>
    },
    {
      title: t('common.editBy'),
      key: 'editBy',
      dataIndex: 'editBy'
    },
    {
      title: t('common.action'),
      key: 'delete',
      render: item => (
        <div>
          <Button icon={<DeleteOutlined />} style={{marginRight: '10px'}} onClick={() => confirmDelete(item.id, item.insurerName)}>{t('common.delete')}</Button>
          <Button icon={<SettingOutlined />} onClick={() => onEdit(item.id)}>{t('common.edit')}</Button>
        </div>
      )
    },
  ];

  const confirmDelete = (id, name) => {
    Modal.confirm({
      title: t('common.confirm'),
      content: `${t('common.confirmDelete')} ${t('insurer.insurerName')} [${name}] ?`,
      cancelButtonProps: {
        type: 'default',
        icon: <CloseCircleOutlined />
      },
      okButtonProps: {
        type: 'default',
        icon: <CheckCircleOutlined />
      },
      onOk() {
        return http.delete(`/Insurer/${id}`).then(onSearch);
      }
    });
  }
  const onEdit = id => {
    http.get(`/Insurer/${id}`).then(res => {
      updateModal(true);
      setActiveId(id);
      form.setFieldsValue({
        id: res.id,
        insurerName: res.insurerName,
        // insurerFullName: res.insurerFullName,
        branch: res.branch,
        subBranch: res.subBranch,
        contactPerson: res.contactPerson,
        phoneNo: res.phoneNo,
        aebInstallOverdueDays: res.aebInstallOverdueDays,
        adasInstallOverdueDays: res.adasInstallOverdueDays,
        policyTypes: res.policyTypes
      })
    })
  }
  const onAdd = () => {
    form.resetFields();
    setVisible(true);
  }
  const {
    visible,
    setVisible,
    confirmLoading,
    setConfirmLoading,
    updateModal
  } = useModal();
  const handleOk = () => {
    setConfirmLoading(true);
    form.validateFields().then(values => {
      const id = form.getFieldValue('id');
      let promise;
      if (!id) {
        promise = http.post('/Insurer', values);
      } else {
        promise = http.put('/Insurer', {...values, id});
      }
      return promise.then(res => {
        setActiveId(null);
        form.resetFields();
        updateModal(false);
      }).then(onSearch)
    }).finally(setConfirmLoading(false))
  }
  const handleCancel = () => {
    setActiveId(null);
    updateModal(false);
  }
  const handleSearch = (e) => {
    const values = queryForm.getFieldsValue();
    setQuery(values);
  }
  const onChange = (list) => {
    setCheckedList(list)
  };
  const handleExport = e => {
    const values = queryForm.getFieldsValue();
    onBlobExport('/Insurer/Export', values).then(res => {
      downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*);/)[1])
    });
  }
  return <div className="container">
    <Form {...formProps} form={queryForm} name="basic">
        <Row align="bottom">
          <Col {...colProps}>
            <Form.Item label={t('insurer.insurerName')} name="insurerName">
              <Input/>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={t('insurer.branch')} name="branch">
              <Input/>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={t('insurer.subBranch')} name="subBranch">
              <Input/>
            </Form.Item>
          </Col>
          <Col {...colProps} style={{marginBottom: '24px'}}>
            <Button icon={<SearchOutlined />}  style={{marginLeft: '32px'}} onClick={handleSearch}>{t('common.search')}</Button>
            <Button style={{marginLeft: '32px'}} icon={<DownloadOutlined />} onClick={handleExport}>{t('common.export')}</Button>
          </Col>
        </Row>
    </Form>
    <div style={{marginBottom: '20px'}}>
      <Button icon={<PlusOutlined />} onClick={onAdd}>{t('common.create')}</Button>
    </div>
    <Table
      scroll={{ x: 1600}}
      loading={loading}
      pagination={pagination}
      rowKey="id"
      columns={columns}
      dataSource={tableData} />
      <Modal
        width={600}
        title={activeId ? t('insurer.editInsurer'): t('insurer.createInsurer')}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Button key="back" icon={<CloseCircleOutlined />} onClick={handleCancel}>
            {t('common.cancel')}
          </Button>,
          <Button key="submit" icon={<CheckCircleOutlined />} onClick={handleOk}>
            {t('common.ok')}
          </Button>
        ]}
        destroyOnClose
      >
        <Form
          form={form}
          name="basic"
          layout="vertical">

          <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                return <Form.Item
                  label={t('insurer.insurerFullName')}
                  name="insurerFullName"
                  shouldUpdate
                >
                  <span></span>
                  <Input disabled value={[getFieldValue('insurerName'), getFieldValue('branch'), getFieldValue('subBranch')].join('-')}/>
                </Form.Item>  
            }}
          </Form.Item>
          <Form.Item
            label={t('insurer.insurerName')}
            name="insurerName"
            rules={[{ required: true, message: t('common.pleaseInput') + t('insurer.insurerName') }]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label={t('insurer.branch')}
            name="branch"
            rules={[{ required: true, message: t('common.pleaseInput') + t('insurer.branch') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('insurer.subBranch')}
            name="subBranch"
            rules={[{ required: true, message: t('common.pleaseInput') + t('insurer.subBranch')  }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('insurer.contactPerson')}
            name="contactPerson"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('insurer.phoneNo')}
            name="phoneNo"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('insurer.aebInstallOverdueDays')}
            name="aebInstallOverdueDays"
            rules={[{ required: true, message: t('common.pleaseInput') + t('insurer.aebInstallOverdueDays')  }]}
          >
            <InputNumber style={{width: '100%'}}/>
          </Form.Item>
          <Form.Item
            label={t('insurer.adasInstallOverdueDays')}
            name="adasInstallOverdueDays"
            rules={[{ required: true, message: t('common.pleaseInput') + t('insurer.adasInstallOverdueDays')   }]}
          >
            <InputNumber style={{width: '100%'}} />
          </Form.Item>
          <Form.Item
            label={t('insurer.policyTypes')}
            name="policyTypes"
            initialValue={defaultCheckedList}
            rules={[{ required: true, message: t('common.pleaseInput') + t('insurer.policyTypes')   }]}
          >
            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
          </Form.Item>
        </Form>
      </Modal>
  </div> 
}
