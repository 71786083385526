import { Button, Table, Space, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { FormOutlined, FileSearchOutlined, SettingOutlined, DeleteOutlined, ShareAltOutlined  } from '@ant-design/icons';
import { useTranslation } from 'react-i18next'
import style from './index.module.css'
import { useTable } from '../../hooks/table';
import http from '../../http';
import moment from 'moment';

export default function Index () {
  const { t } = useTranslation();

  const { tableData, pagination, loading, onSearch, handleTableChange } = useTable(getData);
  const columns = [
    {
      title: t.bind(null,'Recommendation.ReportTitle'),
      dataIndex: 'ReportTitle',
      disabled: true,
      default: true
    },
    {
      title: t.bind(null,'Recommendation.UpdateAt'),
      dataIndex: 'UpdateAt',
      showSorterTooltip: false,
      default: true,
      disabled: true,
      sorter: {
        compare: (a, b) => moment(a.UpdateAt) - moment(b.UpdateAt)
      },
      render: (text) =>{
        return text ? moment(text).format('YYYY-MM-DD') : ''
      }
    },
    {
      title: t.bind(null,'Recommendation.UpdateBy'),
      dataIndex: 'UpdateBy',
      showSorterTooltip: false,
      default: true,
      disabled: true
    },
    {
      title: t.bind(null,'Recommendation.CreatedAt'),
      dataIndex: 'CreateAt',
      showSorterTooltip: false,
      disabled: true,
      default: true,
      sorter: {
        compare: (a, b) => moment(a.CreateAt) - moment(b.CreateAt)
      },
      render: (text) =>{
        return text ? moment(text).format('YYYY-MM-DD') : ''
      }
    },
    {
      title: t.bind(null,'Recommendation.CreatedBy'),
      dataIndex: 'CreateBy',
      showSorterTooltip: false,
      default: true,
      disabled: true,
    },
    {
      title: t.bind(null,'Recommendation.Status'),
      dataIndex: 'ReportStatus',
      showSorterTooltip: false,
      default: true,
      sorter: {
        compare: (a, b) => a.ReportStatus - b.ReportStatus,
      },
      render: (text) =>{
        return <div>{text === '0' ? t('Recommendation.Unpublished') : t('Recommendation.Published')}</div>
      }
    },{
      title: t.bind(null,'Recommendation.Action'),
      render: (text) =>{
        const {ReportId, IsSelf, ReportStatus, Datascope} = text
        return <Space>
          {(IsSelf === '1' || IsSelf === '0') ? <Link to={`/fleet/view-recommendation/${ReportId}`}><Button icon={<FileSearchOutlined />}>{t('common.view')}</Button></Link> : ''}
          {(IsSelf === '1' || IsSelf === '2') ? <Link to={`/fleet/edit-recommendation/${ReportId}`}><Button icon={<SettingOutlined />}>{t('common.edit')}</Button></Link> : ''}
          {(IsSelf === '1' || IsSelf === '2') ? <Button icon={<ShareAltOutlined />} onClick={changeReportStatus.bind(null, ReportId, ReportStatus, Datascope)}>{ReportStatus === '0' ? t('Recommendation.Publish') : t('Recommendation.Unpublish')}</Button> : ''} 
          {(IsSelf === '1' || IsSelf === '2') ? <Button icon={<DeleteOutlined />} onClick={deleteReportStatus.bind(null, ReportId, Datascope)}>{t('common.delete')}</Button> : ''}
        </Space>
      }
    },
  ]

  
  function deleteReportStatus (id, Datascope=[]){
    const strDataScope = Datascope.toString()
    const la = localStorage.getItem('language')
    Modal.confirm({
      title: t('common.info'),
      content: t('common.confirmDelete'),
      cancelText: t('common.cancel'),
      okText: t('common.ok'),
      onOk: async function(){
        let res = await http.get(`/recommendationapi/Recommendation/DeleteRecommendation?strReportId=${id}&strDataScope=${strDataScope}&la=${la}`)
        if(+res.ReportId === +id){
          onSearch()
        }
      }
    });
  }

  async function changeReportStatus (id, ReportStatus, Datascope){
    const strDataScope = Datascope.toString()
    const la = localStorage.getItem('language')
    console.log(id, ReportStatus)
    let status = ReportStatus === '0' ? 1 : 0
    let res = await http.get(`/recommendationapi/Recommendation/UpateReportStatus?strReportId=${id}&strReportStatus=${status}&strDataScope=${strDataScope}&la=${la}`)
    if(+res.ReportId === +id){
      onSearch()
    }
  }

  // get table date
  async function getData (params) {
    let res = await http.get('/recommendationapi/Recommendation/GetListData', {
      PageNum: pagination.current,
      PageSize: pagination.pageSize,
      ...params
    })
    const {UpdDate, ...tableData} = res || {}
    return tableData
  }

  return <div className="container">
    <div className={style.titleBox}>
      <span className={style.title}>{t('menu.Recommendation')}</span>
    </div>
    <div className={style.CreatNewreport}>
      <Link to="/fleet/edit-recommendation">
        <Button>
          <FormOutlined />{t('Recommendation.CreatNewreport')}
        </Button>
      </Link>
    </div>
    <Table pagination={{...pagination, showSizeChanger: true}} onChange={handleTableChange} showSorterTooltip={false} scroll={{ x: '100%' }} columns={columns} loading={loading} dataSource={tableData}/>
  </div>
}
