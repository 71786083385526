import { Form, Select, Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import {  getDealList, getInsuranceList, getRegionList, getVendorList } from '../../../TMXDashboard/DataInsight/data';

const groupOptions = ['ALL', 'DEAL', 'INSURANCE', 'REGION', 'VENDOR'];
const { Option } = Select;


export default function PageDataInsightByType ({ Section, SectionJS }) {
  const { t } = useTranslation();

  const [DATASOURCE, setDATASOURCE] = useState({});
  useEffect(() => {
      Promise.all([getDealList(), getInsuranceList(),  getRegionList(), getVendorList()])
          .then(([dealList, insuranceList, regionList, vendorList]) => {
            setDATASOURCE({
              DEAL: dealList,  
              INSURANCE:  insuranceList,
              REGION: regionList,
              VENDOR: vendorList,
            })
          })
  }, [])

  const deleteGroup = (fn, field, index) => {
    SectionJS.serchParams.groupList.splice(index,1)
    fn(field.name)
  }

  return <div>
    <Form.List 
      name={[Section.name, 'serchParams', 'groupList']}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Row justify="space-between" align="middle" key={index}>
              <Col span={11}>
                <Form.Item
                  label={t('tmx.group')}
                  name={[field.name, 'group']}
                  fieldKey={[field.fieldKey, 'group']}
                  rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.group') }]}
                >
                  <Select>
                    {groupOptions.map(item => <Option value={item} key={item}>{t('tmx.options.' + item)}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={9}>
                { SectionJS.serchParams.groupList[index].group !=='ALL' ? <Form.Item
                        label={t(`tmx.${SectionJS.serchParams.groupList[index].group.toLowerCase()}`)}
                        name={[field.name, 'groupName']}
                        fieldKey={[field.fieldKey, 'groupName']}
                        rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.deal') }]}
                    >
                    <Select options={DATASOURCE[SectionJS.serchParams.groupList[index].group]}></Select>
                  </Form.Item> : null
                }
              </Col>
              <Col>
                <MinusCircleOutlined onClick={ () => deleteGroup(remove, field, index)} />
              </Col>
            </Row>
          ))}
          <Row gutter={8}>
            <Col span={20}>
              <Button type="dashed" onClick={() => add({ group: 'DEAL' })} block icon={<PlusOutlined />}>
                {t('common.addRow')}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Form.List>
  </div>
}