import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
const { SubMenu } = Menu;
import http from '../../http';
import menuMap from './menuMap';
import { useEffect, useState } from 'react';
import { listToTree } from '../../utils/array';

export const SideMenu = (props) => {
    const [menuTree, setMenuTree] = useState([]);
    const { t } = useTranslation();
    const getTabList = () => {
        return http.get(`/fleet-auth/application/tabList?application=${props.app}`).then((res = []) => {
            const menuList = []

            res = res.sort((a, b) => {
                return a.showOrder > b.showOrder ? 1 : -1;
            })
            res.forEach(item => {
                if (menuMap[item.name]) {
                    menuList.push({
                        ...menuMap[item.name],
                        id: item.id,
                        parentId: item.parentId
                    })
                }
            })
            console.log('++tablist++', menuList);
            return listToTree(menuList)
        })
    }
    useEffect(() => {
        getTabList().then(tree => {
            setMenuTree(tree);
        });
    }, [])
    return (
        <Menu theme="dark" mode="inline" style={{ paddingBottom: '50px' }}>
            {menuTree.map(item => (
                <SubMenu key={'m_' + item.key} title={t(item.title)} icon={item.icon}>
                    {
                        item.children && item.children.map(child => (
                            <Menu.Item key={child.key} icon={child.icon}>
                                <Link to={child.link}><span className="menu-title">{t(child.title)}</span></Link>
                            </Menu.Item>
                        ))
                    }
                </SubMenu>
            ))}
        </Menu>
    )
}