import styles from './index.module.css';
import { useTranslation } from 'react-i18next'

export default function Index() {
  const local = localStorage.getItem('application');
  console.log('---local-', local)
  const { t } = useTranslation();

  return (
    <div className={styles.welcontent}>
      {local !== 'pricing' && local !== 'truck' && local !== 'ewi' && (
        <div>
          <div className={styles.welcontenttitle} dangerouslySetInnerHTML={{ __html: t('welcomeFleet.title') }}></div>
          <div className={styles.welcontentbody} dangerouslySetInnerHTML={{ __html: t('welcomeFleet.body') }}></div>
        </div>
      )}
      {local === 'pricing' && (
        <div>
          <div className={styles.welcontenttitle} dangerouslySetInnerHTML={{ __html: t('welcomePricing.title') }}></div>
          <div className={styles.welcontentbody} dangerouslySetInnerHTML={{ __html: t('welcomePricing.body') }}></div>
        </div>
      )}
      {local === 'truck' && (
        <div>
          <div className={styles.welcontenttitle} dangerouslySetInnerHTML={{ __html: t('welcomeTruck.title') }}></div>
          <div className={styles.welcontentbody} dangerouslySetInnerHTML={{ __html: t('welcomeTruck.body') }}></div>
        </div>
      )}
      {local === 'ewi' && (
        <div>
          <div className={styles.welcontenttitle} dangerouslySetInnerHTML={{ __html: t('welcomeEWI.title') }}></div>
          <div className={styles.welcontentbody} dangerouslySetInnerHTML={{ __html: t('welcomeEWI.body') }}></div>
        </div>
      )}
    </div>)
}