import AMapLoader from '@amap/amap-jsapi-loader';
import i18n from '../../locales/i18n';
import moment from 'moment';
window._AMapSecurityConfig = {
    securityJsCode: '70c58d336b258e745a979e1766a81f02',
}

async function loadAmap() {
    return new Promise((resolve, reject) => {
        if (window.AMap) {
            resolve(window.AMap);
        } else {
            AMapLoader.load({
                key: "63b43b66ef60fe47db583568549c5ed7",    // 申请好的Web端开发者Key，首次调用 load 时必填
                version: '2.0',              // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins:[''],  
                AMapUI: {
                    version: '1.1',
                    plugins:['misc/PathSimplifier']
                }
            }).then(AMap => {
                resolve(AMap);
            })
        }
    })
}

async function loadMarker(AMapUI) {
    return new Promise((resolve, reject) => {
        AMapUI.loadUI(['overlay/SimpleMarker'], SimpleMarker => {
            resolve(SimpleMarker)
        })
    })
}
async function loadWindow(AMapUI) {
    return new Promise((resolve, reject) => {
        AMapUI.loadUI(['overlay/SimpleInfoWindow'], SimpleInfoWindow => {
            resolve(SimpleInfoWindow)
        })
    })
}

export async function initMap(id, data, {
    onMarkerClick,
    targetDay
} = {}) {

    const trips = data.map(data => {
        return {
            isTargetDay: targetDay ? moment(data.tripDay).format('YYYY-MM-DD') === targetDay: undefined,
            name: data.tripId,
            path: data.trips
        }
    })
    // const warnings = [];
    // data.forEach(trip => warnings.push(...trip.warnings))


    const AMap = await loadAmap();
    const SimpleMarker = await loadMarker(AMapUI);
    const SimpleWindow = await loadWindow(AMapUI);
    var map = new AMap.Map(id, {
        zoom: 4
    });
    if (!AMapUI.PathSimplifier.supportCanvas) {
        alert('当前环境不支持 Canvas！');
        return;
    }
    var colors = [
        "#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00",
        "#b82e2e", "#316395", "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300", "#8b0707",
        "#651067", "#329262", "#5574a6", "#3b3eac"
    ];
    var pathSimplifierIns = new AMapUI.PathSimplifier({
        zIndex: 100,
        map: map, //所属的地图实例

        getPath: function(pathData, pathIndex) {

            return pathData.path;
        },
        getHoverTitle: function(pathData, pathIndex, pointIndex) {

            if (pointIndex >= 0) {
                //point 
                return pathData.name + '，点：' + pointIndex + '/' + pathData.path.length;
            }

            return pathData.name + '，点数量' + pathData.path.length;
        },
        renderOptions: {
            renderAllPointsIfNumberBelow: 100, //绘制路线节点，如不需要可设置为-1，
            pathLineStyle: {
                dirArrowStyle: true
            },
            getPathStyle: function(pathItem, zoom) {
                let color;
                if (pathItem.pathData.isTargetDay !== undefined) {
                    color = pathItem.pathData.isTargetDay ? '#dc3912' : '#109618';
                } else {
                    color = colors[pathItem.pathIndex % colors.length];
                }
                let lineWidth = Math.round(1 * Math.pow(1.1, zoom - 3)) + 2;

                return {
                    pathLineStyle: {
                        strokeStyle: color,
                        lineWidth: lineWidth,
                        dirArrowStyle: true
                    },
                    pathLineSelectedStyle: {
                        lineWidth: lineWidth + 2,
                        dirArrowStyle: true
                    },
                    pathNavigatorStyle: {
                        fillStyle: color,
                        dirArrowStyle: true
                    }
                };
            }
        }
    });
    window.pathSimplifierIns = pathSimplifierIns;
    const tripsToTransform = [];
    trips.forEach(item => {
        tripsToTransform.push(transformGPS(item.path));
    })
    Promise.allSettled(tripsToTransform).then((tripLnglats)  => {
        trips.forEach((trip, index) => trip.path = tripLnglats[index].value || []);
        pathSimplifierIns.setData(trips.filter(one => one.path.length));
    }).catch(e => {
        console.log(e)
    })
    data.forEach(trip => {
        transformGPS([trip.start.longitude, trip.start.latitude]).then(startPos => {
            let marker = new SimpleMarker({
                iconStyle: {
                    src: '/img/start.png',
                    style: {
                        width:'30px',
                        transform: 'translateX(-5px)'
                    }
                },
                map: map,
                position: startPos[0]
            });
                
            var infoWindow = new SimpleWindow({
                infoTitle: i18n.t('accident.startTime'),
                infoBody: moment(trip.startTime).format('YYYY-MM-DD HH:mm:ss')
            });
            //marker 点击时打开
            marker.on('click', function() {
                infoWindow.open(map, marker.getPosition());
            });
        })
        transformGPS([trip.end.longitude, trip.end.latitude]).then(endPos => {
            let marker = new SimpleMarker({
                iconStyle: {
                    src: '/img/end.png',
                    style: {
                        width:'30px',
                        transform: 'translateX(-5px)'
                    }
                },
                map: map,
                position: endPos[0]
            });
            var infoWindow = new SimpleWindow({
                infoTitle: i18n.t('accident.endTime'),
                infoBody: moment(trip.endTime).format('YYYY-MM-DD HH:mm:ss')
            });
            //marker 点击时打开
            marker.on('click', function() {
                infoWindow.open(map, marker.getPosition());
            });

        })
        trip.warnings.forEach(async warning => {
            const { warningType = ''} = warning;
            const res = await transformGPS([warning.longitude, warning.latitude])
            const marker = new SimpleMarker({
                iconStyle: {
                    src: `/img/icon_${warningType[0]}.png`,
                    style: {
                        width:'30px',
                        transform: 'translateX(-5px)'
                    }
                },
            
                map: map,
                position: res[0]
            });
            marker.on('click', () => {
                onMarkerClick && onMarkerClick(warning.warningId, trip.tripDay)
            })
        })
    })
    
}

export function transformGPS(gps) {
    return new Promise((resolve, reject) => {
        AMap.convertFrom(gps, 'gps', function (status, result) {
            if (result.info === 'ok') {
                var lnglats = result.locations; // Array.<LngLat>
                resolve(lnglats);
            } else {
                reject(result)
            }
        });
    })    
}