import { Form, Input, Button, Select, Transfer} from 'antd';
import { useEffect, useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import http from '../../http';
import {useTranslation} from 'react-i18next'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
export default function Index(props) {
  const { t } = useTranslation();

  const id = props.id;
  const [form] = Form.useForm();
  useEffect(() => {
    id && http.get(`/User/${id}`).then(res => {
      form.setFieldsValue({ 
        displayName: res.displayName,
        login: res.login,
        email: res.email,
        password: res.password,
        role: res.role?.id,
        tenants: res.tenants.map(one => one.id),
        remark: res.remark
       });
       setTargetKeys(res.tenants.map(one => one.id));
    })
  }, [])
  const history = useHistory();

  const onFinish = values => {
    const {value, label} = roleOptions.find(one => one.value === values.role);
    const tenants = []
    
    values.tenants.forEach(tenantId => {
      const target = tenantList.find(one => one.key === tenantId);
      if (target) {
        tenants.push({
          id: target.key,
          name: target.title
        })
      }
    })
    const param = {
      id,
      ...values,
      role: {
        id: value,
        name: label,
      },
      tenants
    }
    if (id) {
      http.put('/User', param).then(res => {
        history.push('/fleet/user-management')
      })
    } else {
      http.post('/User', param).then(res => {
        history.push('/fleet/user-management')
      })
    }
    
    console.log('Success:', param);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  }; 
  
  const [targetKeys, setTargetKeys] = useState();
  const onTransferChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const [roleOptions, setRoleOptions] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  useEffect(() => {
    http.get('/Role').then(res => {
      setRoleOptions(roleOptions =>
        res.data.map(one => {
            return {
              label: one.name,
              value: one.id
            }
        })
      );
    })
    http.get('/Tenant?Page=-1').then(res => {
      setTenantList(tenantList =>
        res.data.map(one => {
          return {
            title: one.tenantID,
            key: one.id
          }
        })
      )
    })
  }, [])
  return <div className="container" style={{width: '600px'}}>
    <div>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item >
          <Button icon={<CheckCircleOutlined />} style={{marginRight: '10px'}}  htmlType="submit">{t('common.ok')}</Button>
          <Button icon={<CloseCircleOutlined />} > <Link to="/fleet/user-management">{t('common.cancel')}</Link></Button>
        </Form.Item>
        <Form.Item
          label={t('user.login')}
          name="login"
          rules={[{ required: true, message: t('common.pleaseInput') + t('user.login') }]}
        >
          <Input disabled={id}/>
        </Form.Item>
        <Form.Item
          label={t('user.email')}
          name="email"
          rules={[{pattern: /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/, message: t('user.error.email')}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('user.password')}
          name="password"
          rules={[{required: !id, pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/, message: t('user.error.password')}]} 
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={t('user.displayName')}
          name="displayName"
          rules={[{ required: true, message: t('common.pleaseInput') + t('user.displayName') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('user.role')}
          name="role"
          rules={[{ required: true, message: t('common.pleaseSelect') + t('user.role') }]}
        >
          <Select options={roleOptions}></Select>
        </Form.Item>
        <Form.Item
          label={t('user.remark')}
          name="remark"
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={t('user.tenant')}
          name="tenants"
          rules={[{ required: true, message: t('common.pleaseSelect') + t('user.tenant') }]}
        >
          <Transfer
            dataSource={tenantList}
            targetKeys={targetKeys}
            onChange={onTransferChange}
            render={item => item.title}
          />
        </Form.Item>
      </Form>
    </div>
  </div> 
}
