import { useEffect, useState } from "react";
import http from '../../http';

export function usePlateNumberVin() {
    const [vinList, setVinList] = useState([]);
    const [plateNumberList, setPlateNumberList] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        http.get('/system-setting/accident/searchPlateNumberVin').then(res => {
            setPlateNumberList(res.plateNumberList.map(one => {
                return {
                  label: one,
                  value: one
                }
              }));
            setVinList(res.vinList.map(one => {
                return {
                  label: one,
                  value: one
                }
              }));
        }).finally(() => {
          setLoading(false);
        })
    }, [])
    return {
        vinList,
        plateNumberList,
        loading
    }
}