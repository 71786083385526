import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import i18n from '../../locales/i18n';
echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);


export const setChart = (data) => {
    const ret = [];
    Object.keys(data).forEach(chartId => {
        var chartDom = document.getElementById(chartId);
        const chartData = data[chartId];
        echarts.init(chartDom);
        var myChart = echarts.init(chartDom);
        var option = {
            color: ['#627D77'],
            title: {
                text: i18n.t(`accident.${chartId}`),
                textStyle: {
                    color: '#627D77'
                }
            },
            tooltip: {
                trigger: 'axis',
            },
            // grid: {
            //     show: true,
            //     left: '3%',
            //     right: 190,
            //     bottom: '3%',
            //     containLabel: true
            // },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: chartData.xaxis.map(one => one.slice(0, 10))
            },
            yAxis: {
                scale: true,
                type: 'value'
            },
            series: {
                data: chartData.yaxis,
                type: 'line',
                markLine: {
                    symbol: 'none',
                    data: [
                        {
                            yAxis: chartData.redHorizontalLine || '',
                            lineStyle: {color: 'red'},
                            label: {
                                formatter: chartData.redHorizontalLine,
                                textStyle: {
                                    color: '#627D77'
                                }
                            }
                        },
                        {
                            yAxis: chartData.blueHorizontalLine || '',
                            lineStyle: {color: 'blue'},
                            label: {
                                formatter: chartData.blueHorizontalLine,
                                textStyle: {
                                    color: '#627D77'
                                }
                            }
                        },
                        {   yAxis: chartData.greenHorizontalLine || '',
                            lineStyle: {color: 'green'},
                            label: {
                                formatter: chartData.greenHorizontalLine,
                                textStyle: {
                                    color: '#627D77'
                                }
                            }
                        },
                        {
                            xAxis: chartData.redVerticalLine.slice(0, 10),
                            lineStyle: {color: 'red'},
                            label: {
                                formatter: chartData.redVerticalLine.slice(0, 10),
                                textStyle: {
                                    color: '#627D77'
                                }
                            }
                        },
                    ]
                }
            }
        }
        option && myChart.setOption(option);
        ret.push(myChart);
    })
    return ret;
}