
import * as echarts from 'echarts/core';
import {
    TooltipComponent,
    GridComponent,
    VisualMapComponent
} from 'echarts/components';
import { HeatmapChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { useTranslation } from 'react-i18next'
import { Spin, Row, Col, Checkbox } from 'antd';
import { useState } from 'react';
import http from '../../../../http';
import { useEffect } from 'react'


echarts.use([
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  HeatmapChart,
  CanvasRenderer
]);


export default function PreviewDataInsightCorrelationMatrix ({ serchParams }) {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true)
  const [initialData, setInitialData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const uid = serchParams.groupUUID

  const onFilterChange = values => {
    const copy = {
        ...initialData,
        relatedCoefficientModels: initialData.relatedCoefficientModels.filter(item => {
            return values.includes(item.param1) && values.includes(item.param2);
        })
    };
    setChartData(copy)
  }

  useEffect(() => {
      chartData && setChart()
  }, [chartData])

  const setChart = () => {
      var chartDom = document.getElementById('correlationChart'+uid);
      echarts.init(chartDom).dispose();
      var myChart = echarts.init(chartDom);
      // prettier-ignore
      const data = chartData.relatedCoefficientModels.map(one => {
          return [t('tmx.options.' + one.param1), t('tmx.options.' + one.param2), one.value.toFixed(2)]
      })
      var option;
      option = {
          // color: ['rgba(98, 125, 119, 0.75)', 'rgba(98, 125, 119, 0.5)'],
          tooltip: {
              position: 'top',
              formatter: '{c}'
          },
          grid: {
              show: false,
              left: '210px',
              height: '80%',
              top: '0%',
          },
          xAxis: {
              type: 'category',
              data: Array.from(new Set(chartData.relatedCoefficientModels.map(one => t('tmx.options.' + one.param1)))),    
              axisTick: {
                  interval: 0
              },
              axisLabel: {
                  interval: 0,
                  rotate: -25
              }
          },
          yAxis: {
              type: 'category',
              data: Array.from(new Set(chartData.relatedCoefficientModels.map(one => t('tmx.options.' + one.param2)))),
          },
          visualMap: {
              min: 0,
              max: 1,
              calculable: false,
              orient: 'horizontal',
              left: 'center',
              inRange: {
                  color: ['rgba(98, 125, 119, 0.75)', ' rgba(98, 125, 119, 0.25)'],
              }
          },
          series: [
              {
                  // name: 'Punch Card',
                  type: 'heatmap',
                  data: data,
                  label: {
                      show: false
                  },
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
      };

      option && myChart.setOption(option);
  }

  const onFinish = values => {
      if (values.group === 'ALL') {
          values.groupName = '';
      }
      setLoading(true);
      http.get('/tmx-dashboard/insight/relatedcoefficient', values).then(res => {
          const data = res.relatedCoefficientModels.sort((a, b) =>  a.param1 < b.param1 ? 1 : -1).sort((a, b) =>  a.param2 > b.param2 ? 1 : -1)
          setChartData({...res, relatedCoefficientModels: data});
          setInitialData({...res, relatedCoefficientModels: data});
      }).finally(() => setLoading(false))
  };

  useEffect(() => {
    onFinish(serchParams)
  }, [])

  return <div>
    <Spin spinning={loading}>
      <Row>
        <Col flex="760px"><div id={`correlationChart${uid}`} style={{ height: 600, width: 760 }}></div></Col>
        <Col flex="auto" style={{ width: 0 }}>
          {
            initialData && <Checkbox.Group onChange={onFilterChange} defaultValue={Array.from(new Set(initialData.relatedCoefficientModels.map(one => one.param1)))}>
              {
                Array.from(new Set(initialData.relatedCoefficientModels.map(one => one.param1))).map(key => {
                  return <Row key={key}><Checkbox value={key} >{t('tmx.options.' + key)}</Checkbox></Row>
                })
              }
            </Checkbox.Group>
          }

        </Col>
      </Row>
    </Spin>
  </div>
}