import { Table, Button, Modal, Form, Input, Select, DatePicker, Row, Col, InputNumber, Switch, Checkbox } from 'antd';
import { useEffect, useState, useRef} from 'react';
import {DownloadOutlined, PlusOutlined, SettingOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined} from '@ant-design/icons'
import http, {onBlobExport, downloadBlob} from '../../http';
import moment from 'moment';
import {useModal} from '../../hooks/useModal';
import {useVerticalForm} from '../../hooks/form';
import {useTranslation} from 'react-i18next'
import {useTable} from '../../hooks/table';
import DealCosting from './DealCosting';
function getDealNameList() {
  return http.get('/AccountTenantTemp/Get');
}

function getInsuranceList() {
  return http.get('/Insurer?Page=-1');
}

function getVendorList() {
  return http.get('/Vendor?Page=-1');
}
export default function Index() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {form:queryForm, formProps, colProps} = useVerticalForm();

  const {tableData, onSearch, pagination, loading, setQuery} = useTable(getData);
  const [activeId, setActiveId] = useState(null);
  const [insurerOptions, setInsurerOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [dealNameOptions, setDealNameOptions] = useState([]);
  const [DealInfo, setDealInfo] = useState([]);


  function getData(param) {
    return http.get('/Tenant', { 
      Page: pagination.current,
      PageSize: pagination.pageSize, 
      ...param
    })
  }
  const columns = [
    {
      title: t('deal.tenantId'),
      key: 'tenantID',
      dataIndex: 'tenantID'
    },
    {
      title: t('deal.insuranceName'),
      key: 'insurerFullName',
      dataIndex: 'insurerFullName',
    },
    {
      title: t('deal.vendorName'),
      key: 'vendorName',
      dataIndex: 'vendorName',
    },
    {
      title: t('deal.dealDescription'),
      key: 'dealDescription',
      dataIndex: 'dealDescription',
    },
    {
      title: t('deal.startDate'),
      key: 'startTimestamp',
      dataIndex: 'startTimestamp',
      render: text => <span>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : text} </span>
    },
    {
      title: t('deal.endDate'),
      key: 'endTimestamp',
      dataIndex: 'endTimestamp',
      render: text => <span>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : text} </span>
    },
    {
      title: t('deal.empoweredShujubao'),
      key: 'empoweredShujubao',
      dataIndex: 'empoweredShujubao',
      render: val => <span>{val ? 'Y': 'N'} </span>
    },
    {
      title: t('deal.empoweredZxl'),
      key: 'empoweredZxl',
      dataIndex: 'empoweredZxl',
      render: val => <span>{val ? 'Y': 'N'} </span>
    },
    {
      title: t('common.editAt'),
      key: 'editAt',
      dataIndex: 'editAt',
      render: text => <span>{text ? moment(text).format('YYYY-MM-DD') : text} </span>
    },
    {
      title: t('common.editBy'),
      key: 'editBy',
      dataIndex: 'editBy'
    },
    {
      title: t('common.action'),
      key: 'delete',
      width: 270,
      fixed: 'right',
      render: item => (
        <div>
          <Button icon={<DeleteOutlined />} style={{marginRight: '10px'}} onClick={() => confirmDelete(item.id, item.tenantID)}>{t('common.delete')}</Button>
          <Button icon={<SettingOutlined />} style={{marginRight: '10px'}} onClick={() => onEdit(item.id)}>{t('common.edit')}</Button>
          <DealCosting tenantID={item.tenantID}></DealCosting>
        </div>
      )
    },
  ];

  const confirmDelete = (id, name) => {
    Modal.confirm({
      title: t('common.confirm'),
      content: `${t('common.confirmDelete')} ${t('deal.tenant')} [${name}] ?`,
      cancelButtonProps: {
        type: 'default',
        icon: <CloseCircleOutlined />
      },
      okButtonProps: {
        type: 'default',
        icon: <CheckCircleOutlined />
      },
      onOk() {
        return http.delete(`/Tenant/${id}`).then(onSearch);
      }
    });
  }
  const onEdit = id => {
    http.get(`/Tenant/${id}`).then(res => {
      updateModal(true);
      setActiveId(id);
      form.setFieldsValue({
        id: res.id,
        tenantID: res.tenantID,
        vendorId: res.vendorId,
        insuranceId: res.insuranceId,
        vendorName: res.vendorName,
        deviceType: res.deviceType,
        dealDescription: res.dealDescription,
        startTimestamp: moment(res.startTimestamp),
        endTimestamp: moment(res.endTimestamp),
        firstPrice: res.firstPrice,
        secondPrice: res.secondPrice,
        thirdPrice: res.thirdPrice,
        fourthPrice: res.fourthPrice,
        empoweredShujubao: res.empoweredShujubao,
        empoweredZxl: res.empoweredZxl
      })
    })
  }
  const onAdd = () => {
    form.resetFields();
    setVisible(true);
  }
  const {
    visible,
    setVisible,
    confirmLoading,
    setConfirmLoading,
    updateModal
  } = useModal();
  const handleOk = () => {
    setConfirmLoading(true);
    form.validateFields().then(values => {
      const id = form.getFieldValue('id');
      values.startTimestamp = new Date(values.startTimestamp).getTime();
      values.endTimestamp = new Date(values.endTimestamp).getTime();
      let promise;
      if (!id) {
        promise = http.post('/Tenant', values);
      } else {
        promise = http.put('/Tenant', {...values, id});
      }
      return promise.then(res => {
        form.resetFields();
        setActiveId(null);
        updateModal(false);
      }).then(onSearch)
    }).finally(setConfirmLoading(false))
  }
  const handleCancel = () => {
    setActiveId(null);
    updateModal(false);
  }
  const handleSearch = (e) => {
    const values = queryForm.getFieldsValue();
    setQuery(values);
  }
  useEffect(() => {
    getInsuranceList().then(res => {
      setInsurerOptions(
        res.data.map(one => {
          return {
            label: one.insurerFullName,
            value: one.id,
          }        
        })
      );
    })
    getVendorList().then(res => {
      setVendorOptions(
        res.data.map(one => {
          return {
            label: one.vendorName,
            value: one.id,
          }        
        })
      );
    })
  }, [])
  useEffect(() => {
    getDealNameList().then(res => {
      setDealNameOptions(
        res.data.map(one => {
          return {
            label: one.name,
            value: one.name,
          }        
        })
      );
    })
  },[visible])
  useEffect(() => {
    getDealNameList().then(res => {
      setDealNameOptions(
        res.data.map(one => {
          return {
            label: one.name,
            value: one.name,
          }        
        })
      );
      setDealInfo(res.data)
    })
  },[visible])

  const getDealDescription = (deal) => {
    const description = DealInfo?.filter(one => one.name === deal)?.[0]?.description
    form.setFieldsValue({dealDescription:description})
  }
  const handleExport = e => {
    const values = queryForm.getFieldsValue();
    onBlobExport('/Tenant/Export', values).then(res => {
      downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*);/)[1])
    });
  }
  return <div className="container">
    <Form {...formProps} form={queryForm} name="basic">
        <Row align="bottom">
          <Col {...colProps}>
            <Form.Item label={t('deal.tenantId')} name="tenantID">
              <Input />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={t('deal.vendorName')} name="vendorId">
              {/* <Input/> */}
              <Select options={vendorOptions} allowClear></Select>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={t('deal.insuranceName')} name="insuranceId">
              {/* <Input/> */}
              <Select options={insurerOptions} allowClear></Select>
            </Form.Item>
          </Col>
          <Col {...colProps} style={{marginBottom: '24px'}}>
            <Button style={{marginLeft: '32px'}}  icon={<SearchOutlined />} onClick={handleSearch}>{t('common.search')}</Button>
            <Button style={{marginLeft: '32px'}} icon={<DownloadOutlined />} onClick={handleExport}>{t('common.export')}</Button>
          </Col>
        </Row>
    </Form>
    <div style={{marginBottom: '20px'}}>
      <Button  icon={<PlusOutlined />} onClick={onAdd}>{t('deal.addTenant')}</Button>
    </div>
    <Table
      scroll={{ x: 1850}}
      loading={loading}
      pagination={pagination}
      rowKey="id"
      columns={columns}
      dataSource={tableData} />
      <Modal
        width={600}
        title={activeId ? t('deal.editTenant'): t('deal.addTenant')}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Button key="back" icon={<CloseCircleOutlined />} onClick={handleCancel}>
            {t('common.cancel')}
          </Button>,
          <Button key="submit" icon={<CheckCircleOutlined />} onClick={handleOk}>
            {t('common.ok')}
          </Button>
        ]}
        destroyOnClose
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          // labelCol={{ span: 10 }}
          // wrapperCol={{ span: 14 }}
          initialValues={{
            firstPrice: 0,
            secondPrice: 0,
            thirdPrice: 0,
            fourthPrice: 0,
            empoweredShujubao: false,
            empoweredZxl: false
          }}
        >
          <Form.Item
            label={t('deal.tenantId')}
            name="tenantID"
            rules={[{ required: true, message: t('common.pleaseInput') + t('deal.tenantId') }, {pattern: /^[0-9a-z-_]+$/, message: t('deal.error.tenantId')}]}
          >
            <Select options={dealNameOptions} disabled={activeId} onChange={getDealDescription}></Select>
          </Form.Item>
          <Form.Item
            label={t('deal.insuranceName')}
            name="insuranceId"
            rules={[{ required: true, message: t('common.pleaseSelect') + t('deal.insuranceName') }]}
          >
            <Select options={insurerOptions}></Select>
          </Form.Item>
          <Form.Item
            label={t('deal.vendorName')}
            name="vendorId"
            rules={[{ required: true, message: t('common.pleaseSelect') + t('deal.vendorName')  }]}
          >
            <Select options={vendorOptions}></Select>
          </Form.Item>
          <Form.Item
            label={t('deal.deviceType')}
            name="deviceType"
            rules={[{ required: true, message: t('common.pleaseSelect') + t('deal.deviceType')  }]}
          >
            <Select>
              <Select.Option value="AEB">AEB</Select.Option>
              <Select.Option value="ADAS">ADAS</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t('deal.dealDescription')}
            name="dealDescription"
          >
            <Input.TextArea></Input.TextArea>
          </Form.Item>
          <Form.Item
            label={t('deal.startDate')}
            name="startTimestamp"
            rules={[{ required: true, message: t('common.pleaseSelect') + t('deal.startDate')  }]}
          >
            <DatePicker style={{width: '100%'}}/>
          </Form.Item>
          <Form.Item
            label={t('deal.endDate')}
            name="endTimestamp"
            rules={[{ required: true, message: t('common.pleaseSelect') + t('deal.endDate')   }]}
          >
            <DatePicker style={{width: '100%'}}/>
          </Form.Item>
          <Form.Item
            label={t('deal.supplierPrice') + t('deal.firstPrice') }
            name="firstPrice"
          >
            <InputNumber style={{width: '100%'}} />
          </Form.Item>
          <Form.Item
            label={t('deal.secondPrice')}
            name="secondPrice"
          >
            <InputNumber style={{width: '100%'}} />
          </Form.Item>
          <Form.Item
            label={t('deal.thirdPrice')}
            name="thirdPrice"
          >
            <InputNumber style={{width: '100%'}} />
          </Form.Item>
          <Form.Item
            label={t('deal.fourthPrice')}
            name="fourthPrice"
          >
            <InputNumber style={{width: '100%'}} />
          </Form.Item>
          <Form.Item
            label={t('deal.empoweredShujubao')}
            name="empoweredShujubao"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label={t('deal.empoweredZxl')}
            name="empoweredZxl"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Form>
      </Modal>
  </div> 
}
