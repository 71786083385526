import { Table, Row, Col, Button, Modal, Form, Input,DatePicker,  Select } from 'antd';
import { DownloadOutlined, SearchOutlined, FileSearchOutlined } from '@ant-design/icons'
import TableColumnFilter from '../../components/TableColumnFilter';

import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useTable } from '../../hooks/table';
import { useVerticalForm } from '../../hooks/form';
import { useDealList } from '../../data/deal';
import { useInsuranceList } from '../../data/insurer';

import {useHistory} from 'react-router-dom';

import moment from "moment";
import http, { onBlobExport, downloadBlob } from '../../http';
import  './index.css';

const formatEmpty = val => {
    return val === true
        ? 'Y'
        : val === false ? 'N': ''
}
const ClaimList = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { tableData, pagination, loading, setQuery, handleTableChange } = useTable(getData);
    const { RangePicker } = DatePicker;

    const { dealList } = useDealList();
    const { insuranceList } = useInsuranceList();
    function getData(param) {
        param = Object.assign(param, form.getFieldsValue())
        if (param.lossDate) {
            param.lossDateBegin = param.lossDate[0].format('YYYY-MM-DD');
            param.lossDateEnd = param.lossDate[1].format('YYYY-MM-DD');
            delete param.lossDate;
        }
        return http.get('/system-setting/claimReport/search', {
            orderBy: 'lossPaid',
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            ...param
        })
    }
    const fieldsColumns = [
        {title: t("fields.policyNumber"),dataIndex: 'policyNumber' },
        {title: t("fields.insurerName"),dataIndex: 'insurerName', width: 350 },
        {title: t("insurer.branch"),dataIndex: 'clientBranch', hide: true  },
        {title: t("fields.deal"),dataIndex: 'deal' },
        {title: t("fields.vin"),dataIndex: 'vin' },
        {title: t("fields.plateNumber"),dataIndex: 'plateNumber' },
        {title: t("claim.status"),dataIndex: 'status', sorter: true },
        {title: t("claim.proportionOfResponsibility"),dataIndex: 'proportionOfResponsibility', sorter: true  },
        {title: t("claim.lossPaid"),dataIndex: 'lossPaid', sorter: true },
        {title: t("claim.lossPaidMod"),dataIndex: 'lossPaidMod', sorter: true, hide: true  },
        {title: t("claim.lossPaidVtpl"),dataIndex: 'lossPaidVtpl', sorter: true, hide: true },
        {title: t("claim.lossPaidPdl"),dataIndex: 'lossPaidPdl', hide: true },
        {title: t("claim.lossPaidBil"),dataIndex: 'lossPaidBil', hide: true },
        {title: t("claim.lossPaidTPLOther"),dataIndex: 'lossPaidTPLOther', hide: true },
        {title: t("claim.lossPaidOther"),dataIndex: 'lossPaidOther',sorter: true, hide: true  },
        {title: t("claim.lossOutstanding"),dataIndex: 'lossOutstanding', sorter: true  },
        {title: t("claim.lossOutstandingMod"),dataIndex: 'lossOutstandingMod', sorter: true, hide: true },
        {title: t("claim.lossOutstandingVtpl"),dataIndex: 'lossOutstandingVtpl', sorter: true, hide: true },
        {title: t("claim.lossOutstandingPdl"),dataIndex: 'lossOutstandingPdl', hide: true },
        {title: t("claim.lossOutstandingBil"),dataIndex: 'lossOutstandingBil', hide: true },
        {title: t("claim.lossOutstandingTPLOther"),dataIndex: 'lossOutstandingTPLOther', hide: true },
        {title: t("claim.lossOutstandingOther"),dataIndex: 'lossOutstandingOther', sorter: true, hide: true },
        {title: t("claim.subrogation"),dataIndex: 'subrogation', sorter: true, hide: true },
        {title: t("claim.reportDate"),dataIndex: 'reportDate', width: 120, render: date => date && date.slice(0, 19).replace('T', ' '), sorter: true, hide: true },
        {title: t("claim.lossDate"), dataIndex: 'incidentTime', width: 120, render: date => date && date.slice(0, 19).replace('T', ' '), sorter: true },
        {title: t("claim.registrationTime"),dataIndex: 'registrationTime', render: date => date && date.slice(0, 19).replace('T', ' '), sorter: true , hide: true},
        {title: t("claim.registrationNumber"),dataIndex: 'registrationNumber'},
        {title: t("claim.cause"),dataIndex: 'cause' },
        {title: t("claim.accidentLocation"),dataIndex: 'accidentLocation', hide: true },
        {title: t("claim.accidentDescription"),dataIndex: 'accidentDescription' , hide: true},
        {title: t("claim.isSinglePartyClaim"), dataIndex: 'isSinglePartyClaim', render: formatEmpty, hide: true },
        {title: t("claim.includeBodilyInjury"),dataIndex: 'includeBodilyInjury',  render: formatEmpty, hide: true },
    ]
    const commonColumns = [
        {
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: time => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            width: 120,
            hide: true
        },
        {
            title: t('common.createdBy'),
            dataIndex: 'createdBy',
            hide: true
        },
        {
            title: t('common.updatedAt'),
            dataIndex: 'updatedAt',
            render: time => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            width: 120,
            hide: true
        },
        {
            title: t('common.updatedBy'),
            dataIndex: 'updatedBy',
            hide: true
        },
    ]
    const [columns, setColumns] = useState([
        ...fieldsColumns,
        ...commonColumns,
        {
            width: 100,
            align: "center",
            title: t("common.detail"),
            fixed: 'right',
            render: item => <Button icon={<FileSearchOutlined />} onClick={() => onClickView(item)}>{t('common.view')}</Button>
        },
    ]);
    const { form, formProps, colProps } = useVerticalForm();
    const handleSearch = (e) => {
        const values = form.getFieldsValue();
        setQuery(values);
    }
    const onClickView = (item) => {
        window.open('/fleet/claim-report/'+ item.id);
        // Modal.confirm({
        //     title: t('common.confirm'),
        //     content: `${t('common.confirmDelete')} [${item.policyNumber}] ?`,
        //     onOk() {
        //         return http.delete(`/system-setting/claims/delete?id=${item.id}`).then(handleSearch);
        //     }
        // });
    }
    const handleExport = e => {
        const params = form.getFieldsValue();
        if (params.lossDate) {
            params.lossDateBegin = params.lossDate[0].format('YYYY-MM-DD');
            params.lossDateEnd = params.lossDate[1].format('YYYY-MM-DD');
            delete params.lossDate;
        }
        onBlobExport('/system-setting/claimReport/export', params).then(res => {
            downloadBlob(res.data, 'claims.xlsx')
        });
    }
    return <div className="container">
        <div className="claim-title">{t('accident.claimList')}</div>
        <Form {...formProps} form={form} name="basic" initialValues={{lossDate: [moment().subtract(92, 'days'), moment()]}}>
            <Row align="bottom">
                <Col {...colProps}>
                        <Form.Item label={t('claim.lossDate')} name="lossDate">
                            <RangePicker style={{width: '100%'}}></RangePicker>
                        </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.deal')} name="tenantId">
                        <Select options={dealList} placeholder={t('fields.deal')} allowClear/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.insurer')} name="insurerId">
                        <Select options={insuranceList} placeholder={t('fields.insurer')} allowClear/>
                    </Form.Item>
                </Col>
                <Col {...colProps} style={{ marginBottom: '24px' }}>
                    <Button icon={<SearchOutlined />}   onClick={handleSearch}>{t('common.search')}</Button>
                    <Button style={{ marginLeft: '16px' }} icon={<DownloadOutlined />} onClick={handleExport}>{t('common.export')}</Button>
                </Col>
            </Row>
        </Form>
        <Row justify="end" align="end">
            <TableColumnFilter columns={columns} onSetColumns={setColumns} />
        </Row>
        <Table
            size="small"
            tableLayout='fixed'
            scroll={{x: columns.filter(column => !column.hide).length * 150}}
            loading={loading}
            pagination={pagination}
            rowKey="id"
            onChange={handleTableChange}
            columns={columns.filter(column => !column.hide)}
            dataSource={tableData} />
        
    </div>
}
export default ClaimList;