import { Modal, Button, Spin, Table} from 'antd';
import { useCallback } from 'react';
import { useState, useEffect } from 'react';
import http from '../../../http';

import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { useTranslation } from 'react-i18next'

echarts.use([GridComponent, BarChart, CanvasRenderer]);

const categoryMap = {
    'missData': 'fnull',
    'inAdvance': 'fzero',
    '1-10' : 'f1t10',
    '11-20': 'f11t20',
    '21-30': 'f21t30',
    '31-40': 'f31t40',
    '41-50': 'f41t50',
    '51-60': 'f51t60',
    '60+': 'f61tMax'
}

const InstallDaysDistribution = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const { t } = useTranslation();

    const columns = [
        {
            title: t('partner.missData'), 
            key: 'fnull',
            dataIndex: 'fnull'
        },
        {
            title: t('partner.inAdvance'),
            key: 'fzero',
            dataIndex: 'fzero'
        },
        {
            title: '1-10',
            key: 'f1t10',
            dataIndex: 'f1t10',
        },
        {
            title: '10-20',
            key: 'f11t20',
            dataIndex: 'f11t20',
        },
        {
            title: '20-30',
            key: 'f21t30',
            dataIndex: 'f21t30',
        },
        {
            title: '30-40',
            key: 'f31t40',
            dataIndex: 'f31t40',
        },
        {
            title: '40-50',
            key: 'f41t50',
            dataIndex: 'f41t50',
        },
        {
            title: '50-60',
            key: 'f51t60',
            dataIndex: 'f51t60',
        },
        {
            title: '60+',
            key: 'f61tMax',
            dataIndex: 'f61tMax',
        },
    ];

    const loadData = useCallback(() => {
        setLoading(true)
        setData({});
        const {dealName, insurerName, partnerName, type} = props;
        return http.get(`/partner-eval/evaluation/${type}/device/installday/distribution`, {dealName, insurerName, partnerName}).then(res => {
            setData(res);
            initChart(Object.keys(categoryMap).map(key => {
                return {
                    name: t(categoryMap),
                    value: res[categoryMap[key]]
                }
            }));
        }).finally(() => setLoading(false))
    })
    const initModal = useCallback(() => {
        setIsModalVisible(true);
        loadData();
    })
    const onCancel = useCallback(() => {
        setIsModalVisible(false);
    })

    const initChart = (seriesData) => {
        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
            },
            grid: {
                bottom: 20,
                top: 10
            },
            color: ['#627D77'],
            xAxis: {
                type: 'category',
                data: [ t('partner.missData'), t('partner.inAdvance'), '0-10', '10-20', '20-30', '30-40', '40-50', '50-60', '60+'],
                axisLabel: {
                    show: true,
                    interval: 0
                }
            },
            yAxis: {
                type: 'value',
                minInterval: 1
            },
            series: [
                {
                    data: seriesData,
                    type: 'bar',
                    barWidth: 30
                }
            ]
        };
        let myChart = echarts.init(document.getElementById('main'));
        myChart.setOption(option);
    }
    return (
    <div>
        <Button type="link" onClick={initModal}>{t('common.view')}</Button>
        <Modal destroyOnClose title={t('partner.installDaysDistribution')} width={850} visible={isModalVisible} footer={null} onCancel={onCancel}>
            <div id="main" style={{ height: 300, width: 800, margin: '0 auto'}}></div>
            <Table dataSource={[data]} columns={columns} pagination={false} loading={loading}></Table>
        </Modal>
    </div>
    )
}

export default InstallDaysDistribution;