
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import { Form, Input, Button, Space, Select, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined,DownloadOutlined,SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
const { Option } = Select;

import { useTranslation } from 'react-i18next'
import http, {onBlobExport, downloadBlob} from '../../../http';
import {getUnit, round2} from './tools';

const getFeatureList = () => http.get('/tmx-dashboard/insight/features');
const FeatureAnalysisByGroup = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [chartData, setChartData] = useState(null);
    const [lineList, setLineList] = useState([]);
    const { t } = useTranslation();
    const [featureList, setFeatureList] = useState([]);
    useEffect(() => {
        form.submit();
        getFeatureList().then(res => {
            setFeatureList(res)
        })
    }, [])
    useEffect(() => {
        chartData && setChart()
    }, [chartData])

    const setChart = () => {
        chartData.forEach((one, index) => {
            var chartDom = document.getElementById(`featureByGroupChart${index}`);
            echarts.init(chartDom).dispose();
            var myChart = echarts.init(chartDom);
            var option;
            
            const seriesData = one.featureDistributedModels[0]?.distributedModels || [];
            const title = one.featureDistributedModels[0]?.feature;
            option = {
                color: ['#1455B4'],
                title: {
                    text: one.groupName,
                    top: '10%',
                    textStyle: {
                        fontSize: '12px',
                        fontWeight: 'normal'
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    appendToBody: true
                },
                grid: {
                    left: '3%',
                    right: '10%',
                    bottom: '3%',
                    containLabel: true
                },
                // xAxis: {
                //     // show: false
                //     type: 'category',
                //     boundaryGap: false,
                //     data: seriesData.map(one => one.left),
                //     axisTick : {
                //         show: false
                //     },
                //     axisLine: {}
                // },
                xAxis: {
                    show: true,
                    type: 'category',
                    boundaryGap: false,
                    data: seriesData.map((data, index) => `(${round2(data.left)} - ${round2(data.right)}) ${getUnit(title)}`),
                    axisTick : {
                        show: true,
                        interval: 0
                    },
                    axisLabel: {
                        formatter: function (value, index) {
                            return value.match(/\((.*)\s-/)[1];
                        }
                    },
                    axisLine: {}
                },
                yAxis: {
                    type: 'value'
                },
                series: seriesData && [{
                    areaStyle: {opacity: 0.2},
                    type: 'line',
                    data: seriesData.map(one => one.number)
                }]
            }
            option && myChart.setOption(option);
        })
        
    };
    const onFinish = values => {
        setLoading(true);
        http.get('/tmx-dashboard/insight/analysisbyfeature', values).then(res => {
            setLineList(Object.keys(res));
            setChartData(res);
        }).finally(() => setLoading(false))
    };
    const onExport = () => {
        form.validateFields().then(values => {
            onBlobExport('/tmx-dashboard/insight/analysisbyfeature/download', values).then(res => {
                downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*)/)[1])
            });
        })
    }
    return (
        <div>
            <Form form={form} onFinish={onFinish} autoComplete="off" layout="vertical" initialValues={{feature: 'Trip mileage'}}>
                <Row align="middle" gutter={16}>
                    <Col span={8}>
                        <Form.Item label={t('tmx.feature')} name="feature" rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.feature') }]}>
                            <Select>
                                {featureList.map(item => <Option value={item} key={item}>{t('tmx.options.' + item)}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Button icon={<SearchOutlined />}   loading={loading}  htmlType="submit">{t('common.search')}</Button>
                    </Col>
                    <Col>
                        <Button icon={<DownloadOutlined />} onClick={onExport}>{t('common.export')}</Button>
                    </Col>
                </Row>
            </Form>
            <Row>
                { lineList.map(key => <div key={key} id={'featureByGroupChart' + key} style={{height: 200, width: 200}}></div>)  }
            </Row>
        </div>
    );
};
export default FeatureAnalysisByGroup;