import { useEffect, useState } from "react";
import http from '../../http';


export function useInsuranceList() {
    const [insuranceList, setInsuranceList] = useState([]);
    useEffect(() => {
        http.get('/partner-eval/evaluation/partner/insurances').then(res => {
            setInsuranceList(res);
        })
    }, [])
    return {
        insuranceList
    }
}

export function useDealList() {
    const [dealList, setDealList] = useState([]);
    useEffect(() => {
        http.get('/system-setting/common/deals').then(res => {
            setDealList(res.map(deal => {
                return {
                    label: `${deal.description}(${deal.value})`,
                    value: deal.value
                }
            }));
        })
    }, [])
    return {
        dealList
    }
}

export function useVendorList() {
    const [vendorList, setVendorList] = useState([]);
    useEffect(() => {
        http.get('/partner-eval/evaluation/partner/partners').then(res => {
            setVendorList(res);
        })
    }, [])
    return {
        vendorList
    }
}