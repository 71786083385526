import { useEffect, useState } from "react";
import http from '../http';

export function useDealList() {
    const [dealList, setDealList] = useState([]);
    useEffect(() => {
        http.get('/system-setting/common/deals').then(res => {
            setDealList(res.map(one => {
                return {
                  label: `${one.description}(${one.value})`,
                  value: one.id
                }
              }));
        })
    }, [])
    return {
        dealList
    }
}