import * as echarts from 'echarts/core';
import {
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Button, Radio, Form, Input, Select, DatePicker, Row, Col } from 'antd';
import {DownloadOutlined, Outlined, SearchOutlined} from '@ant-design/icons'

const { RangePicker } = DatePicker;

import { useTranslation } from 'react-i18next'

import { DataCard } from '../../../components/DataCard/card';
import { useEffect, useState } from 'react';
import styles from '../index.module.css';
import moment from 'moment';
import http, {onBlobExport, downloadBlob} from '../../../http';

import './index.css';
echarts.use([
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    CanvasRenderer
]);

const valueOptions = [
    'ADASFollowCloselyNumber',
    'ADASFollowCloselyPer100kmNumber',
    'ADASForwardCollisionNumber',
    'ADASForwardCollisionPer100kmNumber',
    'ADASLaneDepartureNumber',
    'ADASLaneDeparturePer100kmNumber',
    'ADASWarningNumber',
    'ADASWarningPer100kmNumber',
    'ActiveVehicleNumber',
    'ConnectedVehicleNumber',
    'DSMCellphoneNumber',
    'DSMCellphonePer100kmNumber',
    'DSMFatigueDrivingNumber',
    'DSMFatigueDrivingPer100kmNumber',
    'DSMSmokingNumber',
    'DSMSmokingPer100kmNumber',
    'DSMWarningNumber',
    'DSMWarningPer100kmNumber',
    'Duration',
    'Mileage',
    'TripNumber',
    'WarningNumber',
    'WarningPer100kmNumber'
]
const groupOptions = ['DEAL', 'INSURANCE', 'REGION', 'VENDOR'];

const formatTime = (time, range) => {
    let start, end;
    switch (range) {
        case 'Day':
            start = time[0].startOf('day');
            end = time[1].endOf('day');
            break;
        case 'Week':
            start = time[0].startOf('week');
            end = time[1].endOf('week');
            break;
        case 'Month':
            start = time[0].startOf('month');
            end = time[1].endOf('month');
            break
    }
    return {
        endTimestamp: end.valueOf(),
        startTimestamp: start.valueOf()
    }
}
const disabledDate = current => current && current > moment().endOf('day');
const TMXDashboard = () => {
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState({});
    const [chartTitle, setChartTitle] = useState('');
    const [chartData, setChartData] = useState(null)
    const [dateRange, setDateRange] = useState('Day')
    const [range, setRange] = useState('Day');
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const loadSumary = level => {
        http.get('/tmx-dashboard/tmx/summary', { dateRange: level }).then(res => {
            setSummary(res);
        })
    }
    useEffect(() => {
        form.submit();
    }, [])
    useEffect(() => {
        loadSumary(range)
    }, [range])
    useEffect(() => {
        chartData && setChart()
    }, [chartData])
    const setChart = () => {
        var chartDom = document.getElementById('main');
        echarts.init(chartDom).dispose();
        var myChart = echarts.init(chartDom);
        var option;
        option = {
            color: ['#1455B4', '#91C8FF', '#006E73', '#73E1A5', '#9B14B4', '#FA8CFF'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
            },
            legend: {
                type: 'scroll',
                icon: 'rect',
                itemWidth: 14,
                orient: 'vertical',
                right: 0,
                top: 'center',
                bottom: 20,
                textStyle: {
                    width: 150,
                    overflow: 'break'
                }
            },
            grid: {
                show: true,
                left: '3%',
                top: 10,
                right: 190,
                bottom: '3%',
                containLabel: true
            },
            yAxis: {
                type: 'value'
            },
            xAxis: {
                type: 'category',
                data: chartData.dims.map(one => one.slice(0, 10)),
                boundaryGap: true,
                axisTick: {
                    alignWithLabel: true
                }
            },
            series: Object.keys(chartData.measures).map(deal => {
                return {
                    name: deal,
                    type: 'bar',
                    stack: 'total',
                    data: chartData.measures[deal]
                }
            })
        };

        option && myChart.setOption(option);
    }
    const formatFieldValue = values => {
        const { dateRange, date, group, valueType } = values;
        return {
            dateRange,
            group,
            valueType,
            ...formatTime(date, dateRange)
        };
    }
    const onSubmit = values => {
        setLoading(true);
        http.get('/tmx-dashboard/tmx/tendency', formatFieldValue(values)).then(res => {
            setChartData(res);
            setChartTitle(() => {
                return values.valueType === 'Duration' 
                ? t('tmx.options.' + values.valueType) + ' (h)'
                : values.valueType === 'Mileage' 
                    ? t('tmx.options.' + values.valueType) + ' (km)'
                    : t('tmx.options.' + values.valueType)
            });
        }).finally(() => setLoading(false))
    }
    function onExport(url) {
        onBlobExport('/tmx-dashboard/tmx/tendency/download', formatFieldValue(form.getFieldsValue())).then(res => {
            downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*)/)[1])
        });
    }
    return (
        <div className="data-collection">
            <div className={styles.title}>{t('tmx.dataSummary')}</div>
            <Row justify="space-between">
                <Col><span className="text">{t('common.update')}: {moment(summary.updateDate).format('YYYY/MM/DD')}</span></Col>
                <Col>
                    <div className="button-group">
                        <Button className={range === 'Day' ? 'btn-active' : 'btn-range'} type="link" onClick={() => setRange('Day')}>{t('tmx.total')}</Button>
                        <span> </span>
                        <Button className={range === 'Week' ? 'btn-active' : 'btn-range'} type="link" onClick={() => setRange('Week')}>{t('tmx.7days')}</Button>
                        <span> </span>
                        <Button className={range === 'Month' ? 'btn-active' : 'btn-range'} type="link" onClick={() => setRange('Month')}>{t('tmx.30days')}</Button>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: "8px", marginBottom: "24px" }}>
                <Col span={6}><DataCard title={t('tmx.vehicle')} supNum={summary.vehicleCount - summary.vehicleCountBefore} num={summary.vehicleCount}></DataCard></Col>
                <Col span={6}><DataCard title={t('tmx.trip')} supNum={summary.tripCount - summary.tripCountBefore} num={summary.tripCount}></DataCard></Col>
                <Col span={6}><DataCard title={t('tmx.mileage')} supNum={summary.mileage - summary.milageBefore} num={summary.mileage}></DataCard></Col>
                <Col span={6}><DataCard title={t('tmx.warning')} supNum={summary.warningCount - summary.warningCountBefore} num={summary.warningCount}></DataCard></Col>
            </Row>
            <div className={styles.title}>{t('tmx.tendency')}</div>
            <div>
                <Form
                    layout="vertical"
                    initialValues={{
                        date: [moment().subtract(30, 'days').startOf('day'), moment().endOf('day')],
                        dateRange: 'Day',
                        valueType: 'ActiveVehicleNumber',
                        group: 'DEAL'
                    }}
                    onFinish={onSubmit}
                    form={form}
                >
                    <Row gutter={18} align="bottom">
                        <Col>
                            <Form.Item label={t('tmx.granularity')} name="dateRange">
                                <Radio.Group onChange={e => setDateRange(e.target.value)}>
                                    <Radio.Button value="Day">{t('common.day')}</Radio.Button>
                                    <Radio.Button value="Week">{t('common.week')}</Radio.Button>
                                    <Radio.Button value="Month">{t('common.month')}</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col>
                            {(() => {
                                const DateForm = props => <Form.Item label={t('common.date')} name="date" rules={[{ type: 'array', required: true, message: t('common.pleaseSelect') + t('common.date') }]}>{props.children}</Form.Item>
                                switch (dateRange) {
                                    case 'Day':
                                        return <DateForm><RangePicker disabledDate={disabledDate} allowClear={false}/></DateForm>
                                    case 'Week':
                                        return <DateForm><RangePicker disabledDate={disabledDate} picker="week" allowClear={false} /></DateForm>
                                    case 'Month':
                                        return <DateForm><RangePicker disabledDate={disabledDate} picker="month" allowClear={false}/></DateForm>
                                }
                            })()}
                        </Col>
                        <Col span={6}>
                            <Form.Item label={t('tmx.valueType')} name="valueType" rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.valueType') }]}
                            >
                                <Select >
                                    {valueOptions.map(item => <Select.Option value={item} key={item}>{t(`tmx.options.${item}`)}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label={t('tmx.group')} name="group" rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.group') }]}>
                                <Select>
                                    {groupOptions.map(item => <Select.Option value={item} key={item}>{t('tmx.options.' + item)}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5} style={{ marginBottom: '24px' }}>
                            <Button icon={<SearchOutlined />}   loading={loading} style={{ marginLeft: '8px' }} htmlType="submit">{t('common.search')}</Button>
                            <Button onClick={() => onExport()} style={{ marginLeft: '8px' }} icon={<DownloadOutlined />}>{t('common.export')}</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className={styles.title}>{chartTitle}</div>
            <div id="main" style={{ height: 600, width: '100%' }}></div>
        </div>
    )
}

export default TMXDashboard;