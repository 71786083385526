export const listToTree = (items, idKey='id', parentKey='parentId') => {
    const idMap = items.map(one => one[idKey]);
    const result = []; // 存放结果集
    const itemMap = {}; //
    for (const item of items) {
        const id = item[idKey];
        const pid = item[parentKey];
        if (itemMap[id]) {
            itemMap[id] = {
                ...item,
                children: itemMap[id]['children']
            };
        } else {
            itemMap[id] = {
                ...item
            };
        }
        const treeItem = itemMap[id];

        if (!idMap.includes(pid)) {
            result.push(treeItem);
        } else {
            if (itemMap[pid]) {
                itemMap[pid].children
                    ? itemMap[pid].children.push(treeItem)
                    : (itemMap[pid].children = [treeItem]);
            } else {
                itemMap[pid] = {
                    children: [treeItem]
                };
            }
        }
    }
    return result;
};
