

import { useState, useEffect } from 'react';
import {  Steps, Row, Col, Button, Modal, Form, Input, InputNumber,  Select, DatePicker, message} from 'antd';
import http from '../../http';
import { useTranslation } from 'react-i18next'
import {useInsuranceList} from '../../data/insurer';
import moment from 'moment';

const NewQuarter = props => {
    const {insurerId, onSuccess} = props;
    const {t} = useTranslation();
    const [newLoading, setNewLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [newQuarterSelectList, setNewQuarterSelectList] = useState([]);

    // const [dateRange, setDateRange] = useState(null);

    const [form] = Form.useForm();
    const {insuranceList } = useInsuranceList();

    const disabledDate = (current) => {
        return current < moment().add(30, 'days') || current > moment().add(90, 'days') ;
    }
    const onDateSelect = date => {
        if (date.diff(dateRange[0], 'days') > 60) {
            message.info(t('bills.daysLateTips'));
        }
    }

    const getQuarterDefaultByInsurer = (id) => {
        return http.get('/bill/insurer-bill-quarter/quarter/new/default', {insurerId: id || insurerId}).then(res => {
            if (res) {
                const {currentQuarter, tmxfreezeDate, currentQuarterStartDate, currentQuarterEndDate} = res;
                // setDateRange([moment(currentQuarterStartDate), moment(currentQuarterEndDate)])
                res.newQuarterSelectList?.length>0 && setNewQuarterSelectList(res.newQuarterSelectList);
                form.setFieldsValue({
                    currentQuarter,
                    newQuarterSelectList,
                    tmxfreezeDate: moment().add(45, 'days'),
                    offlineDay: 30,
                    insurerId: id || insurerId
                });
            }
        })
    }
    const handleNew = () => {
        if (insurerId) {
            getQuarterDefaultByInsurer().then(() => setVisible(true));
        } else {
            setVisible(true)
        }   
    }
    const onInsurerChange = (id) => {
        getQuarterDefaultByInsurer(id);
    }
    const confirmNew = () => {
        form.validateFields().then(res => {
            const {tmxfreezeDate, insurerId, offlineDay,currentQuarter} = res;
            setNewLoading(true);
            return http.post('/bill/insurer-bill-quarter/quarter/new', {
                tmxfreezeDate: tmxfreezeDate.format('YYYY-MM-DD'),
                insurerId,
                selectQuarter: currentQuarter,
                offlineDay,
            }).then(res => {
                message.success(t('common.success'))
                onSuccess && onSuccess();
                setVisible(false);
            }).finally(() => setNewLoading(false));
        })
    }
    return <>
        <Button style={{marginRight: '8px'}} onClick={handleNew} loading={newLoading}>{t('bills.newQuarter')}</Button>
        <Modal
            visible={visible}
            onOk={confirmNew}
            onCancel={() => setVisible(false)}
            confirmLoading={newLoading}
        >
            <Form form={form} title={t('bills.newQuarter')} layout="vertical">
                <Form.Item label={t('bills.insurer')} name="insurerId" rules={[{required: 'true'}]}>
                    <Select options={insuranceList} allowClear onChange={onInsurerChange}></Select>
                </Form.Item>
                {/* <Form.Item label={t('bills.billQuarter')} name="currentQuarter" rules={[{required: 'true'}]} disabled>
                    <Input disabled></Input>
                </Form.Item> */}
                <Form.Item label={t('bills.billQuarter')} name="currentQuarter" rules={[{required: 'true'}]} disabled>
                    <Select allowClear disabled={!newQuarterSelectList.length}>
                        {
                        newQuarterSelectList.map(item => <Select.Option key={item?.currentQuarter}>{item?.currentQuarter}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={t('bills.tmxDataFreezeDate')} name="tmxfreezeDate" rules={[{required: 'true'}]}>
                    <DatePicker style={{width: '100%'}} disabledDate={disabledDate} defaultValue={moment().add(45, 'days')}></DatePicker>
                </Form.Item>
                <Form.Item label={t('bills.offlineDay')} name="offlineDay" rules={[{required: 'true'}]}>
                    <InputNumber style={{width: '100%'}}></InputNumber>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default NewQuarter