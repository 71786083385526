import { Table, Select, Button, Row, Col, Form, Spin } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useTable } from '../../../hooks/table';
import { useState } from 'react';
import http, { onBlobExport, downloadBlob }  from '../../../http';
import { useTranslation } from 'react-i18next'
import style from './index.module.css'
import { useEffect } from 'react'
import ChangeColumns from '../components/ChangeColumns'

export default function VehicleRisk () {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const columns = [
    { "title": t.bind(null, 'RenewSuggestion.PlateNumber'), "dataIndex": "PlateNumber", disabled: true, default: true }, 
    { "title": t.bind(null, 'RenewSuggestion.VIN'), "dataIndex": "VIN", disabled: true, default: true }, 
    { "title": t.bind(null, 'RenewSuggestion.Deal'), "dataIndex": "Deal" }, 
    { "title": t.bind(null, 'RenewSuggestion.Region'), "dataIndex": "Region", disabled: true, default: true }, 
    { "title": t.bind(null, 'RenewSuggestion.VehicleType'), "dataIndex": "VehicleType"}, 
    { "title": t.bind(null, 'RenewSuggestion.Manufacture'), "dataIndex": "Manufacture" }, 
    { "title": t.bind(null, 'RenewSuggestion.VehicleAge'), "dataIndex": "VehicleAge", sorter: {compare: (a, b) => a.VehicleAge - b.VehicleAge} }, 
    { "title": t.bind(null, 'RenewSuggestion.PolicyNumber'), "dataIndex": "PolicyNumber", disabled: true, default: true }, 
    { "title": t.bind(null, 'RenewSuggestion.InsuredName'), "dataIndex": "InsuredName"}, 
    { "title": t.bind(null, 'RenewSuggestion.PolicyInceptionDate'), "dataIndex": "InceptionDate"}, 
    { "title": t.bind(null, 'RenewSuggestion.PolicyEndDate'), "dataIndex": "EndDate", disabled: true, default: true, sorter: {compare: (a, b) => a.EndDate - b.EndDate} }, 
    { "title": t.bind(null, 'RenewSuggestion.LeftDays'), "dataIndex": "LeftDays", disabled: true, default: true, sorter: {compare: (a, b) => a.EndDate - b.EndDate} }, 
    { "title": t.bind(null, 'RenewSuggestion.Premium'), "dataIndex": "Premium", sorter: {compare: (a, b) => a.Premium - b.Premium} }, 
    { "title": t.bind(null, 'RenewSuggestion.ClaimsCount'), "dataIndex": "ClaimsCount", sorter: {compare: (a, b) => a.ClaimsCount - b.ClaimsCount} }, 
    { "title": t.bind(null, 'RenewSuggestion.LossPaid'), "dataIndex": "LossPaid", sorter: {compare: (a, b) => a.LossPaid - b.LossPaid} }, 
    { "title": t.bind(null, 'RenewSuggestion.TotalPolicies'), "dataIndex": "TotalPolicies", sorter: {compare: (a, b) => a.TotalPolicies - b.TotalPolicies} }, 
    { "title": t.bind(null, 'RenewSuggestion.TotalClaims'), "dataIndex": "TotalClaims", sorter: {compare: (a, b) => a.TotalClaims - b.TotalClaims} }, 
    { "title": t.bind(null, 'RenewSuggestion.TotalPremium'), "dataIndex": "TotalPremium", sorter: {compare: (a, b) => a.TotalPremium - b.TotalPremium} }, 
    { "title": t.bind(null, 'RenewSuggestion.TotalLoss'), "dataIndex": "TotalLoss", sorter: {compare: (a, b) => a.TotalLoss - b.TotalLoss} }, 
    { "title": t.bind(null, 'RenewSuggestion.SwissReRiskScore'), "dataIndex": "SwissReRiskScore", disabled: true, default: true, sorter: {compare: (a, b) => a.SwissReRiskScore - b.SwissReRiskScore} }, 
    { "title": t.bind(null, 'RenewSuggestion.Tag'), "dataIndex": "Tag" }, 
    { "title": t.bind(null, 'RenewSuggestion.Suggestion'), "dataIndex": "Suggestion", disabled: true, default: true }, 
  ]
  // default dispaly table head
  const [initLoading, setInitLoading] = useState(true)
  const [downDataLoading, setDownDataLoading] = useState(false)
  const [selectOptionsData, setSelectOptionsData] = useState({})
  const [downExclParams, setDownExclParams] = useState({})


  // default dispaly table head
  const defaultColumns = columns.filter(e => e.default)
  // dynamic regulation table head
  const [column, setColumns] = useState(defaultColumns)

  // dynamic regulation table head
  const { tableData, pagination, loading, setQuery, handleTableChange } = useTable(getData);

  const getInit = () => {
    return http.get('/vehicleriskapi/RenewSuggestion/GetInitData')
  }

  // get table date
  async function getData (param) {
    let res = await http.get('/vehicleriskapi/RenewSuggestion/GetData', {
      PageNum: pagination.current,
      PageSize: pagination.pageSize,
      ...param,
    })
    return res
  }

  const onFormSearch = (e) => {
    setQuery(e)
  }

  useEffect(() => {
    setInitLoading(false)
    getInit().then(res => {
      setInitLoading(false)
      setSelectOptionsData(res)
    })
  }, [])

  const onValuesChange = async (e) => {
    const row = (await form.validateFields())
    setDownExclParams(row)
  }

  const exportExcl = () => {
    let params = {
      la: localStorage.getItem('language'),
      ...downExclParams
    }
    setDownDataLoading(true)
    onBlobExport('/vehicleriskapi/RenewSuggestion/GetExcel', params, { 'method': 'get' }).then(res => {
      setDownDataLoading(false)
      downloadBlob(res.data, 'Renew Suggestion')
    });
  }

  const { Option } = Select;
  return <div>
    <div className={style.titleBox}>
      <span className={style.title}>{t('RenewSuggestion.RenewSuggestion')}</span>
    </div>
    <Spin spinning={initLoading}>
      <Form layout="vertical" form={form} onFinish={onFormSearch} onValuesChange={onValuesChange}>
        <Row gutter={[8]}>
          <Col className="gutter-row" span={6}>
            <Form.Item
              name='strDeal'
              label={t('RenewSuggestion.Deal')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.DealsData && selectOptionsData.DealsData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              name='strSuggestion'
              label={t('RenewSuggestion.Suggestion')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.SuggestionData && selectOptionsData.SuggestionData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              name='strLeftDays'
              label={t('RenewSuggestion.LeftDays')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.LeftDaysData && selectOptionsData.LeftDaysData.map(e => <Option key={e.id} value={e.typeName.match(/\d*/)[0]||0}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <div style={style} className={style.btnbox}>
              <Button className={style.submitbtn} htmlType="submit"><SearchOutlined />{t('common.search')}</Button>
              <Button className={style.exportbtn} loading={downDataLoading} onClick={exportExcl}><DownloadOutlined />{t('common.export')}</Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
    <div className={style.right}>
      <ChangeColumns Columns={columns} changeColumns={setColumns} />
    </div>
    <Table pagination={pagination} scroll={{ x: column.length * 150 }} onChange={handleTableChange} columns={column} loading={loading} dataSource={tableData} />
  </div>
}

