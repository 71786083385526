import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useEffect, useState } from 'react';
import http from '../../../../http';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next'
import { Row } from 'antd';

import { getUnit, round2 } from '../../../TMXDashboard/DataInsight/tools';

export default function PreviewDataInsightByGroup ({ serchParams }) {
  console.log('-----serchParams', serchParams)
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState(null);
  const [lineList, setLineList] = useState([]);

  const uid = serchParams.groupUUID

  const onFinish = values => {
    setLoading(true);
    http.get('/tmx-dashboard/insight/analysisbyfeature', values).then(res => {
        setLineList(Object.keys(res));
        setChartData(res);
    }).finally(() => setLoading(false))
  };
  useEffect(() => {
    onFinish(serchParams)
  }, [])

  const setChart = () => {
    chartData.forEach((one, index) => {
        var chartDom = document.getElementById(`featureByGroupChart${uid}${index}`);
        echarts.init(chartDom).dispose();
        var myChart = echarts.init(chartDom);
        var option;
        
        const seriesData = one.featureDistributedModels[0]?.distributedModels || [];
        const title = one.featureDistributedModels[0]?.feature;
        option = {
            color: ['#607D77','#7DA39B', '#7D6966', 'rgba(98, 125, 119, 0.25)', '#627D77', '#747D60', '#69607D', '#747D60'],
            title: {
                text: one.groupName,
                top: '10%',
                textStyle: {
                    fontSize: '12px',
                    fontWeight: 'normal'
                }
            },
            tooltip: {
                trigger: 'axis',
                appendToBody: true
            },
            grid: {
                left: '3%',
                right: '10%',
                bottom: '3%',
                containLabel: true
            },
            // xAxis: {
            //     // show: false
            //     type: 'category',
            //     boundaryGap: false,
            //     data: seriesData.map(one => one.left),
            //     axisTick : {
            //         show: false
            //     },
            //     axisLine: {}
            // },
            xAxis: {
                show: true,
                type: 'category',
                boundaryGap: false,
                data: seriesData.map((data, index) => `(${round2(data.left)} - ${round2(data.right)}) ${getUnit(title)}`),
                axisTick : {
                    show: true,
                    interval: 0
                },
                axisLabel: {
                    formatter: function (value, index) {
                        return value.match(/\((.*)\s-/)[1];
                    }
                },
                axisLine: {}
            },
            yAxis: {
                type: 'value'
            },
            series: seriesData && [{
                areaStyle: {opacity: 0.2},
                type: 'line',
                data: seriesData.map(one => one.number)
            }]
        }
        option && myChart.setOption(option);
    })
    
};

  useEffect(() => {
    chartData && setChart()
  }, [chartData])

  return <div>
    <Spin spinning={loading}>
      <Row>
        {lineList.map(key => <div key={key} id={'featureByGroupChart' + uid + key} style={{ height: 200, width: 200 }}></div>)}
      </Row>
    </Spin>
  </div>
}