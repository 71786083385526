import moment from "moment"

const renderTime = time => {
  return moment(time).format('YYYY-MM-DD')
}
export const vehicleTable = {
    vinErrors: {
        title: '如下车辆VIN码在本期账单内重复：',
        columns:[
            {title:"车牌号", dataIndex: 'carLicense'}, 
            {title:"VIN码", dataIndex: 'vin'},
            {title:"账单季度", dataIndex: "quarter"}
        ]
    },
    vinPastErrors: {
        title:"VIN码与往期账单重复：",
        columns:[
            {dataIndex:"carLicense", title:"车牌号", "minWidth": "180"},
            {dataIndex:"vin", title:"VIN码", "minWidth": "180"},
            {dataIndex:"policyNumber", title:"本期保单号", "minWidth": "200"},
            {dataIndex:"constractDate", title:"本期起保日期", "minWidth": "150", render: renderTime},
            {dataIndex:"pastPolicyNumber", title:"上期保单号", "minWidth": "200"},
            {dataIndex:"pastConstractDate", title:"上期起保日期", "minWidth": "150", render: renderTime},
            {dataIndex:"pastQuarter", title:"上期入账季度", "minWidth": "100"}
        ]
      },
    
    vinMatchErrors: {
        title:"vin码不匹配：",
        columns:[
            { dataIndex:"carLicense", title:"车牌号", "minWidth": "100"},
            {dataIndex:"vendorVin", title:"VIN码（账单）", "minWidth": "150"},
            {dataIndex:"platformVin", title:"VIN码（动静态数据表）", "minWidth": "150"}
        ]
      },
    
    carLicenseMatchErrors: {
        title:"CarLicense不匹配：",
        columns:[
            {dataIndex:"carLicense",title:"车牌号","minWidth": "150"},
            {dataIndex:"vin",title:"VIN码","minWidth": "100"},
            {dataIndex:"vendorCarLicenseMd5",title:"CarLicense（账单）","minWidth": "150"},
            {dataIndex:"platformCarLicenseMd5",title:"CarLicense（动静态数据表）","minWidth": "150"}]
      },
    
    tripDataErrors: {
        title: "没有里程数据：",
        columns: [
            {dataIndex: "carLicense",title: "车牌号","minWidth": "100"},
            {dataIndex: "vin",title: "VIN码","minWidth": "120"},
            {dataIndex: "deviceInstallDate",title: "设备安装日期","minWidth": "100",render: renderTime},
            {dataIndex: "quarter",title: "账单季度","minWidth": "100"}
        ]
      },
    
    deviceWorkErrors: {
        title:"设备疑似工作不正常：",
        columns:[
            {dataIndex:"carLicense",title:"车牌号","minWidth": "100"},
            {dataIndex:"vin",title:"VIN码","minWidth": "120"},
            {dataIndex:"firstTripDay",title:"首次里程日期","minWidth": "120",render: renderTime},
            {dataIndex:"lastTripDay",title:"末次里程日期","minWidth": "120",render: renderTime},
            {dataIndex:"tripCount",title:"行驶次数","minWidth": "100"},
            {dataIndex:"tripDayCount",title:"行驶天数","minWidth": "100"},
            {dataIndex:"daysSinceLastTrip",title:"至今无里程天数（天）","minWidth": "100"}
        ]
      },
    
    installDateErrors: {
        title:"有起保日期：没有按时完成设备安装 (>61天)：",
        columns:[
            {dataIndex:"carLicense",title:"车牌号","minWidth": "100"},
            {dataIndex:"vin",title:"VIN码","minWidth": "100"},
            {dataIndex:"policyNumber",title:"保单号","minWidth": "150"},
            {dataIndex:"contractStartDate",title:"起保日期","minWidth": "120",render: renderTime},
            {dataIndex:"firstTripDay",title:"首次里程日期","minWidth": "120",render: renderTime},
            {dataIndex:"dayDiff",title:"首次里程日期-起保日期（天）","minWidth": "100"}
        ]
      },
    
    tripDayMatchErrors: {
        title:'无起保日期：安装日期与动态里程信息不匹配 (>7天)：',
        columns:[
            {dataIndex:"carLicense",title:"车牌号","minWidth": "100"},
            {dataIndex:"vin",title:"VIN码","minWidth": "100"},
            {dataIndex:"deviceInstallDate",title:"设备安装日期","minWidth": "120",render: renderTime},
            {dataIndex:"firstTripDay",title:"首次里程日期","minWidth": "120",render: renderTime},
            {dataIndex:"dayDiff",title:"首次里程日期-设备安装日期（天）","minWidth": "100"}
        ]
      },
    
      dealsDateErrors:{
        title:"保单不在有效期限内：",
        columns:[
            {dataIndex:"carLicense",  title:"车牌号",  "minWidth": "100"},
            {dataIndex:"vin",title:"VIN码","minWidth": "100"},
            {dataIndex:"policyNumber",title:"保单号","minWidth": "150"},
            {dataIndex:"dealsStartDate",title:"合同开始日期","minWidth": "120", render: renderTime},
            {dataIndex:"dealsEndDate",title:"结束日期","minWidth": "120",render: renderTime},
            {dataIndex:"contractStartDate",title:"实际起保日期","minWidth": "120",render: renderTime}
        ],
      },
    
    amountErrors: {
        title:"账单金额与合同不符：",
        columns:[
            { dataIndex:"carLicense",  title:"车牌号",  "minWidth": "100"},
            {dataIndex:"vin",title:"VIN码","minWidth": "100"},
            {dataIndex:"policyNumber",title:"保单号","minWidth": "150"},
            {dataIndex:"dealsAmount",title:"合同金额","minWidth": "100"},
            {dataIndex:"billsAmount",title:"账单金额","minWidth": "100"}
        ],
      },
}

export const insuranceTable = {
    fieldErrors: {
        title:"以下保单关键信息不全：",
        columns:
          [
            {dataIndex:"policyNumber", title:"保单号", "minWidth": "180",},
            {dataIndex:"contractStartDate", title:"起保日期", "minWidth": "150",render: renderTime  },
            {dataIndex:"totalPremium", title:"保单保费", "minWidth": "180",},
            {dataIndex:"cededPremium", title:"净分保费", "minWidth": "180",}]
      },
}
export const compareColumns = [
    {dataIndex: "insuranceNumber", title: "直保公司账单保单号","min-width": "180",},
    {dataIndex: "vendorNumber", title: "供应商账单保单号","min-width": "180",},
    {dataIndex: "description", title: "备注","min-width": "180",},
]

export const tableProps = {
    style: {marginTop: '10px'},
    pagination: false,
    rowKey: "id"
}