import React, { useImperativeHandle, forwardRef } from 'react';
import { Table, Input, Form, Radio, Select, message } from 'antd';
import style from './components.module.css'
import { useTranslation } from 'react-i18next'

const { Option } = Select;

const TriangleCalculate = forwardRef(({tringle3Columns, tringleData, onSelect, onAverage, onSelection, onUserInput, optionLength},ref)=>{
  const { t } = useTranslation();
  useImperativeHandle(ref, () => ({
    reset
  }))


  const [form] = Form.useForm();
  const [selectForm] = Form.useForm();


  const isEditing = (record) => record.Key === 4;

  const save = async () => {
    const row = (await form.validateFields())
    let vError = false
    Object.keys(row).forEach(e=>{
      if(!row[e]){
        row[e] = '1'
      }else if(!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(row[e])){
        vError = true
      }
    })
    if(vError){
      message.warning(`${t('triangle.greaterThen0')}`);
      return
    }
    onUserInput(row)
  };

  const mergedColumns = (list) => {
    if(!list || !list.length){
      return []
    }
    let columns = list.map(col => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          // onChange: () => {
          //   save()
          // },
          onBlur: () => {
            save()
          }
        }),
      };
    });
    return columns
  }

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
          >
            <Input/>
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const reset = () => {
    selectForm.resetFields();
    form.resetFields()
  }

  const methodSelect = (key) => {
    let list = [0, 1, 2].map(e =>
    <Form.Item name={`methodS${e}`} initialValue={e === 1 ? `${e}-WA` : `${e}-SA`} style={{marginBottom: 0, width: '95%'}}>
      <Select  className={style.item} onSelect={onAverage}>
        <Option value={`${e}-SA`} key={`${e}-SA`}>SA</Option>
        <Option value={`${e}-WA`} key={`${e}-WA`}>WA</Option>
      </Select>
    </Form.Item>
    )
    return list
  }
  const selectionSelect = (key) => {
    let list = [0, 1, 2].map(e =>
      <Form.Item name={`selectS${e}`} initialValue={e === 2 ? `${e}-${optionLength}` : `${e}-${optionLength-1>1?optionLength-1:1}`} style={{marginBottom: 0, width: '95%'}}>
        <Select className={style.item} onSelect={onSelection}>
          {Array(optionLength).fill(0).map((o,i)=><Option value={`${e}-${i+1}`} key={`${e}-${i+1}`}>{i+1}</Option>)}
        </Select>
      </Form.Item>
    )
    return list
  }

  return (
    <div className={style.calculate}>
      {tringleData.length ? <Form form={selectForm} name="control-hooks">
        <div className={style.control}>
          <div className={style.selectList}>
            <div className={style.head}>Select</div>
            <Form.Item name="radio">
              <Radio.Group onChange={onSelect} className={style.radioGroup}>
                <Radio value={0} className={style.radioItem}></Radio>
                <Radio value={1} className={style.radioItem}></Radio>
                <Radio value={2} className={style.radioItem}></Radio>
                <Radio value={3} className={style.radioItem}></Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className={style.method}>
            <div className={style.head}>Average method </div>
            {methodSelect()}
            <div className={style.itemS}>Judgement</div>
            <div className={style.itemS}>Age-Ultimate</div>
          </div>
          <div className={style.selection}>
            <div className={style.head}>Dev.Selection</div>
            {selectionSelect()}
          </div>
        </div>
      </Form> : ''}
      <div className={style.tableBox}>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            size='small'
            bordered
            dataSource={tringleData}
            columns={mergedColumns(tringle3Columns)}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </div>
    </div>
  );

})

export default TriangleCalculate