import { Select, Row, Col, Form, Spin } from 'antd';
import { useState } from 'react';
import http from '../../../../http';
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

export default function FeatureAnalysis ({Section, SectionJS}) {
  const { t } = useTranslation();
  // default dispaly table head
  const [initLoading, setInitLoading] = useState(true)
  const [selectOptionsData, setSelectOptionsData] = useState({})


  // dynamic regulation table head
  const getInit = () => {
    return http.get('/vehicleriskapi/OnewayAnalysis/GetInitData')
  }

  useEffect(() => {
    getInit().then(res => {
      setInitLoading(false)
      setSelectOptionsData(res)
    })
  }, [])

  const saveFeature = (key) => {
    SectionJS.serchParams.strFeature = key
    const {Feature} = selectOptionsData
    const item = Feature.find(e=>e.id === key) || {}
    SectionJS.serchParams.strFeatureLabel = item.typeName
  }
  const saveValue = (key) => {
    SectionJS.serchParams.strValue = key
    const {Value} = selectOptionsData
    const item = Value.find(e=>e.id === key) || {}
    SectionJS.serchParams.strValueLabel = item.typeName
  }


  const { Option } = Select;

  return <div>
    <Spin spinning={initLoading}>
      <Row justify="space-between">
        <Col className="gutter-row" span={11}>
          <Form.Item
            name={[Section.name, 'serchParams', 'strFeature']}
            label={t('featureAnalysis.RiskFeature')}
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select"
              allowClear
              onChange={e=>{saveFeature(e)}}
            >
              {selectOptionsData.Feature && selectOptionsData.Feature.map(e => <Option key={e.id} value={e.id}>{t(`OnewayRiskFeature.${e.typeName}`)}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={11}>
          <Form.Item
            name={[Section.name, 'serchParams', 'strValue']}
            label={t('featureAnalysis.LossFeature')}
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select"
              allowClear
              onChange={e=>{saveValue(e)}}
            >
              {selectOptionsData.Value && selectOptionsData.Value.map(e => <Option key={e.id} value={e.id}>{t(`OnewayLossFeature.${e.typeName}`)}</Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Spin>
  </div>
}