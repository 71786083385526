import { useState } from 'react';

export const useModal = () => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalForm, setModalForm] = useState({});
    const updateModal = (visible, data) => {
        setVisible(visible);
        setModalForm(data);
      };
    return {
        visible,
        setVisible,
        confirmLoading,
        setConfirmLoading,
        updateModal,
    }
}