import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";

import { loginRequest, protectedResources, b2cPolicies } from "../../authConfig";
import { Button } from "antd";
import { LogoutOutlined } from '@ant-design/icons';

import './style.css'
import {useTranslation} from 'react-i18next'
import { useContext } from "react";
import { LoginContext } from "../../App";
export const SignIn = () => {
    const { t } = useTranslation();
    const {idToken}= useContext(LoginContext);
    const onLogout = () => {
        instance.logoutRedirect({ postLogoutRedirectUri: "/", idTokenHint: idToken });
    }
    /**
     * useMsal is hook that returns the PublicClientApplication instance, 
     * an array of all accounts currently signed in and an inProgress value 
     * that tells you what msal is currently doing. For more, visit: 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance} = useMsal();
    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <div className="sign-button">
            <AuthenticatedTemplate>
                <a onClick={() => onLogout()}><LogoutOutlined /> {t('navbar.logout')}</a>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <a onClick={() => instance.loginRedirect(loginRequest())}>{t('navbar.login')}</a>
            </UnauthenticatedTemplate>
        </div>
    );
};