import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useEffect, useState } from 'react';
import http from '../../../../http';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next'
import {  Row } from 'antd';

import {getUnit, round2} from '../../../TMXDashboard/DataInsight/tools';

export default function PreviewDataInsightByType ({ serchParams }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState(null);
  const [lineList, setLineList] = useState([]);
  

  const onFinish = param => {
    setLoading(true);
    http.post('/tmx-dashboard/insight/analysisbytypes?strDataCope=' + serchParams.strDataCope, param).then(res => {
        setLineList(Object.keys(res));
        setChartData(res);
    }).finally(() => setLoading(false))
  };

  useEffect(() => {
    onFinish(serchParams.groupList)
  }, [])

  const setChart = () => {
    chartData.forEach((data, index) => {
        var chartDom = document.getElementById(`featureByTypeChart${serchParams.groupUUID}${index}`);
        echarts.init(chartDom).dispose();
        var myChart = echarts.init(chartDom);
        var option;
        // const seriesData = data.featureDistributedModels[0]?.distributedModels || [];
        option = {
            color: ['#607D77','#7DA39B', '#7D6966', 'rgba(98, 125, 119, 0.25)', '#627D77', '#747D60', '#69607D', '#747D60'],
            title: {
                text: t('tmx.options.' + data.feature),
                top: '10%',
                textStyle: {
                    fontSize: '12px',
                    fontWeight: 'normal'
                }
            },
            tooltip: {
                trigger: 'axis',
                appendToBody: true
                // formatter(val) {
                //     console.log(val)
                //     return JSON.stringify(val)
                //     // return val.map(item => `
                //     // <div style="display: flex; justify-content: space-between; align-items: center">
                //     //     <span>${item.marker}${item.seriesName}</span>
                //     //     <span style="margin-left: 20px">${item.value}</span>
                //     //     <span>${item}</span>
                //     // </div>
                //     // `).join('')
                // }
            },
            grid: {
                left: '3%',
                right: '10%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                show: true,
                type: 'category',
                boundaryGap: false,
                data: data.featureDistributedModels[0].distributedModels ? data.featureDistributedModels[0].distributedModels.map((model, index) => `(${round2(model.left)} - ${round2(model.right)}) ${getUnit(data.feature)}`) : [],
                axisTick : {
                    show: true,
                    interval: 0
                },
                axisLabel: {
                    // interval: 0,
                    formatter: function (value, index) {
                        return value.match(/\((.*)\s-/)[1]
                    }
                },
                axisLine: {}
            },
            yAxis: {
                type: 'value'
            },
            series: data.featureDistributedModels.map(model => {
                return {
                    name: `${model.group} - ${model.groupName}`,
                    areaStyle: {opacity: 0.2},
                    type: 'line',
                    data: model.distributedModels ? model.distributedModels.map(data => data.number) : []
                }
            })
            // series: [{
            //     name: '1',
            //     type: 'line',
            //     data: seriesData.map(data => data.number)
            // }]
        }
        option && myChart.setOption(option);
    })
}
  useEffect(() => {
    chartData && setChart()
  }, [chartData])

  return <div>
    <Spin spinning={loading}>
      <Row>
        { lineList.map(key => <div key={key} id={'featureByTypeChart' + serchParams.groupUUID + '' + key} style={{height: 200, width: 200}}></div>)  }
      </Row>
    </Spin>
  </div>
}