import { Table } from 'antd';
import { useTranslation } from 'react-i18next'
import style from './components.module.css'

const getColumns = (data) => {
  if(!data){
    return []
  }
  const {Key, UWQ, ...dList} = data || {}
  const columns = Object.keys(dList).map(e=>({
    title: e.replace(/(Q)(\d\-\d|\d)/,'$2$1'),
    dataIndex: e,
  }))
  columns.unshift({
    title: '',
    with: 400,
    dataIndex: 'UWQ',
    width: '10%',
  })
  return columns
}

export default function AgeAgeFactor ({ tringleData, title }) {
  const { t } = useTranslation();

  return <div>
    <div className={style.tabletit}><span>{t(`portfolio.${title}`)}</span></div>
    <Table columns={getColumns(tringleData[0])} dataSource={tringleData} pagination={false} size="small" />
  </div>

}