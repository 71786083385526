import { Table, Row, Col, Button, Modal, Form, Input, Select } from 'antd';
import http from '../../http';
import {useTranslation} from 'react-i18next'
import {useTable} from '../../hooks/table';
import {useVerticalForm} from '../../hooks/form';
import moment from "moment";
export default function Bills() {
    const { t } = useTranslation();
    const {tableData, pagination, loading, setQuery} = useTable(getData);
    function getData(param) {
      return http.get('/bill-mgm/file/list', {
        pageNum: pagination.current,
        pageSize: pagination.pageSize, 
        ...param
      })
    }
    const columns = [
      {
        title: t("files.fileName"),
        dataIndex: 'fileName',
      },
      {
        title: t("files.uploadDate"),
        dataIndex: 'uploadDate',
        render: time =>  moment(time).format('YYYY-MM-DD')
      },
      {
        title: t("files.hasSave"),
        dataIndex: 'hasSaved',
        render: hasSave => <span>{hasSave ? 'Y': 'N'}</span>
      },
      {
        title: t("files.action"),
        dataIndex: 'path',
        render: url => <Button type="link" onClick={() => window.open(url)}>{t('common.download')}</Button>
      },
    ];
    const {form, formProps, colProps} = useVerticalForm();
    const handleSearch = (e) => {
      const values = form.getFieldsValue();
      setQuery(values);
    }
    return <div className="container">
       <Form {...formProps} form={form} name="basic">
        <Row align="bottom">
          <Col {...colProps}>
            <Form.Item label={t('files.fileName')} name="fileName">
              <Input/>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={t('files.hasSave')} name="hasSaved">
              <Select allowClear>
                <Select.Option value={true}>Y</Select.Option>
                <Select.Option value={false}>N</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col {...colProps} style={{marginBottom: '24px'}}>
            <Button style={{marginLeft: '32px'}} onClick={handleSearch}>{t('common.search')}</Button>
          </Col>
        </Row>
      </Form>
      <Table
        loading={loading}
        pagination={pagination}
        rowKey="id"
        columns={columns}
        dataSource={tableData} />
    </div> 
  }