import { Form, Select, Row, Col  } from 'antd';
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react';
import {  getDealList, getInsuranceList, getRegionList, getVendorList } from '../../../TMXDashboard/DataInsight/data';

const { Option } = Select;

const groupOptions = ['ALL', 'DEAL', 'INSURANCE', 'REGION', 'VENDOR'];

export default function PageDataInsightCorrelationMatrix ({ Section, SectionJS }) {
  const { t } = useTranslation();

  const [DATASOURCE, setDATASOURCE] = useState({});
  useEffect(() => {
    Promise.all([getDealList(), getInsuranceList(), getRegionList(), getVendorList()])
      .then(([dealList, insuranceList, regionList, vendorList]) => {
        setDATASOURCE({
          DEAL: dealList,
          INSURANCE: insuranceList,
          REGION: regionList,
          VENDOR: vendorList,
        })
      })
  }, [])

  return <div>
    <Row justify="space-between">
      <Col span={11}>
        <Form.Item
          label={t('tmx.group')}
          name={[Section.name, 'serchParams', 'group']}
          rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.group') }]}>
          <Select>{groupOptions.map(item => <Option value={item} key={item}>{t('tmx.options.' + item)}</Option>)}</Select>
        </Form.Item>
      </Col>
      <Col span={11}>
          {SectionJS.serchParams.group!== 'ALL' ? <Form.Item
              label={t(`tmx.${SectionJS.serchParams.group.toLowerCase()}`)}
              name={[Section.name, 'serchParams', 'groupName']}
              rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.deal') }]}>
              <Select options={DATASOURCE[SectionJS.serchParams.group]}></Select>
            </Form.Item> : null }
      </Col>
    </Row>
  </div>
}