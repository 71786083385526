import { Form, Select, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next'
import { useState } from 'react';

import { useVendorList, useInsuranceList, useDealList } from '../../../PartnerEvaluation/data';

export default function PagePartnerEvaluation ({ Section, SectionJS }) {
  const { t } = useTranslation();

  const {vendorList} = useVendorList();
  const {insuranceList} = useInsuranceList();
  const {dealList} = useDealList();

  return <div>
    <Row justify="space-between">
      <Col span={7}>
        <Form.Item
          label={t('partner.deal')}
          name={[Section.name, 'serchParams', 'dealName']}
        >
          <Select allowClear showSearch options={dealList}></Select>
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item
          label={t('partner.partner')}
          name={[Section.name, 'serchParams', 'partnerName']}
        >
          <Select allowClear showSearch>
            {
              vendorList.map(vendorName => <Select.Option key={vendorName}>{vendorName}</Select.Option>)
            }
          </Select>
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item
          label={t('partner.insurer')}
          name={[Section.name, 'serchParams', 'insurerName']}
        >
          <Select allowClear showSearch>
            {
              insuranceList.map(insurerFullName => <Select.Option key={insurerFullName}>{insurerFullName}</Select.Option>)
            }
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </div>
}