import { useEffect } from 'react';
import * as echarts from 'echarts/core';
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart, LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

const LossAnalysis = () => {
    useEffect(() => {
        var chartDom = document.getElementById('main');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
            type: 'cross',
            crossStyle: {
                color: '#999'
            }
            }
        },
        toolbox: {
            feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
            }
        },
        legend: {
            data: ['name 1', 'name 2']
        },
        xAxis: [
            {
            type: 'category',
            data: ['1', '2', '3', '4', '5', '6', '7'],
            axisPointer: {
                type: 'shadow'
            }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'name 1',
                min: 0,
                interval: 50,
            },

        ],
        series: [
            {
                name: 'name 1',
                type: 'bar',
                data: [12.0, 29.9, 7.0, 23.2, 25.6, 76.7, 35.6, 62.2, 32.6, 20.0, 6.4, 3.3]
            },
            {
                name: 'name 2',
                type: 'line',
                data: [2.0, 2.2, 3.3, 33.5, 26.3, 50.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
            }
        ]
        };

        option && myChart.setOption(option);
    })
    return <div id="main" style={{height: 600, width: 1200}}></div>
}

export default LossAnalysis;