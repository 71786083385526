import moment from 'moment';

// Recommen数据结构
class Recommendation{
  constructor(data={}){
    this.strDataScope = data.strDataScope || []
    this.strRecommendationReportTitle = data.strRecommendationReportTitle || ''
    this.strSubTitleOrBrief = data.strSubTitleOrBrief || ''
    this.SectionList = data && data.SectionList && data.SectionList.map(section=>new Section(section)) || [new Section()]
  }

  // 添加Section
  addSectionList(){
    this.SectionList.push(new Section())
  }

  // 移除Section
  removeSectionList(index){
    this.SectionList.splice(index,1)
  }

  // 检查排序是否有重复，如果没有重复就排序一下
  checkAndSortSectionList(){
    this.SectionList.sort((a,b) => {
      return a.strIndex - b.strIndex  
    })

    return this.SectionList.every((e,i)=>{
      if(e.strIndex === this.SectionList[i+1]?.strIndex) return false
      return true
    })
  }
}

// Section数据结构
class Section{
  constructor(data={}){
    this.strSectionTitle = data.strSectionTitle || ''
    this.strIndex = data.strIndex || ''
    this.strContent = data.strContent || ''
    this.strChart = data.strChart || 1
    this.strChartTtile = data.strChartTtile || ''
    this.changeChart(this.strChart, data)
  }

  // 数据来源
  changeChart(strChart, data){
    console.log(strChart)
    switch (strChart) {
      case 1:
        this.addFromPlatform(data)
        break;
      case 2:
        this.addFromDatabase(data)
        break;
      case 3:
        this.addDIY(data)
        break;
      default:
        this.addFromPlatform(data)
        break;
    }
  }

  // 添加来自页面数据
  addFromPlatform(data={}){
    this.strFunctionPage = data.strFunctionPage || '4'
    this.serchParams = new FromPlatformSerchData(this.strFunctionPage, data.serchParams)
  }

  // 手动改变FunctionPage 后改变serchParams 参数
  changeFunctionPage(key){
    this.serchParams = new FromPlatformSerchData(key)
  }

  // 添加来之数据库
  addFromDatabase(data={}){
    this.strDataTable = data.strDataTable || ''
    this.strColumns = data.strColumns || ''
    this.strDateRange = data.strDateRange || ''
    this.startTimestamp = data.startTimestamp || ''
    this.endTimestamp = data.endTimestamp || ''
    this.strTableType = data.strTableType || ''
  }
  // DIY
  addDIY(data={}){
    this.DIYTableData = data.DIYTableData || []
  }
}

// 来源页面时的搜索条件
class FromPlatformSerchData {
  constructor(key, data){
    this.selectFunPage(key, data)
  }
  
  // 15种页面来源
  selectFunPage(key, data={}){
    switch (key) {
      case '1':
        this.addDataCollection(data)
        break;
      case '2':
        this.addDataQuality(data)
        break;
      case '3':
        this.addDataInsightByType(data)
        break;
      case '4':
        this.addDataInsightByGroup(data)
        break;
      case '5':
        this.addDataInsightCorrelationMatrix(data)
        break;
      case '6':
        this.addAccidentInquire(data)
        break;
      case '7':
        this.addClaimReport(data)
        break;
      case '8':
        this.addPartnerEvaluation(data)
        break;
      case '9':
        this.addDealEvaluation(data)
        break;
      case '10':
        this.addTripQuality(data)
        break;
      case '12':
        this.addLossPivotTable(data)
        break;
      case '13':
        this.addLossCorrelationChart(data)
        break;
      case '14':
      case '15':
      case '16':
        this.addVehicleRisk(data)
        break;
      case '17':
        this.addFeatureAnalysis(data)
        break;
      default:
        break;
    }
  }

  // Trip Quality detail筛选条件
  addTripQuality(data){
    this.deal = data.deal || ''
    this.partner = data.partner || ''
    this.plateNumber = data.plateNumber || ''
    this.tripDay = data.tripDay || moment().subtract(2, 'days').format('YYYY-MM-DD')
    this.date = data.date ? moment(data.date) : moment().subtract(2, 'days')
    this.qualityCheckStatus = data.qualityCheckStatus || 0
  }
  //  Deal Evaluation 筛选条件
  addDealEvaluation(data){
    this.dealName = data.dealName || ''
    this.partnerName = data.partnerName || ''
    this.insurerName = data.insurerName || ''
  }

  //  Partner Evaluation 筛选条件
  addPartnerEvaluation(data){
    this.partnerName = data.partnerName || ''
  }

  //  ClaimReport 筛选条件
  addClaimReport(data){
    this.ClaimId = data.ClaimId || ''
    this.plateNumber = data.plateNumber || ''
    this.timestamp = data.timestamp || ''
    this.LossPaid = data.LossPaid || ''
    this.insurerName = data.insurerName || ''
    this.deal = data.deal || ''
    this.cause = data.cause || ''
  }

  //  Accident Inquire 筛选条件
  addAccidentInquire(data){
    this.plateNumber = data.plateNumber || ''
    this.vin = data.vin || ''
    this.date = data.date ? [moment(data.date[0]), moment(data.date[1])] : ''
    this.startTimestamp = data.startTimestamp || ''
    this.endTimestamp = data.endTimestamp || ''
    this.groupUUID = parseInt(Math.random()*10000) + Date.now()
  }

  //  Correlation Matrix 筛选条件
  addDataInsightCorrelationMatrix(data){
    this.group = data.group || 'ALL'
    this.groupName = data.groupName || ''
    this.groupUUID = parseInt(Math.random()*10000) + Date.now()
  }

  // addDataInsightCorrelationMatrix 筛选条件
  addDataInsightByGroup(data){
    this.feature = data.feature || ''
    this.groupUUID = parseInt(Math.random()*10000) + Date.now()
  }

  // Feature Analysis by Type 筛选条件
  addDataInsightByType(data){
    this.groupList = data.groupList || [{group: 'ALL'}]
    this.groupUUID = parseInt(Math.random()*10000) + Date.now()
  }

  // data-Quality 筛选条件
  addDataQuality(data){
    this.valueType = data.valueType || 'TRIP_CORRECT_RATE'
    this.date = data.date && data.date.length ? [moment(data.date[0]).startOf('day'), moment(data.date[1]).endOf('day')] : [moment().subtract(30, 'days').startOf('day'), moment().endOf('day')]
    this.endTimestamp =  data.endTimestamp || 0
    this.startTimestamp =  data.startTimestamp || 0
    this.groupUUID = parseInt(Math.random()*10000) + Date.now()
  }

  // data-collection 筛选条件
  addDataCollection(data){
    this.dateRange = data.dateRange || 'Day'
    this.date = data.date && data.date.length ? [moment(data.date[0]).startOf('day'), moment(data.date[1]).endOf('day')] : [moment().subtract(30, 'days').startOf('day'), moment().endOf('day')]
    this.group =  data.group || 'DEAL'
    this.valueType =  data.valueType || 'ActiveVehicleNumber'
    this.endTimestamp =  data.endTimestamp || 0
    this.startTimestamp =  data.startTimestamp || 0
    this.groupUUID = parseInt(Math.random()*10000) + Date.now()
  }

  // Loss PivotTable 筛选条件
  addLossPivotTable(data){
    this.strGroup = data.strGroup || ['1','2']
    this.strGroupName =  data.strGroupName || ['Client','Tenant']
  }

  // Loss Correlation Chart 筛选条件
  addLossCorrelationChart(data){
    this.strFeature = data.strFeature || '6',
    this.strValue = data.strValue || '7',
    this.strFeatureLabel = data.strFeatureLabel || 'ClientBranch'
    this.strValueLabel = data.strValueLabel || 'AmountOfLossPaid'
    this.groupUUID = parseInt(Math.random()*10000) + Date.now()
  }

  // FeatureAnalysis 筛选条件
  addFeatureAnalysis(data){
    this.strFeature = data.strFeature || '1',
    this.strValue = data.strValue || '7',
    this.strFeatureLabel = data.strFeatureLabel || 'DeviceModel'
    this.strValueLabel = data.strValueLabel || 'Frequency'
    this.groupUUID = parseInt(Math.random()*10000) + Date.now()
  }

  addVehicleRisk(data){
    this.strDeal = data.strDeal || ''
    this.strInsurer = data.strInsurer || ''
    this.strPartner = data.strPartner || ''
    this.strRegion = data.strRegion || ''
    this.strVehicleType = data.strVehicleType || ''
    this.strLoad = data.strLoad || ''
    this.strAge = data.strAge || ''
    this.strLength = data.strLength || ''
    this.strPolicy = data.strPolicy || ['1']
    this.groupUUID = parseInt(Math.random()*10000) + Date.now()
  }
}

export default Recommendation