import { Table, Button, Modal, Form, Input, Select, DatePicker, Row, Col, InputNumber, Switch, Checkbox } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { DownloadOutlined, PlusOutlined, SettingOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import http, { onBlobExport, downloadBlob } from '../../http';
import moment from 'moment';
import { useModal } from '../../hooks/useModal';
import { useVerticalForm } from '../../hooks/form';
import { useTranslation } from 'react-i18next'
import { useTable } from '../../hooks/table';

function getApplicationList() {
  return http.get('/aaa/Application?Page=-1');
}
export default function Index() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { form: queryForm, formProps, colProps } = useVerticalForm();

  const { tableData, onSearch, pagination, loading, setQuery } = useTable(getData, false);
  const [activeId, setActiveId] = useState(null);
  const [ApplicationOptions, setApplicationOptions] = useState([]);
  const YESTERDAY = moment().add(-1, 'days');
  const disabledDate = current => {
    // Can not select days before today and today
    return current && current > moment()
  }


  function getData(param) {
    return http.get('/fleet-auth/log/email/list', {
      Page: pagination.current,
      PageSize: pagination.pageSize,
      ...param
    })
  }
  const columns = [
    {
      title: t('emailLog.sendTime'),
      key: 'sendTime',
      dataIndex: 'sendTime'
    },
    {
      title: t('emailLog.application'),
      key: 'application',
      dataIndex: 'application',
    },
    {
      title: t('emailLog.function'),
      key: 'function',
      dataIndex: 'function',
    },
    {
      title: t('emailLog.emailAddress'),
      key: 'emailAddress',
      dataIndex: 'emailAddress',
    },
    {
      title: t('common.action'),
      key: 'delete',
      // width: 270,
      // fixed: 'right',
      render: item => (
        <div>
          <Button icon={<SettingOutlined />} style={{ marginRight: '10px' }} onClick={()=>{onView(item)}}>{t('common.view')}</Button>
        </div>
      )
    },
  ];

  const onView = (item) => {
    form.setFieldsValue({
        sendTime: moment(item.sendTime),
        application: item.application,
        function: item.function,
        application: item.application,
        emailAddress: item.emailAddress,
        emailContent: item.emailContent,
    })
    setVisible(true);
  }
  const {
    visible,
    setVisible,
    confirmLoading,
    setConfirmLoading,
    updateModal
  } = useModal();
  const handleCancel = () => {
    setActiveId(null);
    updateModal(false);
  }
  const handleSearch = (e) => {
    const values = queryForm.getFieldsValue();
    console.log(values)
    if (values.date) {
      values.date = moment(values.date).format('YYYY-MM-DD')
    }
    setQuery(values);
  }
  useEffect(() => {
    getApplicationList().then(res => {
      setApplicationOptions(
        res.data.map(one => {
          return {
            label: one.fullName,
            value: one.name,
          }
        })
      );
    })
    handleSearch()
  }, [])
  const handleExport = e => {
    const values = queryForm.getFieldsValue();
    if (values.date) {
      values.date = moment(values.date).format('YYYY-MM-DD')
    }
    onBlobExport('/fleet-auth/log/email/list/export', values).then(res => {
      downloadBlob(res.data, 'Emails Log')
    });
  }
  return <div className="container">
    <Form {...formProps} form={queryForm} name="basic">
      <Row align="bottom">
        <Form.Item label={t('emailLog.date')} name="date" initialValue={YESTERDAY}>
          <DatePicker disabledDate={disabledDate} style={{ width: '200px',marginRight:'10px' }} />
        </Form.Item>
        <Col {...colProps}>
          <Form.Item label={t('emailLog.application')} initialValue='fleet' name="application">
            <Select options={ApplicationOptions} allowClear></Select>
          </Form.Item>
        </Col>
        <Col {...colProps} style={{ marginBottom: '24px' }}>
          <Button style={{ marginLeft: '32px' }} icon={<SearchOutlined />} onClick={handleSearch}>{t('common.search')}</Button>
          <Button style={{ marginLeft: '32px' }} icon={<DownloadOutlined />} onClick={handleExport}>{t('common.export')}</Button>
        </Col>
      </Row>
    </Form>
    <Table
      // scroll={{ x: 1850 }}
      loading={loading}
      pagination={pagination}
      rowKey="id"
      columns={columns}
      dataSource={tableData} />
    <Modal
      width={800}
      title={t('emailLog.emailContent')}
      visible={visible}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      footer={false}
      destroyOnClose
    >
      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{}}
      >
        <Form.Item
          label={t('emailLog.sendTime')}
          name="sendTime"
        >
          <DatePicker style={{ width: '100%' }} disabled={true}/>
        </Form.Item>
        <Form.Item
          label={t('emailLog.application')}
          name="application"
        >
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item
          label={t('emailLog.function')}
          name="function"
        >
          <Input disabled={true}/>
        </Form.Item><Form.Item
          label={t('emailLog.emailAddress')}
          name="emailAddress"
        >
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item
          label={t('emailLog.emailContent')}
          name="emailContent"
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 8 }} disabled={true}></Input.TextArea>
        </Form.Item>
      </Form>
    </Modal>
  </div>
}
