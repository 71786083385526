import { useEffect, useState } from 'react';
import { Tabs } from 'antd';

const { TabPane } = Tabs;
import {useTranslation} from 'react-i18next'

import styles from '../index.module.css';
import FeatureAnalysisByType from './FeatureAnalysisByType';
import FeatureAnalysisByGroup from './FeatureAnalysisByGroup';
import CorrelationMatrix from './CorrelationMatrix';


import {  getDealList, getInsuranceList, getRegionList, getVendorList } from './data';
const DataInsight = () => {
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState({});
    useEffect(() => {
        Promise.all([getDealList(), getInsuranceList(),  getRegionList(), getVendorList()])
            .then(([dealList, insuranceList, regionList, vendorList]) => {
                setDataSource({dealList, insuranceList, regionList, vendorList})
            })
    }, [])
    return (
        <div className="data-insight">
            <div className={styles.title}>{t('menu.dataInsight')}</div>
            <Tabs defaultActiveKey="1">
                <TabPane tab={t('tmx.featureAnalysisByType')} key="1">
                    <FeatureAnalysisByType dataSource={dataSource}/>
                </TabPane>
                <TabPane tab={t('tmx.featureAnalysisByGroup')} key="2">
                    <FeatureAnalysisByGroup />
                </TabPane>
                <TabPane tab={t('tmx.correlationMatrix')} key="3">
                    <CorrelationMatrix dataSource={dataSource}/>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default DataInsight;