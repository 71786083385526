import { useEffect, useState } from "react";
import http from '../../http';

export function useUserList() {
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        http.get('/system-setting/privacy/user/list').then(res => {
            setUserList(res.map(one => {
                return {
                  label: one.name,
                  value: one.id
                }
              }));
        })
    }, [])
    return {
        userList
    }
}