import http from "../../../http";
const format = arr => {
    if (!Array.isArray(arr)) return;
    return arr.map(one => {
        return {
            label: one,
            value: one
        }
    })
}
export function getDealList() {
    return http.get('/system-setting/common/deals').then(res => res.map(one => {
        return {
          label: `${one.description}(${one.value})`,
          value: one.value
        }
      })
    );
}

export function getInsuranceList() {
    return http.get('/tmx-dashboard/insight/insurances').then(res => format(res));
}

export function getRegionList() {
    return http.get('/tmx-dashboard/insight/regions').then(res => res.map(item => ({
        label: item.fullRegion,
        value: item.simpleRegion
    })));
}

export function getVendorList() {
    return http.get('/tmx-dashboard/insight/vendors').then(res => format(res));
}
