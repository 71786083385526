import { Select, Button, message, Spin } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import http, { onBlobExport, downloadBlob } from '../../../http';
import { useTranslation } from 'react-i18next'
import TriangleUltimate from '../components/TriangleUltimate'
import AgeAgeFactor from '../components/AgeAgeFactor'
import TriangleCalculate from '../components/TriangleCalculate'
import style from './index.module.css'
import { useEffect, useState, useRef } from 'react'
const { Option } = Select;


export default function Triangle () {
  const { t } = useTranslation();

  const [tringle1, setTringle1] = useState([]) // tringle1表格数据
  const [tringle2, setTringle2] = useState([]) // tringle2表格数据
  const [tringle3, setTringle3] = useState([]) // tringle3表格数据
  const [tringle1Row, setTringle1Row] = useState([[]]) // tringle1 列二维数组值
  const [tringle2Row, setTringle2Row] = useState([[]]) // tringle2 列二维数组值
  const [tringle2RowTitle, setTringle2RowTitle] = useState([]) // tringle1 表头数组值
  const [tringle3Columns, setTtringle3Columns] = useState([]) // tringle3 真正的表头
  const [clientOptions, setClientOptions] = useState([])
  const [UWQFromOptions, setUWQFromOptions] = useState([])
  const [UWQToOptions, setUWQToOptions] = useState([])
  const [AsOfOptions, setAsOfOptions] = useState([])
  const [triangleValueOptions, setTriangleValueOptions] = useState([])
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedtriangleValue, setSelectedtriangleValue] = useState('')
  const [strUWQFrom, setStrUWQFrom] = useState('')
  const [strUWQTo, setStrUWQTo] = useState('')
  const [strAsOf, setStrAsOf] = useState('')
  const [loading, setLoading] = useState(false)
  const [initLoading, setInitLoading] = useState(true)

  const cRef = useRef(null)



  function initSerch () {
    http.get('/portfolioapi/Triangle/GetInitData').then(res => {
      if (res) {
        setClientOptions(res.Client)
        setUWQFromOptions(res.UWQFrom)
        setUWQToOptions(res.UWQTo)
        setAsOfOptions(res.ASOF)
        setTriangleValueOptions(res.TriangleValue)
        setInitLoading(false)
      }
    })
  }


  function getTriangleData (params) {
    setLoading(true)
    http.get('/portfolioapi/Triangle/GetData', params).then(res => {
      setLoading(false)
      if (res && res.Tringle1.length && res.Tringle2.length) {
        setTringle1(res.Tringle1.map(e => ({ ...e, Ultimate: '' })))
        setTringle2(res.Tringle2)
      }
    })
  }

  // verify twoQ greater than oneQ
  function verifyQ (oneQ, twoQ) {
    let oneL = oneQ.split('Q')
    let twoL = twoQ.split('Q')
    console.log(oneL, twoL)
    if (oneL[0] > twoL[0]) return false
    if (oneL[0] == twoL[0] && oneL[1] > twoL[1]) return false
    return true
  }

  function handleSerch () {
    if (!strUWQFrom || !strUWQTo) {
      message.warning(`${t('common.pleaseSelect')}${t('triangle.date')}`);
      return
    }
    if (!selectedtriangleValue) {
      message.warning(`${t('common.pleaseSelect')}${t('triangle.triangleValue')}`);
      return
    }
    if (!strAsOf) {
      message.warning(`${t('common.pleaseSelect')}${t('triangle.asOf')}`);
      return
    }

    if (!verifyQ(strUWQFrom, strUWQTo)) {
      message.warning(`${t('triangle.DateFrom')}${t('triangle.mustThan')}${t('triangle.DateTo')}`);
      return
    }

    if (!verifyQ(strUWQTo, strAsOf)) {
      message.warning(`${t('triangle.asOf')}${t('triangle.mustThan')}${t('triangle.DateTo')}`);
      return
    }

    let params = {
      Client: selectedClient,
      strUWQFrom: strUWQFrom,
      strUWQTo: strUWQTo,
      strSelValue: selectedtriangleValue,
      strAsOf: strAsOf,
    }
    initTringle3()
    getTriangleData(params)
  }

  // init pages data
  useEffect(() => {
    initSerch()
  }, [])

  // 设置基础值
  useEffect(() => {
    if (tringle1.length && tringle2.length) {
      const tringle1Data = getTringleRow(tringle1) // 获取tringle1的表头和列二维数组
      setTringle1Row(tringle1Data.list) // tringle1 的列二维数组
      const tringle2Data = getTringleRow(tringle2) // 获取tringle2的表头和列二维数组
      setTringle2RowTitle(tringle2Data.title)  // tringle2 的表头数组
      setTringle2Row(tringle2Data.list) // tringle2 的列二维数组
    }
  }, [tringle2])

  // 初始化 tringle3
  useEffect(() => {
    if (tringle2Row[0] && tringle2Row[0].length && tringle2RowTitle.length) {
      setTtringle3Columns(getColumns()) // Ttringle3 表头
      initTringle3() // 初始化tringle3的表格数据
    }
  }, [tringle2RowTitle])

  const initTringle3OriginalColumns = (optionLength, sub) => {
    let t3Data = [];
    [0,1,2].forEach((e, i) =>{
      let item = {
        Key: i + 1,
        select: 0,
        average: e === 1 ? 'WA' : 'SA',
        selection: e === 2 ? optionLength : sub,
      }
      t3Data.push(item)
    })
    t3Data.push({
      Key: 4,
      select: 0,
    }, {
      Key: 5,
      select: 0,
    })
    return t3Data
  }

  // 初始化Tringle3 的表格数据
  const initTringle3 = () => {
    let rowLength = tringle1.length
    let sub = rowLength-1 > 1 ? rowLength-1 : 1
    let tringle3OriginalColumns = initTringle3OriginalColumns(rowLength, sub)
    tringle3OriginalColumns.forEach((e, i) => {
      switch (i) {
        case 0:
          tringle3OriginalColumns[i] = { ...e, ...SAFN(sub) }
          break;
        case 1:
          tringle3OriginalColumns[i] = { ...e, ...WAFN(sub) }
          break;
        case 2:
          tringle3OriginalColumns[i] = { ...e, ...SAFN(rowLength) }
          break;
        default:
          tringle3OriginalColumns[i] = { ...e, ...tringle3DefaultColumns() }
      }
    })
    setTringle3(tringle3OriginalColumns)
    if (cRef.current) {
      cRef.current.reset()
    }
  }

  // Ttringle3 表头
  const getColumns = () => {
    const columns = tringle2RowTitle.map(e => ({
      title: e.replace(/(Q)(\d\-\d|\d)/,'$2$1'),
      dataIndex: e,
    }))
    let num = Number(tringle2RowTitle[tringle2RowTitle.length - 1].split('-')[1])
    columns.push({
      title: `Q${num}-${num + 1}`,
      dataIndex: `Q${num}-${num + 1}`,
    })
    return columns
  }

  // 返回表格的表头和列二维数组
  const getTringleRow = (tringleList) => {
    const { Key, UWQ, ...dList } = tringleList[0] || {}
    let list = []
    Object.keys(dList).forEach((a) => {
      let listItem = []
      tringleList.forEach((e) => {
        listItem.push(e[a])
      });
      list.push(listItem)
    })
    return {
      title: Object.keys(dList),
      list
    }
  }

  // 计算 Age-Ultimate 算法
  const ageUltimate = (tringleList, index) => {
    const titleList = tringle2RowTitle
    const tringle = JSON.parse(JSON.stringify(tringleList))
    const item = tringle[index]
    const list = titleList.map(e => item[e])

    let ultimate = []
    let SAData = {}
    titleList.forEach((e, i) => {
      let j = 0
      let res = list.reduce((f, v) => {
        j++
        if (j > i) {
          return Number(f) * Number(v)
        } else {
          return f
        }
      }, 1)
      if (index === 3) {
        let QNum = Number(titleList[titleList.length - 1].split('-')[1])
        res = res * (item[`Q${QNum}-${QNum + 1}`] || 1)
      }
      ultimate.push(res.toFixed(2))
      SAData[e] = res.toFixed(2)
    })
    if (index !== 3) {// 前三行最后一列绝对是1
      addTringle3LastRow(SAData)
      ultimate.push('1')
    } else {
      let QNum = Number(titleList[titleList.length - 1].split('-')[1])
      SAData[`Q${QNum}-${QNum + 1}`] = item[`Q${QNum}-${QNum + 1}`] || '1'
      ultimate.push(item[`Q${QNum}-${QNum + 1}`] || '1')
    }
    tringle.forEach(e => e.select = 0)
    tringle[index].select = 1
    tringle[4] = { ...tringle[4], ...SAData }
    setTringle3(tringle)
    setUltimate(ultimate)
  }

  // 计算tringle1 的Ultimate列
  const setUltimate = (list) => {
    let ageInde = 0
    let tList = JSON.parse(JSON.stringify(tringle1))
    for (let index = tList.length - 1; index >= 0; index--) {
      tList[index].Ultimate = (Number(list[ageInde]) * Number(tList[index][`Q${ageInde + 1}`])).toFixed(2)
      ageInde++
    }
    setTringle1(tList)
  }

  // tringle3 用户输入 Judgement 自定义计算
  const onUserInput = (data) => {
    let tringle = JSON.parse(JSON.stringify(tringle3))
    tringle[3] = { ...tringle[3], ...data }
    if (tringle[3].select) {
      ageUltimate(tringle, 3)
    }else{
      setTringle3(tringle)
    }
  }

  // tringle3 选择使用那一行计算 Age-Ultimate
  const onSelect = (e) => {
    ageUltimate(tringle3, e.target.value)
  }

  // Average 选择触发函数
  const onAverage = (e) => {
    console.log(e)
    let [index, average] = e.split('-')
    let tringle = JSON.parse(JSON.stringify(tringle3))
    const data = average === 'SA' ? SAFN(tringle[index].selection) : WAFN(tringle[index].selection)
    tringle[index] = { ...tringle[index], average, ...data }
    if (tringle[index].select) {
      ageUltimate(tringle, index)
    }else{
      setTringle3(tringle)
    }
  }
  // Dev.Selection 选择触发函数
  const onSelection = (e) => {
    const [index, num] = e.split('-')
    let tringle = JSON.parse(JSON.stringify(tringle3))
    const data = tringle[index].average === 'SA' ? SAFN(Number(num)) : WAFN(Number(num))
    tringle[index] = { ...tringle[index], selection: Number(num), ...data }
    if (tringle[index].select) {
      ageUltimate(tringle, index)
    }else{
      setTringle3(tringle)
    }
  }

  // tringle3 第4和第5行的默认数据
  const tringle3DefaultColumns = () => {
    let SAData = {}
    tringle2RowTitle.forEach((e, i) => {
      SAData[e] = '1'
    })
    addTringle3LastRow(SAData)
    return SAData
  }

  const addTringle3LastRow = (SAData) => {
    if (tringle2RowTitle.length) {
      let QNum = Number(tringle2RowTitle[tringle2RowTitle.length - 1].split('-')[1])
      SAData[`Q${QNum}-${QNum + 1}`] = '1'
    }
    return SAData
  }

  // SA 模式算法
  const SAFN = (num) => {
    const list = tringle2Row
    let SAList = tringle2RowTitle
    let SAData = {}
    let cut = 0
    SAList.forEach((e, i) => {
      let index = num + i + 1
      let res = list[i].reduceRight((f, v) => {
        if (index > 0) {
          index--
          return Number(f) + Number(v)
        } else {
          return f
        }
      }, '')
      if (num + i <= list.length) {
        SAData[e] = (res / num).toFixed(2)
      } else {
        cut++
        SAData[e] = (res / (num - cut > 0 ? num - cut : 1)).toFixed(2)
      }
    })
    addTringle3LastRow(SAData)
    return SAData
  }

  // WA 算法
  const WAFN = (num) => {
    const list = tringle1Row
    let SAList = tringle2RowTitle
    let SAData = {}
    SAList && SAList.forEach((e, i) => {
      if (list[i] && list[i + 1]) {
        let numeratorList = list[i + 1].slice(-num - i - 1, -i - 1) || []
        let denominatorList = list[i].slice(-num - i - 1, -i - 1) || []
        let numerator = numeratorList.reduce((f, v) => Number(f) + Number(v), 0)
        let denominator = denominatorList.reduce((f, v) => Number(f) + Number(v), 0)
        SAData[e] = (numerator / (denominator || 1)).toFixed(2)
      }
    })
    addTringle3LastRow(SAData)
    return SAData
  }

  const tringle3Handle = () => {
    let tringleData = JSON.parse(JSON.stringify(tringle3))
    if(tringleData[3].select !== 1){
      Object.keys(tringleData[3]).forEach(e=>{
        if(e.indexOf('Q') > -1){
          tringleData[3][e] = ''
        }
      })
    }
    return tringleData
  }

  const exportExcl = () => {
    if(!tringle3.length || tringle3.findIndex(e=>e.select === 1) === -1){
      message.warning(t('triangle.calculateUltimate'));
      return
    }
    const tringle3Data = tringle3Handle()
    let params = {
      Client: selectedClient,
      strUWQFrom: strUWQFrom,
      strUWQTo: strUWQTo,
      strSelValue: selectedtriangleValue,
      strAsOf: strAsOf,
      la: localStorage.getItem('language'),
      Tringle1: tringle1,
      Tringle2: JSON.parse(JSON.stringify(tringle2).replace(/\-/g, 'To')),
      Tringle3: JSON.parse(JSON.stringify(tringle3Data).replace(/\-/g, 'To'))
    }
    onBlobExport('/portfolioapi/Triangle/GetExcel', params, { 'method': 'post' }).then(res => {
      downloadBlob(res.data, 'triangle')
    });
  }

  return <div>
    <div className={style.titleBox}>
      <span className={style.title}>{t('portfolio.triangle')}</span>
    </div>
    <Spin spinning={initLoading}>
      <div className={style.selectbox}>
        <div className={style.lossfeature}>
          <div>{t('triangle.client')}</div>
          <Select className={style.selects}
            allowClear
            placeholder={t('triangle.empty')}
            labelInValue={true}
            onChange={(e) => { setSelectedClient(e ? e.label : '') }}>
            {clientOptions.map(e => <Option key={e.id}>{e.typeName}</Option>)}
          </Select>
        </div>
        <div className={style.Tdate}>
          <div className={style.pd}><span className={style.star}>*</span>{t('triangle.date')}</div>
          <Select className={style.doubleSelects}
            allowClear
            placeholder={t('triangle.empty')}
            labelInValue={true}
            onChange={(e) => { setStrUWQFrom(e ? e.label : '') }}>
            {UWQFromOptions.map(e => (strUWQTo && e.typeName > strUWQTo || strAsOf && e.typeName > strAsOf) ? <Option disabled key={e.id}>{e.typeName}</Option> : <Option key={e.id}>{e.typeName}</Option>)}
          </Select>
          -
          <Select className={style.doubleSelects}
            allowClear
            placeholder={t('triangle.empty')}
            labelInValue={true}
            onChange={(e) => { setStrUWQTo(e ? e.label : '') }}>
            {UWQToOptions.map(e => (strUWQFrom && e.typeName < strUWQFrom || strAsOf && e.typeName > strAsOf) ? <Option disabled key={e.id}>{e.typeName}</Option> : <Option key={e.id}>{e.typeName}</Option>)}
          </Select>
        </div>
        <div className={style.asof}>
          <div className={style.pd}><span className={style.star}>*</span>{t('triangle.asOf')}</div>
          <Select className={style.selects}
            allowClear
            placeholder={t('triangle.empty')}
            labelInValue={true}
            onChange={(e) => { setStrAsOf(e ? e.label : '') }}>
            {AsOfOptions.map(e => (strUWQTo && e.typeName < strUWQTo || strUWQFrom && e.typeName < strUWQFrom) ? <Option disabled key={e.id}>{e.typeName}</Option> : <Option key={e.id}>{e.typeName}</Option>)}
          </Select>
        </div>
        <div className={style.lossval}>
          <div className={style.pd}><span className={style.star}>*</span>{t('triangle.triangleValue')}</div>
          <Select className={style.selects}
            allowClear
            placeholder={t('triangle.empty')}
            onChange={setSelectedtriangleValue}>
            {triangleValueOptions.map(e => <Option key={e.id}>{t(`triangleValue.${e.typeName}`)}</Option>)}
          </Select>
        </div>
        <div className={style.lossbtn}>
          <Button className={style.submitbtn} onClick={handleSerch} loading={loading}><SearchOutlined />{t('common.search')}</Button>
          <Button className={style.exportbtn} onClick={exportExcl} ><DownloadOutlined />{t('common.export')}</Button>
        </div>
      </div>
    </Spin>
    <Spin spinning={loading}>
      <TriangleUltimate tringleData={tringle1} title="triangle"></TriangleUltimate>
      <AgeAgeFactor tringleData={tringle2} title="ageAgeFactor"></AgeAgeFactor>
      <TriangleCalculate tringle3Columns={tringle3Columns} optionLength={tringle1.length} tringleData={tringle3} onSelect={onSelect} onAverage={onAverage} onSelection={onSelection} onUserInput={onUserInput} ref={cRef}></TriangleCalculate>
    </Spin>
  </div>
}