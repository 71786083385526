import { Table, Input, Select, Button, Row, Col, Form, Spin, DatePicker, message} from 'antd';
import { PlusOutlined, SearchOutlined, SettingOutlined, ProfileOutlined, DeleteOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import http from '../../http';
import { useTranslation } from 'react-i18next'
import style from './index.module.css'
import moment from 'moment/moment';

export default function TruckPricingAPIIndex () {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const columns = [
    { "title": t('TruckPricingAPI.InsuranceProduct'), "dataIndex": "InsuranceProduct",}, 
    { "title": t('TruckPricingAPI.SumInsured'),"dataIndex": 'SumInsured', render: (text, record) => {
      return record.key === 'sum_insured_tpl' ? 
        <Select allowClear onChange={(e) => record.SumInsured = e}>
          {selectOptionsData[4] && selectOptionsData[4].map(e=><Option value={e.name}>{e.name}</Option>)}
        </Select> :
        <Input placeholder={t('common.pleaseInput')} defaultValue={text} onChange={(e) => record.SumInsured = e.target.value}></Input>
    } }, 
    { "title": t('TruckPricingAPI.StandardPremium'), "dataIndex": 'StandardPremium', render: (text, record) => <Input placeholder={t('common.pleaseInput')} defaultValue={text} onChange={(e) => record.StandardPremium = e.target.value}></Input> }, 
  ]
  const resultColumns = [
    { "title": t('TruckPricingAPI.InsuranceProduct'), width: 250, "dataIndex": "InsuranceProduct",}, 
    { "title": t('TruckPricingAPI.SumInsured'),"dataIndex": 'SumInsured' }, 
  ]

  const [tableData, setTableData] = useState([{
    InsuranceProduct: t('TruckPricingAPI.sum_insured_mod'),
    key: 'sum_insured_mod'
  },{
    InsuranceProduct: t('TruckPricingAPI.sum_insured_tpl'),
    key: 'sum_insured_tpl'
  }])

  const [resultTableData, setResultTableData] = useState([{
    InsuranceProduct: t('TruckPricingAPI.sum_insured_mod'),
    SumInsured: '',
    key: '11'
  },{
    InsuranceProduct: t('TruckPricingAPI.sum_insured_tpl'),
    SumInsured: '',
    key: '12'
  }])

  

  // default dispaly table head
  const [initLoading, setInitLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [selectOptionsData, setSelectOptionsData] = useState([])
  const [modelVersion, setModelVersion] = useState('')

  // dynamic regulation table head

  const getInit = () => {
    // 1-车辆品牌，2-车辆类型，3-地域, 4是保司名称
    return Promise.all([
      http.get('/truckpricingapi/openApi/v1/getModelVersion'),
      http.get('/truckpricingapi/openApi/v1/listDictByType?bizType=1'),
      http.get('/truckpricingapi/openApi/v1/listDictByType?bizType=2'),
      http.get('/truckpricingapi/openApi/v1/listDictByType?bizType=3'),
      http.get('/truckpricingapi/openApi/v1/listDictByType?bizType=4'),
      http.get('/truckpricingapi/openApi/v1/listDictByType?bizType=5'),
    ])
  }

  // get table date
  async function getData (param) {
    let res = await http.post('/truckpricingapi/openApi/v1/inner/getTrunkPricing', param)
    return res
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      tableData.forEach(item => {
        if(selectedRowKeys.includes(item.key)){
          item.checked = true
        }else{
          item.checked = false
        }
      })
    }
  };

  const onFinish = async values => {
    console.log(values)
    setBtnLoading(true)
    setResultTableData([{
      InsuranceProduct: t('TruckPricingAPI.sum_insured_mod'),
      SumInsured: '',
      key: '11'
    },{
      InsuranceProduct: t('TruckPricingAPI.sum_insured_tpl'),
      SumInsured: '',
      key: '12'
    }])
    values.inception_date = moment(values.inceptionDate).format('YYYY/MM/DD')
    console.log(tableData)
    if(tableData[0].checked){
      values.flag_combination_mod = true
      values.sum_insured_mod = tableData[0].SumInsured
      values.std_premium_mod = tableData[0].StandardPremium
    }
    if(tableData[1].checked){
      values.flag_combination_tpl = true
      values.sum_insured_tpl = tableData[1].SumInsured
      values.std_premium_tpl = tableData[1].StandardPremium
    }
    values.plate_no = values.plate_no.toUpperCase()
    form.setFieldsValue({
      plate_no: values.plate_no
    })
    console.log('values', values)
    getData(values).then(res => {
      if(!res) {
        message.error(t('TruckPricingAPI.noData'))
        return
      }
      setResultTableData([{
        InsuranceProduct: t('TruckPricingAPI.sum_insured_mod'),
        SumInsured: res ? Number(res.predicted_mod_calibrated).toFixed(2) : '',
        key: '11'
      },{
        InsuranceProduct: t('TruckPricingAPI.sum_insured_tpl'),
        SumInsured: res ? Number(res.predicted_tpl_calibrated).toFixed(2) : '',
        key: '12'
      }])
    }).finally(()=>{
      setBtnLoading(false)
    })
  }

  useEffect(() => {
    getInit().then(res => {
      setInitLoading(false)
      const [mv, ...OptionsList] = res
      setModelVersion(mv.model_version)
      setSelectOptionsData(OptionsList)
    })
  }, [])

  const { Option } = Select;
  return <div>
    <div className={style.titleBox}>
      <span className={style.title}>{t('TruckPricingAPI.TruckPricingToolResult')}</span>
    </div>
    <Spin spinning={initLoading}>
      <div className={style.contentBox}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row justify="space-between">
            <Col className="gutter-row" span={8}>
              <div className={style.subTitle}>{t('TruckPricingAPI.CarInfo')}</div>
              <Form.Item
                name='plate_no'
                label={t('TruckPricingAPI.PlateNumber')}
                rules={[{ required: true }]}
              >
                <Input placeholder={t('common.pleaseInput')}/>
              </Form.Item>
              <Form.Item
                name='vin'
                label={t('TruckPricingAPI.VIN')}
              >
                <Input placeholder={t('common.pleaseInput')}/>
              </Form.Item>
              <Form.Item
                name='car_age'
                label={t('TruckPricingAPI.VehicleAge')}
                rules={[{ required: true }]}
              >
                <Select allowClear>
                  {Array.apply(null,{length: 25}).map((item,i)=><Option value={i+1} >{i+1}</Option>)}
                </Select>
              </Form.Item>
              <Form.Item
                name='car_brand'
                label={t('TruckPricingAPI.VehicleBrand')}
                rules={[{ required: true }]}
              >
                 <Select allowClear>
                  {selectOptionsData[0] && selectOptionsData[0].map(e=><Option value={e.name}>{e.name}</Option>)}
                </Select>
              </Form.Item>
              <Form.Item
                name='car_class'
                label={t('TruckPricingAPI.VehicleClass')}
                rules={[{ required: true }]}
              >
                 <Select allowClear>
                 {selectOptionsData[1] && selectOptionsData[1].map(e=><Option value={e.name}>{e.name}</Option>)}
                </Select>
              </Form.Item>
              <Form.Item
                name='car_price_new'
                label={t('TruckPricingAPI.VehiclePrice')}
                rules={[{ required: true }]}
              >
                <Input placeholder={t('common.pleaseInput')}/>
              </Form.Item>
              <Form.Item
                name='weight'
                label={t('TruckPricingAPI.Weight')}
                rules={[{ required: true }]}
              >
                <Input placeholder={t('common.pleaseInput')}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={15}>
              <div className={style.subTitle}>{t('TruckPricingAPI.carInsuranceDemand')}</div>
              <Form.Item
                name='insurer'
                label={t('TruckPricingAPI.Insurer')}
                rules={[{ required: true }]}
              >
                 <Select allowClear>
                  {selectOptionsData[3] && selectOptionsData[3].map(e=><Option value={e.name}>{e.name}</Option>)}
                </Select>
              </Form.Item>
              <Form.Item
                name='region'
                label={t('TruckPricingAPI.Region')}
                rules={[{ required: true }]}
              >
                 <Select allowClear>
                  {selectOptionsData[2] && selectOptionsData[2].map(e=><Option value={e.name}>{e.name}</Option>)}
                </Select>
              </Form.Item>
              <Form.Item
                name='inceptionDate'
                label={t('TruckPricingAPI.InceptionDate')}
              >
                <DatePicker style={{width:'100%'}} />
              </Form.Item>
              <Form.Item
                name='ncd'
                label={t('TruckPricingAPI.NCD')}
              >
                <Input placeholder={t('common.pleaseInput')}/>
              </Form.Item>
              <Form.Item
                label={t('TruckPricingAPI.InsuranceDemand')}
              >
                <Table 
                  rowSelection={{ type: 'checkbox',
                    ...rowSelection,
                  }}
                  columns={columns} 
                  dataSource={tableData} 
                  pagination={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Button style={{width: '100%'}} htmlType="submit" loading={btnLoading}>{t('TruckPricingAPI.Submit')}</Button>
        </Form>
        <div className={style.PremiumQuotation}>{t('TruckPricingAPI.PremiumQuotation')} <span>模型版本：v{modelVersion}</span></div>
        <Table 
          loading={btnLoading}
          columns={resultColumns} 
          dataSource={resultTableData} 
          pagination={false}
        />
      </div>
    </Spin>
  </div>
}

