import { Table, Card, Select, Button, Row, Col, Form, Input, Spin, message, InputNumber, DatePicker } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useTable } from '../../../hooks/table';
import { useState } from 'react';
import http, { onBlobExport, downloadBlob }  from '../../../http';
import { useTranslation } from 'react-i18next'
import style from './index.module.css'
import { useEffect } from 'react'
import ChangeColumns from '../components/ChangeColumns'
import moment from 'moment';
import {DataCard} from '../../../components/DataCard/card';

const { RangePicker } = DatePicker;

export default function PolicyAnalysis () {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const columns = [
    {
      "title": t.bind(null,'policyAnalysisTable.Client'),
      "dataIndex": "Client",
      width:300,
      disabled: true,
      default: true,
      sorter: {
        compare: (a, b) => a.Client - b.Client,
      },
    },
    {
      "title": t.bind(null,'policyAnalysisTable.Tenant'),
      "dataIndex": "Tenant",
      disabled: true,
      default: true,
      sorter: {
        compare: (a, b) => a.Tenant - b.Tenant,
      },
    },
    {
      "title": t.bind(null,'policyAnalysisTable.Period'),
      "dataIndex": "Period",
      disabled: true,
      default: true,
    },
    {
      "title": t.bind(null,'policyAnalysisTable.VehicleAge'),
      "dataIndex": "VehicleAge",
    },
    {
      "title": t.bind(null,'policyAnalysisTable.PolicyNumber'),
      "dataIndex": "PolicyNumber"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.EndorsementNumber'),
      "dataIndex": "EndorsementNumber"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.PolicyType'),
      "dataIndex": "PolicyType"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.Vin'),
      "dataIndex": "Vin"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.PlateNumber'),
      "dataIndex": "PlateNumber",
      disabled: true,
      default: true,
      sorter: {
        compare: (a, b) => a.PlateNumber - b.PlateNumber,
      },
    },
    {
      "title": t.bind(null,'policyAnalysisTable.VehicleMake'),
      "dataIndex": "VehicleMake",
      disabled: true,
      default: true,
      sorter: {
        compare: (a, b) => a.VehicleMake - b.VehicleMake,
      },
    },
    {
      "title": t.bind(null,'policyAnalysisTable.VehicleModelCode'),
      "dataIndex": "VehicleModelCode"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.VehicleType'),
      "dataIndex": "VehicleType",
      disabled: true,
      default: true,
      sorter: {
        compare: (a, b) => a.VehicleType - b.VehicleType,
      },
    },
    {
      "title": t.bind(null,'policyAnalysisTable.ApplicantName'),
      "dataIndex": "ApplicantName"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.InsuredName'),
      "dataIndex": "InsuredName"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.InceptionDate'),
      "dataIndex": "InceptionDate",
      disabled: true,
      default: true,
      sorter: {
        compare: (a, b) => a.InceptionDate - b.InceptionDate,
      },
    },
    {
      "title": t.bind(null,'policyAnalysisTable.EndDate'),
      "dataIndex": "EndDate"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.EndorsementEffectiveDate'),
      "dataIndex": "EndorsementEffectiveDate"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.Premium'),
      "dataIndex": "Premium",
      disabled: true,
      default: true,
      sorter: {
        compare: (a, b) => a.Premium - b.Premium,
      },
    },
    {
      "title": t.bind(null,'policyAnalysisTable.ModPremium'),
      "dataIndex": "ModPremium"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.VtplPremium'),
      "dataIndex": "VtplPremium"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.OtherPremium'),
      "dataIndex": "OtherPremium"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.SumInsured'),
      "dataIndex": "SumInsured",
      disabled: true,
      default: true,
      sorter: {
        compare: (a, b) => a.SumInsured - b.SumInsured,
      },
    },
    {
      "title": t.bind(null,'policyAnalysisTable.ModInsured'),
      "dataIndex": "ModInsured"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.VtplInsured'),
      "dataIndex": "VtplInsured"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.OtherInsured'),
      "dataIndex": "OtherInsured"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.NcdFactor'),
      "dataIndex": "NcdFactor"
    },
    {
      "title": t.bind(null,'policyAnalysisTable.BusinessOrigin'),
      "dataIndex": "BusinessOrigin"
    }
  ]

   // default dispaly table head
   const defaultColumns = columns.filter(e=>e.default)
   // dynamic regulation table head
   const [column, setColumns] = useState(defaultColumns)
 
  // default dispaly table head
  const [initLoading, setInitLoading] = useState(true)
  const [selectOptionsData, setSelectOptionsData] = useState({})
  const [titleData, setTitleData] = useState({})
  const [downExclParams, setDownExclParams] = useState({})

  // dynamic regulation table head
  const { tableData, onSearch, pagination, loading, setQuery, handleTableChange } = useTable(getData);

  const getInit = () => {
    return http.get('/portfolioapi/PolicyAnalysis/GetInitData')
  }

  // get table date
  async function getData (param) {
    let res = await http.get('/portfolioapi/PolicyAnalysis/GetData', {
      PageNum: pagination.current,
      PageSize: pagination.pageSize,
      ...param,
    })
    const { PolicyCount, Premium, PremiumPerPolicy, ...tableData } = res || {}
    setTitleData({ PolicyCount, Premium, PremiumPerPolicy })
    return tableData
  }

  const onFormSearch = (e) => {
    if(e.strPremiumRangFrom && e.strPremiumRangFrom >  e.strPremiumRangTo){
      message.warning(`${t('tabletitle.error.MiniMaxmum')}`);
      return
    }
    if(e.strDate){
      e['strDateFrom'] = moment(e.strDate[0]).format('YYYYMMDD')
      e['strDateTo'] = moment(e.strDate[1]).format('YYYYMMDD')
      delete e.strDate
    }
    setQuery(e)
    console.log(e)
  }

  const onValuesChange = async (e) => {
    const row = (await form.validateFields())
    // let params = ''
    // Object.keys(row).forEach(e=>{
    //   if(row[e]){
    //     params += `&${e}=${row[e]}`
    //   }
    // })
    setDownExclParams(row)
  }

  useEffect(() => {
    setInitLoading(true)
    getInit().then(res => {
      setInitLoading(false)
      setSelectOptionsData(res)
    })
  }, [])

  const exportExcl = () => {
    let params = {
      la: localStorage.getItem('language'),
      ...downExclParams
    }
    onBlobExport('/portfolioapi/PolicyAnalysis/GetExcel', params, { 'method': 'get' }).then(res => {
      downloadBlob(res.data, 'Policy Analysis')
    });
  }

  const formatPrice = num => {
    if (!num) return '-';
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
  }


  const { Option } = Select;
  return <div>
    <div className={style.titleBox}>
      <span className={style.title}>{t('portfolio.PolicyAnalysis')}</span>
    </div>
    <Spin spinning={initLoading}>
    <Form layout="vertical" form={form} onFinish={onFormSearch} onValuesChange={onValuesChange}>
      <Row gutter={[8]}>
        <Col className="gutter-row" span={4}>
          <Form.Item
            name='strInsurer'
            label={t('groupname.Client')}
          >
            <Select
              placeholder="Select"
              allowClear
            >
              {selectOptionsData.InsurerData && selectOptionsData.InsurerData.map(e => <Option value={e.typeName}>{e.typeName}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={4}>
          <Form.Item
            name='strTenant'
            label={t('tabletitle.Deal')}
          >
            <Select
              placeholder="Select"
              allowClear
            >
              {selectOptionsData.TenantData && selectOptionsData.TenantData.map(e => <Option value={e.typeName}>{e.typeName}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={4}>
          <Form.Item
            name='strUWY'
            label={t('groupname.UWY')}
          >
            <Select
              placeholder="Select"
              allowClear
            >
              {selectOptionsData.UWYData && selectOptionsData.UWYData.map(e => <Option value={e.typeName}>{e.typeName}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={5}>
          <Form.Item label={t('groupname.PremiumRange')} >
            <div style={{ display: 'flex'}}>
              <Form.Item
                name='strPremiumRangFrom'
                style={{width: 'calc(50% - 12px)' }}
              >
                <InputNumber style={{textAlign: 'center',  width: '100%' }} placeholder="Minimum" />
              </Form.Item>
              <span
                style={{ display: 'block', width: '24px', lineHeight: '32px', textAlign: 'center' }}
              >
                ~
              </span>
              <Form.Item
                name='strPremiumRangTo'
                style={{width: 'calc(50% - 12px)' }}
              >
                <InputNumber
                  className="site-input-right"
                  style={{
                    textAlign: 'center',
                    width: '100%'
                  }}
                  placeholder="Maximum"
                />
              </Form.Item>
            </div>
          </Form.Item>
        </Col>
        </Row>
        <Row gutter={[8]}>
        <Col className="gutter-row" span={4}>
          <Form.Item
            name='strVehicleType'
            label={t('groupname.VehicleType')}
          >
            <Select
              placeholder="Select"
              allowClear
            >
              {selectOptionsData.VehicleTypeData && selectOptionsData.VehicleTypeData.map(e => <Option value={e.typeName}>{e.typeName}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={4}>
          <Form.Item
            name='strVehicleAge'
            label={t('groupname.VehicleAge')}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={4}>
          <Form.Item
            name='strVehicleload'
            label={t('groupname.VehicleLoad')}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={5}>
          <Form.Item
            name='strDate'
            label={t('groupname.LossDate')}
          >
            <RangePicker className={style.date} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={7}>
          <div className={style.btnbox}>
            <Button className={style.submitbtn} htmlType="submit"><SearchOutlined />{t('common.search')}</Button>
            <Button className={style.exportbtn} onClick={exportExcl}><DownloadOutlined />{t('common.export')}</Button>
          </div>
        </Col>
      </Row>
    </Form>
    </Spin>
    <Row style={{marginBottom: "12px"}}>
      <Col span={5}><DataCard title={t('portfolio.PolicyNo')}  num={titleData.PolicyCount}></DataCard></Col>
      <Col span={11}><DataCard title={t('portfolio.SumPremium') + ` (${t('units.CNY')})`} num={formatPrice(titleData.Premium)}></DataCard></Col>
      <Col span={8}><DataCard title={t('portfolio.AvePremium') + ` (${t('units.CNY')})`} num={formatPrice(titleData.PremiumPerPolicy)}></DataCard></Col>
    </Row>
    <div style={{float: 'right'}}><ChangeColumns Columns={columns} changeColumns={setColumns}/></div>
    <Table pagination={pagination} scroll={{ x: column.length * 150 }} columns={column} loading={loading} dataSource={tableData} onChange={handleTableChange} />
  </div>
}