import { Table, Button, Modal, Form, Input, Tree, Row, Col, Radio, Card } from 'antd';
import { useEffect, useState, useRef } from 'react';
import {DownloadOutlined, PlusOutlined, SettingOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined} from '@ant-design/icons'
import http from '../../http';
import moment from 'moment';
import { useModal } from '../../hooks/useModal';
import { useVerticalForm } from '../../hooks/form';

import { useTranslation } from 'react-i18next'
import { useRole, useApplicationList } from './data';
export default function Index() {
    const { t } = useTranslation();
    const { form: queryForm, formProps, colProps } = useVerticalForm();
    const [form] = Form.useForm();
    const { data, loading, onSearch} = useRole();

    const [activeId, setActiveId] = useState(null);

    const { data: applicationData, treeData: applicationTreeData } = useApplicationList(activeId)

    const [checkedKeys, setCheckedKeys] = useState([]);
    const onCheck = (checkedKeysValue) => {
        setCheckedKeys(checkedKeysValue);
    };
    const columns = [
        {
            title: t('role.roleName'),
            key: 'roleName',
            dataIndex: 'name'
        },
        {
            title: t('common.editAt'),
            key: 'editAt',
            dataIndex: 'editAt',
            render: text => <span>{text ? moment(text).format('YYYY-MM-DD') : text} </span>
        },
        {
            title: t('common.editBy'),
            key: 'editBy',
            dataIndex: 'editByUserName'
        },
        {
            title: t('common.createdAt'),
            key: 'createdAt',
            dataIndex: 'createAt',
            render: text => <span>{text ? moment(text).format('YYYY-MM-DD') : text} </span>
        },
        {
            title: t('common.createdBy'),
            key: 'createdBy',
            dataIndex: 'createByUserName'
        },
        {
            title: t('common.action'),
            key: 'delete',
            render: item => (
                <div>
                    <Button icon={<DeleteOutlined />} style={{ marginRight: '10px' }} onClick={() => confirmDelete(item.id, item.name)}>{t('common.delete')}</Button>
                    <Button icon={<SettingOutlined />} onClick={() => onEdit(item.id, item.name)}>{t('common.edit')}</Button>
                </div>
            )
        },
    ];

    const confirmDelete = (id, name) => {
        Modal.confirm({
            title: t('common.confirm'),
            content: `${t('common.confirmDelete')} ${t('role.roleName')} [${name}] ?`,
            cancelButtonProps: {
                type: 'default',
                icon: <CloseCircleOutlined />
              },
              okButtonProps: {
                type: 'default',
                icon: <CheckCircleOutlined />
              },
            onOk() {
                return http.post(`/fleet-auth/role/remove?id=${id}`).then(onSearch);
            }
        });
    }
    const onEdit = (id, roleName) => {
        setVisible(true);
        setActiveId(id);
        form.setFieldsValue({
            roleName
        })
    }
    useEffect(() => {
        if (!applicationData.length) return;
        const checkedList = applicationData.filter(one => one.selected).map(one => one.id);
        if (checkedList.length !== applicationData.length) {
            form.setFieldsValue({ permission: 0})
            setCheckedKeys(checkedList);
        } else {
            form.setFieldsValue({ permission: 1})
            setCheckedKeys([]);
        }
    }, [applicationData])
    const onAdd = () => {
        setCheckedKeys([]);
        form.setFieldsValue({permission: 1});
        setVisible(true);
    }
    const {
        visible,
        setVisible,
        confirmLoading,
        setConfirmLoading,
    } = useModal();
    const handleOk = () => {
        return form.validateFields().then(values => {
            setConfirmLoading(true);
            const param = {
                isAllowAll: values.permission,
                applicationIds: values.permission === 0 ? checkedKeys: [],
                roleName: values.roleName,
            };
            return activeId ? http.post('/fleet-auth/role/update', {...param, roleId: activeId}):  http.post('/fleet-auth/role/create', param);
        }).then(() => {
            onSearch();
            reset();
        }).finally(() => {
            setConfirmLoading(false);
        })
    }
    const handleCancel = () => {
        reset();
    }
    const handleSearch = (e) => {
        const values = queryForm.getFieldsValue();
        onSearch(values);
    }
    const reset = () => {
        setActiveId(null);
        setVisible(false);
        form.resetFields();
    }
    return <div className="container" style={{paddingBottom: '50px'}}>
        <Form {...formProps} form={queryForm} name="basic">
            <Row align="bottom">
                <Col {...colProps}>
                    <Form.Item label={t('role.roleName')} name="roleName">
                        <Input />
                    </Form.Item>
                </Col>
                <Col {...colProps} style={{ marginBottom: '24px' }}>
                    <Button icon={<SearchOutlined />} style={{ marginLeft: '32px' }} onClick={handleSearch}>{t('common.search')}</Button>
                </Col>
            </Row>
        </Form>
        <div style={{ marginBottom: '20px' }}>
            <Button icon={<PlusOutlined />} onClick={onAdd}>{t('common.create')}</Button>
        </div>
        <Table
            loading={loading}
            pagination={false}
            rowKey="id"
            columns={columns}
            dataSource={data} />
        <Modal
            width={600}
            title={activeId ? t('role.editInsurer') : t('role.createInsurer')}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            destroyOnClose
            footer={[
                <Button key="back" icon={<CloseCircleOutlined />} onClick={handleCancel}>
                  {t('common.cancel')}
                </Button>,
                <Button key="submit" icon={<CheckCircleOutlined />} onClick={handleOk}>
                  {t('common.ok')}
                </Button>
            ]}
            style={{top: '10px'}}
        >   
            <div style={{ maxHeight: '60vh', overflow:'auto'}}>
                <Form
                    form={form}
                    name="basic"
                    layout="vertical">
                    <Form.Item
                        label={t('role.roleName')}
                        name="roleName"
                        rules={[{ required: true, message: t('common.pleaseInput') + t('role.roleName') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t('role.permission')}
                        name="permission"
                        rules={[{ required: true, message: t('common.pleaseInput') + t('role.permission') }]}
                    >
                        <Radio.Group>
                            <Radio value={1}>{t('role.allPage')}</Radio>
                            <Radio value={0}>{t('role.selectPage')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => 
                            form.getFieldValue('permission') === 0 ?
                                <Card title={`${checkedKeys.length} / ${applicationData.length}`} size="small">
                                    <Tree
                                        selectable={false}
                                        defaultExpandAll
                                        checkable
                                        checkedKeys={checkedKeys}
                                        onCheck={onCheck}
                                        treeData={applicationTreeData}
                                    />
                                </Card> : null
                        }
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    </div>
}
