import {Tooltip } from 'antd';
import { QuestionCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
export const QuestionTitle = (props) => {
    const { t } = useTranslation();
    const CircleText = text => <div><InfoCircleTwoTone style={{marginRight: '8px'}}/>{text}</div>
    const title = text => <div> {
        Array.isArray(text) 
            ? text.map(para => CircleText(para)) 
            : CircleText(text)}
        </div>
    return <div>{t(`partner.${props.title}`)} { text[props.title] 
        ?  <Tooltip
            title={title(text[props.title])}
            trigger="click"
            overlayInnerStyle={{textAlign: 'center'}}
            >
                <QuestionCircleOutlined /> 
            </Tooltip>
        : null
        }</div>
}

const text = {
    tripDay: "TripDay < '2020-01-01' or TripDay > LoadDay",
    startTime: ["StartTime < '2020-01-01' or StartTime > LoadDay", "StartTime >= EndTime"],
    endTime: ["EndTime < '2020-01-01' or EndTime > LoadDay", "StartTime >= EndTime"],
    startLat: "StartLatitude > 54.0 or StartLatitude < 18.0",
    startLon: "StartLongitude > 136.0 or StartLongitude < 73.0",
    endLat: "EndLatitude > 54.0 or EndLatitude < 18.0",
    endLon: "EndLongitude > 136.0 or EndLongitude < 73.0",
    avgSpeed: ["SpeedUnit is NOT 'km/h'", "AvgSpeed <= 0.0 or AvgSpeed >= 150.0", "AvgSpeed > MaxSpeed"],
    maxSpeed: ["SpeedUnit is NOT 'km/h'", "MaxSpeed <= 0.0 or MaxSpeed >= 150.0", "AvgSpeed > MaxSpeed"],
    mileage1: ["MileageUnit is NOT 'km'", "Mileage1 <= 0.5 or Mileage1 >= 3000.0"],
    mileage2: ["MileageUnit is NOT 'km'", "Mileage2 <= 0.5 or Mileage2 >= 3000.0"],
    ADASForwardCollision: "ADASForwardCollision < 0",
    ADASFrequentChangeLane: "ADASForwardCollision < 0",
    ADASFollowClosely: "ADASFollowClosely < 0",
    ADASPedestrianCollision: "ADASPedestrianCollision < 0",
    ADASLaneDeparture: "ADASLaneDeparture < 0",
    BSDRightsideRear: "BSDRightsideRear < 0",
    BSDLeftsideRear: "BSDLeftsideRear < 0",
    DSMFatigueDriving: "DSMFatigueDriving < 0",
    DSMSmoking: "DSMSmoking < 0",
    DSMCellphone: "DSMCellphone < 0",
    DSMAnomalyDriving: "DSMAnomalyDriving < 0",
    DSMDistractedDriving: "DSMDistractedDriving < 0",
    DSMDriverIdentify: "DSMDriverIdentify < 0",
    DSMChangeDriver: "DSMChangeDriver < 0",
    DSMActiveCapture: "DSMActiveCapture < 0",
    DSMInfraredBlocking: "DSMInfraredBlocking < 0",
    DSMDriverUnbelt: "DSMDriverUnbelt < 0",
    DSMLoosenSteering: "DSMLoosenSteering < 0",
    DSMDriverDrink: "DSMDriverDrink < 0"
}