import { useEffect, useState , useRef} from 'react';
import { Form } from 'antd';

export const useForm = () => {
    const [form] = Form.useForm();
    const formProps = {
        // labelAlign: "right"
        labelCol: {
            span: 8
        },
        wrapperCol: {
            span: 16
        }
        
    };
    const colProps = {
        lg: 8,
        md: 12,
        sm: 24
    }
    return {
        form,
        formProps,
        colProps
    }
}


export const useVerticalForm = () => {
    const [form] = Form.useForm();
    const formProps = {
        layout: 'vertical',
        // labelAlign: "right"
        labelCol: {
            span: 22
        },
        wrapperCol: {
            span: 22
        }
        
    };
    const colProps = {
        lg: 6,
        md: 12,
        sm: 24
    }
    return {
        form,
        formProps,
        colProps
    }
}