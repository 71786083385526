import { Button, Space, Form, Input, Spin, Select, Radio, Modal, message, InputNumber } from 'antd';
import { DeleteOutlined,FileTextOutlined,CopyOutlined,CloseCircleOutlined,FileSearchOutlined,PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next'
import { useParams,useHistory } from 'react-router-dom';
import style from '../index.module.css'
import http from '../../../http';
import { useState, useEffect } from 'react';
import RecommenClass from './RecommenClass'
import FromPlatform from '../components/FromPlatform'
import FromDatabase from '../components/FromDatabase'
import SectionPreview from '../components/SectionPreview'
import DIY from '../components/DIY'

const { TextArea } = Input;
const { Option } = Select;

export default function Index () {
  const { ReportId } = useParams();
  const history = useHistory();
  console.log('ReportId', ReportId)
  const { t } = useTranslation();

  const [groupByLoading, setGroupByLoading] = useState(true)
  const [editLoading, setEditLoading] = useState(false)
  const [previewLoading, setPreviewLoading] = useState(false)
  const [dataScope, setDataScope] = useState([])
  const [form, setForm] = useState(null)

  const [formData] = Form.useForm();

  const getDetails = async () => {
    let res = await http.get(`/recommendationapi/Recommendation/GetRecommendation?ReportId=${ReportId}`)
    setEditLoading(false)
    setForm(new RecommenClass(res.ReportText))
  }

  const getDataScope = () => {
    return http.get('/recommendationapi/Recommendation/GetDealData')
  }

  useEffect(() => {
    setGroupByLoading(true)
    if(ReportId){
      setEditLoading(true)
      getDetails()
    }else{
      setForm(new RecommenClass())
    }
    getDataScope().then(res => {
      setDataScope(res.TenantData)
      setGroupByLoading(false)
    })
  }, [])


  const useForceUpdate = () => {
    formData.setFieldsValue({ SectionList: form.SectionList });
  }

  const handelCancel = () => {
    history.goBack()
  };
  // Section 预览
  const handelPreview = (index) => {
    console.log('form.strDataScope', form.strDataScope)
    if(!form.strDataScope || !form.strDataScope.length){
      message.error({
        content: t('common.pleaseSelect') + t('Recommendation.DataScope')
      })
      return
    }
    setPreviewLoading(true)
    const strDataCope = form.strDataScope.toString()
    const la = localStorage.getItem('language')
    http.get(`/recommendationapi/Recommendation/CheckDataScope?strDataCope=${strDataCope}&la=${la}`).then(res=>{
      openPreview(index)
    }).finally(()=>{
      setPreviewLoading(false)
    })
  };

  const openPreview = (index) => {
    // 局部校验 Form.List
    const SectionList = formData.getFieldValue("SectionList");
    let FieldsList = []
    Object.keys(SectionList[index]).forEach((key) => {
      if (key === 'serchParams') {
        Object.keys(SectionList[index][key]).forEach(e => {
          if(e === "groupList"){
            Object.keys(SectionList[index][key][e]).forEach((j, i) => {
              FieldsList.push(['SectionList', index, 'serchParams', e, i, 'group'])
              FieldsList.push(['SectionList', index, 'serchParams', e, i, 'groupName'])
            })
          }else{
            FieldsList.push(['SectionList', index, 'serchParams', e])
          }
        })
      } else {
        FieldsList.push(['SectionList', index, key])
      }
    })
    console.log('----FieldsList', FieldsList)
    formData.validateFields(FieldsList).then(values => {
      Modal.info({
        closable: true,
        okText: 'ok',
        icon: '',
        width: 1100,
        content: <div style={{ border: '1px solid #627d77', padding: '8px 12px' }}><SectionPreview SectionData={form.SectionList[index]} DataScope={form.strDataScope}/></div>
      })
    });
  }

  const deepClone = (form, value) => {
    let result = form
  
    if (value === null || typeof value !== 'object') {
      return ;
    }
  
    let keysArr = Object.keys(value)
    if(keysArr.length === 0) {
      return 
    }
    keysArr.forEach(key => {
      if(
        (Array.isArray(value[key]) && Object.prototype.toString.call(value[key][0]) === '[object Object]' && value[key][0]._isAMomentObject) || 
        (Object.prototype.toString.call(value[key]) === '[object Object]' && value[key]._isAMomentObject)
      ){   // 判断是一个Moment数组对象或者就直接是一个Moment对象，这两种都直接复制
        result[key] = value[key]
      }else if(key === 'groupList'){
        let lg = value[key].length-1 || 0
        if(result[key][lg]){
          result[key][lg] = {...result[key][lg], ...value[key][lg]}
        }else{
          result[key][lg] = value[key][lg]
        }
      }else if(key === 'strDataScope'){
        result[key] = value[key] ? value[key] : []
      }else{
        if(typeof value[key] === 'object') {
          result[key] = deepClone(result[key], value[key])
        } else {
          console.log('------', value, key)
          result[key] = value[key]
        }
      }
    })
    return result
  }
  // 合并Chart改变后的值
  const handleValuesChange = (changedValues) => {
    let SectionList = changedValues['SectionList']
    let lh = SectionList && SectionList.length
    if (SectionList && SectionList[lh - 1] && SectionList[lh - 1]['strChart']) {
      form.SectionList[lh - 1].changeChart(SectionList[lh - 1]['strChart'])
    }
    console.log('--changedValues', changedValues)
    const newFrom = deepClone(form, changedValues)
    console.log('--newFrom', newFrom)
    setForm(newFrom)
    useForceUpdate()
  };


  const submit = (e, ReportStatus) => {
    console.log(e)
    let ReportIdData = {} // 报告ID
    if(ReportStatus === 0 && ReportId){
      ReportIdData = { ReportId }
    }
    const la = localStorage.getItem('language')
    http.post('/recommendationapi/Recommendation/UpateRecommendation', {
      ...ReportIdData,
      la,
      DataScope: e.strDataScope, // 范围Data Scope
      ReportTitle: e.strRecommendationReportTitle, // 报告标题
      SubTitle: e.strSubTitleOrBrief, // 报告子标题
      ReportText: e, // 报告内容
      strReportStatus: 0 // 编辑和新增加状态都是未发布
    }).then(res=>{
      message.success({
        content: t('common.success')
      })
      history.goBack()
    })
  }

  const SaveReport = (ReportStatus) => {
    formData.validateFields().then(() => {
      console.log('form', form)
      if(!form.checkAndSortSectionList()){// 检查排序
        message.error({
          content: t('tips.SectionSort')
        })
        return
      }
      submit(form, ReportStatus)
    })
  }


  return <div className="container">
    <Spin spinning={editLoading}>
      <div className={style.titleBox}>
        <span className={style.title}>{t('Recommendation.RecommedationReportEdit')}</span>
      </div>
      {form &&  <Form
        layout="vertical"
        autoComplete="off"
        form={formData}
        initialValues={form}
        onValuesChange={handleValuesChange}
      >
        <div className={style.CreatNewreport}>
          <Space>
            {ReportId ? <Button onClick={SaveReport.bind(this, 0)}><FileTextOutlined />{t('Recommendation.Save')}</Button> : '' }
            <Button onClick={SaveReport.bind(this, 1)}><CopyOutlined />{t('Recommendation.SaveAsANewReport')}</Button>
            <Button onClick={handelCancel}><CloseCircleOutlined />{t('common.cancel')}</Button>
          </Space>
        </div>
        <Spin spinning={groupByLoading}>
          <Form.Item
            name='strDataScope'
            label={t('Recommendation.DataScope')}
            rules={[{ required: true }]}
          >
            <Select className={style.select}
              mode="multiple"
              allowClear
              placeholder="Please select"
            >
              {dataScope.map(e => <Option key={e.typeName} value={e.typeName}>{e.typeName}</Option>)}
            </Select>
          </Form.Item>
        </Spin>
        <Form.Item
          name='strRecommendationReportTitle'
          label={t('Recommendation.RecommendationReportTitle')}
          rules={[{ required: true }]}
        >
          <Input maxLength={100}/>
        </Form.Item>
        <Form.Item
          name='strSubTitleOrBrief'
          label={t('Recommendation.SubTitleOrBrief')}
          rules={[{ required: true }]}
        >
          <TextArea showCount rows={4} maxLength={400} />
        </Form.Item>
        <Form.List name="SectionList">
          {(fields) => (
            <>
              {fields.map((Section, key) => <div key={key} className={style.SectionBox}>
                <div className={style.SectionListTitle}>
                  <div className={style.subTitle}>{t('Recommendation.SectionEdit')}</div>
                  <div className={style.subTitleControl}>
                    <Button onClick={handelPreview.bind(null, key)} loading={previewLoading}><FileSearchOutlined />{t('Recommendation.Preview')}</Button>
                    <Button onClick={() => { form.removeSectionList(key); useForceUpdate() }} icon={<DeleteOutlined />}>{t('Recommendation.Remove')}</Button>
                  </div>
                </div>
                <Form.Item
                  name={[Section.name, 'strSectionTitle']}
                  label={t('Recommendation.SectionTitle')}
                  rules={[{ required: true, message: 'Missing Section Title' }]}
                >
                  <Input maxLength={100}/>
                </Form.Item>
                <Form.Item
                  name={[Section.name, 'strIndex']}
                  label={t('Recommendation.Index')}
                  rules={[{ required: true, message: 'Missing Index' }]}
                >
                  <InputNumber min={1} max={20} style={{ width: '100%' }}/>
                </Form.Item>
                <Form.Item
                  name={[Section.name, 'strContent']}
                  label={t('Recommendation.Content')}
                  rules={[{ required: true }]}
                >
                  <TextArea showCount rows={4} maxLength={400} />
                </Form.Item>
                <Form.Item
                  name={[Section.name, 'strChart']}
                  label={t('Recommendation.Chart')}
                  rules={[{ required: false }]}
                >
                  <Radio.Group>
                    <Radio value={1}>{t('Recommendation.FromPlatform')}</Radio>
                    {/* <Radio value={2}>{t('Recommendation.FromDatabase')}</Radio> */}
                    <Radio value={3}>{t('Recommendation.DIY')}</Radio>
                    <Radio value={4}>{t('Recommendation.NoChart')}</Radio>
                  </Radio.Group>
                </Form.Item>
                {form.SectionList[key].strChart === 1 ? <FromPlatform Section={Section} SectionJS={form.SectionList[key]} /> : ''}
                {/* {form.SectionList[key].strChart === 2 ? <FromDatabase Section={Section} SectionJS={form.SectionList[key]} /> : ''} */}
                {form.SectionList[key].strChart === 3 ? <DIY Section={Section} SectionJS={form.SectionList[key]}/> : ''}
              </div>)}
              <Form.Item>
                <div className={style.CreatNewreport}>
                  <Button onClick={() => { form.addSectionList(); useForceUpdate() }}>
                    <PlusOutlined />{t('Recommendation.AddSection')}
                  </Button>
                </div>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
      }
    </Spin>
  </div>
}
