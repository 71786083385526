import { Select, Button, Row, Col, Form, Input, Spin } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';
import http, { onBlobExport, downloadBlob }  from '../../../http';
import { useTranslation } from 'react-i18next'
import style from './index.module.css'
import { useEffect } from 'react'
import RiskAnalysisChart from '../components/riskAnalysisChart'

export default function FeatureAnalysis () {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // default dispaly table head
  const [chartLoading, setChartLoading] = useState(true)
  const [downDataLoading, setDownDataLoading] = useState(false)
  const [chartDataLoading, setChartDataLoading] = useState(false)
  const [initLoading, setInitLoading] = useState(true)
  const [chartData, setChartData] = useState({})
  const [selectOptionsData, setSelectOptionsData] = useState({})
  const [selectParams, setSelectParams] = useState({})
  const [downExclParams, setDownExclParams] = useState({
    strFeature:8,
    strValue: 6
  })
  const [Units, setUnits] = useState({})

  // dynamic regulation table head
  const getInit = () => {
    return http.get('/vehicleriskapi/OnewayAnalysis/GetInitData')
  }

  const onFormSearch = async (e) => {
    console.log(e)
    setChartLoading(true)
    let paramas = {
      strFeature: e.strFeature,
      strValue: e.strValue,
    }
    setSelectParams({
      strFeature: selectOptionsData.Feature ? selectOptionsData.Feature.find(a=>a.id===e.strFeature).typeName : 'AnnualMileageLevel',
      strValue: selectOptionsData.Value ? selectOptionsData.Value.find(a=>a.id===e.strValue).typeName : 'LossRatio',
    })
    setChartDataLoading(true)
    let res = await http.get('/vehicleriskapi/OnewayAnalysis/GetData', paramas)
    setChartDataLoading(false)
    setUnits({
      UnitX: res.UnitX_en,
      UnitY: res.UnitY_en,
    })
    setChartData(res.data)
    setChartLoading(false)
  }

  const onValuesChange = async () => {
    const row = (await form.validateFields())
    setDownExclParams(row)
  }

  useEffect(() => {
    setInitLoading(true)
    getInit().then(res => {
      setInitLoading(false)
      setSelectOptionsData(res)
      onFormSearch({
        strFeature: '8',
        strValue: '6'
      })
    })
  }, [])

  const exportExcl = () => {
    let params = {
      la: localStorage.getItem('language'),
      ...downExclParams
    }
    setDownDataLoading(true)
    onBlobExport('/vehicleriskapi/OnewayAnalysis/GetExcel', params, { 'method': 'get' }).then(res => {
      setDownDataLoading(false)
      downloadBlob(res.data, 'One Way Analysis')
    });
  }


  const { Option } = Select;
  return <div>
    <div className={style.titleBox}>
      <span className={style.title}>{t('menu.featureAnalysis')}</span>
    </div>
    <Spin spinning={initLoading}>
    <Form layout="vertical" form={form} onFinish={onFormSearch} onValuesChange={onValuesChange} style={{marginBottom: '20px'}}>
      <Row gutter={[8]}>
        <Col className="gutter-row" span={8}>
          <Form.Item
            name='strFeature'
            label={t('featureAnalysis.RiskFeature')}
            initialValue='8'
          >
            <Select
              placeholder="Select"
            >
              {selectOptionsData.Feature && selectOptionsData.Feature.map(e => <Option key={e.id} value={e.id}>{t(`OnewayRiskFeature.${e.typeName}`)}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={8}>
          <Form.Item
            name='strValue'
            label={t('featureAnalysis.LossFeature')}
            initialValue='6'
          >
            <Select
              placeholder="Select"
            >
              {selectOptionsData.Value && selectOptionsData.Value.map(e => <Option key={e.id} value={e.id}>{t(`OnewayLossFeature.${e.typeName}`)}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6}>
          <div style={style} className={style.btnbox}>
            <Button className={style.submitbtn} htmlType="submit"><SearchOutlined />{t('common.search')}</Button>
            <Button className={style.exportbtn} loading={downDataLoading} onClick={exportExcl}><DownloadOutlined />{t('common.export')}</Button>
          </div>
        </Col>
      </Row>
    </Form>
    </Spin>
    <Spin spinning={chartLoading}>
      {<div style={{'position': 'absolute', 'left': '50%','transform': 'translateX(-50%)', 'top': '-20px'}}>{t(`OnewayRiskFeature.${selectParams.strFeature}`) + `${Units.UnitX && Units.UnitX !== '-' ? ` : ${t(`units.${Units.UnitX}`)}` : ''}`}</div>}
      <RiskAnalysisChart chartList={chartData} selectParams={selectParams} Units={Units}/>
    </Spin>
  </div>
}