import { Form, Row, Col, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react';
import http from '../../../../http';

const { Option } = Select;

const getGroupBy = () => {
  return http.get('/portfolioapi/LossPivot/GetInitData')
}

export default function PageLossCorrelationChart ({ Section, SectionJS }) {
  const { t } = useTranslation();

  const [groupBy, setGroupBy] = useState([])
  const [groupByLoading, setGroupByLoading] = useState(true)

  useEffect(() => {
    getGroupBy().then(res => {
      setGroupBy(res.GroupBy)
      setGroupByLoading(false)
    })
  },[])

  const saveValueLabel = (list) => {
    SectionJS.serchParams.strGroup = list
    SectionJS.serchParams.strGroupName = list.map(e=>{
      return groupBy.find(g=>g.id === e).typeName
    })
  }

  return <div>
    <Spin spinning={groupByLoading}>
      <Row justify="space-between">
        <Col span={11}>
          <Form.Item
            name={[Section.name, 'serchParams', 'strGroup']}
            label={t('Recommendation.GroupBy')}
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Please select"
              onChange={e=>{saveValueLabel(e)}}
              >
                {groupBy.map(e=><Option key={e.id}>{t(`groupname.${e.typeName}`)}</Option>)}
              </Select>
          </Form.Item>
        </Col>
      </Row>
    </Spin>
  </div>
}
