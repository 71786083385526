import { Select, Row, Col, Form, Spin, Checkbox } from 'antd';
import { useState } from 'react';
import http from '../../../../http';
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

export default function PageVehicleRisk ({Section, SectionJS}) {
  const { t } = useTranslation();
  
  // default dispaly table head
  const [initLoading, setInitLoading] = useState(true)
  const [selectOptionsData, setSelectOptionsData] = useState({})

  const getInit = () => {
    return http.get('/vehicleriskapi/VehicleRisk/GetInitData')
  }

  useEffect(() => {
    setInitLoading(true)
    getInit().then(res => {
      setInitLoading(false)
      setSelectOptionsData(res)
    })
  }, [])

  const saveStrPolicy = (e) => {
    console.log('--------', e)
    SectionJS.serchParams.strPolicy = e
  }

  const { Option } = Select;
  return <div>
    <Spin spinning={initLoading}>
        <Row justify="space-between">
          <Col className="gutter-row" span={5}>
            <Form.Item
              name={[Section.name, 'serchParams', 'strDeal']}
              label={t('vehicleRisk.Deal')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.DealData && selectOptionsData.DealData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name={[Section.name, 'serchParams', 'strInsurer']}
              label={t('vehicleRisk.Insurer')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.InsurerData && selectOptionsData.InsurerData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name={[Section.name, 'serchParams', 'strPartner']}
              label={t('vehicleRisk.Partner')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.PartnerData && selectOptionsData.PartnerData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name={[Section.name, 'serchParams', 'strRegion']}
              label={t('vehicleRisk.Region')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.RegionData && selectOptionsData.RegionData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          </Row>
          <Row justify="space-between">
          <Col className="gutter-row" span={5}>
            <Form.Item
              name={[Section.name, 'serchParams', 'strVehicleType']}
              label={t('vehicleRisk.VehicleType')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.VehicleTypeData && selectOptionsData.VehicleTypeData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name={[Section.name, 'serchParams', 'strLoad']}
              label={t('vehicleRisk.Load')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.VehicleLoadData && selectOptionsData.VehicleLoadData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name={[Section.name, 'serchParams', 'strAge']}
              label={t('vehicleRisk.Age')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.VehicleAgeData && selectOptionsData.VehicleAgeData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name={[Section.name, 'serchParams', 'strLength']}
              label={t('vehicleRisk.Length')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.VehicleLengthData && selectOptionsData.VehicleLengthData.map(e => <Option key={e.id} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={3}>
            <Form.Item
              name={[Section.name, 'serchParams', 'strPolicy']}
              label={t('vehicleRisk.Policy')}
            >
              <Checkbox.Group onChange={saveStrPolicy} options={[ { label: 'True', value: '1' }]} />
            </Form.Item>
          </Col>
        </Row>
    </Spin>
  </div>
}

