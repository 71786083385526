import { useEffect, useState } from 'react';
import { Select, Table, Row, Col, Form, Button } from 'antd';
import { DataCard } from '../../components/DataCard/card';
import { DownloadOutlined ,SearchOutlined} from '@ant-design/icons'

import moment from 'moment';

import styles from './index.module.css';
import { useTranslation } from 'react-i18next'
import http, { onBlobExport, downloadBlob } from '../../http';
import { useTableWithLocalPagination } from '../../hooks/table';
import InstallDaysDistribution from './components/InstallDaysDistribution';
import VehicleRegion from './components/VehicleRegion';
import TableColumnFilter from '../../components/TableColumnFilter';

import {useDealList, useInsuranceList, useVendorList} from './data';

const DataQuality = () => {
  const [summary, setSummary] = useState({});
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { tableData, setQuery, pagination, loading } = useTableWithLocalPagination(getData);

  function getData() {
    return http.get('/partner-eval/evaluation/deal/detail').then(res => {
      return res.sort((a, b) => b.connectedVehicleCount - a.connectedVehicleCount)
    })
  }
  function getSummary(param) {
    return http.get('/partner-eval/evaluation/deal/summary');
  }
  const {vendorList} = useVendorList();
  const {insuranceList} = useInsuranceList();
  const {dealList} = useDealList();


  useEffect(() => {
    getSummary().then(res => {
      setSummary(res);
    })
  }, [])
  const [columns, setColumns] = useState([
    {
      title: t('partner.deal'),
      key: 'dealName',
      dataIndex: 'dealName',
      width: 200,
    },
    {
      title: t('partner.partner'),
      key: 'partnerName',
      dataIndex: 'partnerName',
      width: 250
    },
    {
      title: t('partner.insurer'),
      key: 'insurerName',
      dataIndex: 'insurerName',
      width: 350
    },
    {
      title: t('partner.connectedVehicleCount'),
      key: 'connectedVehicleCount',
      dataIndex: 'connectedVehicleCount',
      sorter: (a, b) => a.connectedVehicleCount - b.connectedVehicleCount
    },
    {
      title: t('partner.aebDeviceCount'),
      key: 'aebDeviceCount',
      dataIndex: 'aebDeviceCount',
      hide: true
    },
    {
      title: t('partner.adasDeviceCount'),
      key: 'adasDeviceCount',
      dataIndex: 'adasDeviceCount',
      hide: true
    },
    {
      title: t('partner.activeVehicleCount'),
      key: 'activeVehicleCount',
      dataIndex: 'activeVehicleCount',
      sorter: (a, b) => a.activeVehicleCount - b.activeVehicleCount,
    },
    {
      title: t('partner.activeVehicleRate'),
      key: 'vehicleActiveRate',
      dataIndex: 'vehicleActiveRate',
      sorter: (a, b) => a.vehicleActiveRate - b.vehicleActiveRate,
      render: text => (text * 100).toFixed(2) + '%'
    },
    {
      title: t('partner.averageInstallDays'),
      key: 'avgInstallDay',
      dataIndex: 'avgInstallDay',
      sorter: (a, b) => a.avgInstallDay - b.avgInstallDay,
      render: text => Math.round(text)
    },
    {
      title: t('partner.installOverDueVehicleCount'),
      key: 'installOverDueVehicleCount',
      dataIndex: 'installOverDueVehicleCount',
      sorter: (a, b) => a.installOverDueVehicleCount - b.installOverDueVehicleCount,
    },
    {
      title: t('partner.installOverdueRate'),
      key: 'installOverDueRate',
      dataIndex: 'installOverDueRate',
      sorter: (a, b) => a.installOverDueRate - b.installOverDueRate,
      render: text => (text * 100).toFixed(2) + '%'
    },
    {
      title: t('partner.tripCount'),
      key: 'tripCount',
      dataIndex: 'tripCount',
      sorter: (a, b) => a.tripCount - b.tripCount,
    },
    {
      title: t('partner.tripDataQualityCorrectRate'),
      key: 'tripCorrectRate',
      dataIndex: 'tripCorrectRate',
      sorter: (a, b) => a.tripCorrectRate - b.tripCorrectRate,
      render: text => (text * 100).toFixed(2) + '%'
    },
    {
      title: t('partner.lossRatio'),
      key: 'lossRatio',
      dataIndex: 'lossRatio',
      sorter: (a, b) => a.lossRatio - b.lossRatio,
      render: text => text >=0 ? (text * 100).toFixed(2) + '%' : '-'
    },
    {
      title: t('partner.installDaysDistribution'),
      key: 'installDaysDistribution',
      render: (text, record) => <InstallDaysDistribution type="deal" dealName={record.dealName} insurerName={record.insurerName} partnerName={record.partnerName} />,
      width: 100
    },
    {
      title: t('partner.region'),
      key: 'region',
      render: (text, record) => <VehicleRegion type="deal" dealName={record.dealName} insurerName={record.insurerName} partnerName={record.partnerName} />
    },
    {
      title: t('common.export'),
      key: 'export',
      render: (text, record) => <Button type="link" icon={<DownloadOutlined />} onClick={() => onExport(record.dealName)}>{t('common.export')}</Button>
    }
  ]);
  const onSubmit = values => {
    setQuery(values);
  }
  function onExport(dealName) {
    const exportFn = dealName 
      ? onBlobExport(`/partner-eval/evaluation/deal/detail/vehicle/download?dealName=${dealName}`)
      : onBlobExport('/partner-eval/evaluation/deal/detail/download', form.getFieldsValue());
    exportFn.then(res => {
      downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*)/)[1])
    });
  }
  return <div>
    <div className={styles.title}>{t('partner.dealSummary')}</div>
    <span className="text">{t('common.update')}: {summary.updateDate ? moment(summary.updateDate).format('YYYY/MM/DD') : '-'}</span>
    <Row style={{ marginTop: "8px", marginBottom: "24px" }}>
      <Col span={8}><DataCard title={t('partner.summary.deal')} num={(summary.dealCount)}></DataCard></Col>
      <Col span={8}><DataCard title={t('partner.summary.vehicle')} num={(summary.vehicleCount)}></DataCard></Col>
      <Col span={8}><DataCard title={t('partner.summary.mileage')} num={(summary.mileageCount)}></DataCard></Col>
    </Row>
    <div className={styles.title}>{t('partner.dealStatus')}</div>
    <div>
      <Form
        layout="vertical"
        initialValues={{
        }}
        onFinish={onSubmit}
        form={form}
      >
        <Row gutter={24} align="bottom">
          <Col span={6}>
            <Form.Item label={t('partner.deal')} name="dealName">
              <Select allowClear showSearch options={dealList}></Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('partner.partner')} name="partnerName">
              <Select allowClear showSearch>
                {
                  vendorList.map(vendorName => <Select.Option key={vendorName}>{vendorName}</Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('partner.insurer')} name="insurerName">
            <Select allowClear showSearch>
                {
                  insuranceList.map(insurerFullName => <Select.Option key={insurerFullName}>{insurerFullName}</Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={4} style={{ marginBottom: '24px' }}>
            <Button icon={<SearchOutlined />}  loading={loading} style={{ marginLeft: '8px' }} htmlType="submit">{t('common.search')}</Button>
            <Button onClick={() => onExport()} style={{ marginLeft: '8px' }} icon={<DownloadOutlined />}>{t('common.export')}</Button>
          </Col>
          <Col span={2} style={{marginBottom: '24px', textAlign: 'right'}}>
            <TableColumnFilter columns={columns} onSetColumns={setColumns} />
          </Col>
        </Row>
      </Form>
      <Table
        size="small"
        scroll={{ x: 2500}}
        loading={loading}
        pagination={pagination}
        rowKey="dealName"
        columns={columns.filter(v => !v.hide)}
        dataSource={tableData} />
    </div>
  </div>
}

export default DataQuality;