import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { useEffect, useState } from 'react';
import http from '../../../../http';
import { Spin } from 'antd';
import moment from 'moment';


echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer
]);


export default function PreviewDataQuality ({ serchParams }) {
  const [chartData, setChartData] = useState(null)
  const [loading, setLoading] = useState(false)
  const uid = serchParams.groupUUID
  

  const formatFieldValue = values => {
    return {
        strDataCope: values.strDataCope,
        valueType: values.valueType,
        startTimestamp: moment(values.date[0]).startOf('day').valueOf(),
        endTimestamp: moment(values.date[1]).endOf('day').valueOf()
    }
}

  const onSubmit = values => {
    setLoading(true)
    http.get('/tmx-dashboard/tmx/quality/tendency', formatFieldValue(serchParams)).then(res => {
        setChartData(res);
    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    onSubmit()
  }, [])

  const setChart = () => {
    var chartDom = document.getElementById('main'+uid);
    echarts.init(chartDom).dispose();
    var myChart = echarts.init(chartDom);
    var option;

    option = {
        color: ['#627D77', '#006E73', '#1455B4', '#9B14B4', 'rgba(98, 125, 119, 0.75)', '#00AA91', '#418CE1', '#D241C8', 'rgba(98, 125, 119, 0.5)', '#73E1A5', '#91C8FF', '#FA8CFF'],
        // title: {
        //     text: 'Line'
        // },
        tooltip: {
            trigger: 'axis',
            formatter(val) {
                return val[0].axisValue + '<br />' + val.map(item => `
                <div style="display: flex; justify-content: space-between; align-items: center">
                    <span>${item.marker}${item.seriesName}</span>
                    <span style="margin-left: 20px">${Math.floor(item.value * 10000) / 100 + "%"}</span>
                </div>
                `).join('')
            }
        },
        legend: {
            icon: 'rect',
            itemWidth: 14,
            orient: 'vertical',
            right: 0,
            top: 'center',
            bottom: 20,
            textStyle: {
                width: 150,
                overflow: 'break'
            }
        },
        grid: {
            show: true,
            left: '3%',
            right: 190,
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: chartData.dims.map(one => one.slice(0, 10))
        },
        yAxis: {
            scale: true,
            type: 'value',
            axisLabel: {
                formatter: function (val) {
                    return (val) * 100 + '%';
                }
            }
        },
        series: Object.keys(chartData.measures).map(key => {
            return {
                name: key,
                type: 'line',
                data: chartData.measures[key],
                smooth: false
            }
        })
    }
    option && myChart.setOption(option);
}

  useEffect(() => {
    chartData && setChart()
  }, [chartData])

  return <div>
    <Spin spinning={loading}>
      <div id={`main${uid}`} style={{height: 600, width: '100%'}}></div>
    </Spin>
  </div>
}