import { Table } from 'antd';
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react';
import http from '../../../../http';
import { useTable } from '../../../../hooks/table';
import ChangeColumns from '../../../PortfolioMgt/components/ChangeColumns'


export default function PreviewLossPivotTable ({ serchParams }) {
  const { t } = useTranslation();

  const { tableData, pagination, loading, handleTableChange } = useTable(getData);
  const [ groupByColumns, setGroupByColumns ] = useState([])

 // get table date
  function getData (param) {
    return http.get('/portfolioapi/LossPivot/GetData', {
      strDataCope: serchParams.strDataCope,
      PageNum: pagination.current,
      PageSize: pagination.pageSize,
      strGroup: serchParams.strGroup.toString(),
      ...param
    })
  }


  const columns = [
    {
      title: t.bind(null,'tabletitle.PolicyCount'),
      dataIndex: 'PolicyCount',
      default: true,
      sorter: {
        compare: (a, b) => a.PolicyCount - b.PolicyCount,
      },
    },
    {
      title: t.bind(null,'tabletitle.Premium'),
      dataIndex: 'WritenPremium',
      default: true,
      sorter: {
        compare: (a, b) => a.WritenPremium - b.WritenPremium,
      },
    },
    {
      title: t.bind(null,'tabletitle.EarnedPremium'),
      dataIndex: 'EarnedPremium',
      showSorterTooltip: false,
      default: true,
      sorter: {
        compare: (a, b) => a.EarnedPremium - b.EarnedPremium,
      },
    },
    {
      title: t.bind(null,'tabletitle.PolicyYears'),
      dataIndex: 'CarYears',
      showSorterTooltip: false,
      default: true,
      sorter: {
        compare: (a, b) => a.PolicyYears - b.PolicyYears,
      },
    }, {
      title: t.bind(null,'tabletitle.ExpYears'),
      dataIndex: 'ExpYears',
      default: true,
      sorter: {
        compare: (a, b) => a.ExpYears - b.ExpYears,
      },
    }, {
      title: t.bind(null,'tabletitle.ClaimCount'),
      dataIndex: 'ClaimCount',
      default: true,
      sorter: {
        compare: (a, b) => a.ClaimCount - b.ClaimCount,
      },
    }, {
      title: t.bind(null,'tabletitle.AmountOfLossPaid'),
      dataIndex: 'AmountOfLossPaid',
      default: true,
      sorter: {
        compare: (a, b) => a.AmountOfLossPaid - b.AmountOfLossPaid,
      },
    }, {
      title: t.bind(null,'tabletitle.LossRatio'),
      dataIndex: 'LossRatio',
      default: true,
      sorter: {
        compare: (a, b) => a.LossRatio - b.LossRatio,
      },
      render: (text) =>{
        return (text * 100).toFixed(2) + '%'
      }
    }, {
      title: t.bind(null,'tabletitle.Frequency'),
      dataIndex: 'Frequency',
      default: true,
      sorter: {
        compare: (a, b) => a.Frequency - b.Frequency,
      }
    }, {
      title: t.bind(null,'tabletitle.Severity'),
      dataIndex: 'Severity',
      default: true,
      sorter: {
        compare: (a, b) => a.Severity - b.Severity,
      }
    }
  ];

  // default dispaly table head
  const defaultColumns = columns.filter(e=>e.default)

  // true table columns
  const [tableColumn, setTableColumns] = useState(defaultColumns)

   // create GroupByColumns by GroupByList
  function initGroupByColumns () {
    let groupByColumns = serchParams.strGroupName.map(e => ({
      title: t.bind(null,`groupname.${e}`),
      dataIndex: e,
    }));
    setGroupByColumns(groupByColumns)
    // init show the first two groupBy
    setTableColumns(groupByColumns.concat(defaultColumns))
  }

  useEffect(() => {
    initGroupByColumns()
  },[])

  function changeColumns(list){
    setTableColumns(groupByColumns.concat(list))
  }

  return <div>
      <div style={{float: 'right'}}><ChangeColumns Columns={columns} changeColumns={changeColumns} /></div>
      <Table pagination={{...pagination, showSizeChanger: true}} onChange={handleTableChange} scroll={{ x: '100%' }} showSorterTooltip={false} columns={tableColumn} loading={loading} dataSource={tableData} />
  </div>
}
