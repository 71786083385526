import { Table, Row, Col, Button, Modal, Form, Input, Select, DatePicker } from 'antd';
import {useState} from 'react';
import {useTable} from '../../../hooks/table';
import { useTranslation } from 'react-i18next'
import http from '../../../http';
import moment from 'moment';
const PolicyHistory = ({params: formParams}) => {
    const { t } = useTranslation();

    const [modalVisible, setModalVisible] = useState(false);
    const {tableData, pagination, loading, setQuery, onSearch} = useTable(getData, false);
    function getData(params) {
      return http.get('/system-setting/accident/searchPolicies', { 
        pageNum: pagination.current,
        pageSize: pagination.pageSize, 
        ...params
      })
    }
    const onClickPolicyHistory = () => {
        onSearch(formParams).then(res => {
            setModalVisible(true)
        })
    }
    const columns = [
      { title: t("fields.policyNumber"),dataIndex: 'policyNumber'},
      { title: t("policy.endorsementNumber"),dataIndex: 'endorsementNumber'},
      { title: t("policy.policyType"),dataIndex: 'policyType'},
      { title: t("fields.vin"),dataIndex: 'vin'},
      { title: t("fields.plateNumber"),dataIndex: 'plateNumber'},
      { title: t("policy.vehicleMake"), dataIndex: 'vehicleMake' },
      { title: t("policy.vehicleModelCode"), dataIndex: 'vehicleModelCode' },
      { title: t("policy.vehicleType"), dataIndex: 'vehicleType'},
      { title: t("policy.applicantName"), dataIndex: 'applicantName'  },
      { title: t("policy.insuredName"), dataIndex: 'insuredName'  },
      { title: t("policy.inceptionDate"), dataIndex: 'inceptionDate', render: date => moment(date).format('YYYY-MM-DD') },
      { title: t("policy.inceptionEndDate"), dataIndex: 'endDate', render: date => moment(date).format('YYYY-MM-DD')  },
      { title: t("policy.endorsementEffectiveDate"), dataIndex: 'endorsementEffectiveDate', render: date => date ? moment(date).format('YYYY-MM-DD') : ''  },
      { title: t("policy.premium"), dataIndex: 'premium' },
      { title: t("policy.modPremium"), dataIndex: 'modPremium'  },
      { title: t("policy.vtplPremium"), dataIndex: 'vtplPremium'  },
      { title: t("policy.otherPremium"), dataIndex: 'otherPremium'  },
      { title: t("policy.sumInsured"), dataIndex: 'sumInsured' },
      { title: t("policy.modInsured"), dataIndex: 'modInsured'  },
      { title: t("policy.vtplInsured"), dataIndex: 'vtplInsured'  },
      { title: t("policy.otherInsured"), dataIndex: 'otherInsured'  },
      { title: t("policy.noClaimDiscountFactor"), dataIndex: 'ncdFactor' },
      { title: t("policy.insureOwnPricingFactor"), dataIndex: 'iopFactor'  },
      { title: t("policy.businessOrigin"), dataIndex: 'businessOrigin'  },
    ];

    return (
        <>
            <Modal
                width={1200}
                title={t('accident.policyHistory')}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
            >
                <Table
                    size="small"
                    scroll={{x: 2800}}
                    loading={loading}
                    pagination={pagination}
                    rowKey="id"
                    columns={columns}
                    dataSource={tableData} />
            </Modal>
            <Button onClick={onClickPolicyHistory} loading={loading}>{t('accident.policyHistory')}</Button>
        </>
    )
}

export default PolicyHistory