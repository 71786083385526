import './style.css'
import { useTranslation } from 'react-i18next'
import { MailOutlined } from '@ant-design/icons'
export const ContactUs = () => {
    const { t } = useTranslation();
    return (
        <div className="contactus-button">
            <a href="mailto:">
                <MailOutlined />
                <span className='contactus-content'>{t('navbar.contactus')}</span>
            </a>
        </div>
    );
};