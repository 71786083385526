
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { Form, Input, Button, Space, Select, Row, Col } from 'antd';
import { useEffect, useState } from 'react';

import { MinusCircleOutlined, PlusOutlined, SearchOutlined, DownloadOutlined } from '@ant-design/icons';

const { Option } = Select;

echarts.use([
    LegendComponent,
    TooltipComponent,
    GridComponent,
    CanvasRenderer
]);

import { useTranslation } from 'react-i18next'
import http, {onBlobExport, downloadBlob} from '../../../http';
import {getUnit, round2} from './tools';
const groupOptions = ['ALL', 'DEAL', 'INSURANCE', 'REGION', 'VENDOR'];
const legendColors = ['#1455B4', '#91C8FF', '#9B14B4', '#006E73', '#FA8CFF', '#73E1A5', '#D241C8', '#418CE1', '#00AA91'];

const FeatureAnalysisByType = props => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [chartData, setChartData] = useState(null);
    const [lineList, setLineList] = useState([]);
    const [legendTypes, setLegendTypes] = useState([]);
    const DATASOURCE = {
        DEAL: props.dataSource.dealList,  
        INSURANCE:  props.dataSource.insuranceList,
        REGION: props.dataSource.regionList,
        VENDOR: props.dataSource.vendorList,
    }
    useEffect(() => {
        chartData && setChart()
    }, [chartData])
    const setChart = () => {
        chartData.forEach((data, index) => {
            var chartDom = document.getElementById(`featureByTypeChart${index}`);
            echarts.init(chartDom).dispose();
            var myChart = echarts.init(chartDom);
            var option;
            // const seriesData = data.featureDistributedModels[0]?.distributedModels || [];
            option = {
                color: legendColors,
                title: {
                    text: t('tmx.options.' + data.feature),
                    top: '10%',
                    textStyle: {
                        fontSize: '12px',
                        fontWeight: 'normal'
                    }
                },
                legend: {
                    show: false,
                },
                tooltip: {
                    trigger: 'axis',
                    appendToBody: true
                },
                grid: {
                    left: '3%',
                    right: '10%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    show: true,
                    type: 'category',
                    boundaryGap: false,
                    data: data.featureDistributedModels[0].distributedModels.map((model, index) => `(${round2(model.left)} - ${round2(model.right)}) ${getUnit(data.feature)}`),
                    axisTick : {
                        show: true,
                        interval: 0
                    },
                    axisLabel: {
                        // interval: 0,
                        formatter: function (value, index) {
                            return value.match(/\((.*)\s-/)[1]
                        }
                    },
                    axisLine: {}
                },
                yAxis: {
                    type: 'value'
                },
                series: data.featureDistributedModels.map(model => {
                    return {
                        name: `${model.group} - ${model.groupName}`,
                        areaStyle: {opacity: 0.2},
                        type: 'line',
                        data: model.distributedModels.map(data => data.number)
                    }
                })
                // series: [{
                //     name: '1',
                //     type: 'line',
                //     data: seriesData.map(data => data.number)
                // }]
            }
            option && myChart.setOption(option);
        })
    }
    const onFinish = values => {
        const param = values.params.map(one => {
            return {
                group: one.group,
                groupName: one.tenant || ''
            }
        })
        setLoading(true);
        http.post('/tmx-dashboard/insight/analysisbytypes', param).then(res => {
            setLineList(Object.keys(res));
            setChartData(res);
            setLegendTypes(res.find(one => one.featureDistributedModels.length).featureDistributedModels);
        }).finally(() => setLoading(false))
    };
    const onExport = () => {
        form.validateFields().then(values => {
            const groups = values.params.map(one => {
                return {
                    group: one.group,
                    groupName: one.tenant || ''
                }
            })
            onBlobExport('/tmx-dashboard/insight/analysisbytypes/download', groups, {method: 'post'}).then(res => {
                downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*)/)[1])
            });
        })
    }
    const onGroupChange = (val, index) => {
        const {params} = form.getFieldsValue();
        params[index].tenant = undefined;
        form.setFieldsValue(params)
    }
    return (
        <div>
            <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" layout="vertical" initialValues={{params: []}}>
                <Form.List name="params">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <Row gutter={12} align="middle" key={index}>
                                    <Col span={12}>
                                        <Form.Item
                                            {...field}
                                            label={t('tmx.group')}
                                            name={[field.name, 'group']}
                                            fieldKey={[field.fieldKey, 'group']}
                                            rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.group') }]}
                                        >
                                            <Select onChange={(val) => onGroupChange(val, index)}>
                                                {groupOptions.map(item => <Option value={item} key={item}>{t('tmx.options.' + item)}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item noStyle shouldUpdate>
                                        {({ getFieldValue }) => {
                                            return getFieldValue('params')[field.name].group !=='ALL' ? <Form.Item
                                                {...field}
                                                label={t(`tmx.${getFieldValue('params')[field.name].group.toLowerCase()}`)}
                                                name={[field.name, 'tenant']}
                                                fieldKey={[field.fieldKey, 'tenant']}
                                                rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.deal') }]}
                                            >
                                            <Select options={DATASOURCE[getFieldValue('params')[field.name].group]}></Select>
                                        </Form.Item> : null
                                        }}
                                        </Form.Item>
                                        
                                    </Col>
                                    <Col>
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Col>
                                </Row>
                            ))}
                            <Row gutter={8}>
                                <Col span={20}><Button type="dashed" onClick={() => add({group: 'DEAL'})} block icon={<PlusOutlined />}>
                                    {t('common.addRow')}
                                </Button></Col>
                                <Col><Button icon={<SearchOutlined />}  loading={loading}  htmlType="submit" disabled={!form.getFieldValue('params').length}>
                                    {t('common.search')}
                                </Button></Col>
                                <Col><Button icon={<DownloadOutlined />} onClick={onExport} disabled={!form.getFieldValue('params').length}>{t('common.export')}</Button></Col>
                            </Row>
                        </>
                    )}
                </Form.List>
            </Form>
            <Row justify="center" style={{marginTop: '16px'}}>
                <Space>
                {
                    legendTypes.map((item, index) => <div>
                        <div style={{
                            width: '20px',
                            height: '10px',
                            marginRight: '8px',
                            // background: legendColors[index],
                            backgroundImage:`url("../img/legendTypeV1/Union-${index}.png")`,
                            backgroundSize:'100%',
                            display: 'inline-block',
                            }}></div>{t(`tmx.options.${item.group}`)}-{item.groupName}
                    </div>)
                }
                </Space>

            </Row>
            <Row>
                { lineList.map(key => <div key={key} id={'featureByTypeChart' + key} style={{height: 200, width: 200}}></div>)  }
            </Row>
        </div>
    );
};
export default FeatureAnalysisByType;