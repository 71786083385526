import { useEffect, useState, useRef } from 'react';
import { Select, Input, Table, Row, Col, Form, Button } from 'antd';
import { DataCard } from '../../components/DataCard/card';
import { DownloadOutlined , SearchOutlined} from '@ant-design/icons'

import moment from 'moment';

import styles from './index.module.css';
import { useTranslation } from 'react-i18next'
import http, { onBlobExport, downloadBlob } from '../../http';
import {formatPercent} from '../../utils/number';
import { useTableWithLocalPagination } from '../../hooks/table';
import InstallDaysDistribution from './components/InstallDaysDistribution';
import VehicleRegion from './components/VehicleRegion';
import TableColumnFilter from '../../components/TableColumnFilter';
import {useVendorList} from './data';

const DataQuality = () => {
  const [summary, setSummary] = useState({});
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { tableData, setQuery, pagination, loading } = useTableWithLocalPagination(getData);
  const {vendorList} = useVendorList();
  function getData() {
    return http.get('/partner-eval/evaluation/partner/detail').then(res => {
      return res.sort((a, b) => b.connectedVehicleCount - a.connectedVehicleCount)
    })
  }
  function getSummary(param) {
    return http.get('/partner-eval/evaluation/partner/summary');
  }
  useEffect(() => {
    getSummary().then(res => {
      setSummary(res);
    })
  }, [])
  const [columns, setColumns] = useState([
    {
      title: t('partner.partner'),
      key: 'partnerName',
      dataIndex: 'partnerName',
      width: 250
    },
    {
      title: t('partner.dealCount'),
      key: 'dealCount',
      dataIndex: 'dealCount',
      sorter: (a, b) => a.dealCount - b.dealCount,
    },
    {
      title: t('partner.connectedVehicleCount'),
      key: 'connectedVehicleCount',
      dataIndex: 'connectedVehicleCount',
      sorter: (a, b) => a.connectedVehicleCount - b.connectedVehicleCount
    },
    {
      title: t('partner.aebDeviceCount'),
      key: 'aebDeviceCount',
      dataIndex: 'aebDeviceCount',
      hide: true
    },
    {
      title: t('partner.adasDeviceCount'),
      key: 'adasDeviceCount',
      dataIndex: 'adasDeviceCount',
      hide: true
    },
    {
      title: t('partner.activeVehicleCount'),
      key: 'activeVehicleCount',
      dataIndex: 'activeVehicleCount',
      sorter: (a, b) => a.activeVehicleCount - b.activeVehicleCount,
    },
    {
      title: t('partner.activeVehicleRate'),
      key: 'vehicleActiveRate',
      dataIndex: 'vehicleActiveRate',
      sorter: (a, b) => a.vehicleActiveRate - b.vehicleActiveRate,
      render: text => (text * 100).toFixed(2) + '%'
    },
    {
      title: t('partner.averageInstallDays'),
      key: 'avgInstallDay',
      dataIndex: 'avgInstallDay',
      sorter: (a, b) => a.avgInstallDay - b.avgInstallDay,
      render: text => Math.round(text)
    },
    {
      title: t('partner.installOverDueVehicleCount'),
      key: 'installOverDueVehicleCount',
      dataIndex: 'installOverDueVehicleCount',
      sorter: (a, b) => a.installOverDueVehicleCount - b.installOverDueVehicleCount,
    },
    {
      title: t('partner.installOverdueRate'),
      key: 'installOverDueRate',
      dataIndex: 'installOverDueRate',
      sorter: (a, b) => a.installOverDueRate - b.installOverDueRate,
      render: text => (text * 100).toFixed(2) + '%'
    },
    {
      title: t('partner.tripCount'),
      key: 'tripCount',
      dataIndex: 'tripCount',
      sorter: (a, b) => a.tripCount - b.tripCount,
    },
    {
      title: t('partner.tripDataQualityCorrectRate'),
      key: 'tripCorrectRate',
      dataIndex: 'tripCorrectRate',
      sorter: (a, b) => a.tripCorrectRate - b.tripCorrectRate,
      render: text => (text * 100).toFixed(2) + '%'
    },
    {
      title: t('partner.lossRatio'),
      key: 'lossRatio',
      dataIndex: 'lossRatio',
      sorter: (a, b) => a.lossRatio - b.lossRatio,
      render: text => text >=0 ? (text * 100).toFixed(2) + '%' : '-'

    },
    {
      title: t('partner.installDaysDistribution'),
      key: 'installDaysDistribution',
      render: (text, record) => <InstallDaysDistribution type="partner" dealName={record.dealName} insurerName={record.insurerName} partnerName={record.partnerName} />,
      width: 100
    },
    {
      title: t('partner.region'),
      key: 'region',
      render: (text, record) => <VehicleRegion type="partner" dealName={record.dealName} insurerName={record.insurerName} partnerName={record.partnerName} />
    },
  ]);
  const onSubmit = values => {
    setQuery(values)
  }
  function onExport() {
    onBlobExport('/partner-eval/evaluation/partner/detail/download').then(res => {
      downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*)/)[1])
    });
  }
  return <div className="data-quality">
    <div className={styles.title}>{t('partner.partnerSummary')}</div>
    <span className="text">{t('common.updatedOn')} {summary.updateDate ? moment(summary.updateDate).format('YYYY-MM-DD') : '-'}</span>
    <Row style={{ marginTop: "8px", marginBottom: "24px" }}>
      <Col span={6}><DataCard title={t('partner.summary.partner')} num={(summary.partnerCount)}></DataCard></Col>
      <Col span={6}><DataCard title={t('partner.summary.deal')} num={(summary.dealCount)}></DataCard></Col>
      <Col span={6}><DataCard title={t('partner.summary.vehicle')} num={(summary.vehicleCount)}></DataCard></Col>
      <Col span={6}><DataCard title={t('partner.summary.mileage')} num={(summary.mileageCount)}></DataCard></Col>
    </Row>
    <div className={styles.title}>{t('partner.partnerStatus')}</div>
    <div>
      <Form
        layout="vertical"
        initialValues={{
        }}
        onFinish={onSubmit}
        form={form}
      >
        <Row gutter={24} align="bottom">
          <Col span={4}>
            <Form.Item label={t('partner.partner')} name="partnerName">
              <Select allowClear showSearch>
                {
                  vendorList.map(vendorName => <Select.Option key={vendorName}>{vendorName}</Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} style={{ marginBottom: '24px' }}>
            <Button icon={<SearchOutlined />}  style={{ marginLeft: '8px' }} htmlType="submit">{t('common.search')}</Button>
            <Button onClick={() => onExport()} style={{ marginLeft: '8px' }} icon={<DownloadOutlined />}>{t('common.export')}</Button>
          </Col>
          <Col span={14} style={{ marginBottom: '24px', textAlign: 'right' }} >
            <TableColumnFilter columns={columns} onSetColumns={setColumns} />
          </Col>
        </Row>
      </Form>
      <Table
        size="small"
        loading={loading}
        scroll={{ x: 1850 }}
        pagination={pagination}
        rowKey="partnerName"
        columns={columns.filter(v => !v.hide)}
        dataSource={tableData} />
    </div>
  </div>
}

export default DataQuality;