import { Table, Row, Col, Button, Modal, Form, Input, Select, DatePicker } from 'antd';
import { DownloadOutlined, PlusOutlined, SettingOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { FileUpload } from './components/Upload';
import TableColumnFilter from '../../components/TableColumnFilter';

import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useTable } from '../../hooks/table';
import { useVerticalForm } from '../../hooks/form';
import { useEdit } from '../../hooks/useEdit';
import { useDealList } from '../../data/deal';
import { useInsuranceList } from '../../data/insurer';

import http, { onBlobExport, downloadBlob } from '../../http';
import moment from 'moment';

const deviceTypeList = ['GPS', 'DMS', 'ADAS', 'BSD', 'AEB'];
const VehicleInformation = () => {
    const { t } = useTranslation();
    const { tableData, pagination, loading, setQuery, handleTableChange } = useTable(getData);

    const { dealList } = useDealList();
    const { insuranceList } = useInsuranceList();

    const { form, formProps, colProps } = useVerticalForm();
    const handleSearch = (e) => {
        const values = form.getFieldsValue();
        setQuery(values);
    }
    const beforeSetFields = values => {
        return {
            ...values,
            dateDeviceInstallation: moment(values.dateDeviceInstallation),
            deviceType: values.deviceType ? values.deviceType.split('+'): []
        }
    }
    const transformBeforeSubmit  = values => {
        return {
            ...values,
            dateDeviceInstallation: values.dateDeviceInstallation.format('YYYY-MM-DD'),
            deviceType: values.deviceType.join('+')

        }
    }
    const {
        onEdit,
        onEditOk,
        onEditCancel,
        editForm,
        editVisible
    } = useEdit('/system-setting/vehicle/update', {afterOk: handleSearch, beforeSetFields, transformBeforeSubmit});

    function getData(param) {
        return http.get('/system-setting/vehicle/search', {
            orderBy: 'updatedAt',
            up: 0,
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            ...param
        })
    }
    const fieldsColumns = [
        { title: t("fields.deal"), dataIndex: 'deal' },
        { title: t("fields.vehicleId"), dataIndex: 'id', hide: true },
        { title: t("fields.region"), dataIndex: 'region' },
        { title: t("fields.plateNumber"), dataIndex: 'plateNumber' },
        { title: t("fields.usedPlateNumbers"), dataIndex: 'usedPlateNumbers' },
        { title: t("fields.carLicense"), dataIndex: 'carLicense', width: 200, hide: true },
        { title: t("fields.plateColor"), dataIndex: 'plateColor' },
        { title: t("fields.vin"), dataIndex: 'vin' },
        { title: t("fields.deviceId"), dataIndex: 'deviceID' },
        { title: t("fields.installDate"), dataIndex: 'dateDeviceInstallation' },
        { title: t("fields.deviceType"), dataIndex: 'deviceType' },
        { title: t("fields.deviceManufacture"), dataIndex: 'deviceManufacture', hide: true },
        { title: t("fields.deviceNo"), dataIndex: 'deviceNo', hide: true}
    ]
    const commonColumns = [
        {
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: time => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            width: 120,
            hide: true
        },
        {
            title: t('common.createdBy'),
            dataIndex: 'createdBy',
            hide: true
        },
        {
            title: t('common.editAt'),
            dataIndex: 'updatedAt',
            render: time => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            sorter: true,
            width: 120
        },
        {
            title: t('common.editBy'),
            dataIndex: 'updatedBy',
        },
    ]
    const [columns, setColumns] = useState([
        ...fieldsColumns,
        ...commonColumns,
        {
            title: t("common.action"),
            fixed: 'right',
            width: 180,
            hide: true,
            render: item => <div>
                <Button icon={<DeleteOutlined />} style={{marginRight: '8px'}} onClick={() => confirmDelete(item)}>{t('common.delete')}</Button>
                <Button icon={<SettingOutlined />} onClick={() => onEdit(item)}>{t('common.edit')}</Button>
            </div>,
        },
    ]);
    const confirmDelete = (item) => {
        Modal.confirm({
            title: t('common.confirm'),
            content: `${t('common.confirmDelete')} [${item.plateNumber}] ?`,
            cancelButtonProps: {
                type: 'default',
                icon: <CloseCircleOutlined />
              },
              okButtonProps: {
                type: 'default',
                icon: <CheckCircleOutlined />
              },
            onOk() {
                return http.delete(`/system-setting/vehicle/delete?vid=${item.id}`).then(handleSearch);
            }
        });
    }
    const handleExport = e => {
        const values = form.getFieldsValue();
        onBlobExport('/system-setting/vehicle/export', null, { method: 'post', params: values }).then(res => {
            downloadBlob(res.data, 'vehicles.xlsx')
        });
    }
    return <div className="container">
        <Form {...formProps} form={form} name="basic">
            <Row align="bottom">
                <Col {...colProps}>
                    <Form.Item label={t('fields.plateNumber')} name="plateNumber">
                        <Input />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.vin')} name="vin">
                        <Input  />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.deal')} name="tenantId">
                        <Select options={dealList} allowClear/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.insurer')} name="insurerId">
                        <Select options={insuranceList} allowClear/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Button icon={<SearchOutlined />}  onClick={handleSearch}>{t('common.search')}</Button>
                    <Button style={{ marginLeft: '16px' }} icon={<DownloadOutlined />} onClick={handleExport}>{t('common.export')}</Button>
                </Col>
            </Row>
        </Form>
        <Row justify="space-between" align="middle">
            <FileUpload
                url="/system-setting/vehicle"
                template={{
                    fileName: "vehicle-template.xlsx"
                }}
                onSubmit={handleSearch}
                columns={fieldsColumns}
                tableProps={{scroll:{ x: 1200 }}}
            />
            <TableColumnFilter columns={columns} onSetColumns={setColumns} />
        </Row>
        <Table
            size="small"
            scroll={{x: columns.filter(column => !column.hide).length * 150}}
            loading={loading}
            pagination={pagination}
            onChange={handleTableChange}
            rowKey="id"
            columns={columns.filter(column => !column.hide)}
            dataSource={tableData} />
        <Modal
            width={600}
            title="VehicleInfo"
            visible={editVisible}
            onOk={onEditOk}
            onCancel={onEditCancel}
            footer={[
                <Button key="back" icon={<CloseCircleOutlined />} onClick={onEditCancel}>
                  {t('common.cancel')}
                </Button>,
                <Button key="submit" icon={<CheckCircleOutlined />} onClick={onEditOk}>
                  {t('common.ok')}
                </Button>
              ]}
            destroyOnClose
        >
            <Form
                form={editForm}
                name="basic"
                layout="vertical">
                <Form.Item noStyle name="id"></Form.Item>
                <Form.Item label={t('fields.deal')} name="deal">
                    <Select allowClear>
                        {
                            dealList.map(deal => {
                                return <Select.Option key={deal.label} value={deal.label}>{deal.label}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label={t('fields.plateNumber')}
                    name="plateNumber"
                    rules={[{ required: true, message: t('common.pleaseInput') + t('fields.plateNumber') }]}
                ><Input/></Form.Item>
                <Form.Item
                    label={t('fields.usedPlateNumbers')}
                    name="usedPlateNumbers"
                >
                    <Input></Input>
                    {/* <Select mode="tags" style={{ width: '100%' }} tokenSeparators={[',']}></Select> */}
                </Form.Item>
                <Form.Item label={t('fields.carLicense')} name="carLicense">
                    <Input disabled/>
                </Form.Item>
                <Form.Item label={t('fields.region')} name="region" >
                    <Input /> 
                </Form.Item>
                <Form.Item label={t('fields.vin')} name="vin" >
                    <Input /> 
                </Form.Item>
                <Form.Item label={t('fields.plateColor')} name="plateColor" >
                    <Input /> 
                </Form.Item>
                <Form.Item label={t('fields.deviceId')} name="deviceID" >
                    <Input /> 
                </Form.Item>
                <Form.Item label={t('fields.deviceType')} name="deviceType" >
                    <Select allowClear mode="multiple">
                        {
                            deviceTypeList.map(type => <Select.Option key={type} value={type}>{type}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={t('fields.installDate')} name="dateDeviceInstallation" >
                    <DatePicker style={{width: '100%'}} /> 
                </Form.Item>
                <Form.Item label={t('fields.deviceManufacture')} name="deviceManufacture" >
                    <Input /> 
                </Form.Item>
                <Form.Item label={t('fields.deviceNo')} name="deviceNo" >
                    <Input /> 
                </Form.Item>
            </Form>
        </Modal>
    </div>
}
export default VehicleInformation;