import { Button, Form, Input, message} from 'antd';
import { useState,useEffect } from 'react';
import {  CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import http from '../../http';

import { useTranslation } from 'react-i18next'

export default function Index() {
    const { t } = useTranslation();
    const param = window.location.search?.replace("?", "")?.split('&');
    const key = param?.[0]?.split('=')?.[1]
    const [expire, setExpire] = useState(null);
    useEffect(() => {
        const days = param?.[1]?.split('=')?.[1]
        setExpire(days)
    }, []);
    const setStatus = (status) => {
        if (key) {
            http.get(`/aaa/User/valid?key=${key}&status=${status}`).then(res => {
                message.success('successful');
            })
        }
    }

    return <div className="container" style={{ width: '600px', margin:'0 auto'}}>
        <Form >
            <div style={{ marginBottom: '20px', fontSize: '18px', color: '#627D77' }} >{t('userVaild.whetherToUse')}</div>
            <Form.Item>
                <Input value={t('userVaild.whetherToExtend1')+expire+t('userVaild.whetherToExtend2')} disabled />
            </Form.Item>
            <Form.Item >
                <Button icon={<CheckCircleOutlined />} style={{ marginRight: '10px' }} onClick={() => setStatus(0)}>{t('userVaild.continueToUse')}</Button>
                <Button icon={<CloseCircleOutlined />} onClick={() => setStatus(-1)}>{t('userVaild.outOfUse')}</Button>
            </Form.Item>
        </Form>
    </div>
}
