import { useState } from 'react';
import { Form } from 'antd';
import http from '../http';

export const useEdit = (url, {beforeSetFields, transformBeforeSubmit, afterOk}) => {
    const [editForm] = Form.useForm();
    const [editVisible, setEditVisible] = useState(false);
    const onEdit = item => {
        editForm.setFieldsValue(beforeSetFields ? beforeSetFields(item) : item);
        setEditVisible(true)
    };
    const onEditCancel = () => {
        setEditVisible(false)
    }
    const onEditOk = () => {
        const params = transformBeforeSubmit ? transformBeforeSubmit(editForm.getFieldsValue()): editForm.getFieldsValue();
        return http.post(url, params).finally(() => {
            afterOk();
            setEditVisible(false);
        })
    }
    return {
        editForm,
        editVisible,
        onEdit,
        onEditCancel,
        onEditOk
    }
}