import { Form, Input, Row, Col, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next'
import style from './components.module.css'
import http from '../../../http';
import { intersectionBy } from 'lodash'
import { useEffect, useState } from 'react';
import PageLossCorrelationChart from './FromPlatformSerchParams/PageLossCorrelationChart'
import PageLossPivotTable from './FromPlatformSerchParams/PageLossPivotTable'
import PageFeatureAnalysis from './FromPlatformSerchParams/PageFeatureAnalysis'
import PageVehicleRisk from './FromPlatformSerchParams/PageVehicleRisk'
import PageDataCollection from './FromPlatformSerchParams/PageDataCollection'
import PageDataQuality from './FromPlatformSerchParams/PageDataQuality'
import PageDataInsightByType from './FromPlatformSerchParams/PageDataInsightByType'
import PageAccidentInquire from './FromPlatformSerchParams/PageAccidentInquire'
import PageDataInsightByGroup from './FromPlatformSerchParams/PageDataInsightByGroup'
import PageDataInsightCorrelationMatrix from './FromPlatformSerchParams/PageDataInsightCorrelationMatrix'
import PagePartnerEvaluation from './FromPlatformSerchParams/PagePartnerEvaluation'
import PageDealEvaluation from './FromPlatformSerchParams/PageDealEvaluation'
import PageTripQuality from './FromPlatformSerchParams/PageTripQuality'
import PageClaimReport from './FromPlatformSerchParams/PageClaimReport'

const { Option } = Select;

const FunctionPage = [{
  value: "1",
  label: "DataCollection"
}, {
  value: "2",
  label: "DataQuality"
}, {
  value: "3",
  label: "FeaturAnalysisByType"
}, {
  value: "4",
  label: "FeatureAnalysisByGroup"
}, {
  value: "5",
  label: "CorrelationMatrix"
}, {
  value: "6",
  label: "AccidentInquire"
}, {
  value: "7",
  label: "ClaimReport"
}, {
  value: "8",
  label: "PartnerEvaluation"
}, {
  value: "9",
  label: "DealEvaluation"
}, {
  value: "10",
  label: "Trip"
}, {
  value: "11",
  label: "PortfolioSummary"
}, {
  value: "12",
  label: "LossPivotTable"
}, {
  value: "13",
  label: "LossCorrelationChart"
}, {
  value: "14",
  label: "RiskChart"
}, {
  value: "15",
  label: "VehicleDistribution"
}, {
  value: "16",
  label: "VehicleRiskFeatureDetail"
}, {
  value: "17",
  label: "One-way"
}]



export default function FromPlatform ({ Section, SectionJS }) {

  const [filFunctionPage, setFilFunctionPage] = useState([])
  const [fPageLoading, setFPageLoading] = useState(true)

  const getDetails = async () => {
    setFPageLoading(true)
    let res = await http.get(`/recommendationapi/Recommendation/GetPage`)
    const filterData = intersectionBy(FunctionPage, res.Data, 'value')
    setFPageLoading(false)
    setFilFunctionPage(filterData)
  }
  
  useEffect(() => {
    getDetails()
  }, [])

  const { t } = useTranslation();

  return <div className={style.box}>
    <Row justify="space-between">
      <Col span={11}>
        <Form.Item
          name={[Section.name, 'strChartTtile']}
          label={t('Recommendation.ChartTtile')}
          rules={[{ required: true }]}
        >
          <Input placeholder="example" />
        </Form.Item>
      </Col>
      <Col span={11}>
        <Spin spinning={fPageLoading}>
          <Form.Item
            name={[Section.name, 'strFunctionPage']}
            label={t('Recommendation.FunctionPage')}
            rules={[{ required: true }]}
          >
            <Select onChange={(e)=>{SectionJS.changeFunctionPage(e)}}>
              {filFunctionPage.map(e => <Option key={e.value} value={e.value}>{t(`FunctionPage.${e.label}`)}</Option>)}
            </Select>
          </Form.Item>
        </Spin>
      </Col>
    </Row>
    {SectionJS.strFunctionPage === '1' && <PageDataCollection Section={Section} SectionJS={SectionJS}/> }
    {SectionJS.strFunctionPage === '2' && <PageDataQuality Section={Section} SectionJS={SectionJS}/> }
    {SectionJS.strFunctionPage === '3' && <PageDataInsightByType Section={Section} SectionJS={SectionJS}/> }
    {SectionJS.strFunctionPage === '4' && <PageDataInsightByGroup Section={Section} SectionJS={SectionJS}/> }
    {SectionJS.strFunctionPage === '5' && <PageDataInsightCorrelationMatrix Section={Section} SectionJS={SectionJS}/> }
    {SectionJS.strFunctionPage === '6' && <PageAccidentInquire Section={Section} SectionJS={SectionJS}/> }
    {SectionJS.strFunctionPage === '7' && <PageClaimReport Section={Section} SectionJS={SectionJS}/> }
    {SectionJS.strFunctionPage === '8' && <PagePartnerEvaluation Section={Section} SectionJS={SectionJS}/> }
    {SectionJS.strFunctionPage === '9' && <PageDealEvaluation Section={Section} SectionJS={SectionJS}/> }
    {SectionJS.strFunctionPage === '10' && <PageTripQuality Section={Section} SectionJS={SectionJS}/> }
    {SectionJS.strFunctionPage === '12' && <PageLossPivotTable Section={Section} SectionJS={SectionJS}/> }
    {SectionJS.strFunctionPage === '13' && <PageLossCorrelationChart Section={Section} SectionJS={SectionJS}/> }
    {(SectionJS.strFunctionPage === '14' || SectionJS.strFunctionPage === '15' || SectionJS.strFunctionPage === '16') && <PageVehicleRisk Section={Section} SectionJS={SectionJS}/> }
    {SectionJS.strFunctionPage === '17' && <PageFeatureAnalysis Section={Section} SectionJS={SectionJS}/> }
  </div>
}