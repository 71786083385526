import { Table, Button, Modal, Form, Row, Col, Input } from 'antd';
import { SearchOutlined, PlusOutlined, DeleteOutlined, SettingOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import http from '../../http';
import { useVerticalForm } from '../../hooks/form';
import { useTranslation } from 'react-i18next'
import { useTable } from '../../hooks/table';
import moment from 'moment';

export default function Index() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { form: queryForm, formProps, colProps } = useVerticalForm();
  const { tableData, onSearch, pagination, loading, setQuery } = useTable(getData);

  function getData(param) {
    return http.get('/aaa/User', {
      Page: pagination.current,
      PageSize: pagination.pageSize,
      Sorting: 'CreatedAt',
      IsDesc: true,
      ...param
    })
  }
  const handleSearch = (e) => {
    const values = queryForm.getFieldsValue();
    setQuery(values);
  }
  const columns = [
    {
      title: '#',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: t("user.displayName"),
      dataIndex: 'displayName',
    },
    {
      title: t("user.login"),
      dataIndex: 'login',
    },
    {
      title: t("user.email"),
      dataIndex: 'email',
    },
    {
      title: t("user.role"),
      dataIndex: 'role',
      render: role => role?.name,
    },
    {
      title: t('common.createdBy'),
      key: 'createdBy',
      dataIndex: 'createdBy',
    },
    {
      title: t('common.createdAt'),
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: text => <span>{text ? moment(text).format('YYYY-MM-DD') : text} </span>
    },
    {
      title: t('common.editBy'),
      key: 'editBy',
      dataIndex: 'editBy',
    },
    {
      title: t('account.editDate'),
      key: 'editAt',
      dataIndex: 'editAt',
      render: text => <span>{text ? moment(text).format('YYYY-MM-DD') : text} </span>
    },
    {
      title: t("common.action"),
      key: 'delete',
      render: item =>
        <div>
          <Button icon={<DeleteOutlined />} style={{ marginRight: '10px' }} onClick={() => confirmDelete(item.id, item.displayName)}>{t('common.delete')}</Button>
          <Button icon={<SettingOutlined />} onClick={() => history.push(`/aaa/user-management/edit/${item.id}`)}>{t('common.edit')}</Button>
        </div>

    },
  ];
  const history = useHistory();
  const confirmDelete = (id, name) => {
    Modal.confirm({
      title: t('common.confirm'),
      content: `${t('common.confirmDelete')} ${t('user.user')} [${name}] ?`,
      cancelButtonProps: {
        type: 'default',
        icon: <CloseCircleOutlined />
      },
      okButtonProps: {
        type: 'default',
        icon: <CheckCircleOutlined />
      },
      onOk() {
        return http.delete(`/aaa/User/${id}`).then(onSearch);
      }
    });
  }
  return <div className="container" style={{ paddingBottom: '50px' }}>
    <Form {...formProps} form={queryForm} name="basic">
      <Row align="bottom">
        <Col {...colProps}>
          <Form.Item label={t('privacy.user')} name="name">
            <Input />
          </Form.Item>
        </Col>
        <Col {...colProps} style={{ marginBottom: '24px' }}>
          <Button icon={<SearchOutlined />} style={{ marginLeft: '32px' }} onClick={handleSearch}>{t('common.search')}</Button>
        </Col>
      </Row>
    </Form>
    <div style={{ marginBottom: '20px' }}>
      <Button icon={<PlusOutlined />}><Link to="/aaa/user-management/add"> {t('common.create')}</Link></Button>
    </div>
    <Table
      loading={loading}
      pagination={pagination}
      rowKey="id"
      columns={columns}
      dataSource={tableData} />
  </div>
}
