import { Upload, Button, message, Table } from 'antd';
import { useTranslation } from 'react-i18next'
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';


export default function DIY ({SectionJS}) {
  const { t } = useTranslation();
  const [columns, setColumns] = useState([])
  const [dataSource, setDataSource] = useState([])

  const props = {
    name: 'excelfile',
    maxCount: 1,
    action: '/recommendationapi/Recommendation/GetExcelJson',
    headers: {
      authorization: `Bearer ${window.localStorage.getItem('token')}`,
      'X-Application': window.localStorage.getItem('application') || 'fleet',
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        let list = info?.file?.response?.response?.data || []
        if(list[0]){
          let keys = Object.keys(list[0])
          let column = keys.map(e=>({
            title: e,
            dataIndex: e,
            key: e,
          }))
          setColumns(column)
          setDataSource(list)
          SectionJS.DIYTableData = list
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }else if(info.file.status === 'removed'){
        resetTable()
      }
    },
  };

  const resetTable = () => {
    setColumns([])
    setDataSource([])
  }

  return <div>
      <Upload {...props}>
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
    <div style={{marginTop: '20px'}}>
      <Table dataSource={dataSource}  scroll={{x: 1000}} columns={columns} />
    </div>
  </div>

}