import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useEffect, useState } from 'react';
import { Select, DatePicker, Row, Col, Form, Button } from 'antd';
import {DataCard} from '../../../components/DataCard/card';
import {DownloadOutlined, SearchOutlined} from '@ant-design/icons'

import moment from 'moment';

import styles from '../index.module.css';
import {useTranslation} from 'react-i18next'
import http, {onBlobExport, downloadBlob} from '../../../http';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

const formatPercent = num => {
    if (!num) return '-';
    return (num * 100).toFixed(2) + '%';
}
const disabledDate = current => current && current > moment().endOf('day');

const DataQuality = () => {
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState({});
    const [form] = Form.useForm();
    const [chartData, setChartData] = useState(null)
    const { t } = useTranslation();
    useEffect(() => {
        form.submit();
        http.get('/tmx-dashboard/tmx/quality/summary').then(res => {
            setSummary(res);
        })
    }, []) 
    useEffect(() => {
        chartData && setChart();
    }, [chartData])
    const setChart = () => {
        var chartDom = document.getElementById('main');
        echarts.init(chartDom).dispose();
        var myChart = echarts.init(chartDom);
        var option;

        option = {
            color: ['#1455B4', '#91C8FF', '#006E73', '#73E1A5', '#9B14B4', '#FA8CFF'],
            // title: {
            //     text: 'Line'
            // },
            tooltip: {
                trigger: 'axis',
                formatter(val) {
                    return val[0].axisValue + '<br />' + val.map(item => `
                    <div style="display: flex; justify-content: space-between; align-items: center">
                        <span>${item.marker}${item.seriesName}</span>
                        <span style="margin-left: 20px">${Math.floor(item.value * 10000) / 100 + "%"}</span>
                    </div>
                    `).join('')
                }
            },
            legend: {
                icon: 'rect',
                itemWidth: 14,
                orient: 'vertical',
                right: 0,
                top: 'center',
                bottom: 20,
                textStyle: {
                    width: 150,
                    overflow: 'break'
                }
            },
            grid: {
                show: true,
                left: '3%',
                right: 190,
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: chartData.dims.map(one => one.slice(0, 10))
            },
            yAxis: {
                scale: true,
                type: 'value',
                axisLabel: {
                    formatter: function (val) {
                        return (val) * 100 + '%';
                    }
                }
            },
            series: Object.keys(chartData.measures).map(key => {
                return {
                    name: key,
                    type: 'line',
                    data: chartData.measures[key],
                    smooth: false
                }
            })
        }
        option && myChart.setOption(option);
    }
    const formatFieldValue = values => {
        return {
            valueType: values.valueType,
            startTimestamp: values.date[0].startOf('day').valueOf(),
            endTimestamp: values.date[1].endOf('day').valueOf()
        }
    }
    const onSubmit = values => {
        setLoading(true)
        http.get('/tmx-dashboard/tmx/quality/tendency', formatFieldValue(values)).then(res => {
            setChartData(res);
        }).finally(() => setLoading(false))
    }
    function onExport(url) {
        onBlobExport('/tmx-dashboard/tmx/quality/tendency/download', formatFieldValue(form.getFieldsValue())).then(res => {
            downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*)/)[1])
        });
    }
    return  <div className="data-quality">
        <div className={styles.title}>{t('tmx.dataQuality')}</div>
        <span className="text">{t('tmx.dataSummary')}</span>
        <Row style={{marginTop: "8px", marginBottom: "24px"}}>
            <Col span={6}><DataCard title={t('tmx.activeRateIn7days')}  num={formatPercent(summary.activeVehicleRate7Days)}></DataCard></Col>
            <Col span={6}><DataCard title={t('tmx.activeRateIn30days')} num={formatPercent(summary.activeVehicleRate30Days)}></DataCard></Col>
            <Col span={6}><DataCard title={t('tmx.deviceNormalRate')} num={formatPercent(summary.deviceNormalRateTotal)}></DataCard></Col>
            <Col span={6}><DataCard title={t('tmx.tripCorrectRate')} num={formatPercent(summary.tripCorrectRateTotal)}></DataCard></Col>
        </Row>
        <div className={styles.title}>{t('tmx.tendency')}</div>
        <div>
            <Form
                layout="vertical"
                initialValues={{
                    date: [moment().subtract(30, 'days').startOf('day'), moment().endOf('day')],
                    valueType: 'TRIP_CORRECT_RATE',
                }}
                onFinish={onSubmit}
                form={form}
            >
                <Row gutter={24} align="bottom">
                    <Col>
                        <Form.Item label={t('common.date')} name="date" rules={[{ type: 'array', required: true, message: t('common.pleaseSelect') + t('common.date') }]}><DatePicker.RangePicker disabledDate={disabledDate} allowClear={false}/></Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label={t('tmx.valueType')} name="valueType" rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.valueType') }]}>
                            <Select>
                                <Select.Option value="TRIP_CORRECT_RATE">{t('tmx.vehicleTripCorrectRate')}</Select.Option>
                                <Select.Option value="VEHICLE_ACTIVE_RATE">{t('tmx.vehicleActiveRate')}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{marginBottom: '24px'}}>
                        <Button icon={<SearchOutlined />}  loading={loading} style={{marginLeft: '8px'}} htmlType="submit">{t('common.search')}</Button>
                        <Button onClick={() => onExport()} style={{ marginLeft: '8px' }} icon={<DownloadOutlined />}>{t('common.export')}</Button>
                    </Col>
                </Row>
                
            
            </Form>
        </div>
        <div id="main" style={{height: 600, width: '100%'}}></div>
    </div>
}

export default DataQuality;