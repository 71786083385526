
import * as echarts from 'echarts/core';
import {
    TooltipComponent,
    GridComponent,
    VisualMapComponent
} from 'echarts/components';
import { HeatmapChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { MinusCircleOutlined, PlusOutlined,DownloadOutlined,SearchOutlined } from '@ant-design/icons';

echarts.use([
    TooltipComponent,
    GridComponent,
    VisualMapComponent,
    HeatmapChart,
    CanvasRenderer
]);
import { useEffect, useState } from 'react';

import { Form, Input, Button, Checkbox, Select, Row, Col } from 'antd';
import http, {onBlobExport, downloadBlob} from '../../../http';

const { Option } = Select;
import { useTranslation } from 'react-i18next'
const groupOptions = ['ALL', 'DEAL', 'INSURANCE', 'REGION', 'VENDOR'];

const CorrelationMatrix = props => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [initialData, setInitialData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const DATASOURCE = {
        DEAL: props.dataSource.dealList,  
        INSURANCE:  props.dataSource.insuranceList,
        REGION: props.dataSource.regionList,
        VENDOR: props.dataSource.vendorList,
    }
    useEffect(() => {
        form.submit();
    }, [])
    useEffect(() => {
        chartData && setChart()
    }, [chartData])
    const setChart = () => {
        var chartDom = document.getElementById('correlationChart');
        echarts.init(chartDom).dispose();
        var myChart = echarts.init(chartDom);
        // prettier-ignore
        const data = chartData.relatedCoefficientModels.map(one => {
            return [t('tmx.options.' + one.param1), t('tmx.options.' + one.param2), one.value.toFixed(2)]
        })
        var option;
        option = {
            // color: ['rgba(98, 125, 119, 0.75)', 'rgba(98, 125, 119, 0.5)'],
            tooltip: {
                position: 'top',
                formatter: '{c}'
            },
            grid: {
                show: false,
                left: '210px',
                height: '80%',
                top: '0%',
            },
            xAxis: {
                type: 'category',
                data: Array.from(new Set(chartData.relatedCoefficientModels.map(one => t('tmx.options.' + one.param1)))),    
                axisTick: {
                    interval: 0
                },
                axisLabel: {
                    interval: 0,
                    rotate: -25
                }
            },
            yAxis: {
                z: 2,
                axisTick: {
                    show: true
                },
                type: 'category',
                data: Array.from(new Set(chartData.relatedCoefficientModels.map(one => t('tmx.options.' + one.param2)))),
            },
            visualMap: {
                type: 'continuous',
                min: -1,
                max: 1,
                calculable: false,
                orient: 'horizontal',
                left: 'center',
                inRange: {
                    color: ['#9B14B4', '#EEEEEE', '#1455B4'],
                }
            },
            series: [
                {
                    type: 'heatmap',
                    data: data,
                    label: {
                        show: true
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };

        option && myChart.setOption(option);
    }
    const onFinish = values => {
        if (values.group === 'ALL') {
            values.groupName = '';
        }
        setLoading(true);
        http.get('/tmx-dashboard/insight/relatedcoefficient', values).then(res => {
            const data = res.relatedCoefficientModels.sort((a, b) =>  a.param1 < b.param1 ? 1 : -1).sort((a, b) =>  a.param2 > b.param2 ? 1 : -1)
            setChartData({...res, relatedCoefficientModels: data});
            setInitialData({...res, relatedCoefficientModels: data});
        }).finally(() => setLoading(false))
    };
    const onExport = () => {
        form.validateFields().then(values => {
            values.groupName = encodeURI(encodeURI(values.groupName))
            onBlobExport('/tmx-dashboard/insight/relatedcoefficient/download', values).then(res => {
                downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*)/)[1])
            });
        })
    }
    const onFilterChange = values => {
        const copy = {
            ...initialData,
            relatedCoefficientModels: initialData.relatedCoefficientModels.filter(item => {
                return values.includes(item.param1) && values.includes(item.param2);
            })
        };
        setChartData(copy)
    }
    const onGroupChange = (val) => {
        const params = form.getFieldsValue(); 
        params.groupName = undefined;
        form.setFieldsValue(params)
    }
    return (
        <div>
            <Form form={form} onFinish={onFinish} autoComplete="off" layout="vertical" initialValues={{group: "ALL" }}>
                <Row align="middle" gutter={16}>
                    <Col span={8}>
                        <Form.Item label={t('tmx.group')} name="group" rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.group') }]}>
                            <Select onChange={onGroupChange}>{groupOptions.map(item => <Option value={item} key={item}>{t('tmx.options.' + item)}</Option>)}</Select>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                return getFieldValue('group') !=='ALL' ? <Form.Item 
                                        label={t(`tmx.${getFieldValue('group').toLowerCase()}`)}
                                        name="groupName"
                                        rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.deal') }]}>
                                        <Select options={DATASOURCE[getFieldValue('group')]}></Select>
                                     </Form.Item>  : null
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Button icon={<SearchOutlined />}  style={{marginRight: '8px'}} loading={loading}  htmlType="submit">{t('common.search')}</Button>
                        <Button icon={<DownloadOutlined />}onClick={onExport} >{t('common.export')}</Button>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col flex="870px"><div id="correlationChart" style={{ height: 600, width: 870 }}></div></Col>
                <Col flex="auto" style={{width: 0}}>
                {
                    initialData && <Checkbox.Group onChange={onFilterChange} defaultValue={Array.from(new Set(initialData.relatedCoefficientModels.map(one => one.param1)))}>
                    {
                        Array.from(new Set(initialData.relatedCoefficientModels.map(one => one.param1))).map(key => {
                            return <Row key={key}><Checkbox value={key} >{t('tmx.options.' + key)}</Checkbox></Row>
                        })
                    }
                    </Checkbox.Group>
                }
        
                </Col>
            </Row>
        </div>
    );
};
export default CorrelationMatrix;