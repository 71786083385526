import { useEffect } from 'react';
import * as echarts from 'echarts/core';
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart, LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useTranslation } from 'react-i18next'

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

const DatabaseChart = ({ chartList, selectParams }) => {
  console.log('chartList', chartList)
  const { t } = useTranslation();

  function initChartRender (list) {
    const { xAxis, count } = list.reduce((p, v) => {
      p.xAxis.push(v.Feature)
      p.count.push(v.count)
      return p
    }, { xAxis: [], count: [] })
    const chartDom = document.getElementById('main');
    const myChart = echarts.init(chartDom);
    const colors = ['#627d77'];
    const option = {
      color: colors,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      grid: {
        right: '20%'
      },
      legend: {
        data: [t(`OnewayRiskFeature.${selectParams.strFeature}`)]
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          axisLabel:{
            rotate: 15
          },
          // prettier-ignore
          data: xAxis
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: t(`OnewayRiskFeature.${selectParams.strFeature}`),
          min: 0,
          position: 'left',
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[0]
            }
          }
        },
      ],
      series: [
        {
          name: t(`OnewayRiskFeature.${selectParams.strFeature}`),
          type: 'bar',
          data: count
        }
      ]
    };
    myChart.setOption(option);
  }

  if (chartList.length) {
    initChartRender(chartList)
  }

  return <div id="main" style={{ height: 600, width: 1200, margin: '0 auto' }}></div>
}

export default DatabaseChart;