import { useState } from "react";
import { Dropdown, Menu } from 'antd';
import { useEffect } from "react";
import { DownOutlined } from '@ant-design/icons';
import i18n from '../../locales/i18n';

const AppMenu = (props) => {
    const { applications, app, activeApp } = props;
    const local = localStorage.getItem('application');
    const onClick = ({ key }) => {
        localStorage.setItem('application', key);
        window.location.href = '/';
    }
    const menu = (
        <Menu onClick={onClick}>
            {
                applications.map(item => <Menu.Item key={item.name}>{i18n.t(`app.${item.name}`)}</Menu.Item>)
            }
        </Menu>
    );
    return (
        applications.length > 1 ?
            <Dropdown overlay={menu}>
                <a className="ant-dropdown-link" style={{ fontSize: '16px', marginRight: '10px', color: '#000' }} onClick={e => e.preventDefault()}>{i18n.t(`app.${local}`)}<DownOutlined /></a>
            </Dropdown>
            : <span>{i18n.t(`app.${local}`)}</span>
    );

}
export default AppMenu;