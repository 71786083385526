import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react';
import http from '../../../../http';

const { Option } = Select;

const getFeatureList = () => http.get('/tmx-dashboard/insight/features');

export default function PageDataInsightByGroup ({ Section, SectionJS }) {
  const { t } = useTranslation();

  const [featureList, setFeatureList] = useState([]);
  useEffect(() => {
    getFeatureList().then(res => {
      setFeatureList(res)
    })
  }, [])

  return <div>
    <Form.Item
      label={t('tmx.feature')}
      name={[Section.name, 'serchParams', 'feature']}
      rules={[{ required: true, message: t('common.pleaseSelect') + t('tmx.feature') }]}
    >
      <Select>
        {featureList.map(item => <Option value={item} key={item}>{t('tmx.options.' + item)}</Option>)}
      </Select>
    </Form.Item>
  </div>
}