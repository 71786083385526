import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'; 

import en from './en-us.json';
import cn from './zh-cn.json';

const resources = {
    en: {
      translation: en
    },
    cn: {
      translation: cn
    }
  };

if (!localStorage.language) {
  localStorage.setItem('language', 'en')
}
i18n.use(initReactI18next) 
    .init({
        resources,
        lng: localStorage.language || 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, 
        }
    });


export default i18n;