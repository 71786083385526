import { Upload, Button, message, Table } from 'antd';
import { useTranslation } from 'react-i18next'
import { UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';


export default function DIY ({serchParams}) {
  const { t } = useTranslation();
  const [columns, setColumns] = useState([])
  const [dataSource, setDataSource] = useState([])

  const init = (list) => {
    if(list[0]){
      let keys = Object.keys(list[0])
      let column = keys.map(e=>({
        title: e,
        dataIndex: e,
        key: e,
      }))
      setColumns(column)
      setDataSource(list)
    }
  }

  useEffect(()=>{
    init(serchParams.DIYTableData)
  },[serchParams])

  return <div>
      <div style={{marginTop: '20px'}}>
        <Table dataSource={dataSource}  scroll={{x: 1000}} columns={columns} />
      </div>
  </div>
}