import { useEffect, useState } from 'react';
import { Slider } from 'antd';
import { useTranslation } from 'react-i18next'
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  GridComponent,
  LegendComponent,
  VisualMapComponent
} from 'echarts/components';
import { ScatterChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { sortBy, intersectionBy, clone } from 'lodash'

echarts.use([
  TitleComponent,
  GridComponent,
  VisualMapComponent,
  LegendComponent,
  ScatterChart,
  CanvasRenderer,
  UniversalTransition
]);


let chartData = [];
let AnnualMileageMax = []  // AnnualMileage升序数组
let AnnualDurationMax = [] // AnnualDuration 升序数组
let WaringPer100kmMax = [] // WaringPer100km升序数组
let SwissReScoreMax = [] // SwissReScore升序数组
let selectMileage = []
let selectDuration = []
let selectPer100km = []
let selectReScore = []
let DT = null

const BubbleChart = ({ chartList = [], groupUUID}) => {
  const { t } = useTranslation();
  const uid = groupUUID || 0

  const initChartData = (list) => {
    chartData = []
    AnnualMileageMax = sortBy(list, [function(o){return Number(o.AnnualMileage)}])
    AnnualDurationMax = sortBy(list, [function(o){return Number(o.AnnualDuration)}])
    WaringPer100kmMax = sortBy(list, [function(o){return Number(o.WaringPer100km)}])
    SwissReScoreMax = sortBy(list, [function(o){return Number(o.SwissReScore)}])

    let lg = AnnualMileageMax.length
    selectMileage = AnnualMileageMax.slice((5 / 100) * lg, (95 / 100) * lg)
    selectDuration = AnnualDurationMax.slice((5 / 100) * lg, (95 / 100) * lg)
    selectPer100km = clone(WaringPer100kmMax)
    selectReScore = clone(SwissReScoreMax)

    serchChartData()
  }

  const serchChartData = () => {
    const list = intersectionBy(selectMileage, selectDuration, selectPer100km, selectReScore, 'Key')
    chartData = []
    list.forEach(e => {
      if(e.WaringPer100km && (e.AnnualMileage || e.AnnualDuration || e.SwissReScore)){
        chartData.push([e.AnnualMileage, e.AnnualDuration, e.SwissReScore, e.WaringPer100km, e.VIN, e.PlateNumber])
      }
    })
    chartRender()
  }

  useEffect(() => {
    if (chartList && chartList.length) {
      initSelect()
      initChartData(chartList)
    } else {
      chartData = []
      chartRender()
    }
  }, [chartList])


  const schema = [
    { name: 'VIN', index: 1, text: 'VIN' },
    { name: 'AnnualMileage', index: 2, text: t('BubbleChart.AnnualMileage') },
    { name: 'AnnualDuration', index: 3, text: t('BubbleChart.AnnualDuration') },
    { name: 'WaringPer100km', index: 4, text: t('BubbleChart.WaringPer100km') },
    { name: 'SwissReScore', index: 5, text: t('BubbleChart.SwissReScore') }
  ];

  function chartRender () {
    const chartDom = document.getElementById('main'+uid);
    const myChart = echarts.init(chartDom);
    const data = chartData
    const option = {
      backgroundColor: new echarts.graphic.RadialGradient(0.3, 0.3, 0.8, [
        {
          offset: 0,
          color: '#fefefe'
        },
        {
          offset: 1,
          color: '#fefefe'
        }
      ]),
      grid: {
        left: '13%',
        top: '10%',
        x1: 150,
        x2: 150,
      },
      xAxis: {
        name: `${t('BubbleChart.AnnualMileage')} (${t('BubbleChart.km')})`,
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        }
      },
      yAxis: {
        name: `${t('BubbleChart.AnnualDuration')} (${t('BubbleChart.hour')})`,
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        },
        scale: true
      },
      tooltip: {
        backgroundColor: 'rgba(255,255,255,0.7)',
        formatter: function (param) {
          var value = param.value;
          if (param.color === "rgba(255,255,255,1)") {
            return ''
          }
          // prettier-ignore
          return '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
            + t('BubbleChart.plateNumber') + '：' + value[5]
            + '</div>'
            + schema[0].text + '：' + value[4] + '<br>'
            + schema[1].text + '：' + value[0] + '<br>'
            + schema[2].text + '：' + value[1] + '<br>'
            + schema[4].text + '：' + value[2] + '<br>'
            + schema[3].text + '：' + value[3] + '<br>'
        }
      },
      visualMap: [
        {
          left: 'right',
          bottom: '-5%',
          dimension: 2,
          min: 0,
          max: 100,
          itemWidth: 0,
          itemHeight: 0,
          calculable: false,
          inRange: {
            symbolSize: [20, 5]
          }
        },
        {
          left: 'right',
          bottom: '-5%',
          dimension: 3,
          min: 0,
          max: 100,
          itemWidth: 0,
          itemHeight: 0,
          calculable: false,
          inRange: {
            color: ['#91C8FF','#1455B4']
          }
        }
      ],
      series: [
        {
          name: '',
          type: 'scatter',
          symbolSize: function (data) {
            return data[2];
          },
          itemStyle: {
            opacity: 0.8,
            shadowBlur: 4,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
          data: data
        }
      ]
    };
    myChart.setOption(option);
  }


  const deb = (fn, time) => {
    if (DT) {
      clearTimeout(DT)
    }
    DT = setTimeout(fn, time)
  }

  const onChange1 = (e) => {
    setAM(e)
    deb(function () {
      let lg = AnnualMileageMax.length
      selectMileage = AnnualMileageMax.slice((e[0] / 100) * lg, (e[1] / 100) * lg)
      serchChartData()
    }, 300)
  }
  const onChange2 = (e) => {
    setAD(e)
    deb(function () {
      let lg = AnnualDurationMax.length
      selectDuration = AnnualDurationMax.slice((e[0] / 100) * lg, (e[1] / 100) * lg)
      serchChartData()
    }, 300)
  }
  const onChange3 = (e) => {
    setWP(e)
    deb(function () {
      let lg = WaringPer100kmMax.length
      selectPer100km = WaringPer100kmMax.slice((e[0] / 100) * lg, (e[1] / 100) * lg)
      serchChartData()
    }, 300)
  }

  const onChange4 = (e) => {
    setSR(e)
    deb(function () {
      let lg = SwissReScoreMax.length
      selectReScore = SwissReScoreMax.slice((e[0] / 100) * lg, (e[1] / 100) * lg)
      serchChartData()
    }, 300)
  }

  const marks = {
    0: '0%',
    25: '25%',
    75: '75%',
    100: '100%',
  };

  const initSelect = () => {
    setAM([5, 95])
    setAD([5, 95])
    setWP([0, 100])
    setSR([0, 100])
  }

  const [AM, setAM] = useState([5, 95])
  const [AD, setAD] = useState([5, 95])
  const [WP, setWP] = useState([0, 100])
  const [SR, setSR] = useState([0, 100])

  return <div style={{ margin: '0 auto', padding: '20px 10px', background: "#fff", display: 'flex', justifyContent: ' space-around' }}>
    <div id={`main${uid}`} style={{ width: 800, height: 600 }}></div>
    <div style={{ width: '300px', padding: '20px' }}>
      <div>{t('BubbleChart.AnnualMileage')}</div>
      <Slider range marks={marks} value={AM} onChange={onChange1} />
      <div>{t('BubbleChart.AnnualDuration')}</div>
      <Slider range marks={marks} value={AD} onChange={onChange2} />
      <div>{t('BubbleChart.WaringPer100km')}</div>
      <Slider range marks={marks} value={WP} onChange={onChange3} />
      <div>{t('BubbleChart.SwissReScore')}</div>
      <Slider range marks={marks} value={SR} onChange={onChange4} />
      <div style={{ paddingTop: '15px', lineHeight: '36px', fontSize: '18px' }}>
        <div>{t('BubbleChart.Radius')}: {t('BubbleChart.SwissReScore')}</div>
        <div>{t('BubbleChart.Color')}: {t('BubbleChart.WaringPer100km')}</div>
        <div style={{ display: 'flex', alignItems: 'center' }} >{t('BubbleChart.Low')} <div style={{ width: '100px', margin: '0 10px', height: '20px', background: 'linear-gradient(90deg, #91C8FF, #1455B4)' }}></div> {t('BubbleChart.High')}</div>
      </div>
    </div>
  </div>
}

export default BubbleChart;