import { Form, Select, Table, Button, message, Upload, Row, Col, Typography, Modal } from 'antd';
import http from '../../http';

import {useTranslation} from 'react-i18next'
import { useEffect, useState } from 'react';
import {DownloadOutlined, UploadOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import {generateQuarterOptions} from './select';
import {vehicleTable, insuranceTable, compareColumns, tableProps} from './table';

import './index.css';
const { Title } = Typography;

const quarterOptions = generateQuarterOptions();

export default function Bills() {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [tenantOptions, setTenantOptions] = useState([]);
    const [vendorBillsFileId, setVendorBillsFileId] = useState(null);
    const [insuranceBillsFileId, setInsuranceBillsFileId] = useState(null);
    const [vendorBillsData, setVendorBillsData] = useState({});
    const [insuranceBillsData, setInsuranceBillsData] = useState({});
    const [compareData, setCompareData] = useState({});
    const [checkVendorLoading, setCheckVendorLoading] = useState(false);
    const [checkInsuranceLoading, setCheckInsuranceLoading] = useState(false);
    function validate(arr) {
        return new Promise((resolve, reject) => {
            let ret = {};
            let result = arr.every(key => {
                if (['dealsId', 'vendorBillQuarter', 'insuranceBillQuarter'].includes(key)) {
                    ret[key] = form.getFieldValue(key);
                    !ret[key] && Modal.info({
                        title: t('common.info'),
                        content:  `${t('common.pleaseSelect')}${key}`
                    });
                    return ret[key];
                } else if (key === 'vendorBillsFileId'){
                    ret.vendorBillsFileId = vendorBillsFileId;
                    !vendorBillsFileId && Modal.info({
                        title: t('common.info'),
                        content:  `${t('common.pleaseUpload')}Vendor Bills`
                    });
                    return vendorBillsFileId;
                } else if (key === 'insuranceBillsFileId') {
                    ret.insuranceBillsFileId = insuranceBillsFileId;
                    !insuranceBillsFileId && Modal.info({
                        title: t('common.info'),
                        content:  `${t('common.pleaseUpload')}Insurance Company Bill`
                    });
                    return insuranceBillsFileId;
                }
            })
            return result ? resolve(ret): reject();
        })
    }
    function replaceConfirm(fn) {
        Modal.confirm({
            title: t('common.info'),
            icon: <ExclamationCircleOutlined />,
            content: t('tips.alreadyExsit'),
            onOk() {
                return fn();
            },
            onCancel() {
                message.success('已取消重新保存');
            }
        })
    }
    function checkResult(data) {
        let result = true;
        for (const key in data) {
            let arr = data[key];
            if (arr instanceof Array && arr.length > 0) {
                result = false;
                break;
            }
        }
        if (result) {
            Modal.success({
                title: t('common.success'),
                content:  'Excel符合规则'
            });
        }
        return result;
    }
    function onCheckVendor() {
        setVendorBillsData([]);
        setCheckVendorLoading(true);
        validate(['dealsId', 'vendorBillQuarter', 'vendorBillsFileId']).then(values => {
            return http.post(`/bill-mgm/vendorBills/check?id=${vendorBillsFileId}&dealsId=${values.dealsId}&quarter=${values.vendorBillQuarter}`);
        }).then(data => {
            !checkResult(data) && setVendorBillsData(data);
        }).finally(() => setCheckVendorLoading(false));
    }
    function onBeforeSaveVendor() {
        validate(['dealsId', 'vendorBillsFileId']).then(values => {
            return http.get(`/bill-mgm/vendorBills/checkBeforeSave?id=${vendorBillsFileId}&dealsId=${values.dealsId}&quarter=${form.getFieldValue('vendorBillQuarter')|| ''}`)
        }).then(res => {
            if (res) {
                replaceConfirm(saveVendorBills);
            } else {
                saveVendorBills();
            }
        })
    }
    function saveVendorBills() {
        return http.post(`/bill-mgm/vendorBills/save?id=${vendorBillsFileId}&dealsId=${form.getFieldValue('dealsId')|| ''}&quarter=${form.getFieldValue('vendorBillQuarter')|| ''}`).then(res => {
            message.success('save to database success');
        })
    }
    function onExport(url) {
        if (url) {
            window.open(url)
        } else {
            Modal.info({
                title: t('common.info'),
                content:  '无错误结果导出'
            });
        }
    }
    function onCheckInsurance() {
        setInsuranceBillsData([])
        setCheckInsuranceLoading(true);
        validate(['dealsId', 'insuranceBillsFileId', 'insuranceBillQuarter']).then(values => {
            return http.post(`/bill-mgm/insuranceBills/check?id=${insuranceBillsFileId}&dealsId=${values.dealsId}&quarter=${values.insuranceBillQuarter}`);
        }).then(data => {
            !checkResult(data) && setInsuranceBillsData(data)
        }).finally(() => setCheckInsuranceLoading(false));
    }
    function onBeforeSaveInsurance() {
        validate(['dealsId', 'insuranceBillsFileId']).then(values => {
            return http.get(`/bill-mgm/insuranceBills/checkBeforeSave?id=${insuranceBillsFileId}&dealsId=${values.dealsId}&quarter=${form.getFieldValue('insuranceBillQuarter')|| ''}`)
        }).then(res => {
            if (res) {
                replaceConfirm(saveInsuranceBills);
            } else {
                saveInsuranceBills();
            }
        })
    }
    function saveInsuranceBills() {
        return http.post(`/bill-mgm/insuranceBills/save?id=${insuranceBillsFileId}&dealsId=${form.getFieldValue('dealsId')|| ''}&quarter=${form.getFieldValue('insuranceBillQuarter')|| ''}`).then(res => {
            message.success('save to database success');
        })
    }
    function getUploadProps(type = 'vendor') {
        return {
            maxCount: 1,
            name: 'file',
            action: `/api/bill-mgm/${type === 'vendor' ? 'vendorBills': 'insuranceBills'}/upload`,
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                'X-Application': window.localStorage.getItem('application') || 'fleet'
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                    if (type === 'vendor') {
                        setVendorBillsFileId(info.file.response.data);
                    } else {
                        setInsuranceBillsFileId(info.file.response.data);
                    }
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
    }
    function compare() {
        validate(['vendorBillsFileId', 'insuranceBillsFileId'])
        .then(values => http.post(`/bill-mgm/compare?insuranceId=${insuranceBillsFileId}&vendorId=${vendorBillsFileId}`)).then(data => {
            if(data.compareErrors.length == 0){
                Modal.success({
                    title: t('common.success'),
                    content:  '保单账号一致'
                });
            }
            setCompareData(data);
        })
    }
    useEffect(() => {
        http.get('/bill-mgm/deals/getAll').then(res => {
            setTenantOptions(res.list.map(one => ({label: one.dealsName, value: one.id })));
        })
    }, [])
    return <div className="container">
        <Title className='apply-title' level={3}>{t('billCheck.vendorBillsCheck')}</Title>
        <Form
            form={form}
            layout="vertical"
        >
            <Row gutter={48}>
                <Col span={12}>
                    <Form.Item label={t('billCheck.tenant')} name="dealsId">
                        <Select options={tenantOptions}></Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={t('billCheck.billQuarter')} name="vendorBillQuarter">
                        <Select options={quarterOptions}></Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <Upload {...getUploadProps('vendor')}>
                        <Button icon={<UploadOutlined />} block style={{borderRadius: '2px'}}>{t('common.upload')}</Button>
                    </Upload>
                </Col>
                <Col offset={6}>
                    <Button style={{margin: '0 8px'}} onClick={onCheckVendor} loading={checkVendorLoading}>{t('billCheck.check')}</Button>
                    <Button onClick={onBeforeSaveVendor}>{t('billCheck.saveToDb')}</Button>
                </Col>
            </Row>
            <div className="export-title" onClick={() => onExport(vendorBillsData.downloadUrl)}>{t('billCheck.vendorBillsCheckResult')} <DownloadOutlined /></div>
            {
                vendorBillsData.lackFields && vendorBillsData.lackFields.length ? <div className="table-title">{t('billCheck.missKeyFields')}: {vendorBillsData.lackFields.map(fields => <span>{fields};</span>)}</div>: null
            }
            {
                Object.keys(vehicleTable).map(key => {
                    return vendorBillsData[key] && vendorBillsData[key].length
                        ? <div>
                            <div className="table-title">{vehicleTable[key].title}</div>
                            <Table {...tableProps} columns={vehicleTable[key].columns} dataSource={vendorBillsData[key]} /> 
                        </div>: null
                })
            }
            
            <Title className='apply-title' level={3}>{t('billCheck.insuranceBillsCheck')}</Title>
            <Form.Item label={t('billCheck.billQuarter')} name="insuranceBillQuarter">
                <Select options={quarterOptions}></Select>
            </Form.Item>
            <Row>
                <Col span={6}>
                    <Upload {...getUploadProps('insurance')}>
                        <Button icon={<UploadOutlined />} block style={{borderRadius: '2px'}}>{t('common.upload')}</Button>
                    </Upload>
                </Col>
                <Col offset={6}>
                    <Button style={{margin: '0 8px'}} loading={checkInsuranceLoading} onClick={onCheckInsurance}>{t('billCheck.check')}</Button>
                    <Button onClick={onBeforeSaveInsurance}>{t('billCheck.saveToDb')}</Button>
                </Col>
            </Row>
            <div className="export-title" onClick={() => onExport(insuranceBillsData.downloadUrl)}>{t('billCheck.insuranceBillsCheckResult')} <DownloadOutlined /></div>
            {
                insuranceBillsData.lackFields && insuranceBillsData.lackFields.length 
                ? <div className="table-title">{t('billCheck.missKeyFields')}: {insuranceBillsData.lackFields.map(fields => <span>{fields};</span>)}</div>
                : null
            }
            {
                Object.keys(insuranceBillsData).map(key => {
                    return insuranceTable[key] && insuranceBillsData[key].length
                        ? <div>
                            <div className="table-title">{insuranceTable[key].title}</div>
                            <Table {...tableProps} columns={insuranceTable[key].columns} dataSource={insuranceBillsData[key]} /> 
                        </div>: null
                })
            }
            <Row><Button style={{margin: '0 8px'}} block onClick={compare}>{t('billCheck.compare')}</Button></Row>
            <div className="export-title" onClick={() => onExport(compareData.downloadUrl)}>{t('billCheck.crossBillCheckResult')} <DownloadOutlined /></div>
            {compareData.compareErrors && compareData.compareErrors.length ? <Table {...tableProps} columns={compareColumns} dataSource={compareData.compareErrors}/> : null}
        </Form>
    </div> 
  }