import { Table, Row, Col, Button, DatePicker, Modal, Form, Input,  Select } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useTable } from '../../hooks/table';
import { useVerticalForm } from '../../hooks/form';
import {useUserList} from './user';
import moment from "moment";
import http, { onBlobExport, downloadBlob, downloadByBlob } from '../../http';
import styles from './index.module.css';
const { RangePicker } = DatePicker;
const StatusTag = ({status}) => {
    const { t } = useTranslation();
    const map = {
        'NULL': '',
        'PASS': 'green',
        'UNREAD': 'yellow',
        'ERROR': 'red'
    }
    const text = {
        'NULL': t('privacy.null'),
        'PASS': t('privacy.pass'),
        'UNREAD':t('privacy.unread'),
        'ERROR': t('privacy.reject'),
    }
    return <span className={styles.dot + ' ' + styles[map[status]]}>{text[status]}</span>
}

const PrivacyList = () => {
    const { t } = useTranslation();
    const { tableData, pagination, loading, setQuery, handleTableChange } = useTable(getData);
    const {userList} = useUserList();
    function getData(param) {
        return http.get('/system-setting/privacy/search', {
            startTimestamp: moment().startOf('day').subtract('30', 'days').valueOf(),
            endTimestamp: moment().endOf('day').valueOf(),
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            ...param
        })
    }
    const onClickView = (fileUrl) => {
        fileUrl && downloadByBlob(fileUrl, fileUrl.split('/')[fileUrl.split('/').length -1]);
    }
    const onClickPass = id => {
        http.get('/system-setting/privacy/pass', {id}).then(res => {
            handleSearch();
        })
    }
    const onClickReject = id => {
        http.get('/system-setting/privacy/invalid', {id}).then(res => {
            handleSearch();
        })
    }
    const columns = [
        { title: t("privacy.confirmDate"), dataIndex: 'confirmDate', render: date => date && date.slice(0, 10)},
        { title: t("privacy.user"), dataIndex: 'user'},
        { title: t("privacy.plateNumber"), dataIndex: 'plateNumber'},
        { title: t("privacy.status"), dataIndex: 'status', render: status => <StatusTag status={status}/>},
        { title: t("common.editBy"), dataIndex: 'editBy'},
        { title: t("common.editAt"), dataIndex: 'editAt', render: date => date && date.slice(0, 10)},
        {
            title: <div style={{textAlign: 'center'}}>{t("common.action")}</div>,
            align: 'right',
            width: 250,
            fixed: 'right',
            render: item => <div>
                {item.fileUrl && <Button type="text" onClick={() => onClickView(item.fileUrl)}>{t('common.view')}</Button>}
                <Button type="text" style={{marginLeft: '8px'}} onClick={() => onClickPass(item.id)}>{t('common.pass')}</Button>
                <Button type="text" style={{marginLeft: '8px'}} onClick={() => onClickReject(item.id)}>{t('common.reject')}</Button>
            </div>
        },
    ];
    const { form, formProps } = useVerticalForm();
    const colProps = {
        lg: 6,
        md: 12,
        sm: 24
    };  
    const handleSearch = (e) => {
        form.validateFields().then(values => {
            const {confirmDate, userId, status} = values;
            setQuery({
                startTimestamp: confirmDate[0].startOf('day').valueOf(),
                endTimestamp: confirmDate[1].endOf('day').valueOf(),
                userId,
                status
            });
        })

    }
    const handleExport = e => {
        form.validateFields().then(values => {
            const {confirmDate, userId, status} = values;
            const params = {
                startTimestamp: confirmDate[0].startOf('day').valueOf(),
                endTimestamp: confirmDate[1].endOf('day').valueOf(),
                userId,
                status
            };
            onBlobExport('/system-setting/privacy/export', params).then(res => {
                downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*)/)[1])
            });
        })
    }
    return <div className="container">
        <Form {...formProps} form={form} name="basic" initialValues={{confirmDate: [moment().subtract('30', 'days'), moment()]}}>
            <Row align="bottom">
                <Col {...colProps}>
                    <Form.Item label={t('privacy.confirmDate')} name="confirmDate" rules={[{ required: true, message: t('common.pleaseSelect') + t('privacy.confirmDate') }]}>
                        <RangePicker style={{width: '100%'}}/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('privacy.user')} name="userId">
                        <Select options={userList} allowClear></Select>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('privacy.status')} name="status">
                        <Select allowClear>
                            <Select.Option value="NULL">{t('privacy.null')}</Select.Option>
                            <Select.Option value="UNREAD">{t('privacy.unread')}</Select.Option>
                            <Select.Option value="PASS">{t('privacy.pass')}</Select.Option>
                            <Select.Option value="ERROR">{t('privacy.reject')}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...colProps} style={{ marginBottom: '24px' }}>
                    <Button icon={<SearchOutlined />}   onClick={handleSearch}>{t('common.search')}</Button>
                    <Button style={{ marginLeft: '16px' }} icon={<DownloadOutlined />} onClick={handleExport}>{t('common.export')}</Button>
                </Col>
            </Row>
        </Form>
        <Table
            size="small"
            loading={loading}
            pagination={pagination}
            rowKey="id"
            onChange={handleTableChange}
            columns={columns}
            dataSource={tableData} />
    </div>
}
export default PrivacyList;