import { Card, Row } from 'antd';
import {FallOutlined, RiseOutlined} from '@ant-design/icons'
import style from './card.module.css';
const formatNum = num => {
    return typeof num === 'string' ? num: Math.abs(Math.round(num)).toLocaleString();
}
const formatSmall = num => {
    return num === 0 ? '— 0': Math.abs(Math.round(num)).toLocaleString();
}
export const DataCard = ({title, supNum: smallNum, num}) => {
    return (
        <Card>
            <Row justify="space-between">
                <div className={style.title}>{title}</div>
                { smallNum !== undefined && !Number.isNaN(smallNum) ? 
                    <div className={`${style.supNum} ${smallNum > 0 && style.increase} ${smallNum < 0 && style.decrease} ${smallNum === 0 && style.zero}`}>
                        {smallNum !== 0 &&  (smallNum > 0 ? <RiseOutlined /> : <FallOutlined />)}
                        {formatSmall(smallNum)}
                    </div> : null
                }
            </Row>
            <div className={style.num}>{formatNum(num || 0)}</div>
        </Card>
    )
}