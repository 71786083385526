import { Row, Col, Spin } from 'antd';
import { useState } from 'react';
import http from '../../../../http';
import { useEffect } from 'react'
import VehicleType from '../../../RiskAnalysis/components/VehicleType'
import { cloneDeep } from 'lodash';


export default function PreviewVehicleDistribution ({serchParams}) {
  // default dispaly table head
  const [chartDataLoading, setChartDataLoading] = useState(true)
  const [chartData, setChartData] = useState({})


  const getChartData = async () => {
    const params = cloneDeep(serchParams)
    params.strDisPlayAll = params.strPolicy[0] == 1 ? 1 : 0
    let res = await http.get('/vehicleriskapi/VehicleRisk/GetData', params)
    setChartData(res)
    setChartDataLoading(false)
  }

  useEffect(() => {
    getChartData()
  }, [])

  return <div>
    <Spin spinning={chartDataLoading}>
      <div style={{ 'marginTop': '20px', 'marginBottom': '20px', 'background': "#fff", 'paddingTop': '10px' }}>
        <Row>
          <Col className="gutter-row" span={12}>
            <VehicleType chartList={chartData.VehicleType} type="VehicleType" />
          </Col>
          <Col className="gutter-row" span={12}>
            <VehicleType chartList={chartData.VehicleLoad} type="Load" />
          </Col>
        </Row>
        <Row>
          <Col className="gutter-row" span={12}>
            <VehicleType chartList={chartData.VehicleAge} type="Age" />
          </Col>
          <Col className="gutter-row" span={12}>
            <VehicleType chartList={chartData.VehicleLength} type="Length" />
          </Col>
        </Row>
      </div>
    </Spin>

  </div>
}

