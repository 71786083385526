import { Spin } from 'antd';
import http from '../../../../http';
import { useState, useEffect } from 'react';
import LossAnalysisChart from '../../../PortfolioMgt/components/LossAnalysisChart'
import { useTranslation } from 'react-i18next'

export default function PageLossCorrelationChart ({serchParams}) {
  const [chartLoading, setChartLoading] = useState(true)
  const [chartList, setChartList] = useState([])
  const [Units, setUnits] = useState({})

  const { t } = useTranslation();

  function getChartData () {
    setChartLoading(true)
    http.get('/portfolioapi/Losscorrelationchart/GetData', {
      strDataCope: serchParams.strDataCope,
      strFeature: serchParams.strFeature,
      strValue: serchParams.strValue
    }).then(res => {
      let list = res.data
      if(serchParams.strValue === '8' || serchParams.strValue === '9'){ // show percentage
        list.forEach(e => {
          e.Value = (e.Value * 100).toFixed(2)
        })
      }
      setUnits({
        UnitX: res.UnitX_en,
        UnitY: res.UnitY_en,
      })
      setChartList(list)
      setChartLoading(false)
    })
  }

  useEffect(() => {
    getChartData()
  }, [])

  return <div style={{marginTop: '20px'}}>
    <Spin spinning={chartLoading}>
      {<div style={{'position': 'absolute', 'left': '50%','transform': 'translateX(-50%)', 'top': '-20px'}}>{t(`groupname.${serchParams.strFeatureLabel}`) + `${Units.UnitX && Units.UnitX !== '-' ? ` : ${t(`units.${Units.UnitX}`)}`:''}`}</div>}
      <LossAnalysisChart chartList={chartList} strValueLabel={serchParams.strValueLabel} Units={Units} valueSelect={serchParams.strValue} groupUUID={serchParams.groupUUID}></LossAnalysisChart>
    </Spin>
  </div>
}