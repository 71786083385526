import { Spin } from 'antd';
import http from '../../../../http';
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react';
import {initMap} from '../../../AccidentInquire/amap';

export default function PreviewAnalysis ({serchParams}) {
  const { t } = useTranslation();

  const [loading ,setLoading] = useState(false);
  const uid = serchParams.groupUUID


  const onSearchVehicle = () => {
    setLoading(true);
    const {vin, plateNumber, startTimestamp, endTimestamp, strDataCope} = serchParams;
    const tripParams = { vin, plateNumber, startTimestamp, endTimestamp, strDataCope }
    http.get('/accident/trips', tripParams).then(res => {
        initMap('vehicleTrip'+uid,res)
        setLoading(false)
    })
  }

  useEffect(() => {
    onSearchVehicle()
  }, [])

  return <div>
    <Spin spinning={loading}>
      <div id={`vehicleTrip${uid}`} style={{height: '500px'}}></div>
    </Spin>
  </div>
}