import { Form, Select, Row, Col, Input,DatePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import {useVendorList, useDealList} from '../../../PartnerEvaluation/data';


export default function PageDataInsightByGroup ({ Section, SectionJS }) {
  const { t } = useTranslation();

  const {dealList} = useDealList();
  const {vendorList} = useVendorList();

  const saveTripDay = (e) => {
    SectionJS.serchParams.tripDay = moment(e).format('YYYY-MM-DD')
  }

  return <div>
    <Row justify="space-between">
      <Col span={11}>
        <Form.Item
          label={t('partner.deal')}
          name={[Section.name, 'serchParams', 'deal']}
        >
          <Select allowClear showSearch options={dealList}></Select>
        </Form.Item>
      </Col>
      <Col span={11}>
        <Form.Item
          label={t('partner.partner')}
          name={[Section.name, 'serchParams', 'partner']}
        >
          <Select allowClear showSearch>
            {
              vendorList.map(vendorName => <Select.Option key={vendorName}>{vendorName}</Select.Option>)
            }
          </Select>
        </Form.Item>
      </Col>
    </Row>
    <Row justify="space-between">
      <Col span={7}>
        <Form.Item
          label={t('partner.plateNumber')}
          name={[Section.name, 'serchParams', 'plateNumber']}
        >
          <Input></Input>
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item
          label={t('partner.tripDay')}
          name={[Section.name, 'serchParams', 'date']}
        >
          <DatePicker onChange={saveTripDay} style={{ width: '100%' }} allowClear={false}></DatePicker>
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item
          label={t('partner.qualityCheckStatus')}
          name={[Section.name, 'serchParams', 'qualityCheckStatus']}
        >
          <Select allowClear>
            <Select.Option value={1}>Y</Select.Option>
            <Select.Option value={0}>N</Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </div>
}