import { Modal, Button, Spin, Table} from 'antd';
import { useCallback } from 'react';
import { useState, useEffect } from 'react';
import http from '../../../http';

import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { useTranslation } from 'react-i18next'

echarts.use([GridComponent, BarChart, CanvasRenderer]);

const VehicleRegion = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    const { t } = useTranslation();

    const loadData = useCallback(() => {
        setLoading(true);
        setTableData([]);
        setColumns([]);
        const {dealName, insurerName, partnerName, type} = props;
        return http.get(`/partner-eval/evaluation/${type}/vehicle/distribution`, {dealName, insurerName, partnerName}).then(res => {
            let tableData = [{region: t('partner.chart.number')}];
            res.forEach(one => {
                tableData[0][one.region] = one.vehicleCount;
            })
            setTableData(tableData);
            setColumns([{title: t('partner.chart.region'), dataIndex: 'region', key: 'region'}, ...res.map(item => ({title: item.region, dataIndex: item.region, key: item.region}))]);
            initChart(res);
        }).finally(() => setLoading(false))
    })
    const initModal = useCallback(() => {
        setIsModalVisible(true);
        loadData();
    })
    const onCancel = useCallback(() => {
        setIsModalVisible(false);
    })

    const initChart = (data) => {
        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
            },
            grid: {
                left: 180,
                bottom: 20,
                top: 10
            },
            color: ['#627D77'],
            xAxis: {
                type: 'value',
                minInterval: 1
            },
            yAxis: {
                type: 'category',
                data: data.map(one => one.region)
            },
            series: [
                {
                    data: data.map(one => one.vehicleCount),
                    type: 'bar',
                    barWidth: 20
                }
            ]
        };
        let myChart = echarts.init(document.getElementById('vehicle-region-chart'));
        myChart.setOption(option);
    }
    return (
    <div>
        <Button type="link" onClick={initModal}>{t('common.view')}</Button>
        <Modal destroyOnClose title={t('partner.region')} width={800} visible={isModalVisible} footer={null} onCancel={onCancel}>
            <div id="vehicle-region-chart" style={{ height: 300, width: 800, margin: '0 auto'}}></div>
            <Table size="small" scroll={{x: 750}} dataSource={tableData} columns={columns} pagination={false} loading={loading}></Table>
        </Modal>
    </div>
    )
}

export default VehicleRegion;