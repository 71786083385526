import { Table, Row, Col, Button, Modal, Form, Input, Select } from 'antd';
import { DownloadOutlined, PlusOutlined, SettingOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { FileUpload } from './components/Upload';
import TableColumnFilter from '../../components/TableColumnFilter';

import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useTable } from '../../hooks/table';
import { useVerticalForm } from '../../hooks/form';
import { useDealList } from '../../data/deal';
import { useInsuranceList } from '../../data/insurer';

import http, { onBlobExport, downloadBlob } from '../../http';
import moment from 'moment';
const InsuranceBills = () => {
    const { t } = useTranslation();
    const { tableData, pagination, loading, setQuery, handleTableChange} = useTable(getData);
    const { dealList } = useDealList();
    const { insuranceList } = useInsuranceList();
    function getData(param) {
        return http.get('/system-setting/insuranceBill/search', {
            orderBy: '',
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            ...param
        })
    }
    const { form, formProps, colProps } = useVerticalForm();
    const handleSearch = (e) => {
        const values = form.getFieldsValue();
        setQuery(values);
    }

    const fieldsColumns = [
        { title: t("fields.policyNumber"), dataIndex: 'policyNumber' },
        { title: t("fields.insurerName"), dataIndex: 'insurerName', sorter: true },
        { title: t("fields.deal"), dataIndex: 'tenantName', sorter: true },
        { title: t("fields.vin"), dataIndex: 'vin' },
        { title: t("fields.plateNumber"), dataIndex: 'plateNumber' },
        { title: t("policy.inceptionDate"), dataIndex: 'inceptionDate', sorter: true },
        { title: t("bills.endDate"), dataIndex: 'endDate', sorter: false },
        { title: t("policy.policyType"), dataIndex: 'policyType', sorter: false },
    ]
    const commonColumns = [
        {
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: time => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            width: 120,
            hide: true
        },
        {
            title: t('common.createdBy'),
            dataIndex: 'createdBy',
            hide: true
        },
        {
            title: t('common.updatedAt'),
            dataIndex: 'updatedAt',
            render: time => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            width: 120,
            hide: true
        },
        {
            title: t('common.updatedBy'),
            dataIndex: 'updatedBy',
            hide: true
        },
    ]
    const [columns, setColumns] = useState([
        ...fieldsColumns,
        ...commonColumns,
        {
            title: t("common.action"),
            hide: true,
            render: item => <div>
                <Button icon={<DeleteOutlined />} onClick={() => confirmDelete(item)}>{t('common.delete')}</Button>
            </div>
        },
    ]);
    const confirmDelete = (item) => {
        Modal.confirm({
            title: t('common.confirm'),
            content: `${t('common.confirmDelete')} [${item.policyNumber}] ?`,
            cancelButtonProps: {
                type: 'default',
                icon: <CloseCircleOutlined />
              },
              okButtonProps: {
                type: 'default',
                icon: <CheckCircleOutlined />
              },
            onOk() {
                return http.delete(`/system-setting/insuranceBill/delete?ibid=${item.id}`).then(handleSearch);
            }
        });
    }
    const handleExport = e => {
        const values = form.getFieldsValue();
        onBlobExport('/system-setting/insuranceBill/export', null, { method: 'post', params: values }).then(res => {
            downloadBlob(res.data, 'insurance-bills.xlsx')
        });
    }
    return <div className="container">
        <Form {...formProps} form={form} name="basic">
            <Row align="bottom">
                <Col {...colProps}>
                    <Form.Item label={t('fields.policyNumber')} name="policyNumber">
                        <Input />
                    </Form.Item>
                </Col>

                <Col {...colProps}>
                    <Form.Item label={t('fields.deal')} name="deal">
                        <Select options={dealList} allowClear/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.insurer')} name="insurer">
                        <Select options={insuranceList} allowClear/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.plateNumber')} name="plateNumber">
                        <Input />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.vin')} name="vin">
                        <Input />
                    </Form.Item>
                </Col>
                <Col {...colProps} style={{ marginBottom: '24px' }}>
                    <Button icon={<SearchOutlined />}   onClick={handleSearch}>{t('common.search')}</Button>
                    <Button style={{ marginLeft: '16px' }} icon={<DownloadOutlined />} onClick={handleExport}>{t('common.export')}</Button>
                </Col>
            </Row>
        </Form>
        <Row justify="space-between" align="middle">
            <FileUpload
                url="/system-setting/insuranceBill"
                template={{
                    fileName: "insurance-bill-template.xlsx"
                }}
                onSubmit={handleSearch}
                columns={fieldsColumns}
            />
            <TableColumnFilter columns={columns} onSetColumns={setColumns} />
        </Row>
        <Table
            size="small"
            loading={loading}
            pagination={pagination}
            rowKey="id"
            columns={columns.filter(column => !column.hide)}
            dataSource={tableData}
            onChange={handleTableChange} />
    </div>
}
export default InsuranceBills;