import { useEffect, useState } from 'react';
import { DatePicker, Select, Input, Table, Row, Col, Form, Button, Modal } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons'
import moment from 'moment';

import styles from './index.module.css';
import { useTranslation } from 'react-i18next'
import http, { onBlobExport, downloadBlob } from '../../http';
import {useDealList, useVendorList} from './data';
import {QuestionTitle} from './components/QuestionTitle';
import TableColumnFilter from '../../components/TableColumnFilter';

const DataQuality = () => {
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const initQuery = {
    tripDay: moment().subtract(2, 'days'),
    qualityCheckStatus: 0
  };
  const [query, setQuery] = useState(initQuery);
  const [pageIndex, setPageIndex] = useState(0);
  const {dealList} = useDealList();
  const {vendorList} = useVendorList();
  useEffect(() => {
    getData(query).then(res => {
      setTableData(res);
    });
  }, [pageIndex, query])
  function getData(param) {
    Object.keys(param).forEach(key => param[key] = param[key] ?? undefined)
    if (param.tripDay) {
      param.tripDay = moment(param.tripDay).format('YYYY-MM-DD')
    }
    setLoading(true);
    return http.get('/partner-eval/evaluation/trip/query', {
      pageIndex,
      pageSize: 10,
      ...param
    }).finally(() => setLoading(false))
  }
  const onCell = (record, key) => {
    return record[`${key}Status`] !== true ? {
      style: {
        background: 'pink'
      }
    }: {}
  };
  const [columns, setColumns] = useState([
    { title: <QuestionTitle title="deal" />, dataIndex: 'deal', width: 200},
    { title: <QuestionTitle title="partner" />, dataIndex: 'partner', width: 250},
    { title: <QuestionTitle title="tripID" />, width: 200, dataIndex: 'tripID'},
    { title: <QuestionTitle title="plateNumber" />, dataIndex: 'plateNumber' },
    { title: <QuestionTitle title="qualityCheckStatus" />,  width: 120, dataIndex: 'qualityCheckStatus', render: text => text ? 'Y': 'N'},
    { title: <QuestionTitle title="tripDay" />, dataIndex: 'tripDay', render: date => <span>{moment(date).format('YYYY-MM-DD')}</span> , onCell: record =>  onCell(record, 'tripDay')},
    { title: <QuestionTitle title="startTime" />, dataIndex: 'startTime', render: date => <span>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</span>, onCell: record =>  onCell(record, 'startTime') },
    { title: <QuestionTitle title="endTime" />, dataIndex: 'endTime', render: date => <span>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</span>, onCell: record =>  onCell(record, 'endTime') },
    { title: <QuestionTitle title="startLat" />, dataIndex: 'startLatitude', onCell: record =>  onCell(record, 'startLatitude')  },
    { title: <QuestionTitle title="startLon" />, dataIndex: 'startLongitude', onCell: record =>  onCell(record, 'startLongitude')  },
    { title: <QuestionTitle title="endLat" />, dataIndex: 'endLatitude', onCell: record =>  onCell(record, 'endLatitude')  },
    { title: <QuestionTitle title="endLon" />, dataIndex: 'endLongitude', onCell: record =>  onCell(record, 'endLongitude')  },
    { title: <QuestionTitle title="mileage1" />, dataIndex: 'mileage1', onCell: record =>  onCell(record, 'mileage1')  },
    { title: <QuestionTitle title="mileage2" />, hide: true, dataIndex: 'mileage2', onCell: record =>  onCell(record, 'mileage2')  },
    { title: <QuestionTitle title="avgSpeed" />, dataIndex: 'avgSpeed', onCell: record =>  onCell(record, 'avgSpeed')  },
    { title: <QuestionTitle title="maxSpeed" />, dataIndex: 'maxSpeed', onCell: record =>  onCell(record, 'maxSpeed')  },
    { title: <QuestionTitle title="ADASForwardCollision" />, dataIndex: 'adasforwardCollision', onCell: record =>  onCell(record, 'adasforwardCollision')  },
    { title: <QuestionTitle title="ADASFrequentChangeLane" />, dataIndex: 'adasfrequentChangeLane', onCell: record =>  onCell(record, 'adasfrequentChangeLane')  },
    { title: <QuestionTitle title="ADASFollowClosely" />, dataIndex: 'adasfollowClosely', onCell: record =>  onCell(record, 'adasfollowClosely')  },
    { title: <QuestionTitle title="ADASPedestrianCollision" />, dataIndex: 'adaspedestrianCollision', onCell: record =>  onCell(record, 'adaspedestrianCollision')  },
    { title: <QuestionTitle title="ADASLaneDeparture" />, dataIndex: 'adaslaneDeparture', onCell: record =>  onCell(record, 'adaslaneDeparture')  },
    { title: <QuestionTitle title="BSDRightsideRear" />, dataIndex: 'bsdrightsideRear', onCell: record =>  onCell(record, 'bsdrightsideRear')  },
    { title: <QuestionTitle title="BSDLeftsideRear" />, dataIndex: 'bsdleftsideRear', onCell: record =>  onCell(record, 'bsdleftsideRear')  },
    { title: <QuestionTitle title="DSMFatigueDriving" />, dataIndex: 'dsmfatigueDriving', onCell: record =>  onCell(record, 'dsmfatigueDriving')  },
    { title: <QuestionTitle title="DSMSmoking" />, dataIndex: 'dsmsmoking', onCell: record =>  onCell(record, 'dsmsmoking')  },
    { title: <QuestionTitle title="DSMCellphone" />, dataIndex: 'dsmcellphone', onCell: record =>  onCell(record, 'dsmcellphone')  },
    { title: <QuestionTitle title="DSMAnomalyDriving" />, dataIndex: 'dsmanomalyDriving', onCell: record =>  onCell(record, 'dsmanomalyDriving')  },
    { title: <QuestionTitle title="DSMDistractedDriving" />, dataIndex: 'dsmdistractedDriving', onCell: record =>  onCell(record, 'dsmdistractedDriving')  },
    { title: <QuestionTitle title="DSMDriverIdentify" />, dataIndex: 'dsmdriverIdentify', onCell: record =>  onCell(record, 'dsmdriverIdentify')  },
    { title: <QuestionTitle title="DSMChangeDriver" />, dataIndex: 'dsmchangeDriver', onCell: record =>  onCell(record, 'dsmchangeDriver')  },
    { title: <QuestionTitle title="DSMActiveCapture" />, dataIndex: 'dsmactiveCapture', onCell: record =>  onCell(record, 'dsmactiveCapture')  },
    { title: <QuestionTitle title="DSMInfraredBlocking" />, dataIndex: 'dsminfraredBlocking', onCell: record =>  onCell(record, 'dsminfraredBlocking')  },
    { title: <QuestionTitle title="DSMDriverUnbelt" />, dataIndex: 'dsmdriverUnbelt', onCell: record =>  onCell(record, 'dsmdriverUnbelt')  },
    { title: <QuestionTitle title="DSMLoosenSteering" />, dataIndex: 'dsmloosenSteering', onCell: record =>  onCell(record, 'dsmloosenSteering')  },
    { title: <QuestionTitle title="DSMDriverDrink" />, dataIndex: 'dsmdriverDrink', onCell: record =>  onCell(record, 'dsmdriverDrink')  }
  ]);
  const onSubmit = values => {
    setQuery(values);
    setPageIndex(0);
  }
  function onExport() {
    setExportLoading(true);
    const param = form.getFieldsValue();
    param.tripDay = param.tripDay.format('YYYY-MM-DD');
    http.get('/partner-eval/evaluation/trip/tripDownload', param).then(res => {
      if (res.couldDownload) {
        return onBlobExport(`/Blob/Download/${encodeURIComponent(res.url)}`);
      } else {
        Modal.info({
          title: t('common.info'),
          content:  t('tips.exporting')
        });
        return;
      }
    }).then(res => {
      res && downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*);/)[1])
    }).finally(() => {
      setExportLoading(false);
    })
  }  
  return <div className="data-quality">
    <div className={styles.title}>{t('partner.tripStatus')}</div>
    <div>
      <Form
        layout="vertical"
        initialValues={initQuery}
        onFinish={onSubmit}
        form={form}
      >
        <Row gutter={24} align="bottom">
          <Col span={6}>
            <Form.Item label={t('partner.deal')} name="deal">
              <Select allowClear showSearch options={dealList}></Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('partner.partner')} name="partner">
              <Select allowClear showSearch>
                {
                  vendorList.map(vendorName => <Select.Option key={vendorName}>{vendorName}</Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('partner.plateNumber')}  name="plateNumber"><Input></Input></Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('partner.tripDay')} name="tripDay"><DatePicker style={{width: '100%'}} allowClear={false}></DatePicker></Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('partner.qualityCheckStatus')}  name="qualityCheckStatus">
              <Select allowClear>
                <Select.Option value={1}>Y</Select.Option>
                <Select.Option value={0}>N</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} style={{ marginBottom: '24px' }}>
            <Button icon={<SearchOutlined />}  loading={loading} style={{ marginLeft: '8px' }} htmlType="submit">{t('common.search')}</Button>
            <Button loading={exportLoading} onClick={onExport} style={{ marginLeft: '8px' }} icon={<DownloadOutlined />}>{t('common.export')}</Button>
          </Col>
          <Col span={12} style={{ marginBottom: '24px', textAlign: 'right' }}>
            <TableColumnFilter columns={columns} onSetColumns={setColumns} />
          </Col>
        </Row>
      </Form>
      <Table
        size='small'
        loading={loading}
        scroll={{ x: 4500}}
        rowKey="tripID"
        columns={columns.filter(v => !v.hide)}
        dataSource={tableData}
        pagination={false} 
        tableLayout="fixed"
      />
      <div style={{float: 'right', padding: '10px'}}>
        <Button style={{marginRight: '8px'}} disabled={pageIndex === 0} onClick={() => setPageIndex(pageIndex - 1)}>{'<'}</Button>
        <Button onClick={() => setPageIndex(pageIndex + 1)}>{'>'}</Button>
      </div>
    </div>
  </div>
}

export default DataQuality;