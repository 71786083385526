import { useState, useEffect } from 'react';
import {  Steps, Row, Col, Button, Modal, Form, Input, InputNumber,  Select, DatePicker, message} from 'antd';
import http from '../../http';
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import NewQuarter from './NewQuarter';

const { Step } = Steps;

const BillQuarter = ({insurerId, currentQuarter}) => {
    const {t} = useTranslation();
    const [quarterInfo, setQuarterInfo] = useState({});
    const [nextLoading, setNextLoading] = useState(false);
    const [backLoading, setBackLoading] = useState(false);
    const getQuarterInfo = () => {
        http.get('/bill/insurer-bill-quarter/queryById', {
            insurerId: insurerId,
            currentQuarter: currentQuarter
        }).then(res => {
            setQuarterInfo(res);
        })
    }
    useEffect(getQuarterInfo, [insurerId, currentQuarter])
   
    const currentStep = quarterInfo.current ? quarterInfo.currentStage: 4;
    const handleBack = () => {
        setBackLoading(true);
        http.get('/bill/insurer-bill-quarter/stage/pre', {insurerId}).then(res => {
            setQuarterInfo(res);
        }).finally(() => setBackLoading(false));
    }
    const handleNext = () => {
        setNextLoading(true);
        http.get('/bill/insurer-bill-quarter/stage/next', {insurerId}).then(res => {
            setQuarterInfo(res);
        }).finally(() => setNextLoading(false));
    }
    return <>
            <div style={{marginBottom: '8px'}}>{t('bills.currentQuarter')}: {quarterInfo.currentQuarter}</div>
            <Row style={{marginBottom: '8px'}}>
                <Col span={19}>
                    <Steps current={currentStep - 1}>
                        <Step title={t("bills.stage.tmxCheck")} description={
                            <>
                                <div>{t('bills.startAt') + moment(quarterInfo.currentQuarterStartDate).format('YYYY-MM-DD')}</div>
                                <div>{t('bills.endAt') + moment(quarterInfo.stage1EndDate || quarterInfo.tmxfreezeDate).format('YYYY-MM-DD')}</div>
                            </>
                        } />
                        <Step title={t("bills.stage.policyCheck")} description={
                            <div>{quarterInfo.stage2EndDate ? t('bills.endAt') + moment(quarterInfo.stage2EndDate).format('YYYY-MM-DD'): ''}</div>
                        } />
                        <Step title={t("bills.stage.bdxCheck")} description={
                            <div>{quarterInfo.stage3EndDate ? t('bills.endAt') + moment(quarterInfo.stage3EndDate).format('YYYY-MM-DD'): ''}</div>
                        } />
                        {/* <Step title={t("bills.stage.policyCheck")+'(MI)'} description={
                            <div>{quarterInfo.stage4EndDate ? t('bills.endAt') + moment(quarterInfo.stage4EndDate).format('YYYY-MM-DD'): ''}</div>
                        } />
                        <Step title={t("bills.stage.bdxCheck")+'(MI)'} description={
                            <div>{quarterInfo.stage5EndDate ? t('bills.endAt') + moment(quarterInfo.stage5EndDate).format('YYYY-MM-DD'): ''}</div>
                        } /> */}
                    </Steps>
                </Col>
                {quarterInfo.current && <Col span={4} offset={1}>
                    {currentStep > 2 && <Button style={{marginRight: '8px'}} onClick={handleBack} loading={backLoading}>{t('bills.back')}</Button>}
                    {currentStep < 3 && <Button style={{marginRight: '8px'}} onClick={handleNext} loading={nextLoading}>{t('bills.next')}</Button>}
                    {currentStep === 3 && <NewQuarter insurerId={insurerId} onSuccess={getQuarterInfo} />}
                </Col>
                }
            </Row>
        </>
}
export default BillQuarter;