import http from '../../../http';
import { useTranslation } from 'react-i18next'
import {Checkbox, Row, Col, Alert} from 'antd';
import { useEffect, useState } from 'react';
import UploadPrivacy from './Upload';


const AgreeCheck = ({params}) => {
    const {t} = useTranslation();
    const [checked, setChecked] = useState(false);
    const [status, setStatus] = useState('');
    const statusMap = {
        'NULL': {
            text: t('privacy.null'),
            class: 'claim-alert-gray',
            type: 'info'
        },
        'PASS': {
            text: t('privacy.pass'),
            class: 'claim-alert-success',
            type: 'success'
        },
        'UNREAD': {
            text: t('privacy.unread'),
            class: 'claim-alert-gray',
            type: 'info'
        },
        'ERROR': {
            text: t('privacy.reject'),
            class: 'claim-alert-red',
            type: 'error'
        },
    }
    useEffect(() => {
        if (params.vin || params.plateNumber) {
            http.get('/accident/privacyAgreement', params).then(res => {
                if (res) {
                    setChecked(res.agree)
                    setStatus(res.status)
                }
            })
        }
    }, [params])
    function onCheck() {
        http.post('/accident/privacyAgreement/agree', {}, {params}).then(res => {
            if (res) {
                setChecked(true)
            }
        })
    }
    return <Row align='top'>
                <Col span={24}>
                    <Checkbox disabled={checked} checked={checked} onChange={onCheck}>{t('tips.privacyConfirm')}</Checkbox>
                </Col>
                {/* <Col span={8}>
                    <UploadPrivacy params={params} />
                </Col>
                <Col span={8}>
                    <div>{t('tips.privacyStatus')}</div>
                    { status && <Alert
                        message={statusMap[status].text}
                        className={statusMap[status].class}
                        type={statusMap[status].type}
                        showIcon
                    />}
                </Col> */}
            </Row>
}

export default AgreeCheck;