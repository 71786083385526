import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next'
import style from '../index.module.css'
import http, { onBlobExport, downloadBlob } from '../../../http';
import { useParams } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react';
import SectionPreview from '../components/SectionPreview'

export default function Index () {
  const { t } = useTranslation();
  const { ReportId } = useParams();
  const [recommendationData, setRecommendationData] = useState({})
  const [updateTime, setUpdateTime] = useState('')
  const [detailsLoading, setDetailsLoading] = useState(true)
  const [downDataLoading, setDownDataLoading] = useState(false)
  console.log(ReportId)

  const getDetails = () => {
    return http.get(`/recommendationapi/Recommendation/GetRecommendation?ReportId=${ReportId}`)
  }

  const downloadFile = () => {
    setDownDataLoading(true)
    const values = {
      strReportid: ReportId
    }
    onBlobExport('/recommendationapi/Recommendation/GetExcel', values).then(res => {
      setDownDataLoading(false)
      downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*);/)[1] || 'Recommendation.xlsx')
    });
  }

  useEffect(() => {
    getDetails().then(res => {
      const {UpdateAt, ReportText} = res
      setRecommendationData(ReportText)
      setUpdateTime(UpdateAt)
      setDetailsLoading(false)
    })
  }, [])



  return <div>
    <Spin spinning={detailsLoading}>
      <div className={style.previewTitle}>
        <div className={style.title}>{recommendationData.strRecommendationReportTitle}</div>
        <div className={style.updateTime}>update: {updateTime}</div>
      </div>
      <div className={style.exportBox}>
        <Button className={style.exportbtn} loading={downDataLoading} onClick={downloadFile}><DownloadOutlined />{t('common.export')}</Button>
      </div>
      <div className={style.box}>
        <div className={style.info}>{recommendationData.strSubTitleOrBrief}</div>
      </div>
      <div>
        {recommendationData.SectionList && recommendationData.SectionList.map((Section,i) =>
          <div className={style.box} key={i}>
            <SectionPreview SectionData={Section} DataScope={recommendationData.strDataScope}/>
          </div>
        )}
      </div>
    </Spin>
  </div>
}
