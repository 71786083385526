import { Table } from 'antd';
import { useTable } from '../../../../hooks/table';
import http from '../../../../http';
import { useTranslation } from 'react-i18next'
import { useState } from 'react';
import ChangeColumns from '../../../PortfolioMgt/components/ChangeColumns'

export default function PreviewAnalysis ({ serchParams }) {
  const { t } = useTranslation();
  const { tableData, pagination, loading, handleTableChange } = useTable(getData);
  const columns = [
    {
      title: t.bind(null,'tabletitle.Client'),
      dataIndex: 'Client',
      disabled: true,
      default: true
    },
    {
      title: t.bind(null,'tabletitle.Branch'),
      dataIndex: 'Branch',
      showSorterTooltip: false,
      default: true,
      disabled: true
    },
    {
      title: t.bind(null,'tabletitle.SubBranch'),
      dataIndex: 'SubBranch',
      showSorterTooltip: false,
      default: true,
      disabled: true
    },
    {
      title: t.bind(null,'tabletitle.Tenant'),
      dataIndex: 'Tenant',
      showSorterTooltip: false,
      disabled: true,
      default: true
    },
    {
      title: t.bind(null,'tabletitle.Period'),
      dataIndex: 'Period',
      showSorterTooltip: false,
      disabled: true,
      default: true
    },
    {
      title: t.bind(null,'tabletitle.ClaimCount'),
      dataIndex: 'ClaimCount',
      showSorterTooltip: false,
      default: true,
      disabled: true,
      sorter: {
        compare: (a, b) => a.ClaimCount - b.ClaimCount,
      },
    },
    {
      title: t.bind(null,'tabletitle.PolicyCount'),
      dataIndex: 'PolicyCount',
      showSorterTooltip: false,
      default: true,
      sorter: {
        compare: (a, b) => a.PolicyCount - b.PolicyCount,
      },
    }, {
      title: t.bind(null,'tabletitle.WrittenPremium'),
      dataIndex: 'WrittenPremium',
      default: true,
      sorter: {
        compare: (a, b) => a.WrittenPremium - b.WrittenPremium,
      },
    }, {
      title: t.bind(null,'tabletitle.CarYears'),
      dataIndex: 'CarYears',
      default: true,
      sorter: {
        compare: (a, b) => a.CarYears - b.CarYears,
      },
    }, {
      title: t.bind(null,'tabletitle.PremiumPerPolicy'),
      dataIndex: 'PremiumPerPolicy',
      default: true,
      sorter: {
        compare: (a, b) => a.PremiumPerPolicy - b.PremiumPerPolicy,
      },
    }, {
      title: t.bind(null,'tabletitle.EarnedPremium'),
      dataIndex: 'EarnedPremium',
      default: true,
      sorter: {
        compare: (a, b) => a.EarnedPremium - b.EarnedPremium,
      },
      render: (text) =>{
        return (text*1).toFixed(2)
      }
    }, {
      title: t.bind(null,'tabletitle.ExpYears'),
      dataIndex: 'ExpYears',
      default: true,
      sorter: {
        compare: (a, b) => a.ExpYears - b.ExpYears,
      },
    }, {
      title: t.bind(null,'tabletitle.Frequency'),
      dataIndex: 'Frequency',
      default: true,
      sorter: {
        compare: (a, b) => a.Frequency - b.Frequency,
      }
    }, {
      title: t.bind(null,'tabletitle.Severity'),
      dataIndex: 'Severity',
      default: true,
      sorter: {
        compare: (a, b) => a.Severity - b.Severity,
      }
    }, {
      title: t.bind(null,'tabletitle.LossAmount'),
      dataIndex: 'LossAmount',
      default: true,
      sorter: {
        compare: (a, b) => a.LossAmount - b.LossAmount,
      },
      render: (text) =>{
        return (text*1).toFixed(2)
      }
    }, {
      title: t.bind(null,'tabletitle.LossRatio'),
      dataIndex: 'LossRatio',
      default: true,
      sorter: {
        compare: (a, b) => a.LossRatio - b.LossRatio,
      },
      render: (text) =>{
        return (text * 100).toFixed(2) + '%'
      }
    }, {
      title: t.bind(null,'tabletitle.CostingLossRatio'),
      dataIndex: 'CostingLossRatio',
      default: true,
      sorter: {
        compare: (a, b) => a.CostingLossRatio - b.CostingLossRatio,
      },
      render: (text) =>{
        return (text * 100).toFixed(2) + '%'
      }
    },
  ]
  // default dispaly table head
  const defaultColumns = columns.filter(e=>e.default)
  // dynamic regulation table head
  const [column, setColumns] = useState(defaultColumns)

  // get table date
  async function getData (param) {
    let res = await http.get('/portfolioapi/PortfolioSummary/getdata', {
      strDataCope: serchParams.strDataCope,
      PageNum: pagination.current,
      PageSize: pagination.pageSize,
      ...param
    })
    const {UpdDate, ...tableData} = res || {}
    return tableData
  }


  return <div>
    <div style={{float: 'right'}}>
      <ChangeColumns Columns={columns} changeColumns={setColumns}/>
    </div>
    <Table pagination={{...pagination, showSizeChanger: true}} onChange={handleTableChange} showSorterTooltip={false} scroll={{ x: '100%' }} columns={column} loading={loading} dataSource={tableData}/>
  </div>
}