import style from './index.module.css'
import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { Checkbox, Select } from 'antd';
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

const Login = ({ params }) => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const [checked, setChecked] = useState(false);
    const [application, setApplication] = useState(null);


    function downloadBlobFile(type) {
        const env = process.env.REACT_APP_ENV === 'prod'
            ? 'prd'
            : process.env.REACT_APP_ENV === 'uat'
                ? 'np1' : 'np';

        if (type === 1) {
            return '/privacy/term-use-20220711.htm';
            return `https://ce2sdp${env}sta001.blob.core.chinacloudapi.cn/public-asserts/%E8%B4%A7%E8%BD%A6%E9%A1%B9%E7%9B%AE%E7%AE%A1%E7%90%86%E5%B9%B3%E5%8F%B0%20Term%20of%20Use%20-%20Clean.docx`;
        }
        if (type === 2) {
            return '/privacy/privacy-policy-20220711.htm';
            return `https://ce2sdp${env}sta001.blob.core.chinacloudapi.cn/public-asserts/%E7%91%9E%E5%A3%AB%E5%86%8D%E4%BF%9D%E9%99%A9%E8%B4%A7%E8%BD%A6%E9%A1%B9%E7%9B%AE%E7%AE%A1%E7%90%86%E5%B9%B3%E5%8F%B0%20PIPP%20-%20Clean.docx`;
        }
    }
    function onCheck(e) {
        setChecked(e.target.checked || false)
    }
    function onLogin() {
        instance.loginRedirect(loginRequest())
        localStorage.setItem('language', 'en')
    }
    return (
        <div className={style.loginPage} style={{ backgroundImage: "url('/img/swiss_login_bg.png')" }}>
            <img className={style.iconNew} src="/img/logoNew.png"></img>
            <div className={style.titleNew}>{t('navbar.platformName')}</div>
            <div className={style.loginBlock}>
                {/* <div className={style.agree}>
                    <Checkbox className={style.checkAgree} onChange={onCheck}></Checkbox>
                    <span>{t('navbar.agree1')} <a target="_blank" href={downloadBlobFile(1)}>{t('navbar.agreeLinkName1')}</a> </span>
                    <span>{t('navbar.agree2')} <a target="_blank" href={downloadBlobFile(2)}>{t('navbar.agreeLinkName2')}</a> </span>
                    <span className={style.agreeAlert}><br /> {t('navbar.agreeAlert')}</span>
                </div> */}
                {/* <div className={style.application}>
                    <span>{t('account.application')}: </span>
                    <span>
                        <Select className={style.selectApplication} size='small' onChange={setApplication}>
                            <Option value='aaa' key='aaa'>AAA Platform</Option>
                            <Option value='fleet' key='fleet'>Fleet Platform</Option>
                            <Option value='pricing' key='pricing'>AV Pricing Tool</Option>
                        </Select>
                    </span>
                </div> */}
                <a className={style.button}  onClick={onLogin}>
                    <img className={style.icon} src="/img/icon.png"></img>
                    <span>{t('navbar.login')}</span>
                </a>
            </div>
            <div className={style.footer}>
                <div className={style.footerItem}><span><a href='https://beian.miit.gov.cn/' target="_blank">京ICP备2020037404号</a></span>  <span>工信部网站 本站支持IPv6</span></div>
            </div>
        </div>
    )
}
export default Login;
