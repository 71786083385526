import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { useEffect, useState } from 'react';
import http from '../../../../http';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next'
import moment from 'moment';


echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer
]);

const formatTime = (time, range) => {
  let start, end;
  switch (range) {
    case 'Day':
      start = moment(time[0]).startOf('day');
      end = moment(time[1]).endOf('day');
      break;
    case 'Week':
      start = moment(time[0]).startOf('week');
      end = moment(time[1]).endOf('week');
      break;
    case 'Month':
      start = moment(time[0]).startOf('month');
      end = moment(time[1]).endOf('month');
      break
  }
  return {
    endTimestamp: end.valueOf(),
    startTimestamp: start.valueOf()
  }
}

export default function PreviewDataCollection ({ serchParams }) {
  const [chartData, setChartData] = useState(null)
  const [chartTitle, setChartTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const uid = serchParams.groupUUID

  const { t } = useTranslation();

  const formatFieldValue = () => {
    const { dateRange, date, group, valueType, strDataCope } = serchParams;
    return {
      dateRange,
      group,
      valueType,
      strDataCope,
      ...formatTime(date, dateRange)
    };
  }

  const onSubmit = () => {
    setLoading(true);
    http.get('/tmx-dashboard/tmx/tendency', formatFieldValue()).then(res => {
      setChartData(res);
      setChartTitle(() => {
        return serchParams.valueType === 'Duration'
          ? t('tmx.options.' + serchParams.valueType) + ' (h)'
          : serchParams.valueType === 'Mileage'
            ? t('tmx.options.' + serchParams.valueType) + ' (km)'
            : t('tmx.options.' + serchParams.valueType)
      });
    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    onSubmit()
  }, [])

  const setChart = () => {
    var chartDom = document.getElementById('main'+uid);
    echarts.init(chartDom).dispose();
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      color: ['#627D77', '#006E73', '#1455B4', '#9B14B4', 'rgba(98, 125, 119, 0.75)', '#00AA91', '#418CE1', '#D241C8', 'rgba(98, 125, 119, 0.5)', '#73E1A5', '#91C8FF', '#FA8CFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        }
      },
      legend: {
        type: 'scroll',
        icon: 'rect',
        itemWidth: 14,
        orient: 'vertical',
        right: 0,
        top: 'center',
        bottom: 20,
        textStyle: {
          width: 150,
          overflow: 'break'
        }
      },
      grid: {
        show: true,
        left: '3%',
        top: 10,
        right: 190,
        bottom: '3%',
        containLabel: true
      },
      yAxis: {
        type: 'value'
      },
      xAxis: {
        type: 'category',
        data: chartData.dims.map(one => one.slice(0, 10)),
        boundaryGap: true,
        axisTick: {
          alignWithLabel: true
        }
      },
      series: Object.keys(chartData.measures).map(deal => {
        return {
          name: deal,
          type: 'bar',
          stack: 'total',
          data: chartData.measures[deal]
        }
      })
    };

    option && myChart.setOption(option);
  }

  useEffect(() => {
    chartData && setChart()
  }, [chartData])

  return <div>
    <Spin spinning={loading}>
      <div style={{ color: '#627D77', fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', marginBottom: '8px', }}>{chartTitle}</div>
      <div id={`main${uid}`} style={{ height: 600, width: '100%' }}></div>
    </Spin>
  </div>
}