import { Select, Button, Spin } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import http, { onBlobExport, downloadBlob }  from '../../http';
import { useTranslation } from 'react-i18next'
import style from './Analysis.module.css'
import LossAnalysisChart from './components/LossAnalysisChart'
import { useState, useEffect } from 'react';
const { Option } = Select;


function handleChange (value) {
  console.log(`selected ${value}`);
}

const getInitData = () => {
  return http.get('/portfolioapi/Losscorrelationchart/GetInitData')
}

export default function LossCorrelation () {
  const { t } = useTranslation();

  const [paramsLoading, setParamsLoading] = useState(true)
  const [chartLoading, setChartLoading] = useState(true)
  const [featureData, setFeatureData] = useState([])
  const [valueData, setValueData] = useState([])
  const [featureSelect, setFeatureSelect] = useState()
  const [valueSelect, setValueSelect] = useState()
  const [chartList, setChartList] = useState([])
  const [Units, setUnits] = useState({})
  const [paramsValue, setParamsValue] = useState([])

  function getChartData () {
    setChartLoading(true)
    const value = valueSelect || '7'
    http.get('/portfolioapi/Losscorrelationchart/GetData', {
      strFeature: featureSelect || '6',
      strValue: value
    }).then(res => {
      if (featureData.length) {
        typeNameList(featureData, valueData)
      }
      let list = res.data
      if(value === '8' || value === '9' ){ // show percentage
        list.forEach(e => {
          e.Value = (e.Value * 100).toFixed(2)
        })
      }
      setUnits({
        UnitX: res.UnitX_en,
        UnitY: res.UnitY_en,
      })
      setChartList(list)
      setChartLoading(false)
    })
  }

  function typeNameList(featureList, valueList){
    let featureName = featureList.find(e => e.id === (featureSelect || '6')).typeName
    let valueName = valueList.find(e => e.id === (valueSelect || '7')).typeName
    setParamsValue([featureName, valueName])
  }



  useEffect(() => {
    getInitData().then(res => {
      setFeatureData(res.Feature)
      setValueData(res.Value)
      setFeatureSelect('6')
      setValueSelect('7')
      typeNameList(res.Feature, res.Value)
      getChartData()
      setParamsLoading(false)
    })
  }, [])


  const exportExcl = () => {
    let params = {
      la: localStorage.getItem('language'),
      strFeature: featureSelect,
      strValue: valueSelect,
    }
    onBlobExport('/portfolioapi/Losscorrelationchart/GetExcel', params, { 'method': 'get' }).then(res => {
      downloadBlob(res.data, 'Loss Correlation Chart')
    });
  }

  return <div>
    <div className={style.titleBox}>
      <span className={style.title}>{t('portfolio.lossCorrelationChart')}</span>
    </div>
    <div className={style.selectbox}>
      <Spin spinning={paramsLoading}>
        <div className={style.lossfeature}>
          <div className={style.pd}><span className={style.star}>*</span>{t('tmx.feature')}</div>
          <Select className={style.selects}
            allowClear
            placeholder="Please select"
            value={featureSelect}
            onChange={setFeatureSelect}>
            {featureData.map(e => <Option key={e.id}>{t(`groupname.${e.typeName}`)}</Option>)}
          </Select>
        </div>
        <div className={style.lossval}>
          <div className={style.pd}><span className={style.star}>*</span>{t('portfolio.value')}</div>
          <Select className={style.selects}
            allowClear
            placeholder="Please select"
            value={valueSelect}
            onChange={setValueSelect}>
            {valueData.map(e => <Option key={e.id}>{t(`tabletitle.${e.typeName}`)}</Option>)}
          </Select>
        </div>
      </Spin>
      <div className={style.lossbtn}>
        <Button className={style.submitbtn} onClick={getChartData} disabled={!featureSelect || !valueSelect}><SearchOutlined />{t('common.search')}</Button>
        <Button className={style.exportbtn} onClick={exportExcl}><DownloadOutlined />{t('common.export')}</Button>
      </div>
    </div>
    <Spin spinning={chartLoading}>
      {<div className={style.UnitX}>{t(`groupname.${paramsValue[0]}`) + `${Units.UnitX && Units.UnitX !== '-' ? ` : ${t(`units.${Units.UnitX}`)}` : ''}`}</div>}
      <LossAnalysisChart chartList={chartList} strValueLabel={paramsValue[1]} Units={Units} valueSelect={valueSelect}></LossAnalysisChart>
    </Spin>
  </div>
}