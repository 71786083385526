import { Table, Button, Modal, Form, Input, Select, DatePicker, Row, Col, InputNumber, Switch, Checkbox } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { DownloadOutlined, PlusOutlined, SettingOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import http, { onBlobExport, downloadBlob } from '../../http';
import moment from 'moment';
import { useModal } from '../../hooks/useModal';
import { useVerticalForm } from '../../hooks/form';
import { useTranslation } from 'react-i18next'
import { useTable } from '../../hooks/table';
function getApplicationList() {
    return http.get('/aaa/Application?Page=-1');
}
export default function Index() {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { form: queryForm, formProps, colProps } = useVerticalForm();

    const { tableData, onSearch, pagination, loading, setQuery } = useTable(getData);
    const [activeId, setActiveId] = useState(null);
    const [ApplicationOptions, getApplicationOptions] = useState([]);

    function getData(param) {
        return http.get('/aaa/Account', {
            Page: pagination.current,
            PageSize: pagination.pageSize,
            ...param
        })
    }
    const columns = [
        {
            title: t('account.account'),
            key: 'accountName',
            dataIndex: 'accountName'
        },
        {
            title: t('account.description'),
            key: 'accountDescription',
            dataIndex: 'accountDescription',
        },
        {
            title: t('account.application'),
            key: 'application',
            dataIndex: 'application'
        },
        {
            title: t('common.createdBy'),
            key: 'createdBy',
            dataIndex: 'createdBy',
        },
        {
            title: t('common.createdAt'),
            key: 'createdAt',
            dataIndex: 'createdAt',
            render: text => <span>{text ? moment(text).format('YYYY-MM-DD') : text} </span>
        },
        {
            title: t('common.editBy'),
            key: 'editBy',
            dataIndex: 'editBy',
        },
        {
            title: t('account.editDate'),
            key: 'editAt',
            dataIndex: 'editAt',
            render: text => <span>{text ? moment(text).format('YYYY-MM-DD') : text} </span>
        },
        {
            title: t('common.action'),
            key: 'delete',
            width: 200,
            fixed: 'right',
            render: item => (
                <div>
                    <Button icon={<DeleteOutlined />} style={{ marginRight: '10px' }} onClick={() => confirmDelete(item.id, item.name)}>{t('common.delete')}</Button>
                    <Button icon={<SettingOutlined />} onClick={() => onEdit(item.id, item.name)}>{t('common.edit')}</Button>
                </div>
            )
        },
    ];

    const confirmDelete = (id, name) => {
        Modal.confirm({
            title: t('common.confirm'),
            content: `${t('common.confirmDelete')} ?`,
            cancelButtonProps: {
                type: 'default',
                icon: <CloseCircleOutlined />
            },
            okButtonProps: {
                type: 'default',
                icon: <CheckCircleOutlined />
            },
            onOk() {
                return http.delete(`/aaa/Account/${id}`).then(onSearch);
            }
        });
    }
    const onEdit = id => {
        http.get(`/aaa/Account/${id}`).then(res => {
            updateModal(true);
            setActiveId(id);
            form.setFieldsValue(
                {
                    id: res.id,
                    accountName: res.accountName,
                    applicationId: res.applicationId,
                    application: res.application,
                    aType: res.aType,
                    accountDescription: res.accountDescription,
                    createdAt: res.createdAt,
                    createdBy: res.createdBy,
                    editAt: res.editAt,
                    editBy: res.editBy,
                })
        })
    }
    const onAdd = () => {
        form.resetFields();
        setVisible(true);
    }
    const {
        visible,
        setVisible,
        confirmLoading,
        setConfirmLoading,
        updateModal
    } = useModal();
    const handleOk = () => {
        setConfirmLoading(true);
        form.validateFields().then(values => {
            const id = form.getFieldValue('id');
            // values.startTimestamp = new Date(values.startTimestamp).getTime();
            // values.endTimestamp = new Date(values.endTimestamp).getTime();
            let promise;
            if (!id) {
                promise = http.post('/aaa/Account', values);
            } else {
                promise = http.put('/aaa/Account', { ...values, id });
            }
            return promise.then(res => {
                form.resetFields();
                setActiveId(null);
                updateModal(false);
            }).then(onSearch)
        }).finally(setConfirmLoading(false))
    }
    const handleCancel = () => {
        setActiveId(null);
        updateModal(false);
    }
    const handleSearch = (e) => {
        const values = queryForm.getFieldsValue();
        setQuery(values);
    }
    useEffect(() => {
        getApplicationList().then(res => {
            getApplicationOptions(
                res.data.map(one => {
                    return {
                        label: one.fullName,
                        value: one.id,
                    }
                })
            );
        })
    }, [])
    return <div className="container" style={{ paddingBottom: '50px' }}>
        <Form {...formProps} form={queryForm} name="basic">
            <Row align="bottom">
                <Col {...colProps}>
                    <Form.Item label={t('account.account')} name="AccountName">
                        <Input />
                    </Form.Item>
                </Col>
                <Col {...colProps} style={{ marginBottom: '24px' }}>
                    <Button icon={<SearchOutlined />} style={{ marginLeft: '32px' }} onClick={handleSearch}>{t('common.search')}</Button>
                </Col>
            </Row>
        </Form>
        <div style={{ marginBottom: '20px' }}>
            <Button icon={<PlusOutlined />} onClick={onAdd}>{t('common.create')}</Button>
        </div>
        <Table
            scroll={{ x: 1050 }}
            loading={loading}
            pagination={pagination}
            rowKey="id"
            columns={columns}
            dataSource={tableData} />
        <Modal
            width={600}
            title={activeId ? t('account.editAccount') : t('account.createAccount')}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            destroyOnClose
            footer={[
                <Button key="back" icon={<CloseCircleOutlined />} onClick={handleCancel}>
                    {t('common.cancel')}
                </Button>,
                <Button key="submit" icon={<CheckCircleOutlined />} onClick={handleOk}>
                    {t('common.ok')}
                </Button>
            ]}
            style={{ top: '10px' }}
        >
            <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                <Form
                    form={form}
                    name="basic"
                    layout="vertical">
                    <Form.Item
                        label={t('account.account')}
                        name="accountName"
                        rules={[{ required: true, message: t('common.pleaseInput') + t('account.account') }]}
                    >
                        <Input disabled={activeId} />
                    </Form.Item>
                    <Form.Item
                        label={t('account.application')}
                        name="applicationId"
                        rules={[{ required: true, message: t('common.pleaseInput') + t('account.application') }]}
                    >
                        <Select options={ApplicationOptions} disabled={activeId}></Select>
                    </Form.Item>
                    <Form.Item
                        label={t('account.accountDescription')}
                        name="accountDescription"
                        rules={[{ required: true, message: t('common.pleaseInput') + t('account.accountDescription') }]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                </Form>
            </div>
        </Modal>
    </div>
}
