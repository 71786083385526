import { Spin } from 'antd';
import { useState } from 'react';
import http from '../../../../http';
import { useEffect } from 'react'
import RiskAnalysisChart from '../../../RiskAnalysis/components/riskAnalysisChart'
import { useTranslation } from 'react-i18next'


export default function FeatureAnalysis ({serchParams}) {
  // default dispaly table head
  const [chartLoading, setChartLoading] = useState(true)
  const [chartData, setChartData] = useState({})
  const [selectParams, setSelectParams] = useState({
    strFeature: serchParams.strFeatureLabel,
    strValue: serchParams.strValueLabel,
  })
  const [Units, setUnits] = useState({})
  const { t } = useTranslation();

  const onFormSearch = async (paramas) => {
    setChartLoading(true)
    let res = await http.get('/vehicleriskapi/OnewayAnalysis/GetData', paramas)
    setUnits({
      UnitX: res.UnitX_en,
      UnitY: res.UnitY_en,
    })
    setChartData(res.data)
    setChartLoading(false)
  }


  useEffect(() => {
    onFormSearch({
      strDataCope: serchParams.strDataCope,
      strFeature: serchParams.strFeature,
      strValue: serchParams.strValue
    })
  }, [])

  return <div>
    <Spin spinning={chartLoading}>
      {<div style={{'position': 'absolute', 'left': '50%','transform': 'translateX(-50%)', 'top': '-20px'}}>{t(`OnewayRiskFeature.${selectParams.strFeature}`) + `${Units.UnitX && Units.UnitX !== '-' ? ` : ${t(`units.${Units.UnitX}`)}`:''}`}</div>}
      <div style={{marginTop: '26px'}}>
        <RiskAnalysisChart chartList={chartData} selectParams={selectParams} groupUUID={serchParams.groupUUID} Units={Units}/>
      </div>
    </Spin>
  </div>
}