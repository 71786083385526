import { Form, Input, Button, Select, Card, DatePicker, Radio, Tabs, Checkbox} from 'antd';
const CheckboxGroup = Checkbox.Group;

import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import http from '../../http';
import { useTranslation } from 'react-i18next'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
const { TabPane } = Tabs

export default function Index(props) {
  const { t } = useTranslation();

  const id = props.id;
  const [form] = Form.useForm();
  const defaultDate = moment().add(180, 'days')
  useEffect(() => {
    id && http.get(`aaa/User/${id}`).then(res => {
      form.setFieldsValue({
        displayName: res.displayName,
        login: res.login,
        email: res.email,
        password: res.password,
        confirmPassword: res.pasconfirmPasswordsword,
        role: res.role?.id,
        validity: moment(res.validity),
        accounts: res.accounts?.map(one => one.id),
        remark: res.remark,
        dataScope: res.dataScope
      });
      let checkAll = res.applicationScopeAll?.split(',')
      setCheckedAllList(checkAll)
      setTargetKeys(res.accounts?.map(one => one.id));
      let obj={}
      checkAll?.forEach(one=>{
        obj[one]=true
      })
      setDisabled(obj)
    })
  }, [])
  const history = useHistory();

  const onFinish = values => {
    const { value, label } = roleOptions.find(one => one.value === values.role);
    const accounts = []
    targetKeys?.forEach(accountId => {
      const target = accountList.find(one => one.value === accountId);
      if (target) {
        accounts.push({
          id: target.value,
          name: target.label
        })
      }
    })
    const param = {
      id,
      ...values,
      role: {
        id: value,
        name: label,
      },
      accounts,
      applicationScopeAll: values.dataScope === false ? checkedAllList.toString(): '',
    }
    if (id) {
      http.put('aaa/User', param).then(res => {
        history.push('/aaa/user-management')
      })
    } else {
      http.post('aaa/User', param).then(res => {
        history.push('/aaa/user-management')
      })
    }

    console.log('Success:', param);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const [targetKeys, setTargetKeys] = useState();
  const onTransferChange = (keys) => {
    setTargetKeys(keys);
  };

  const [roleOptions, setRoleOptions] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  useEffect(() => {
    http.get('/fleet-auth/role/list').then(res => {
      setRoleOptions(roleOptions =>
        res.map(one => {
          return {
            label: one.name,
            value: one.id
          }
        })
      );
    })
    http.get('/aaa/Account?Page=-1').then(res => {
      setAccountList(accountList =>
        res.data.map(one => {
          return {
            // title: one.accountName,
            // key: one.id,
            label: one.accountName,
            value: one.id,
            application: one.application,
          }
        })
      )
    })
    http.get('/aaa/Application?Page=-1').then(res => {
      setApplicationList(res.data)
    })
  }, [])
  const [checkedAllList, setCheckedAllList] = useState([]);
  const [disabled, setDisabled] = useState({});

  const onCheckAllChange = (target,name) =>{
      let arr = checkedAllList
      if(target.length>0){
          arr?.push(name)
          arr = Array.from(new Set(arr))
          setCheckedAllList(arr)
      } else {
          const index = arr?.indexOf(name)
          if(index>-1){
              arr?.splice(index, 1)
          }
          setCheckedAllList(arr)
      }
      let obj={}
      arr.forEach(one=>{
        obj[one]=true
      })
      setDisabled(obj)
  }
  return <div className="container" style={{ width: '600px' }}>
    <div>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item >
          <Button icon={<CheckCircleOutlined />} style={{ marginRight: '10px' }} htmlType="submit">{t('common.ok')}</Button>
          <Button icon={<CloseCircleOutlined />} > <Link to="/aaa/user-management">{t('common.cancel')}</Link></Button>
        </Form.Item>
        <Form.Item
          label={t('user.username')}
          name="login"
          rules={[{ required: true, message: t('common.pleaseInput') + t('user.username') }]}
        >
          <Input disabled={id} />
        </Form.Item>
        <Form.Item
          label={t('user.password')}
          name="password"
          rules={[{ required: !id, pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/, message: t('user.error.password') }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={t('user.confirmPassword')}
          name="confirmPassword"
          rules={[{ required: !id, pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/, message: t('user.error.password') }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={t('user.role')}
          name="role"
          rules={[{ required: true, message: t('common.pleaseSelect') + t('user.role') }]}
        >
          <Select options={roleOptions}></Select>
        </Form.Item>
        <Form.Item
          label={t('user.validity')}
          name="validity"
          initialValue={defaultDate}
          rules={[{ required: true, message: t('common.pleaseInput') + t('user.validity') }]}
        >
          <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          label={t('user.displayName')}
          name="displayName"
          rules={[{ required: true, message: t('common.pleaseInput') + t('user.displayName') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('user.email')}
          rules={[{ required: true, message: t('common.pleaseInput') + t('user.email') }]}
          name="email"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('user.userDescription')}
          name="remark"
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={t('user.dataScope')}
          name="dataScope"
          rules={[{ required: true, message: t('common.pleaseInput') + t('user.dataScope') }]}
        >
          <Radio.Group>
            <Radio value={true}>{t('user.all')}</Radio>
            <Radio value={false}>{t('user.select')}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item shouldUpdate >
          {() => {
            return form.getFieldValue('dataScope') === false ?
              <Form.Item name="accounts">
                <Tabs
                  defaultActiveKey="0"
                >
                  {applicationList.map((i, index) => {
                    return (
                      <TabPane tab={i.fullName} key={index}>
                        <Card size="small">
                        {/* {`${disabled[i.name] ? accountList.filter(one => one.application === i.name)?.length : targetKeys?.length} / ${accountList.filter(one => one.application === i.name)?.length}`} */}
                          <CheckboxGroup
                            defaultValue={checkedAllList}
                            style={{
                              width: '100%',
                            }}
                            onChange={(checkedValue) => { onCheckAllChange(checkedValue, i.name) }}
                          >
                            <Checkbox value={i.name} >
                              {t('common.checkAll')}
                            </Checkbox>
                          </CheckboxGroup>
                          <CheckboxGroup style={{display:'flex', flexDirection: 'column'}} disabled={disabled[i.name]} options={accountList.filter(one => one.application === i.name)} onChange={onTransferChange} value={targetKeys}/>
                        </Card>
                        {/* <Transfer
                          dataSource={accountList.filter(one => one.application === i.name)}
                          targetKeys={targetKeys}
                          onChange={onTransferChange}
                          render={item => item.title}
                        /> */}
                      </TabPane>
                    )
                  })}
                </Tabs>
              </Form.Item> : null
          }}
        </Form.Item>
      </Form>
    </div>
  </div>
}
