import { Table, Button } from 'antd';
import { useTableWithLocalPagination } from '../../../../hooks/table';
import http, {onBlobExport, downloadBlob} from '../../../../http';
import { useTranslation } from 'react-i18next'
import { useState } from 'react';
import TableColumnFilter from '../../../../components/TableColumnFilter'
import InstallDaysDistribution from '../../../PartnerEvaluation/components/InstallDaysDistribution'
import VehicleRegion from '../../../PartnerEvaluation/components/VehicleRegion'


export default function PreviewAnalysis ({serchParams}) {
  const { t } = useTranslation();
  const { tableData, pagination, loading } = useTableWithLocalPagination(getData);

  function getData() {
    return http.get('/partner-eval/evaluation/deal/detail', {strDataCope: serchParams.strDataCope}).then(res => {
      let result = res.sort((a, b) => b.connectedVehicleCount - a.connectedVehicleCount)
      const filterData = result.filter(item => {
        return Object.keys(serchParams).every(key => {
            if(key === 'strDataCope') return true
            if (!serchParams[key]) return true;
            console.log(item, key, item[key])
            return item[key] && item[key].includes(serchParams[key]);
        })
      })
      console.log('------filterData-----', filterData)
      return filterData
    })
  }

  function onExport(dealName) {
    const exportFn = dealName 
      ? onBlobExport(`/partner-eval/evaluation/deal/detail/vehicle/download?dealName=${dealName}`)
      : onBlobExport('/partner-eval/evaluation/deal/detail/download', form.getFieldsValue());
    exportFn.then(res => {
      downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*)/)[1])
    });
  }

  const [columns, setColumns] = useState([
    {
      title: t('partner.deal'),
      key: 'dealName',
      dataIndex: 'dealName',
      width: 200,
    },
    {
      title: t('partner.partner'),
      key: 'partnerName',
      dataIndex: 'partnerName',
      width: 250
    },
    {
      title: t('partner.insurer'),
      key: 'insurerName',
      dataIndex: 'insurerName',
      width: 350
    },
    {
      title: t('partner.connectedVehicleCount'),
      key: 'connectedVehicleCount',
      dataIndex: 'connectedVehicleCount',
      sorter: (a, b) => a.connectedVehicleCount - b.connectedVehicleCount
    },
    {
      title: t('partner.aebDeviceCount'),
      key: 'aebDeviceCount',
      dataIndex: 'aebDeviceCount',
      hide: true
    },
    {
      title: t('partner.adasDeviceCount'),
      key: 'adasDeviceCount',
      dataIndex: 'adasDeviceCount',
      hide: true
    },
    {
      title: t('partner.activeVehicleCount'),
      key: 'activeVehicleCount',
      dataIndex: 'activeVehicleCount',
      sorter: (a, b) => a.activeVehicleCount - b.activeVehicleCount,
    },
    {
      title: t('partner.activeVehicleRate'),
      key: 'vehicleActiveRate',
      dataIndex: 'vehicleActiveRate',
      sorter: (a, b) => a.vehicleActiveRate - b.vehicleActiveRate,
      render: text => (text * 100).toFixed(2) + '%'
    },
    {
      title: t('partner.averageInstallDays'),
      key: 'avgInstallDay',
      dataIndex: 'avgInstallDay',
      sorter: (a, b) => a.avgInstallDay - b.avgInstallDay,
      render: text => Math.round(text)
    },
    {
      title: t('partner.installOverDueVehicleCount'),
      key: 'installOverDueVehicleCount',
      dataIndex: 'installOverDueVehicleCount',
      sorter: (a, b) => a.installOverDueVehicleCount - b.installOverDueVehicleCount,
    },
    {
      title: t('partner.installOverdueRate'),
      key: 'installOverDueRate',
      dataIndex: 'installOverDueRate',
      sorter: (a, b) => a.installOverDueRate - b.installOverDueRate,
      render: text => (text * 100).toFixed(2) + '%'
    },
    {
      title: t('partner.tripCount'),
      key: 'tripCount',
      dataIndex: 'tripCount',
      sorter: (a, b) => a.tripCount - b.tripCount,
    },
    {
      title: t('partner.tripDataQualityCorrectRate'),
      key: 'tripCorrectRate',
      dataIndex: 'tripCorrectRate',
      sorter: (a, b) => a.tripCorrectRate - b.tripCorrectRate,
      render: text => (text * 100).toFixed(2) + '%'
    },
    {
      title: t('partner.lossRatio'),
      key: 'lossRatio',
      dataIndex: 'lossRatio',
      sorter: (a, b) => a.lossRatio - b.lossRatio,
      render: text => text >=0 ? (text * 100).toFixed(2) + '%' : '-'
    },
    {
      title: t('partner.installDaysDistribution'),
      key: 'installDaysDistribution',
      render: (text, record) => <InstallDaysDistribution type="deal" dealName={record.dealName} insurerName={record.insurerName} partnerName={record.partnerName} />,
      width: 100
    },
    {
      title: t('partner.region'),
      key: 'region',
      render: (text, record) => <VehicleRegion type="deal" dealName={record.dealName} insurerName={record.insurerName} partnerName={record.partnerName} />
    },
    {
      title: t('common.export'),
      key: 'export',
      render: (text, record) => <Button type="link" onClick={() => onExport(record.dealName)}>{t('common.export')}</Button>
    }
  ]);

  return <div>
    <div style={{float: 'right', marginBottom: '10px'}}>
      <TableColumnFilter columns={columns} onSetColumns={setColumns} />
    </div>
    <Table
        size="small"
        scroll={{ x: 1800}}
        loading={loading}
        pagination={pagination}
        rowKey="id"
        columns={columns.filter(v => !v.hide)}
        dataSource={tableData} />
  </div>
}