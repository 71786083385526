import { Table, Button, Modal, Form, Input, Row, Col } from 'antd';
import { useEffect, useState, useRef} from 'react';
import {DownloadOutlined, PlusOutlined, SettingOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined} from '@ant-design/icons'
import http, {onBlobExport, downloadBlob} from '../../http';
import moment from 'moment';
import {useModal} from '../../hooks/useModal';
import {useVerticalForm} from '../../hooks/form';

import {useTranslation} from 'react-i18next'
import {useTable} from '../../hooks/table';

export default function Index() {
  const { t } = useTranslation();
  const {form:queryForm, formProps, colProps} = useVerticalForm();
  const [form] = Form.useForm();
  const {tableData, onSearch, pagination, loading, setQuery} = useTable(getData);
  const [activeId, setActiveId] = useState(null);


  function getData(param) {
    return http.get('/Vendor', { 
      Page: pagination.current,
      PageSize: pagination.pageSize, 
      ...param
    })
  }
  const columns = [
    {
      title: t('vendor.vendorName'),
      key: 'vendorName',
      dataIndex: 'vendorName',
    },
    {
      title: t('user.email'),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: t('vendor.contactPerson'),
      key: 'contactPerson',
      dataIndex: 'contactPerson',
    },
    {
      title: t('vendor.phoneNo'),
      key: 'phoneNo',
      dataIndex: 'phoneNo',
    },
    {
      title: t('common.editAt'),
      key: 'editAt',
      dataIndex: 'editAt',
      render: text => <span>{text ? moment(text).format('YYYY-MM-DD') : text} </span>
    },
    {
      title: t('common.editBy'),
      key: 'editBy',
      dataIndex: 'editBy'
    },
    {
      title: t('common.action'),
      key: 'delete',
      render: item => (
        <div>
          <Button icon={<DeleteOutlined />}  style={{marginRight: '10px'}} onClick={() => confirmDelete(item.id, item.vendorName)}>{t('common.delete')}</Button>
          <Button icon={<SettingOutlined />} onClick={() => onEdit(item.id)}>{t('common.edit')}</Button>
        </div>
      )
    },
  ];

  const confirmDelete = (id, name) => {
    Modal.confirm({
      title: t('common.confirm'),
      content: `${t('common.confirmDelete')} ${t('vendor.vendorName')} [${name}] ?`,
      cancelButtonProps: {
        type: 'default',
        icon: <CloseCircleOutlined />
      },
      okButtonProps: {
        type: 'default',
        icon: <CheckCircleOutlined />
      },
      onOk() {
        return http.delete(`/Vendor/${id}`).then(onSearch);
      }
    });
  }
  const onEdit = id => {
    http.get(`/Vendor/${id}`).then(res => {
      updateModal(true);
      setActiveId(id);
      form.setFieldsValue({
        id: res.id,
        vendorName: res.vendorName,
        email:res.email,
        contactPerson: res.contactPerson,
        phoneNo: res.phoneNo
      })
    })
  }
  const onAdd = () => {
    form.resetFields();
    setVisible(true);
  }
  const {
    visible,
    setVisible,
    confirmLoading,
    setConfirmLoading,
    updateModal
  } = useModal();
  const handleOk = () => {
    setConfirmLoading(true);
    form.validateFields().then(values => {
      const id = form.getFieldValue('id');
      let promise;
      if (!id) {
        promise = http.post('/Vendor', values);
      } else {
        promise = http.put('/Vendor', {...values, id});
      }
      return promise.then(res => {
        setActiveId(null);
        form.resetFields();
        updateModal(false);
      }).then(onSearch)
    }).finally(setConfirmLoading(false))
  }
  const handleCancel = () => {
    setActiveId(null);
    updateModal(false);
  }
  const handleSearch = (e) => {
    const values = queryForm.getFieldsValue();
    setQuery(values);
  }
  const handleExport = e => {
    const values = queryForm.getFieldsValue();
    onBlobExport('/Vendor/Export', values).then(res => {
      downloadBlob(res.data, res.headers['content-disposition'].match(/filename=(.*);/)[1])
    });
  }
  return <div className="container">
    <Form {...formProps} form={queryForm} name="basic">
        <Row align="bottom">
          <Col {...colProps}>
            <Form.Item label={t('vendor.vendorName')} name="vendorName">
              <Input />
            </Form.Item>
          </Col>
          <Col {...colProps} style={{marginBottom: '24px'}}>
            <Button icon={<SearchOutlined />} style={{marginLeft: '32px'}}  onClick={handleSearch}>{t('common.search')}</Button>
            <Button style={{marginLeft: '32px'}} icon={<DownloadOutlined />} onClick={handleExport}>{t('common.export')}</Button>
          </Col>
        </Row>
    </Form>
    <div style={{marginBottom: '20px'}}>
      <Button icon={<PlusOutlined />} onClick={onAdd}>{t('common.create')}</Button>
    </div>
    <Table
      tableLayout='fixed'
      loading={loading}
      pagination={pagination}
      rowKey="id"
      columns={columns}
      dataSource={tableData} />
      <Modal
        width={600}
        title={activeId ? t('vendor.editVendor'): t('vendor.createVendor')}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Button key="back" icon={<CloseCircleOutlined />} onClick={handleCancel}>
            {t('common.cancel')}
          </Button>,
          <Button key="submit" icon={<CheckCircleOutlined />} onClick={handleOk}>
            {t('common.ok')}
          </Button>
        ]}
        destroyOnClose
      >
        <Form
          form={form}
          name="basic"
          layout="vertical">
          <Form.Item
            label={t('vendor.vendorName')}
            name="vendorName"
            rules={[{ required: true, message: t('common.pleaseInput') + t('vendor.vendorName') }]}
          >
            <Input maxLength="40"/>
          </Form.Item>
          <Form.Item
            label={t('user.email')}
            name="email"
            rules={[{required: true, message: t('user.error.email')}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('vendor.contactPerson')}
            name="contactPerson"
          >
            <Input maxLength="10"/>
          </Form.Item>
          <Form.Item
            label={t('vendor.phoneNo')}
            name="phoneNo"
          >
            <Input maxLength="20"/>
          </Form.Item>
        </Form>
      </Modal>
  </div> 
}
