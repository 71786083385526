import style from './components.module.css'
import PreviewLossCorrelationChart from './FromPlatformPreview/PreviewLossCorrelationChart'
import PreviewLossPivotTable from './FromPlatformPreview/PreviewLossPivotTable'
import PreviewAnalysis from './FromPlatformPreview/PreviewAnalysis'
import PreviewFeatureAnalysis from './FromPlatformPreview/PreviewFeatureAnalysis'
import PreviewDatabase from './FromPlatformPreview/PreviewDatabase'
import PreviewRiskChart from './FromPlatformPreview/PreviewRiskChart'
import PreviewVehicleDistribution from './FromPlatformPreview/PreviewVehicleDistribution'
import PreviewVehicleRiskFeatureDetail from './FromPlatformPreview/PreviewVehicleRiskFeatureDetail'
import PreviewDataCollection from './FromPlatformPreview/PreviewDataCollection'
import PreviewDataQuality from './FromPlatformPreview/PreviewDataQuality'
import PreviewDataInsightByType from './FromPlatformPreview/PreviewDataInsightByType'
import PreviewDataInsightByGroup from './FromPlatformPreview/PreviewDataInsightByGroup'
import PreviewDataInsightCorrelationMatrix from './FromPlatformPreview/PreviewDataInsightCorrelationMatrix'
import PreviewAccidentInquire from './FromPlatformPreview/PreviewAccidentInquire'
import PreviewPartnerEvaluation from './FromPlatformPreview/PreviewPartnerEvaluation'
import PreviewDealEvaluation from './FromPlatformPreview/PreviewDealEvaluation'
import PreviewTripQuality from './FromPlatformPreview/PreviewTripQuality'
import PreviewClaimReport from './FromPlatformPreview/PreviewClaimReport'
import PreviewDIY from './FromPlatformPreview/PreviewDIY'


export default function SectionPreview ({SectionData, DataScope=[]}) {
  console.log('-----DataScope-DataScope', DataScope)
  const strDataCope = DataScope.toString()
  const serchParams = {...SectionData.serchParams, strDataCope }
  return <div className={style.previewBox}>
    <div className={style.SectionTitle}>{SectionData.strSectionTitle}</div>
    <div className={style.Content}>{SectionData.strContent}</div>
    <div className={style.SectionTitle}>{SectionData.strChartTtile}</div>
    {SectionData.strChart === 1 && <div>
      {SectionData.strFunctionPage === '1'  && <PreviewDataCollection serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '2'  && <PreviewDataQuality serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '3'  && <PreviewDataInsightByType serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '4'  && <PreviewDataInsightByGroup serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '5'  && <PreviewDataInsightCorrelationMatrix serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '6'  && <PreviewAccidentInquire serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '7'  && <PreviewClaimReport serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '8'  && <PreviewPartnerEvaluation serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '9'  && <PreviewDealEvaluation serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '10' && <PreviewTripQuality serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '11' && <PreviewAnalysis serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '12' && <PreviewLossPivotTable serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '13' && <PreviewLossCorrelationChart serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '14' && <PreviewRiskChart serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '15' && <PreviewVehicleDistribution serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '16' && <PreviewVehicleRiskFeatureDetail serchParams={serchParams}/>}
      {SectionData.strFunctionPage === '17' && <PreviewFeatureAnalysis serchParams={serchParams}/>}
    </div>}
    {SectionData.strChart === 2 && <div>
      <PreviewDatabase serchParams={SectionData}/>
      </div>}
    {SectionData.strChart === 3 && <PreviewDIY serchParams={SectionData}/>}
  </div>
}