import { Table, Row, Col, Button, Modal, Form, Input, Select } from 'antd';
import http, { onBlobExport, downloadBlob } from '../../http';
import TableColumnFilter from '../../components/TableColumnFilter';
import {DownloadOutlined, PlusOutlined, SettingOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined} from '@ant-design/icons'
import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useTable } from '../../hooks/table';
import { useVerticalForm } from '../../hooks/form';
import moment from "moment";
import { useDealList } from '../../data/deal';
import { useInsuranceList } from '../../data/insurer';
import { FileUpload } from './components/Upload';
const ClaimAggData = () => {
    const { t } = useTranslation();
    const { tableData, pagination, loading, setQuery, handleTableChange } = useTable(getData);

    const { dealList } = useDealList();
    const { insuranceList } = useInsuranceList();
    function getData(param) {
        return http.get('/system-setting/claimAgg/search', {
            orderBy: 'totalLossPaid',
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            ...param
        })
    }
    const fieldsColumns = [
        { title: t("fields.policyNumber"), dataIndex: 'policyNumber' },
        { title: t("claimAgg.asOfDate"), dataIndex: 'asOfDate', render: date => moment(date).format('YYYY-MM-DD'), sorter: true },
        { title: t("fields.insurerName"), dataIndex: 'insurerName' },
        { title: t("insurer.branch"), dataIndex: 'clientBranch', hide: true },
        { title: t("insurer.subBranch"), dataIndex: 'clientSubBranch', hide: true },
        { title: t("fields.deal"), dataIndex: 'deal' },
        { title: t("fields.vin"), dataIndex: 'vin' },
        { title: t("fields.plateNumber"), dataIndex: 'plateNumber' },
        { title: t("claimAgg.totalClaimCount"), dataIndex: 'totalCaseCount' },
        { title: t("claimAgg.totalLossPaid"), dataIndex: 'totalLossPaid', sorter: true },
        { title: t("claimAgg.totalLossPaidMod"), dataIndex: 'totalLossPaidMod', sorter: true, hide: true },
        { title: t("claimAgg.totalLossPaidVtpl"), dataIndex: 'totalLossPaidVtpl', sorter: true, hide: true},
        { title: t("claimAgg.totalLossPaidPdl"), dataIndex: 'totalLossPaidPdl', hide: true },
        { title: t("claimAgg.totalLossPaidBil"), dataIndex: 'totalLossPaidBil', hide: true },
        { title: t("claimAgg.totalLossPaidTPLOther"), dataIndex: 'totalLossPaidTPLOther', hide: true },
        // { title: t("claimAgg.totalLossPaidOther"), dataIndex: 'totalLossPaidOther', sorter: true },
        { title: t("claimAgg.totalOutstanding"), dataIndex: 'totalLossOutstanding', sorter: true },
        { title: t("claimAgg.totalOutstandingMod"), dataIndex: 'totalLossOutstandingMod', sorter: true, hide: true },
        { title: t("claimAgg.totalOutstandingVtpl"), dataIndex: 'totalLossOutstandingVtpl', sorter: true, hide: true },
        { title: t("claimAgg.totalOutstandingPdl"), dataIndex: 'totalLossOutstandingPdl', hide: true },
        { title: t("claimAgg.totalOutstandingBil"), dataIndex: 'totalLossOutstandingBil', hide: true },
        { title: t("claimAgg.totalOutstandingTPLOther"), dataIndex: 'totalLossOutstandingTPLOther', hide: true },
        { title: t("claimAgg.totalOutstandingOther"), dataIndex: 'totalLossOutstandingOther', sorter: true, hide: true },
        { title: t("claimAgg.totalSubrogation"), dataIndex: 'totalAmountSubrogation', sorter: true, hide: true }
    ];
    const commonColumns = [
        {
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: time => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            width: 120,
            hide: true
        },
        {
            title: t('common.createdBy'),
            dataIndex: 'createdBy',
            hide: true
        },
        {
            title: t('common.updatedAt'),
            dataIndex: 'updatedAt',
            render: time => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            width: 120,
            hide: true
        },
        {
            title: t('common.updatedBy'),
            dataIndex: 'updatedBy',
            hide: true
        },
    ]
    const [columns, setColumns] = useState([
        ...fieldsColumns,
        ...commonColumns,
        {
            title: t("common.action"),
            fixed: 'right',
            hide: true,
            render: item => <Button icon={<DeleteOutlined />} onClick={() => confirmDelete(item)}>{t('common.delete')}</Button>
        }
    ])
    const { form, formProps, colProps } = useVerticalForm();
    const handleSearch = (e) => {
        const values = form.getFieldsValue();
        setQuery(values);
    }
    const confirmDelete = (item) => {
        Modal.confirm({
            title: t('common.confirm'),
            content: `${t('common.confirmDelete')} [${item.policyNumber}] ?`,
            cancelButtonProps: {
                type: 'default',
                icon: <CloseCircleOutlined />
              },
              okButtonProps: {
                type: 'default',
                icon: <CheckCircleOutlined />
              },
            onOk() {
                return http.delete(`/system-setting/claimAgg/delete?id=${item.id}`).then(handleSearch);
            }
        });
    }
    const handleExport = e => {
        const values = form.getFieldsValue();
        onBlobExport('/system-setting/claimAgg/export', values).then(res => {
            downloadBlob(res.data, 'claim-agg-data.xlsx')
        });
    }
    return <div className="container">
        <Form {...formProps} form={form} name="basic">
            <Row align="bottom">
                <Col {...colProps}>
                    <Form.Item label={t('fields.policyNumber')} name="policyNumber">
                        <Input />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.deal')} name="tenantId">
                        <Select options={dealList} allowClear/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.insurer')} name="insurerId">
                        <Select options={insuranceList} allowClear/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.plateNumber')} name="plateNumber">
                        <Input />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label={t('fields.vin')} name="vin">
                        <Input />
                    </Form.Item>
                </Col>
                <Col {...colProps} style={{ marginBottom: '24px' }}>
                    <Button icon={<SearchOutlined />}  onClick={handleSearch}>{t('common.search')}</Button>
                    <Button icon={<DownloadOutlined />} style={{ marginLeft: '32px' }} onClick={handleExport}>{t('common.export')}</Button>
                </Col>
            </Row>
        </Form>
        <Row justify="space-between" align="middle">
            <FileUpload
                url="/system-setting/claimAgg"
                template={{
                    method: "get",
                    fileName: "claims-agg-template.xlsx"
                }}
                onSubmit={handleSearch}
                columns={fieldsColumns}
                tableProps={{scroll:{ x: 2400 }}}
            />
            <TableColumnFilter columns={columns} onSetColumns={setColumns} />
        </Row>
        <Table
            size="small"
            scroll={{x: columns.filter(column => !column.hide).length * 120}}
            loading={loading}
            pagination={pagination}
            rowKey="id"
            onChange={handleTableChange}
            columns={columns.filter(column => !column.hide)}
            dataSource={tableData} />
    </div>
}
export default ClaimAggData;