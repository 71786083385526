import { Table, Button, Modal, Form, Input, Tree, Row, Col, Radio, Card, Tabs, Checkbox } from 'antd';
const CheckboxGroup = Checkbox.Group;
const { TabPane } = Tabs
import { useEffect, useState, useRef } from 'react';
import { DownloadOutlined, PlusOutlined, SettingOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import http from '../../http';
import moment from 'moment';
import { useModal } from '../../hooks/useModal';
import { useVerticalForm } from '../../hooks/form';

import { useTranslation } from 'react-i18next'
import { useRole, useApplicationList } from './data';
import { useTabs } from './tab';
import { useMemo } from 'react';
export default function Index() {
    const { t } = useTranslation();
    const { form: queryForm, formProps, colProps } = useVerticalForm();
    const [form] = Form.useForm();
    const appTabList = useTabs();
    const { data, loading, onSearch } = useRole();

    const [activeId, setActiveId] = useState(null);
    const { data: applicationData, treeData: applicationTreeData } = useApplicationList(activeId)

    const [checkedKeysMap, setCheckedKeysMap] = useState({});

    const onCheck = (name, keys) => {
        setCheckedKeysMap({
            ...checkedKeysMap,
            [name]: keys
        })
    };

    const columns = [
        {
            title: t('role.roleName'),
            key: 'roleName',
            dataIndex: 'name'
        },
        {
            title: t('common.editAt'),
            key: 'editAt',
            dataIndex: 'editAt',
            render: text => <span>{text ? moment(text).format('YYYY-MM-DD') : text} </span>
        },
        {
            title: t('common.editBy'),
            key: 'editBy',
            dataIndex: 'editByUserName'
        },
        {
            title: t('common.createdAt'),
            key: 'createdAt',
            dataIndex: 'createAt',
            render: text => <span>{text ? moment(text).format('YYYY-MM-DD') : text} </span>
        },
        {
            title: t('common.createdBy'),
            key: 'createdBy',
            dataIndex: 'createByUserName'
        },
        {
            title: t('common.action'),
            key: 'delete',
            render: item => (
                <div>
                    <Button icon={<DeleteOutlined />} style={{ marginRight: '10px' }} onClick={() => confirmDelete(item.id, item.name)}>{t('common.delete')}</Button>
                    <Button icon={<SettingOutlined />} onClick={() => onEdit(item)}>{t('common.edit')}</Button>
                </div>
            )
        },
    ];

    const confirmDelete = (id, name) => {
        Modal.confirm({
            title: t('common.confirm'),
            content: `${t('common.confirmDelete')} ${t('role.roleName')} [${name}] ?`,
            cancelButtonProps: {
                type: 'default',
                icon: <CloseCircleOutlined />
            },
            okButtonProps: {
                type: 'default',
                icon: <CheckCircleOutlined />
            },
            onOk() {
                return http.post(`/fleet-auth/role/remove?id=${id}`).then(onSearch);
            }
        });
    }
    const [checkedAllList, setCheckedAllList] = useState([]);
    const [disabled, setDisabled] = useState({});

    const onCheckAllChange = (target, name) => {
        let arr = checkedAllList
        if (target.length > 0) {
            arr?.push(name)
            arr = Array.from(new Set(arr))
            setCheckedAllList(arr)
        } else {
            const index = arr?.indexOf(name)
            if (index > -1) {
                arr?.splice(index, 1)
            }
            setCheckedAllList(arr)
        }
        let obj = {}
        arr.forEach(one => {
            obj[one] = true
        })
        setDisabled(obj)
    }

    const onEdit = (item) => {
        setVisible(true);
        setActiveId(item.id);
        form.setFieldsValue({
            roleName: item.name,
            description: item.description,
            isAllowAll: item.fullAccessToApplications,
            accounts: item.applicationInfosList,
            // checkAll:item.applicationScopeAll
        })
        let allArr = item.applicationScopeAll ? item.applicationScopeAll.split(',') : []
        setCheckedAllList(allArr)
        let obj = {}
        allArr.forEach(one => {
            obj[one] = true
        })
        setDisabled(obj)
    }

    useEffect(() => {
        if (!applicationData.length) return;

        const selectedList = applicationData.filter(one => one.selected);

        if (selectedList.map(one => one.id).length === applicationData.length) {
            form.setFieldsValue({ permission: 1 })
            return;
        }

        const map = {};
        selectedList.forEach(one => {
            if (map[one.application]) {
                map[one.application].push(one.id)
            } else {
                map[one.application] = [one.id]
            }
        })
        setCheckedKeysMap(map);
        form.setFieldsValue({ permission: 0 });

    }, [applicationData])

    const onAdd = () => {
        setCheckedKeysMap({});
        form.setFieldsValue({ permission: 1 });
        setVisible(true);
    }
    const {
        visible,
        setVisible,
        confirmLoading,
        setConfirmLoading,
    } = useModal();
    const handleOk = () => {
        return form.validateFields().then(values => {
            setConfirmLoading(true);
            const param = {
                applicationScopeAll: values.isAllowAll === false ? checkedAllList.toString() : '',
                isAllowAll: values.isAllowAll,
                description: values.description,
                applicationIds: values.isAllowAll === false ? Object.values(checkedKeysMap).flat() : [],
                roleName: values.roleName,
            };
            return activeId ? http.post('/fleet-auth/role/update', { ...param, roleId: activeId }) : http.post('/fleet-auth/role/create', param);
        }).then(() => {
            onSearch();
            reset();
        }).finally(() => {
            setConfirmLoading(false);
        })
    }
    const handleCancel = () => {
        reset();
    }
    const handleSearch = (e) => {
        const values = queryForm.getFieldsValue();
        onSearch(values);
    }
    const reset = () => {
        setActiveId(null);
        setVisible(false);
        form.resetFields();
    }
    const count = useMemo(() => {
        return Object.values(checkedKeysMap).flat().length
    }, [checkedKeysMap])
    return <div className="container" style={{ paddingBottom: '50px' }}>
        <Form {...formProps} form={queryForm} name="basic">
            <Row align="bottom">
                <Col {...colProps}>
                    <Form.Item label={t('role.roleName')} name="roleName">
                        <Input />
                    </Form.Item>
                </Col>
                <Col {...colProps} style={{ marginBottom: '24px' }}>
                    <Button icon={<SearchOutlined />} style={{ marginLeft: '32px' }} onClick={handleSearch}>{t('common.search')}</Button>
                </Col>
            </Row>
        </Form>
        <div style={{ marginBottom: '20px' }}>
            <Button icon={<PlusOutlined />} onClick={onAdd}>{t('common.create')}</Button>
        </div>
        <Table
            loading={loading}
            pagination={false}
            rowKey="id"
            columns={columns}
            dataSource={data} />
        <Modal
            width={600}
            title={activeId ? t('role.editInsurer') : t('role.createInsurer')}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            destroyOnClose
            footer={[
                <Button key="back" icon={<CloseCircleOutlined />} onClick={handleCancel}>
                    {t('common.cancel')}
                </Button>,
                <Button key="submit" icon={<CheckCircleOutlined />} onClick={handleOk}>
                    {t('common.ok')}
                </Button>
            ]}
            style={{ top: '10px' }}
        >
            <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                <Form
                    form={form}
                    name="basic"
                    layout="vertical">
                    <Form.Item
                        label={t('role.roleName')}
                        name="roleName"
                        rules={[{ required: true, message: t('common.pleaseInput') + t('role.roleName') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t('role.roledescription')}
                        name="description"
                        rules={[{ required: true, message: t('common.pleaseInput') + t('role.roledescription') }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        label={t('role.permission')}
                        name="isAllowAll"
                        rules={[{ required: true, message: t('common.pleaseInput') + t('role.permission') }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>{t('role.allPage')}</Radio>
                            <Radio value={false}>{t('role.selectPage')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => {
                            return form.getFieldValue('isAllowAll') == false ?
                                <Form.Item name="accounts">
                                    {`${count} / ${applicationData.length}`}
                                    <Tabs
                                        defaultActiveKey="0"
                                    >
                                        {appTabList.map((i, index) => {
                                            return (
                                                <TabPane tab={i.fullName} key={i.name}>
                                                    <Card size="small">
                                                        <CheckboxGroup
                                                            defaultValue={checkedAllList}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            onChange={(checkedValue) => { onCheckAllChange(checkedValue, i.name) }}
                                                        >
                                                            <Checkbox value={i.name} >
                                                                {t('common.checkAll')}
                                                            </Checkbox>
                                                        </CheckboxGroup>
                                                        <Tree
                                                            disabled={disabled[i.name]}
                                                            selectable={false}
                                                            defaultExpandAll
                                                            checkable
                                                            checkedKeys={checkedKeysMap[i.name]}
                                                            onCheck={val => onCheck(i.name, val)}
                                                            treeData={applicationTreeData.filter(one => one.application === i.name)}
                                                        />
                                                    </Card>
                                                </TabPane>
                                            )
                                        })}
                                    </Tabs>
                                </Form.Item> : null
                        }}
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    </div>
}
