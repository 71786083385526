import { Form, Input, Row, Col, Select, DatePicker, Spin } from 'antd';
import { useTranslation } from 'react-i18next'
import style from './components.module.css'
import http from '../../../http'
import { useState, useEffect } from 'react';

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function FromDatabase ({ Section, SectionJS }) {
  const { t } = useTranslation();

  const [tableName, setTableName] = useState([])
  const [columnName, setColumnName] = useState([])
  const [GetColumnNameLoading, setGetColumnNameLoading] = useState(false)


  const initDataTable = () => {
    return http.get('/recommendationapi/Recommendation/GetDataTable')
  }

  const getColumns = async (TableName) => {
    setGetColumnNameLoading(true)
    SectionJS.strColumns = ''
    let res = await http.get(`/recommendationapi/Recommendation/GetColumnName?TableName=${TableName}`)
    setGetColumnNameLoading(false)
    setColumnName(res.ColumnData)
  }

  const setSerchParams = (val) => {
    let item = columnName.find(e=>e.columnName === val)
    SectionJS.strTableType = item.TableType
  }

  const saveFeature = (data) => {
    SectionJS.startTimestamp = data[0].format('YYYYMMDD')
    SectionJS.endTimestamp = data[1].format('YYYYMMDD')
  }

  useEffect(() => {
    initDataTable().then(res => {
      setTableName(res.TableName)
      console.log(res)
      // getColumns('Claims')
    })
  }, [])

  return <div className={style.box}>
    <Row justify="space-between">
      <Col span={7}>
        <Form.Item
          name={[Section.name, 'strChartTtile']}
          label={t('Recommendation.ChartTtile')}
          rules={[{ required: true }]}
        >
          <Input placeholder="example" />
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item
          name={[Section.name, 'strDataTable']}
          label={t('Recommendation.DataTable')}
          rules={[{ required: true }]}
        >
          <Select className={style.select}
            allowClear
            placeholder="Please select"
            onChange={getColumns}
          >
            {tableName.map(e => <Option key={e.id} value={e.tableName}>{e.tableName}</Option>)}
          </Select>
        </Form.Item>
      </Col>
      <Col span={7}>
        <Spin spinning={GetColumnNameLoading}>
          <Form.Item
            name={[Section.name, 'strColumns']}
            label={t('Recommendation.Columns')}
            rules={[{ required: true }]}
          >
            <Select className={style.select}
              allowClear
              placeholder="Please select"
              onChange={setSerchParams}
            >
              {columnName.map(e => <Option key={e.id} value={e.columnName}>{e.columnName}</Option>)}
            </Select>
          </Form.Item>
        </Spin>
      </Col>
    </Row>
    <Row justify="space-between">
      {SectionJS.strTableType === '1' && <Col span={11}>
        <Form.Item
          name={[Section.name, 'strDateRange']}
          label={t('Recommendation.DateRange')}
          rules={[{ required: true }]}
        >
          <RangePicker style={{ width: '100%' }} onChange={saveFeature}/>
        </Form.Item>
      </Col>}
    </Row>
  </div>
}