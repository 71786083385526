import { Table, Row, Col, Button, Modal, Upload, Alert, Form, Input, Select, message } from 'antd';
const { Dragger } = Upload;

import { DownloadOutlined, UploadOutlined, InboxOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useDealList } from '../../../data/deal';

import http, { onBlobExport, downloadBlob } from '../../../http';

export const FileUpload = (props) => {
    const { t } = useTranslation();
    const {dealList} = useDealList();
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [previewList, setPreviewList] = useState({})
    const [form] = Form.useForm();
    
    const saveKey = props.saveKey || 'tenantId';
    const columns = [
        { title: t("common.error"), dataIndex: 'error', width: 1200 },
        ...props.columns
    ]
    const previewTable = <Table
        // tableLayout="fixed"
        {...props.tableProps}
        pagination={false}
        rowKey="vin"
        columns={columns}
        dataSource={previewList.errorList} />

    const reset = () => {
        form.resetFields();
        setVisible(false);
        setPreviewList({});
    }
    const downloadTemplate = () => {
        onBlobExport(props.url + '/downloadTemplate', null, { method: props.template.method || 'post' }).then(res => {
            downloadBlob(res.data, props.template.fileName)
        });
    }
    const uploadProps = {
        maxCount: 1,
        name: 'file',
        action: file => Promise.resolve(`/api${props.url}/check?${saveKey}=${form.getFieldValue('deal')}`),
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            'X-Application': window.localStorage.getItem('application') || 'fleet',
        },
        onChange(info) {
            // if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            // }
            if (info.file.status === 'done') {
                const result = info.file.response.data;
                if (!result) {
                    message.error(info.file.response.message);
                    info.file.status = 'error';
                    return ;
                }
                if (!result.errorList.length) {
                    message.success(`${info.file.name} file uploaded successfully`);
                    setFile(info.file.originFileObj)
                }
                setPreviewList(result);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: file => {
            return form.validateFields().then(res => file).catch(res => Upload.LIST_IGNORE)
        },
    };
    const handleOk = () => {
        if (!file) {
            return message.error('Please upload the file first');
        }
        form.validateFields().then(res => {
            setConfirmLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            return http.post(`${props.url}/save?${saveKey}=${form.getFieldValue('deal')}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                reset();
                props.onSubmit && props.onSubmit();
            }).finally(() => setConfirmLoading(false))
        })
        
    }
    return <Row style={{ margin: '12px 0' }}>
        <Button icon={<UploadOutlined />} onClick={() => setVisible(true)}>{t('common.import')}</Button>
        <Modal
            title="Upload"
            width={800}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={reset}
            okButtonProps={{disabled: !file}}
            footer={[
                <Button key="back" icon={<CloseCircleOutlined />} onClick={reset}>
                  {t('common.cancel')}
                </Button>,
                <Button key="submit" icon={<CheckCircleOutlined />} onClick={handleOk}>
                  {t('common.ok')}
                </Button>
              ]}
            destroyOnClose
        >
            <Form form={form} style={{marginBottom: '16px'}}>
                <Form.Item
                    label={t('fields.deal')}
                    name="deal"
                    rules={[{ required: true, message: t('common.pleaseSelect') + t('fields.deal') }]}
                >
                    <Select options={dealList} showSearch optionFilterProp="label"/>
                </Form.Item>
                <Button icon={<DownloadOutlined />}  onClick={downloadTemplate}>{t('common.fileTemplate')}</Button>
            </Form>
            <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                    band files
                </p>
            </Dragger>
            {
                previewList.successList ? <Alert
                    style={{ marginTop: '12px' }}
                    message={`${previewList.successList.length} records uploaded, ${previewList.errorList.length} errors`}
                    description={previewList.errorList.length && previewTable}
                    type="warning"
                /> : null
            }
        </Modal>
    </Row>
}
