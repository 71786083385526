export const generateQuarterOptions = () => {
    const thisYear = new Date().getFullYear();
    const lastYear = thisYear - 1;
    return [...generate(thisYear), ...generate(lastYear)];
}
function generate(year) {
    let ret = [];
    for (let i = 4; i > 0; i--) {
        ret.push({
            label: `${year}Q${i}`,
            value: `${year}Q${i}`
        })
    }
    return ret;
}