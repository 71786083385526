import { Form, Spin, Row, Col, Select } from 'antd';
import { useTranslation } from 'react-i18next'
import http from '../../../../http';
import { useState, useEffect } from 'react';

const { Option } = Select;

const getInitData = () => {
  return http.get('/portfolioapi/Losscorrelationchart/GetInitData')
}

export default function PageLossCorrelationChart ({ Section, SectionJS }) {
  const { t } = useTranslation();

  const [featureData, setFeatureData] = useState([])
  const [valueData, setValueData] = useState([])
  const [paramsLoading, setParamsLoading] = useState(true)

  useEffect(() => {
    getInitData().then(res => {
      setFeatureData(res.Feature)
      setValueData(res.Value)
      setParamsLoading(false)
    })
  }, [])

  const saveValueLabel = (key) => {
    const item = valueData.find(e=>e.id == key) || {}
    SectionJS.serchParams.strValueLabel = item.typeName
  }

  const saveFeatureLabel = (key) => {
    const item = featureData.find(e=>e.id == key) || {}
    SectionJS.serchParams.strFeatureLabel = item.typeName
  }

  return <div>
    <Spin spinning={paramsLoading}>
      <Row justify="space-between">
        <Col span={11}>
          <Form.Item
            name={[Section.name, 'serchParams', 'strFeature']}
            label={t('tmx.feature')}
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              placeholder="Please select"
              onChange={e=>{saveFeatureLabel(e)}}
            >
              {featureData.map(e => <Option key={e.id}>{t(`groupname.${e.typeName}`)}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            name={[Section.name, 'serchParams', 'strValue']}
            label={t('portfolio.value')}
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              placeholder="Please select"
              onChange={e=>{saveValueLabel(e)}}
              >
              {valueData.map(e => <Option key={e.id}>{t(`tabletitle.${e.typeName}`)}</Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Spin>
  </div>
}