import { Spin } from 'antd';
import { useState } from 'react';
import http from '../../../../http';
import { useEffect } from 'react'
import BubbleChart from '../../../RiskAnalysis/components/BubbleChart'
import { cloneDeep } from 'lodash';


export default function PreviewRiskChart ({serchParams}) {
  
  // default dispaly table head
  const [chartDataLoading, setChartDataLoading] = useState(true)
  const [chartData, setChartData] = useState({})


  const getChartData = async () => {
    const params = cloneDeep(serchParams)
    params.strDisPlayAll = params.strPolicy[0] == 1 ? 1 : 0
    let res = await http.get('/vehicleriskapi/VehicleRisk/GetData', params)
    setChartData(res)
    setChartDataLoading(false)
  }

  useEffect(() => {
    getChartData()
  }, [])

  return <div>
    <Spin spinning={chartDataLoading}>
      <BubbleChart chartList={chartData.dtVehicleRiskBubbleChart} groupUUID={serchParams.groupUUID}/>
    </Spin>
  </div>
}

