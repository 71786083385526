import { Table, Card, Spin, Select, Button, Row, Col, Form, DatePicker, Input } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useTable } from '../../../hooks/table';
import { useState } from 'react';
import http, { onBlobExport, downloadBlob } from '../../../http';
import { useTranslation } from 'react-i18next'
import style from './index.module.css'
import { useEffect } from 'react'
import moment from 'moment';
import ChangeColumns from '../components/ChangeColumns'
import {DataCard} from '../../../components/DataCard/card';


export default function ClaimAnalysis () {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const columns = [
    { "title": t.bind(null, 'claimAnalysisTable.PolicyNo'), "dataIndex": "PolicyNo",  "disabled": true, "default": true}, 
    { "title": t.bind(null, 'claimAnalysisTable.Insurer'), "dataIndex": "Insurer",  },
    { "title": t.bind(null, 'claimAnalysisTable.Branch'), "dataIndex": "Branch",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.Deal'), "dataIndex": "Deal",  "disabled": true, "default": true}, 
    { "title": t.bind(null, 'claimAnalysisTable.Period'), "dataIndex": "Period",  "disabled": true, "default": true}, 
    { "title": t.bind(null, 'claimAnalysisTable.VIN'), "dataIndex": "VIN",  "disabled": true, "default": true}, 
    { "title": t.bind(null, 'claimAnalysisTable.PlateNumber'), "dataIndex": "PlateNumber",  "disabled": true, "default": true}, 
    { "title": t.bind(null, 'claimAnalysisTable.ClaimStatus'), "dataIndex": "ClaimStatus",  "disabled": true, "default": true, sorter: { compare: (a, b) => a.ClaimStatus - b.ClaimStatus}}, 
    { "title": t.bind(null, 'claimAnalysisTable.ProportionOfResponsibility'), "dataIndex": "ProportionOfResponsibility",  "disabled": true, "default": true, sorter: { compare: (a, b) => a.ProportionOfResponsibility - b.ProportionOfResponsibility}}, 
    { "title": t.bind(null, 'claimAnalysisTable.TotalLoss'), "dataIndex": "TotalLoss",  "disabled": true, "default": true, sorter: { compare: (a, b) => a.TotalLoss - b.TotalLoss}}, 
    { "title": t.bind(null, 'claimAnalysisTable.LossPaid'), "dataIndex": "LossPaid",  "disabled": true, "default": true, sorter: { compare: (a, b) => a.LossPaid - b.LossPaid}}, 
    { "title": t.bind(null, 'claimAnalysisTable.LossPaidMod'), "dataIndex": "LossPaidMod",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossPaidMed'), "dataIndex": "LossPaidMed",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossPaidVtpl'), "dataIndex": "LossPaidVtpl",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossPaidPdl'), "dataIndex": "LossPaidPdl",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossPaidBil'), "dataIndex": "LossPaidBil",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossPaidTPLOther'), "dataIndex": "LossPaidTPLOther",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossPaidOther'), "dataIndex": "LossPaidOther",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossOutstanding'), "dataIndex": "LossOutstanding",  "disabled": true, "default": true, sorter: { compare: (a, b) => a.LossOutstanding - b.LossOutstanding}}, 
    { "title": t.bind(null, 'claimAnalysisTable.LossOutstandingMod'), "dataIndex": "LossOutstandingMod",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossOutstandingMed'), "dataIndex": "LossOutstandingMed",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossOutstandingVtpl'), "dataIndex": "LossOutstandingVtpl",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossOutstandingPdl'), "dataIndex": "LossOutstandingPdl",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossOutstandingBil'), "dataIndex": "LossOutstandingBil",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossOutstandingTPLOther'), "dataIndex": "LossOutstandingTPLOther",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.LossOutstandingOther'), "dataIndex": "LossOutstandingOther",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.Subrogation'), "dataIndex": "Subrogation",  sorter: { compare: (a, b) => a.Subrogation - b.Subrogation} }, 
    { "title": t.bind(null, 'claimAnalysisTable.ReportDate'), "dataIndex": "ReportDate",  sorter: { compare: (a, b) => a.ReportDate - b.ReportDate}},
    { "title": t.bind(null, 'claimAnalysisTable.IncidentTime'), "dataIndex": "IncidentTime", "disabled": true, "default": true,sorter: { compare: (a, b) => a.IncidentTime - b.IncidentTime} }, 
    { "title": t.bind(null, 'claimAnalysisTable.RegistrationDate'), "dataIndex": "RegistrationDate", sorter: { compare: (a, b) => a.RegistrationDate - b.RegistrationDate} }, 
    { "title": t.bind(null, 'claimAnalysisTable.RegistrationNumber'), "dataIndex": "RegistrationNumber",  "disabled": true, "default": true, sorter: { compare: (a, b) => a.RegistrationNumber - b.RegistrationNumber}}, 
    { "title": t.bind(null, 'claimAnalysisTable.Cause'), "dataIndex": "Cause",  "disabled": true, "default": true}, 
    { "title": t.bind(null, 'claimAnalysisTable.AccidentLocation'), "dataIndex": "AccidentLocation",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.AccidentDescription'), "dataIndex": "AccidentDescription",  },
    { "title": t.bind(null, 'claimAnalysisTable.FlagSinglePartyClaim'), "dataIndex": "FlagSinglePartyClaim",  }, 
    { "title": t.bind(null, 'claimAnalysisTable.FlagBodilyInjuryInvolved'), "dataIndex": "FlagBodilyInjuryInvolved",  },
    { "title": t.bind(null, 'claimAnalysisTable.AsOf'), "dataIndex": "AsOfDate",  }
  ]
  // default dispaly table head
  const defaultColumns = columns.filter(e => e.default)
  // dynamic regulation table head
  const [column, setColumns] = useState(defaultColumns)


  // default dispaly table head
  const [initLoading, setInitLoading] = useState(true)
  const [selectOptionsData, setSelectOptionsData] = useState({})
  const [titleData, setTitleData] = useState({})
  const [downExclParams, setDownExclParams] = useState({})

  // dynamic regulation table head
  const { tableData, pagination, loading, setQuery, handleTableChange } = useTable(getData);

  const getInit = () => {
    return http.get('/portfolioapi/ClaimAnalysis/GetInitData')
  }

  // get table date
  async function getData (param) {
    let res = await http.get('/portfolioapi/ClaimAnalysis/GetData', {
      ...param,
      PageNum: pagination.current,
      PageSize: pagination.pageSize
    })
    const { ClaimNo, Severity, TotalLoss, ...tableData } = res
    setTitleData({ ClaimNo, Severity, TotalLoss })
    return tableData
  }

  const onValuesChange = async (e) => {
    const row = (await form.validateFields())
    // let params = ''
    // Object.keys(row).forEach(e=>{
    //   if(row[e]){
    //     params += `&${e}=${row[e]}`
    //   }
    // })
    setDownExclParams(row)
  }

  const onFormSearch = (e) => {
    const { strInsurer, strTenant, strLossRangFrom, strLossRangTo, strLossDate } = e
    let strLossDateFrom = strLossDate ? moment(strLossDate[0]).format('YYYYMMDD') : ''
    let strLossDateTo = strLossDate ? moment(strLossDate[1]).format('YYYYMMDD') : ''
    let params = {
      strInsurer,
      strTenant,
      strLossRangFrom,
      strLossRangTo,
      strLossDateFrom,
      strLossDateTo
    }
    setQuery(params)
    console.log(e)
  }

  useEffect(() => {
    setInitLoading(true)
    getInit().then(res => {
      setInitLoading(false)
      setSelectOptionsData(res)
    })
  }, [])

  const exportExcl = () => {
    let params = {
      la: localStorage.getItem('language'),
      ...downExclParams
    }
    onBlobExport('/portfolioapi/ClaimAnalysis/GetExcel', params, { 'method': 'get' }).then(res => {
      downloadBlob(res.data, 'Claim Analysis')
    });
  }

  const formatPrice = num => {
    if (!num) return '-';
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
  }

  const { Option } = Select;
  const { RangePicker } = DatePicker;
  return <div>
    <div className={style.titleBox}>
      <span className={style.title}>{t('portfolio.ClaimAnalysis')}</span>
    </div>
    <Spin spinning={initLoading}>
      <Form layout="vertical" onFinish={onFormSearch} form={form} onValuesChange={onValuesChange}>
        <Row gutter={[8]}>
          <Col className="gutter-row" span={4}>
            <Form.Item
              name="strInsurer"
              label={t('groupname.Insurer')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.InsurerData && selectOptionsData.InsurerData.map(e => <Option key={e.typeName} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={4}>
            <Form.Item
              name="strTenant"
              label={t('tabletitle.Tenant')}
            >
              <Select
                placeholder="Select"
                allowClear
              >
                {selectOptionsData.TenantData && selectOptionsData.TenantData.map(e => <Option key={e.typeName} value={e.typeName}>{e.typeName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item
              name="strLossDate"
              label={t('groupname.IncidentTime')}
            >
              <RangePicker className={style.date} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              name='strLossRangFrom'
              label={t('groupname.LossRange')}
              style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            >
              <Input style={{ textAlign: 'center' }} placeholder="Minimum" />
            </Form.Item>
            <span
              style={{ display: 'inline-block', width: '12px', marginTop: '28px', lineHeight: '32px', textAlign: 'center' }}
            >
              ~
            </span>
            <Form.Item
              name='strLossRangTo'
              style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
              label=' '
            >
              <Input
                className="site-input-right"
                style={{
                  textAlign: 'center',
                }}
                placeholder="Maximum"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <div style={style} className={style.btnbox}>
              <Button className={style.submitbtn} htmlType="submit"><SearchOutlined />{t('common.search')}</Button>
              <Button className={style.exportbtn} onClick={exportExcl}><DownloadOutlined />{t('common.export')}</Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
    <Row style={{marginBottom: "12px"}}>
      <Col span={5}><DataCard title={t('portfolio.ClaimNo')}  num={titleData.ClaimNo}></DataCard></Col>
      <Col span={11}><DataCard title={t('portfolio.TotalLoss') + ` (${t('units.CNY')})`} num={formatPrice(titleData.TotalLoss)}></DataCard></Col>
      <Col span={8}><DataCard title={t('tabletitle.Severity') + ` (${t('units.CNY')})`} num={formatPrice(titleData.Severity)}></DataCard></Col>
    </Row>
    <div style={{ float: 'right' }}><ChangeColumns Columns={columns} changeColumns={setColumns} /></div>
    <Table pagination={pagination} scroll={{ x: column.length * 150 }} onChange={handleTableChange} columns={column} loading={loading} dataSource={tableData} />
  </div>
}