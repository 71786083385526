import { useEffect, useState } from "react";
import http from "../http";

export function useApplication(token) {
    const [applications, setApplications] = useState([]);
    const [activeApp, setActiveApp] = useState('');
    const [loading, setLoading] = useState(false);
    const setLocal = (data) => {
        const local = localStorage.getItem('application');

        if (local) {
            const current = data.find(one => one.name === local);
            if (current) {
                setActiveApp(current);
            } else {
                setActiveApp(data[0])
                localStorage.setItem('application', data[0].name);
            }
        } else {
            setActiveApp(data[0])
            localStorage.setItem('application', data[0].name);
        }
    }
    useEffect(() => {
        if (activeApp?.fullName) {
            document.getElementsByTagName("title")[0].innerText = activeApp.fullName
        }
    }, [activeApp])
    useEffect(() => {
        if (!token) return;
        setLoading(true);
        http.get('/aaa/User/applications').then(res => {
            setApplications(res.data)
            console.log(111)
            setLocal(res.data);
        }).finally(e => {
            setLoading(false);
        })
    }, [token])
    return {
        loading,
        applications,
        activeApp
    }
}