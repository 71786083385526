import { Form, Select, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next'
import { useState } from 'react';

import {useVendorList} from '../../../PartnerEvaluation/data';

export default function PagePartnerEvaluation ({ Section, SectionJS }) {
  const { t } = useTranslation();

  const {vendorList} = useVendorList();

  return <div>
    <Row>
      <Col span={11}>
        <Form.Item 
          label={t('partner.partner')} 
          name={[Section.name, 'serchParams', 'partnerName']}
        >
          <Select allowClear showSearch>
            {
              vendorList.map(vendorName => <Select.Option key={vendorName}>{vendorName}</Select.Option>)
            }
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </div>
}